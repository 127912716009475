import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import { Flex, Spacer } from '../../components/atoms';
import { Grid } from '../../components/molecule';
import { useAuthentication } from '../../hooks';
import AddressField from './AddressField';
const PrimaryTextField = withStyles(theme => ({
  root: {
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& input': {
      color: '#ffffff',
    },
    '& input:valid + fieldset': {
      borderColor: theme.palette.primary.main + '!important',
      borderWidth: 2,
    },
  },
}))(TextField);

const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const Form = () => {
  const history = useHistory();
  const firestore = useFirestore();
  const { userId } = useAuthentication();
  const [type, setType] = useState<string>();
  const [name, setName] = useState<string>('');
  const [adminName, setAdminName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [fbpage, setFbpage] = useState<string>('');
  const [igpage, setIgpage] = useState<string>('');
  const [error, setError] = useState<string>();
  // const [slug, setSlug] = useState<string>();

  const isValid = useMemo(() => {
    return (
      type &&
      name &&
      adminName &&
      phone &&
      email &&
      address &&
      city &&
      website &&
      fbpage &&
      igpage
    );
  }, [
    type,
    name,
    adminName,
    phone,
    email,
    address,
    city,
    website,
    fbpage,
    igpage,
  ]);

  const findSlug = useCallback(
    async (title: string, collection: string, increase = 0): Promise<any> => {
      const slug = title
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
      const findAlias = await firestore
        .collection(collection)
        .where('slug', '==', slug + (increase ? '-' + increase : ''))
        .get();
      if (findAlias.size) {
        increase++;
        return await findSlug(title, collection, increase);
      } else {
        return slug + (increase ? '-' + increase : '');
      }
    },
    [firestore],
  );

  const handleRegistration = useCallback(async () => {
    const collection = type === 'Club' ? 'clubs' : 'organizations';
    const slug = await findSlug(name, collection);
    if (!isValid) {
      setError('Tutti i campi sono obbligatori');
      return;
    }

    const res = await firestore.add(collection, {
      name,
      admin: adminName,
      createdBy: userId,
      phone,
      email,
      address,
      city,
      slug,
      website,
      fbpage,
      igpage,
    });
    if (res.id) {
      history.push('/thank-you');
    }
  }, [
    isValid,
    type,
    name,
    adminName,
    userId,
    phone,
    email,
    address,
    city,
    website,
    fbpage,
    igpage,
    firestore,
    findSlug,
    history,
  ]);

  return (
    <Grid>
      <Flex alignItems="center" justifyContent="space-between" w={12}>
        <Typography style={{ color: 'white' }}>Seleziona chi sei</Typography>
        <FormControlLabel
          style={{ color: '#fff' }}
          color="primary"
          checked={type === 'Club'}
          onChange={(_, checked) => setType(checked ? 'Club' : 'Organizzatore')}
          control={<WhiteCheckbox />}
          label="Club"
        />
        <FormControlLabel
          style={{ color: '#fff' }}
          color="primary"
          checked={type === 'Organizzatore'}
          onChange={(_, checked) =>
            setType(!checked ? 'Club' : 'Organizzatore')
          }
          control={<WhiteCheckbox />}
          label="Organizzatore"
        />
        <Spacer width="100px" />
      </Flex>
      {type ? (
        <PrimaryTextField
          label={`Nome ${type}`}
          color="primary"
          variant="outlined"
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
          // @ts-ignore
          w={12}
        />
      ) : (
        <div />
      )}

      <Spacer height="20px" w={12} />
      <PrimaryTextField
        label="Nome e cognome"
        color="primary"
        variant="outlined"
        autoComplete="none"
        fullWidth
        value={adminName}
        onChange={e => setAdminName(e.target.value)}
        // @ts-ignore
        w={12}
      />
      <PrimaryTextField
        label="N. di telefono - Informazione riservata"
        autoComplete="none"
        color="primary"
        variant="outlined"
        fullWidth
        value={phone}
        onChange={e => setPhone(e.target.value)}
        // @ts-ignore
        w={12}
      />
      <PrimaryTextField
        label="E-mail diretta"
        autoComplete="none"
        color="primary"
        variant="outlined"
        fullWidth
        value={email}
        onChange={e => setEmail(e.target.value)}
        // @ts-ignore
        w={12}
      />

      <AddressField
        inputValue={city}
        setInputValue={setCity}
        label="Città"
        type="city"
        // @ts-ignore
        w={12}
      />
      <PrimaryTextField
        label={'Indirizzo'}
        autoComplete="none"
        color="primary"
        variant="outlined"
        fullWidth
        value={address}
        onChange={e => setAddress(e.target.value)}
        // @ts-ignore
        w={12}
      />

      <PrimaryTextField
        label="Sito Internet"
        autoComplete="none"
        color="primary"
        variant="outlined"
        fullWidth
        value={website}
        onChange={e => setWebsite(e.target.value)}
        // @ts-ignore
        w={12}
      />
      <PrimaryTextField
        label="Link completo alla pagina Facebook"
        autoComplete="none"
        color="primary"
        variant="outlined"
        fullWidth
        value={fbpage}
        onChange={e => setFbpage(e.target.value)}
        // @ts-ignore
        w={12}
      />
      <PrimaryTextField
        label="Link completo alla pagina Instagram"
        autoComplete="none"
        color="primary"
        variant="outlined"
        fullWidth
        value={igpage}
        onChange={e => setIgpage(e.target.value)}
        // @ts-ignore
        w={12}
      />
      <Flex w={12} justifyContent="flex-end">
        <Typography variant="body2" style={{ color: 'white' }} align="right">
          Tutti i campi sono obbligatori
        </Typography>
      </Flex>
      <Spacer height="20px" w={12} />
      <Flex w={12} justifyContent="start">
        <Typography style={{ color: 'white' }}>
          Attivando le funzioni di importazione automatica da il consenso a
          tilllate.world di importare in modo automatizzato eventi e/o foto
          dalla vostra pagina facebook per aumentare la portata della pubblicità
          fatta in modo gratuito ai vostri eventi.
        </Typography>
      </Flex>
      <Flex w={12} justifyContent="start">
        <Typography style={{ color: 'red' }}>{error && error}</Typography>
      </Flex>

      <Flex w={12} justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleRegistration}
          // disabled={!isValid}
        >
          Registrati
        </Button>
      </Flex>
    </Grid>
  );
};

export default Form;
