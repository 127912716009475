import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEvent,
  useGuestListRegistrations,
  useMedia,
  useOrganizations,
} from '../../../../../hooks';
import { GuestList } from '../../../../../store/types';
import { PdfGuestListData } from '../types/PdfGuestListData';

type Props = {
  guestList: GuestList;
};

type usePdfCreation = {
  generatePdfData: () => PdfGuestListData;
};

export const usePdfCreation = (props: Props) => {
  const { guestList } = props;
  const { event } = useEvent({ id: guestList.eventId });
  const { getRegistrationsByGuestList } = useGuestListRegistrations({
    guestListId: guestList.id || '',
  });
  const { getEncodedMedia } = useMedia();
  const { findOrganizations } = useOrganizations();
  const { t } = useTranslation();
  const { venue } = event || {};

  const generatePdfData = useCallback(async () => {
    const guestListRegistration = getRegistrationsByGuestList(guestList.id!);
    let organizations: { logoImage: unknown | undefined; name: string }[] = [];
    if (event?.organizations) {
      const orgs = findOrganizations(Object.keys(event.organizations));
      orgs.forEach(async org => {
        if (org[1]?.logoUrl) {
          const logoImage = await getEncodedMedia(org[1].logoUrl);
          organizations.push({ logoImage, name: org[1].name });
        } else {
          organizations.push({ logoImage: undefined, name: org[1].name });
        }
      });
    }
    const eventCoverBase64 = event?.coverUrl
      ? await getEncodedMedia(event?.coverUrl)
      : undefined;
    const venueLogoBase64 = venue?.logoUrl
      ? await getEncodedMedia(venue?.logoUrl)
      : undefined;
    const pdfData = {
      eventCoverBase64,
      venueLogoBase64,
      organizations,
      guestListRegistration,
      venueName: venue?.name ?? '',
      clubInfo: t('event.clubInfo'),
      organizationsInfo: t('organization.info'),
      benefitText: t('guestList.benefits'),
      benefits: guestList?.benefits,
      conditionsText: t('guestList.conditions'),
      conditions: guestList?.conditions,
      date: event?.date,
      eventName: event?.name,
      guestListName: guestList?.name,
      registeringPerson: t('guestList.registeringPerson'),
      guests: t('guestList.guest'),
    };
    return pdfData;
  }, [
    guestList,
    event,
    getEncodedMedia,
    getRegistrationsByGuestList,
    t,
    venue,
    findOrganizations,
  ]);

  return {
    generatePdfData,
  };
};
