import { Event, NewsItem, Venue } from '../../store/types';

const filterAbuseReports = async (
  el: NewsItem[] | Event[] | Venue[],
  currentUser?: string,
) => {
  if (!currentUser) {
    return el;
  } else {
    let newList: any[] = [];
    el.forEach((item: any) => {
      if (!item.reported_by) {
        newList.push(item);
      } else {
        if (!item.reported_by.includes(currentUser)) {
          newList.push(item);
        }
      }
    });
    return newList;
  }
};

const filterAbuseReportsSync = (
  el: NewsItem[] | Event[] | Venue[],
  currentUser?: string,
) => {
  if (!currentUser) {
    return el;
  } else {
    let newList: any[] = [];
    el.forEach((item: any) => {
      if (!item.reported_by) {
        newList.push(item);
      } else {
        if (!item.reported_by.includes(currentUser)) {
          newList.push(item);
        }
      }
    });
    return newList;
  }
};

const useAbuseFilter = {
  filterAbuseReports: filterAbuseReports,
  filterAbuseReportsSync: filterAbuseReportsSync,
};

export default useAbuseFilter;
