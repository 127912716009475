import React from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { sortObjectByValues } from '../../../utils/ui';
import { GuestListRegistration } from '../../../store/types';
import useStyle from './styles';

type Props = {
  guestListRegistration: GuestListRegistration;
  onAccessedGuest: (
    guestListRegistration: GuestListRegistration,
    guestName: string,
  ) => void;
};

const RegisterEventGuestDetails: React.FC<Props> = ({
  guestListRegistration,
  onAccessedGuest,
}) => {
  const styles = useStyle();
  return (
    <List className={styles.list}>
      {sortObjectByValues(guestListRegistration.guests, 'name').map(
        (guest, ind) => {
          return (
            <ListItem
              key={guest.name + ind}
              className={styles.listItem}
              button
              onClick={() => onAccessedGuest(guestListRegistration, guest.name)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={guest.accessed}
                />
              </ListItemIcon>
              <ListItemText
                className={styles.listItemText}
                primary={guest.name}
              />
            </ListItem>
          );
        },
      )}
    </List>
  );
};

RegisterEventGuestDetails.displayName = 'RegisterEventGuestDetails';

export default React.memo(RegisterEventGuestDetails);
