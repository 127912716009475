import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { Container } from './styles';

const Contact = () => {
  return (
    <PageWrapper>
      <Container>
        <h1>Contatti</h1>
        <p>Till late Holding 2</p>
        <p>sagl Via Livio 8 Hanomaghof</p>
        <p>6830 Chiasso Swiss</p>
        <a href="mailto:it@tilllate.world">it@tilllate.world</a>
        <p className="info">*Per informazioni</p>

        <h1>Supporto</h1>
        <a href="mailto:support@tilllate.world">support@tilllate.world</a>
        <p className="info">*Per segnalare abusi ed altri utenti</p>
      </Container>
    </PageWrapper>
  );
};

export default Contact;
