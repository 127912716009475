import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  entranceButton: {
    borderRadius: '100px',
    border: 0,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '1px',
    color: 'white',
    padding: '12px',
    boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .3)',
  },
  avatar: {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: '10px',
  },
  smallDisclaimer: {
    fontSize: 10,
    marginTop: '5px',
  },
  signContainer: {
    '&:not(:last-child)': {
      marginRight: '25px',
    },
  },
  signs: {
    boxShadow:
      'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
    borderRadius: '50%',
    width: 70,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lastSign: {
    boxShadow:
      'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
    borderRadius: '50%',
  },
  selectedSign: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  signHelperText: {
    fontSize: '15px',
    letterSpacing: '1px',
    marginTop: '4px',
  },
  emoji: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
  fontSize10: {
    fontSize: 10,
  },
}));
