import { select, put } from 'redux-saga/effects';
import { Firestore } from '../../../rrf.config';

export function* populateSaga(
  action: any,
  root: string,
  child: string,
  fsCollection?: string,
) {
  const missingDocs = new Set<string>();
  const data = yield select(state => state.firestore.data[root]);
  const ordered = yield select(state => state.firestore.ordered[root]);

  for (let item of action.payload.ordered) {
    if (item[child]) {
      if (!data || !data?.[item[child]]) {
        missingDocs.add(item[child]);
      }
    }
  }
  const newData = {};
  const newOrdered = [];
  for (let missingDoc of [...Array.from(missingDocs)]) {
    const ref = yield Firestore.collection(fsCollection || root)
      .doc(missingDoc)
      .get();
    const doc = yield ref.data();
    newOrdered.push({ ...doc, id: missingDoc });
    newData[missingDoc] = doc;
  }

  yield put({
    type: '@@reduxFirestore/GET_SUCCESS',
    meta: {
      collection: root,
    },
    payload: {
      data: {
        ...(data || {}),
        ...newData,
      },
      ordered: [...(ordered || []), ...newOrdered],
    },
    merge: {
      docs: true,
      collections: true,
    },
  });
}
