import { Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import useStyle from './style';

type Props = {
  date: string;
  hideTime?: boolean;
};

const DateWidget: React.FC<Props> = ({ date, hideTime }) => {
  const day = date && dayjs(date).format('DD');
  const month = date && dayjs(date).format('MMM');
  const hour = date && dayjs(date).format('HH:mm');
  const style = useStyle();
  return (
    <Box className={style.date}>
      <Typography className={style.day}>{day}</Typography>
      <Typography className={style.month}>{month}</Typography>
      {!hideTime && <Typography className={style.hour}>{hour}</Typography>}
    </Box>
  );
};

DateWidget.displayName = 'DateWidget';

export default React.memo(DateWidget);
