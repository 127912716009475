import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '../../../hooks';
import { FilterCheckboxType } from '../../../store/types';
import CheckboxDialog from './CheckboxDialog';
import { sortCheckboxOptionsBySelectedValues } from './sortCheckboxOptionsBySelectedValues';
import useStyles from './styles';

type Props = {
  state: FilterCheckboxType;
  setState: React.Dispatch<React.SetStateAction<{}>>;
  title: string;
  visibleOptionsNumber?: number;
  btnSize?: 'small' | 'medium';
  oneItemInOneLine?: boolean;
  showDialogAboveNumberOptions?: number;
};

const FilterOptions: React.FC<Props> = ({
  state,
  setState,
  title,
  visibleOptionsNumber = 4,
  btnSize = 'small',
  oneItemInOneLine = true,
  showDialogAboveNumberOptions = 20,
}) => {
  const [showMore, setShowMore] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isTabletOrMobile } = useMediaQuery();
  const [options, setOptions] = useState(state);

  useEffect(() => {
    setOptions(sortCheckboxOptionsBySelectedValues(state));
  }, [state]);

  const renderCheckBox = useCallback(
    (key: string, label: string) => {
      return oneItemInOneLine ? (
        <Box key={key + Math.random()}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                size={btnSize}
                checked={state?.[key]?.value}
                onChange={() => {
                  setState({
                    ...state,
                    [key]: {
                      ...state?.[key],
                      value: !state?.[key].value,
                    },
                  });
                }}
              />
            }
            label={label}
          />
        </Box>
      ) : (
          <FormControlLabel
            key={key + Math.random()}
            control={
              <Checkbox
                color="primary"
                size={btnSize}
                checked={state?.[key].value}
                onChange={() => {
                  setState({
                    ...state,
                    [key]: {
                      ...state?.[key],
                      value: !state?.[key].value,
                    },
                  });
                }}
              />
            }
            label={label}
          />
        );
    },
    [btnSize, oneItemInOneLine, setState, state],
  );

  return (
    <>
      {Object.keys(options)?.length ? (
        <>
          {Object.entries(options)
            .slice(0, visibleOptionsNumber)
            .map(([key, value]: [string, { value: boolean; name: string }]) => {
              return renderCheckBox(key, value.name);
            })}
          {Object.keys(options).length === visibleOptionsNumber &&
            isTabletOrMobile && (
              <Box className={classes.collapseInfo}>&nbsp;</Box>
            )}
          {Object.keys(options).length > visibleOptionsNumber && (
            <>
              {!showMore && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  component="span"
                  onClick={
                    Object.keys(options).length >=
                      showDialogAboveNumberOptions || isTabletOrMobile
                      ? () => setDialogOpen(true)
                      : () => setShowMore(true)
                  }
                  className={classes.collapseInfo}
                >
                  {t('shared.showMore')}
                </Box>
              )}
              <Collapse in={showMore}>
                {Object.entries(options)
                  .slice(visibleOptionsNumber)
                  .map(
                    ([key, value]: [
                      string,
                      { value: boolean; name: string },
                    ]) => {
                      return renderCheckBox(key, value.name);
                    },
                  )}
                {showMore && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    component="span"
                    onClick={() => setShowMore(false)}
                    className={classes.collapseInfo}
                  >
                    {t('shared.showLess')}
                  </Box>
                )}
              </Collapse>
              <CheckboxDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                options={options}
                renderCheckBox={renderCheckBox}
                visibleOptionsNumber={showDialogAboveNumberOptions}
                title={title}
              />
            </>
          )}
        </>
      ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="primary" size="small" />
          </Box>
        )}
    </>
  );
};

FilterOptions.displayName = 'FilterOptions';

export default React.memo(FilterOptions);
