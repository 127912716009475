import { combineReducers } from 'redux';
import {
  FirebaseReducer,
  firebaseReducer,
  FirestoreReducer,
} from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { DateRoom } from '../types/dateRooms';
import { Profile } from '../types';
import { configurationReducer } from './configurations/rootReducer';
import { tilldateConfigurationReducer } from './tilldateConfigurations/rootReducer';

export type RootState = {
  firebase: FirebaseReducer.Reducer<Profile>;
  firestore: FirestoreReducer.Reducer;
  configuration: {
    region:
    | {
      id: string;
      coords: string;
      img: string;
    }
    | null
    | undefined;
    inAppHistory: string[];
  };
  tilldateConfiguration: {
    selectedRoom: DateRoom | undefined;
  };
};
export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  configuration: configurationReducer,
  tilldateConfiguration: tilldateConfigurationReducer,
});
