import imageCompression from 'browser-image-compression';
import { useCallback, useState } from 'react';

type Props = {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
};

type UseCompressedImage = [
  File[] | string,
  React.Dispatch<File[] | string>,
  (files: File[]) => void,
  number,
];

type UseCompressedImages = [
  (File | string)[],
  React.Dispatch<(File | string)[]>,
  (files: File[]) => void,
  number,
];

export const useCompressedImage = ({
  maxSizeMB = 0.8,
  maxWidthOrHeight = 1920,
}: Props = {}): UseCompressedImage => {
  const [image, setImage] = useState<File[] | string>([]);
  const [progress, setProgress] = useState<number>(0);

  const setAndCompress = useCallback(
    async (files: File[]) => {
      const options = {
        maxSizeMB,
        maxWidthOrHeight,
        useWebWorker: true,
        onProgress: (p: any) => setProgress(p),
      };
      const compressed = await imageCompression(files[0], options);
      setProgress(0);
      setImage([compressed as any]);
    },
    [maxSizeMB, maxWidthOrHeight],
  );

  return [image, setImage, setAndCompress, progress];
};

export const useCompressedImages = ({
  maxSizeMB = 0.8,
  maxWidthOrHeight = 1920,
}: Props = {}): UseCompressedImages => {
  const [images, setImages] = useState<(File | string)[]>([]);
  const [progress, setProgress] = useState<number>(0);

  const setAndCompress = useCallback(
    async (files: File[]) => {
      const options = {
        maxSizeMB,
        maxWidthOrHeight,
        useWebWorker: true,
      };
      const compressed = [];

      for (let file of files) {
        if (typeof file !== 'string') {
          const compressedFile = await imageCompression(file, options);
          compressed.push(compressedFile);
          setProgress((compressed.length / files.length) * 100);
        } else {
          compressed.push(file);
        }
      }
      setImages(compressed);
      setProgress(0);
    },
    [maxSizeMB, maxWidthOrHeight],
  );

  return [images, setImages, setAndCompress, progress];
};
