import React, { useEffect, useState } from 'react';
import {
  Grid,
  Divider,
  Fab,
  List,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { LabeledBox } from '../../molecule';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { HtmlDecode } from '../../atoms';
import { Event, GuestListRegistration } from '../../../store/types';
import {
  useGlobalStyles,
  useGuestList,
  useGuestListRegistrations,
  useGuestLists,
} from '../../../hooks';
import GuestList from './GuestList';
import useStyle from './styles';
import CreateGuestListDialog from './CreateGuestListDialog/CreateGuestListDialog';

type Props = {
  event: Event;
  isAdmin?: boolean;
  match: {
    params: { [key: string]: string };
  };
};

const GuestLists: React.FC<Props> = ({ event, isAdmin, match }) => {
  const [createDialog, toggleCreateDialog] = useToggleState(false);
  const { guestListsList } = useGuestLists({ event });
  const { registerGuestList } = useGuestList();
  const { getUserExistingRegistrationsByEvent } = useGuestListRegistrations({
    myRegistrations: true,
  });
  const style = useStyle();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [existingRegistrations, setExistingRegistrations] = useState<
    GuestListRegistration[]
  >([]);

  const hoursToEvent =
    (new Date(event?.date).getTime() - new Date().getTime()) / 3600000;

  const canRegisterGuestList = hoursToEvent > 3;

  useEffect(() => {
    if (event && event?.id) {
      setExistingRegistrations(getUserExistingRegistrationsByEvent(event.id));
    }
  }, [getUserExistingRegistrationsByEvent, event]);

  const onSubmit = async (values: any) => {
    registerGuestList({ ...values, eventId: event.id });
  };

  return (
    <LabeledBox label="GuestLists">
      <>
        <div id="guestlist" />
        {!!existingRegistrations.length && (
          <Grid item md={12} xs={12}>
            <Divider className={globalStyle.mv2} />
            <Typography
              color="error"
              align="center"
              className={globalStyle.textSmall}
            >
              <HtmlDecode>
                {t('guestList.alreadyRegistered', {
                  guestListName: existingRegistrations[0].guestListName,
                  userName: existingRegistrations[0].name,
                  guests: existingRegistrations[0].guests
                    .map(guest => guest.name)
                    .join(', '),
                })}
              </HtmlDecode>
            </Typography>
          </Grid>
        )}
        <List>
          {guestListsList.map(guestList => (
            <GuestList
              registeredToDifferentList={existingRegistrations.length > 0}
              isRegistered={
                existingRegistrations?.[0]?.guestListId === guestList.id
              }
              key={guestList.id}
              guestList={guestList}
              isAdmin={isAdmin}
              match={match}
              canRegisterGuestList={canRegisterGuestList}
            />
          ))}
          {isAdmin && !guestListsList.length && (
            <Typography align="center">
              No GuestList created for your event yet,{' '}
              <Typography
                component="span"
                color="primary"
                onClick={toggleCreateDialog}
                className={globalStyle.textLink}
              >
                click here to create it now
              </Typography>
            </Typography>
          )}
        </List>
        {isAdmin && canRegisterGuestList && (
          <Tooltip title={t('guestList.createGuestList')!}>
            <Fab
              onClick={toggleCreateDialog}
              size="small"
              className={style.fab}
              color="primary"
            >
              <PostAdd />
            </Fab>
          </Tooltip>
        )}
        <CreateGuestListDialog
          open={createDialog}
          onClose={toggleCreateDialog}
          onSubmit={onSubmit}
        />
      </>
    </LabeledBox>
  );
};

GuestLists.displayName = 'GuestLists';

export default React.memo(GuestLists);
