import React from 'react';
import { IonList, IonItem, IonIcon, IonRippleEffect } from '@ionic/react';
import shortid from 'shortid';
import { useMenuList } from '../../../hooks';

type Props = {};

const MainMenu: React.FC<Props> = () => {
  const actions = useMenuList();
  return (
    <IonList>
      {actions.map(action => (
        <IonItem
          hidden={action.hidden}
          key={shortid.generate()}
          onClick={action.handler}
          className="ion-activatable ripple-parent"
        >
          <>
            <IonIcon icon={action.icon} slot="start" />
            <span>{action.text}</span>
            <IonRippleEffect type="unbounded" />
          </>
        </IonItem>
      ))}
    </IonList>
  );
};

MainMenu.displayName = 'MainMenu';

export default React.memo(MainMenu);
