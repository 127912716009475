import React from 'react';
import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { DashboardHeader } from '../../components/molecule';
import logo from '../../assets/img/logo_w.png';
import bg from '../../assets/img/bg-business.jpg';
import { Flex } from '../../components/atoms';
import { useAuthentication } from '../../hooks';
import TextArea from './TextArea';
import Form from './Form';

const RegisterBusiness = () => {
  const theme = useTheme();
  const history = useHistory();
  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/business');
    }
  }, [isAuthenticated, history]);

  return (
    <div className="container" style={{ backgroundColor: 'black' }}>
      <DashboardHeader logo={logo} hideMenu />
      <Flex alignItems="start" w={12} flexDirection="column">
        <TextArea />
      </Flex>
      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          height: 5,
          width: '100%',
          margin: '50px 0 0',
        }}
      />
      <div
        style={{
          paddingTop: 50,
          paddingLeft: 50,
          paddingRight: 50,
          margin: '0 auto',
          paddingBottom: 100,
          background: `url(${bg}) top center`,
          backgroundSize: '500px',
        }}
      >
        <Form />
      </div>
    </div>
  );
};

export default RegisterBusiness;
