import { ActionType } from 'typesafe-actions';
import { RootState } from '..';
import { selectRoom } from '../../actions/tilldateConfiguration/selectRoom';

export const selectRoomCase = (
  state: RootState['tilldateConfiguration'],
  action: ActionType<typeof selectRoom>,
): RootState['tilldateConfiguration'] => ({
  ...state,
  selectedRoom: action.payload,
});
