import { Chip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles } from '../../../hooks';
import { Event } from '../../../store/types';
import { DateWidget } from '../../molecule';
import CardListWidget from '../../molecule/CardListWidget/CardListWidget';

type Props = {
  event: Event;
  disableCover?: boolean;
};

const MyEventItem: React.FC<Props> = ({ event, disableCover }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const currentDate = new Date().toString();

  return (
    <CardListWidget
      onClick={() => history.push(`/events/${event?.slug}`)}
      renderLogo={<DateWidget date={event?.date} hideTime />}
      coverUrl={event?.coverUrl}
      disableCover={disableCover}
      title={event?.name}
      subtitle={event?.venue?.name}
      cardActions={[
        {
          type: 'primary',
          label: Date.parse(event.date) > Date.parse(currentDate) ? t('guestList.registerGuests') : t('event.viewEvent'),
          onClick: () => Date.parse(event.date) > Date.parse(currentDate) ? history.push(`/register-guests/${event?.slug}`) : history.push(`/events/${event?.slug}`),
        },
        {
          type: 'secondary',
          label: t('shared.edit'),
          onClick: () => history.push(`/edit-events/${event?.slug}`),
        },
      ]}
    >
      {!!event?.guestLists?.length && (
        <Chip
          component="span"
          label={`${event?.guestLists?.length ?? 0} GuestLists`}
          variant="outlined"
          size="small"
          className={globalStyle.mr1}
        />
      )}
    </CardListWidget>
  );
};

MyEventItem.displayName = 'MyEventItem';

export default React.memo(MyEventItem);
