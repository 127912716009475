import React, { useEffect, useState } from 'react';
import { Avatar, Typography } from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import { useAuthentication, useGlobalStyles } from '../../../hooks';
import UpdateProfilePicture from '../UpdateProfilePicture';
import useStyles from './styles';

const UserAvatar: React.FC = () => {
  const firebase = useFirebase();
  const globalStyle = useGlobalStyles();
  const [visibleButton, setVisibleButton] = useState<boolean>(false);
  const { initials, profilePicture } = useAuthentication();
  const [photo, setPhoto] = useState<string>();
  const [file, setFile] = useState<File | null>();
  const classes = useStyles({ photo });

  useEffect(() => {
    if (file && URL.createObjectURL(file)) {
      setPhoto(URL.createObjectURL(file));
      return;
    }
    setPhoto(profilePicture);
  }, [firebase, file, profilePicture]);

  return (
    <div
      className={[
        globalStyle.size100,
        globalStyle.relative,
        globalStyle.overflowHidden,
        globalStyle.borderPrimary,
        globalStyle.rounded,
      ].join(' ')}
      onMouseOver={() => setVisibleButton(true)}
      onMouseOut={() => setVisibleButton(false)}
    >
      <Avatar
        className={[
          globalStyle.size100,
          globalStyle.square,
          photo || profilePicture ? classes.avatar : undefined,
        ].join(' ')}
        src={photo || profilePicture}
        variant="square"
      >
        <Typography variant="h2" className={globalStyle.bold}>
          {initials}
        </Typography>
      </Avatar>
      <UpdateProfilePicture
        file={file}
        setFile={setFile}
        visible={visibleButton}
      />
    </div>
  );
};

UserAvatar.displayName = 'UserAvatar';

export default React.memo(UserAvatar);
