import React from 'react';

type Props = {
  width?: string | number;
  height?: string | number;
  videoUri: string;
  title: string;
};

const YoutubeIFrame: React.FC<Props> = ({
  width = '100%',
  height = 315,
  videoUri,
  title,
}) => {
  return (
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${videoUri}`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

YoutubeIFrame.displayName = 'YoutubeIFrame';

export default React.memo(YoutubeIFrame);
