import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCompressedImage,
  useGlobalStyles,
  useOrganization,
  useAuthentication,
  useVerification,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import OrganizationForm from '../OrganizationForm';
import { Form } from '../OrganizationForm/OrganizationForm';
import { LoadingCover, Spacer } from '../../atoms';
import ResendVerificationEmailButton from '../ResendVerificationEmailButton';

const RegisterOrganization: React.FC = () => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, , setCompressCover] = useCompressedImage();
  const { verified } = useAuthentication();
  const [logo, , setCompressLogo] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { registerOrganization, loading } = useOrganization();
  const { onOrganizationCreationEmail } = useVerification();

  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    if (!values.fullAddress) {
      setCustomErrors(prev => ({
        ...prev,
        fullAddress: t('form.invalidAddress'),
      }));
      return;
    }
    setCustomErrors({});
    try {
      const slug = await registerOrganization({ cover, logo, ...values });
      onOrganizationCreationEmail(slug);
    } catch (error) {}
  };

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      {!verified ? (
        <ResendVerificationEmailButton />
      ) : (
        <>
          <PageCoverAndLogo.Form
            logo={logo}
            setLogo={setCompressLogo}
            cover={cover}
            setCover={setCompressCover}
          />
          <OrganizationForm onSubmit={onSubmit} customErrors={customErrors} />
        </>
      )}
    </div>
  );
};

RegisterOrganization.displayName = 'RegisterOrganization';

export default React.memo(RegisterOrganization);
