import React, { useCallback } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  useTheme,
  RadioGroup,
  Radio,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NotInterested } from '@material-ui/icons';

const useStyle = makeStyles(theme => ({
  wrapper: { backgroundColor: '#fff' },
  form: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  group: {
    flexDirection: 'row',
    borderBottom: '1px solid ' + theme.palette.primary.main,
    margin: '8px 4px',
  },
}));

type Props = {
  error?: string | null;
  label: string;
  name: string;
  langDomain?: string;
  register: any;
  required?: boolean;
  groups?: { id: string; options: string[] }[];
  defaultGroups?: { [key: string]: string } | { [key: string]: string }[];
  allowClear?: boolean;
  fieldClassName?: string;
  onChange?: (data: any) => void;
};

const RadioField: React.FC<Props> = ({
  error,
  groups,
  defaultGroups,
  langDomain,
  name,
  register,
  required,
  allowClear,
  fieldClassName,
  onChange,
}) => {
  const style = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const getLabel = useCallback(
    group => {
      if (group.label) {
        return group.label;
      }
      return langDomain ? t(`${langDomain}.${group.id}`) : group.id;
    },
    [langDomain, t],
  );
  return (
    <Box
      border={1}
      p={2}
      borderRadius={'4px'}
      borderColor={theme.palette.primary.main}
      className={[fieldClassName, style.wrapper].join(' ')}
    >
      <FormControl
        required={required}
        error={!!error}
        component="fieldset"
        className={style.form}
      >
        {groups?.map((group, i) => (
          <RadioGroup
            key={`group-${group.id}-${i}`}
            aria-label={group.id}
            className={style.group}
            onChange={(_, b) => onChange && onChange({ [group.id]: b })}
            defaultValue={
              defaultGroups && defaultGroups[group.id]
                ? defaultGroups[group.id]
                : ''
            }
            row
          >
            <Box>
              <FormLabel component="legend">{getLabel(group)}</FormLabel>
              {group.options.map((option, ind) => (
                <FormControlLabel
                  value={option}
                  key={`option-${option}-${ind}`}
                  inputRef={register({
                    required: required
                      ? (t('form.required') as string)
                      : undefined,
                  })}
                  name={`${name}[${group.id}]`}
                  control={<Radio size="small" color="primary" />}
                  label={langDomain ? t(`${langDomain}.${option}`) : option}
                />
              ))}
              {allowClear && (
                <FormControlLabel
                  value={''}
                  key={'option-none'}
                  inputRef={register({
                    required: required
                      ? (t('form.required') as string)
                      : undefined,
                  })}
                  name={`${name}[${group.id}]`}
                  control={
                    <Radio
                      color="primary"
                      size="small"
                      icon={<NotInterested fontSize="small" />}
                      checkedIcon={<></>}
                    />
                  }
                  label=""
                />
              )}
            </Box>
          </RadioGroup>
        ))}
      </FormControl>
    </Box>
  );
};

RadioField.displayName = 'RadioField';

export default React.memo(RadioField);
