import { fade, makeStyles } from '@material-ui/core';
import hp_1 from '../../../assets/img/hp_1.png';

export default makeStyles(theme => ({
  wrapper: { position: 'relative', height: '500px' },
  slideWrapper: {
    backgroundColor: theme.palette.secondary.dark,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  slideLoading: {
    pointerEvents: 'none',
    background: `url(${hp_1}) top center`,
    backgroundSize: '100%',
    transition: '1s all ease',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99,
  },
  bottomBar: {
    position: 'absolute',
    width: '100%',
    height: 50,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.9)',
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
  slideInfoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.light,
    padding: 8,
    fontSize: '0.75rem',
    position: 'absolute',
    top: 0,
    background: fade(theme.palette.secondary.dark, 0.6),
    width: '100%',
  },
  slideInfoBoxItem: {
    maxWidth: '70%',
    textAlign: 'center',
    '& span': {
      maxWidth: '100%',
      overflow: 'hidden',
    },
    '& svg': {
      fontSize: '1rem',
    },
  },
}));
