import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { THRESHOLD } from '../../constants';
import {
  filteredVenuesSelector,
  venuesListSelector,
  venuesSelector,
  myVenuesSelector,
} from '../../store/selectors/venues';
import { Venue } from '../../store/types';
import { useAuthentication } from '../auth';

type Props = {
  region?: string | undefined;
  userId?: string;
  searchFor?: string;
  isVerified?: boolean | undefined;
  musicStyles?: string[];
  page?: number;
  myVenues?: boolean;
};

type UseVenues = {
  venues: { [key: string]: Venue };
  venuesList: Venue[];
  myVenuesList: Venue[];
  filteredVenues: Venue[];
  searchedVenues: (text: string) => Venue[];
  loaded?: boolean;
  filteredVenuesByPage: { clubs: Venue[]; totalResults: number };
  canCreateEvent: boolean;
};

export const useVenues = (props: Props = {}): UseVenues => {
  const {
    region,
    searchFor,
    isVerified,
    musicStyles,
    page = 1,
    myVenues,
  } = props;

  const { userId } = useAuthentication();
  const venues = useSelector(venuesSelector);
  const venuesList = useSelector(venuesListSelector);
  const myVenuesList = useSelector(myVenuesSelector);
  const filteredVenues = useSelector(
    filteredVenuesSelector({
      searchFor,
      region,
      userId: myVenues ? userId : undefined,
      musicStyles,
      isVerified,
      currentUser: userId,
    }),
  );

  const loaded = isLoaded(venues);
  const canCreateEvent = venuesList?.some(
    venue => venue.created_by === userId || venue?.admins?.includes(userId),
  );

  const searchedVenues = useCallback(
    (text: string) => {
      return (
        venuesList?.filter(venue => {
          if (text) {
            return venue?.name?.toLowerCase()?.startsWith(text.toLowerCase());
          }
          return false;
        }) ?? []
      );
    },
    [venuesList],
  );

  const filteredVenuesByPage = {
    clubs: filteredVenues.slice(THRESHOLD * (page - 1), THRESHOLD * page) || [],
    totalResults: filteredVenues?.length || 0,
  };

  const query: ReduxFirestoreQuerySetting = {
    collection: 'clubs',
    limit: 99,
  };

  if (myVenues) {
    query.where = [['created_by', '==', userId]];
  }

  useFirestoreConnect(query);

  return {
    venues,
    loaded,
    venuesList: venuesList || [],
    filteredVenues: filteredVenues || [],
    searchedVenues,
    filteredVenuesByPage,
    canCreateEvent,
    myVenuesList,
  };
};
