export const MUSIC_STYLES = [
  'hip-hop',
  'r-n-b',
  'rock',
  'dance',
  'classic',
  'jazz',
  'blues',
  'reggaeton',
  'chillout',
  '70-80-90',
  'techno',
  'trance / psy trance',
  'hardcore',
  'trap / rap',
  'house',
  'pop',
  'country',
  'reggaeton',
];

export const ORGANIZATION_RULES = ['assigned', 'canEdit'];

export const ARTIST_RULES = ['assigned'];
