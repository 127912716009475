import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { DividerWithSpace } from '..';
import { useGlobalStyles } from '../../../hooks';

const useStyles = makeStyles({
  filterCategory: {
    fontWeight: 700,
    lineHeight: 1,
    fontSize: 16,
  },
});

type Props = {
  category: string;
  children: React.ReactNode;
};

const FilterCategory: React.FC<Props> = ({ category, children }) => {
  const globalStyle = useGlobalStyles();
  const classes = useStyles();

  return (
    <>
      <Box>
        <Box
          component="span"
          display="block"
          className={[globalStyle.mb1, classes.filterCategory].join(' ')}
        >
          {category}
        </Box>
        {children}
      </Box>
      <DividerWithSpace space="20px" />
    </>
  );
};

FilterCategory.displayName = 'FilterCategory';

export default React.memo(FilterCategory);
