import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { EditTwoTone, NewReleasesTwoTone } from '@material-ui/icons';
import { isEmpty } from 'react-redux-firebase';
import {
  ExpandableText,
  LabeledBox,
  PageActions,
  PageWrapper,
} from '../../components/molecule';
import {
  PageCoverAndLogo,
  OrganizationDetails,
  EventCardWidget,
} from '../../components/organisms';
import {
  useEvents,
  useOrganization,
  useMediaQuery,
  useGlobalStyles,
} from '../../hooks';
import { Spacer } from '../../components/atoms';
import { Event } from '../../store/types';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const Organization: React.FC<Props> = ({ match }) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const { organization: slugOrId } = match.params;
  const [events, setEvents] = useState<Event[]>([]);
  const { organization, loading, loaded, isAdmin } = useOrganization({
    slugOrId,
  });
  const { getEventsByOrganization } = useEvents();
  const { slug, id } = organization || {};
  const history = useHistory();

  const onCreateEvent = useCallback(() => {
    history.push({
      pathname: '/create-event',
      state: { oxrganizationId: organization?.id },
    });
  }, [history, organization]);

  const onEditOrganization = useCallback(() => {
    history.push(`/edit-organization/${slug}`);
  }, [history, slug]);

  const renderActions = useCallback(
    containerClassName => {
      return (
        isAdmin && (
          <PageActions
            containerClassName={containerClassName}
            actions={[
              {
                label: t('event.create'),
                action: onCreateEvent,
                icon: <EditTwoTone style={{ width: 18 }} />,
              },
              {
                label: t('organization.modify'),
                action: onEditOrganization,
                icon: <NewReleasesTwoTone style={{ width: 18 }} />,
              },
            ]}
          />
        )
      );
    },
    [isAdmin, onCreateEvent, onEditOrganization, t],
  );

  useEffect(() => {
    if (loaded && isEmpty(organization)) {
      history.push('/organizations');
    }
  }, [organization, history, loading, loaded]);

  useEffect(() => {
    if (id) {
      setEvents(getEventsByOrganization(id));
    }
  }, [getEventsByOrganization, id]);

  return (
    <>
      <PageWrapper boxed={false}>
        <PageCoverAndLogo
          logo={organization?.logoUrl}
          cover={organization?.coverUrl}
          renderActions={renderActions}
        />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 3}>
              <OrganizationDetails organization={organization} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 9}>
              <LabeledBox label={t('organization.description')}>
                <ExpandableText
                  content={organization?.description}
                  limit={250}
                />
              </LabeledBox>
              <Spacer height="20px" />
              <LabeledBox label={t('venue.nextEvents')}>
                <Grid container spacing={2}>
                  {events.map(event => (
                    <Grid
                      item
                      md={6}
                      key={event.id}
                      className={globalStyle.fullWidth}
                    >
                      <EventCardWidget event={event} />
                    </Grid>
                  ))}
                </Grid>
              </LabeledBox>
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
    </>
  );
};

export default Organization;
