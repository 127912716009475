import { Grid, Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MUSIC_STYLES } from '../../../constants';
import { useMediaQuery } from '../../../hooks';
import { Artist } from '../../../store/types';
import { GridBreak, Spacer } from '../../atoms';
import { FormField } from '../../molecule';

export type Form = Artist;

type Props = {
  artist?: Form;
  onSubmit: (values: Form) => void;
  customErrors?: Partial<Form>;
};

const ArtistForm: React.FC<Props> = ({ onSubmit, customErrors, artist }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const { handleSubmit, register, errors, control, setValue } = useForm<Form>({
    defaultValues: artist,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormField
            name="name"
            label={t('artist.name')}
            register={register}
            required
            error={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormField
            name="facebook"
            type="facebook"
            label={t('artist.linkFacebook')}
            register={register}
            error={errors.facebook?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormField
            name="instagram"
            type="instagram"
            label={t('artist.linkInstagram')}
            register={register}
            error={errors.instagram?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormField
            name="website"
            type="url"
            label={t('artist.website')}
            register={register}
            error={errors.website?.message}
          />
          {!isMobile && <Spacer height="10px" />}
        </Grid>
        <GridBreak />
        <Grid item xs={12} sm={6}>
          <FormField
            name="phone"
            label={t('artist.phone')}
            helperText={t('artist.reservedInformation')}
            register={register}
            required
            error={errors.phone?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="email"
            label={t('artist.email')}
            helperText={t('artist.reservedInformation')}
            register={register}
            required
            error={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="musicStyles"
            label={t('artist.musicStyles')}
            type="checkbox"
            langDomain="shared.musicStyles"
            options={MUSIC_STYLES}
            defaultOptions={artist?.musicStyles}
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="description"
            label={t('artist.description')}
            type="textarea"
            required
            control={control}
            setValue={setValue}
            register={register}
            error={customErrors?.description}
          />
          <Spacer height="20px" />
        </Grid>
        <Grid container justify="space-around">
          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
          >
            {artist ? t('artist.edit') : t('artist.goLive')}
          </Button>
        </Grid>
      </Grid>
      <Spacer height="40px" />
    </form>
  );
};

ArtistForm.displayName = 'ArtistForm';

export default React.memo(ArtistForm);
