import React, { useCallback, useMemo } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PageWrapper } from '../../components/molecule';
import {
  BecomePhotographer as BecomePhotographerComponent,
  UserDetails,
} from '../../components/organisms';
import { Spacer } from '../../components/atoms';

const BecomePhotographer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const goToProfile = useCallback(() => history.push('/profile'), [history]);

  const renderMain = useMemo(() => {
    return <BecomePhotographerComponent />;
  }, []);

  return (
    <PageWrapper>
      <Box>
        <Spacer height="20px" />
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <UserDetails />
            <Spacer height="10px" />
            <Button fullWidth variant="outlined" onClick={goToProfile}>
              {t('profile.backToProfile')}
            </Button>
          </Grid>
          <Grid item md={9} xs={12}>
            {renderMain}
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default BecomePhotographer;
