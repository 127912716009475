import { populate } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { firestoreState } from '..';
import {
  GuestListRegistration,
  PopulatedGuestListRegistration,
} from '../../types';
import { authSelector } from '../selectors';

export const guestListRegistrationsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: GuestListRegistration } =>
    firestore?.data?.guestListRegistrations ?? {},
);

export const guestListRegistrationsListSelector = createSelector(
  guestListRegistrationsSelector,
  (guestListRegistrations): GuestListRegistration[] =>
    Object.entries(guestListRegistrations || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const guestListRegistrationsByEventSelector = (eventId?: string) =>
  createSelector(
    guestListRegistrationsListSelector,
    (guestListRegistrations): GuestListRegistration[] =>
      guestListRegistrations.filter(reg => reg.eventId === eventId) || [],
  );

export const populatedGuestListRegistrationsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: PopulatedGuestListRegistration } =>
    populate(firestore, 'guestListRegistrations', [
      { child: 'eventId', root: 'events', childAlias: 'event' },
    ]) || {},
);

export const populatedGuestListRegistrationsListSelector = createSelector(
  populatedGuestListRegistrationsSelector,
  (populatedGuestListRegistrations): PopulatedGuestListRegistration[] =>
    Object.entries(populatedGuestListRegistrations || {}).map(
      ([key, val]: any[]) => ({
        ...val,
        id: key,
      }),
    ),
);

export const guestListRegistrationsByUserSelector = (
  timePeriod: 'past' | 'future' | undefined,
) =>
  createSelector(
    populatedGuestListRegistrationsListSelector,
    authSelector,
    (guestListRegistrationsList, auth) =>
      guestListRegistrationsList.filter(reg => {
        if (timePeriod) {
          return auth.uid === reg.userId && timePeriod === 'past'
            ? new Date().getTime() > new Date(reg.event.date).getTime()
            : new Date().getTime() < new Date(reg.event.date).getTime();
        }
        return auth.uid === reg.userId;
      }),
  );
