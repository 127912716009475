import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { EditTwoTone, NewReleasesTwoTone } from '@material-ui/icons';
import { isEmpty } from 'react-redux-firebase';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import {
  PageActions,
  PageWrapper,
  LabeledBox,
  TabsWithPath,
  EventsList,
  ExpandableText,
} from '../../components/molecule';
import {
  PageCoverAndLogo,
  ArtistDetails,
  EventCardWidget,
  YoutubeUrlDialog,
} from '../../components/organisms';
import {
  useEvents,
  useArtist,
  useMediaQuery,
  useGlobalStyles,
} from '../../hooks';
import { LoadingCover, Spacer, YoutubeIFrame } from '../../components/atoms';
import { Event } from '../../store/types';
import defaultCover from '../../assets/img/defaultCover.jpg';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const Artist: React.FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const { artist: slugOrId } = match.params;
  const { isMobile } = useMediaQuery();
  const globalStyle = useGlobalStyles();
  const [events, setEvents] = useState<Event[]>([]);
  const { artist, loading, loaded, isAdmin, updateArtist } = useArtist({
    slugOrId,
  });
  const {
    getEventsByArtist,
    getFutureEventsByArtist,
    getPastEventsByArtist,
  } = useEvents();
  const { slug, id } = artist || {};
  const history = useHistory();
  const futureEvents = id && getFutureEventsByArtist(id);
  const pastEvents = id && getPastEventsByArtist(id);
  const [videoUrlDialogOpen, setVideoUrlDialogOpen] = useState(false);

  const onCreateEvent = useCallback(() => {
    history.push({
      pathname: '/create-event',
      state: { artistId: artist?.id },
    });
  }, [history, artist]);

  const onEditArtist = useCallback(() => {
    history.push(`/edit-artist/${slug}`);
  }, [history, slug]);

  const onAddYoutubeUrl = useCallback(() => {
    setVideoUrlDialogOpen(true);
  }, []);

  const saveVideo = useCallback(
    videoUri => {
      updateArtist({ videoUri });
    },
    [updateArtist],
  );

  const deleteVideo = useCallback(() => {
    updateArtist({ videoUri: '' });
  }, [updateArtist]);

  const renderActions = useCallback(
    containerClassName => {
      return (
        isAdmin && (
          <PageActions
            containerClassName={containerClassName}
            actions={[
              {
                label: t('event.create'),
                action: onCreateEvent,
                icon: <EditTwoTone style={{ width: 18 }} />,
              },
              {
                label: t('artist.modifyArtist'),
                action: onEditArtist,
                icon: <NewReleasesTwoTone style={{ width: 18 }} />,
              },
              {
                label: artist?.videoUri
                  ? t('shared.changeVideo')
                  : t('shared.addVideo'),
                action: onAddYoutubeUrl,
                icon: <VideoLibraryIcon style={{ width: 18 }} />,
              },
            ]}
          />
        )
      );
    },
    [artist, isAdmin, onAddYoutubeUrl, onCreateEvent, onEditArtist, t],
  );

  useEffect(() => {
    if (loaded && isEmpty(artist)) {
      history.push('/artists');
    }
  }, [artist, history, loading, loaded]);

  useEffect(() => {
    if (id) {
      setEvents(getEventsByArtist(id));
    }
  }, [getEventsByArtist, id]);

  const renderTabs = useCallback(
    containerClassName => {
      const homeTab = (
        <>
          <LabeledBox label={t('artist.description')}>
            <ExpandableText content={artist?.description} limit={250} />
          </LabeledBox>
          <Spacer height="20px" />
          {artist?.videoUri && (
            <LabeledBox label={t('shared.video')}>
              <YoutubeIFrame
                videoUri={artist.videoUri}
                title={`${artist.name}-video`}
              />
            </LabeledBox>
          )}
          <Spacer height="20px" />
          <LabeledBox label={t('general.events')}>
            <Grid container spacing={2}>
              {events.map((event, idx) => (
                <Grid
                  item
                  md={6}
                  className={globalStyle.fullWidth}
                  key={`${event?.slug}-${idx}`}
                >
                  <EventCardWidget event={event} />
                </Grid>
              ))}
            </Grid>
          </LabeledBox>
        </>
      );

      const nextEventsTab =
        futureEvents && futureEvents.length ? (
          <EventsList events={futureEvents} />
        ) : (
          <Typography variant="h3">{t('event.noEvents')}</Typography>
        );

      const pastEventsTab =
        pastEvents && pastEvents.length ? (
          <EventsList events={pastEvents} />
        ) : (
          <Typography variant="h3">{t('event.noEvents')}</Typography>
        );
      return (
        <TabsWithPath
          containerClassName={containerClassName}
          tabs={[
            {
              label: t('venue.home'),
              content: homeTab,
            },
            {
              label: t('venue.nextEvents'),
              content: nextEventsTab,
              hash: '#future-events',
            },
            {
              label: t('venue.pastEvents'),
              content: pastEventsTab,
              hash: '#past-events',
            },
          ]}
        />
      );
    },
    [t, artist, globalStyle.fullWidth, events, futureEvents, pastEvents],
  );

  return (
    <>
      {loading && <LoadingCover />}
      <PageWrapper boxed={false}>
        <PageCoverAndLogo
          logo={artist?.logoUrl}
          cover={artist?.coverUrl || defaultCover}
          renderActions={renderActions}
        />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 3}>
              <ArtistDetails artist={artist} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 9}>
              {renderTabs('')}
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
      <YoutubeUrlDialog
        open={videoUrlDialogOpen}
        setOpen={setVideoUrlDialogOpen}
        title={t('artist.addVideo')}
        cbFunctionOnSave={saveVideo}
        cbFunctionOnDelete={deleteVideo}
        videoExistsInDb={!!artist?.videoUri}
      />
    </>
  );
};

export default Artist;
