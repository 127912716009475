import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

type StyleProps = {};

export const useCoverStyle = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    '&:not(.MuiDropzoneArea-active)': {
      backgroundColor: grey['200'],
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    height: 100,
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
  },
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.4,
  },
}));
export const useStyle = makeStyles(theme => ({
  photo: {
    width: '100%',
    objectFit: 'cover',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
    marginBottom: 10,
  },
  photoWrapper: {
    columnWidth: 280,
  },
  ctaSection: {
    position: 'absolute',
    right: 5,
    bottom: 20,
  },
  fab: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(30%, -30%)',
  },
  dialogContent: {
    width: 800,
    maxWidth: '100%',
  },
}));
