import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { dateRoomByEventSelector } from '../../store/selectors/dateRooms';
import { Event } from '../../store/types';

type UseDateRoom = {
  event: Event;
};

const useDateRoom = ({ event }: UseDateRoom) => {
  const firestore = useFirestore();
  const dateRoom = useSelector(dateRoomByEventSelector(event?.id));

  const registerDateRoom = useCallback(async () => {
    const existingRoom = await firestore
      .collection('dateRooms')
      .where('eventId', '==', event.id)
      .get();
    if (existingRoom.empty) {
      const dateRoomRef = await firestore.collection('dateRooms').add({
        eventId: event.id,
        start: event.timestamp,
        end: event.endTimestamp || dayjs().add(12, 'hour').valueOf(),
      });
      return dateRoomRef;
    }
    return existingRoom;
  }, [event, firestore]);

  useFirestoreConnect({
    collection: 'dateRooms',
    where: ['eventId', '==', event.id],
  });

  return { dateRoom, registerDateRoom };
};

export default useDateRoom;
