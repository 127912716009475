import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFirestoreQuerySetting, useFirestore } from 'react-redux-firebase';
import { guestListSelector } from '../../store/selectors/guestLists';
import { GuestList } from '../../store/types';
import { useAuthentication } from '../auth';
import { useGuestLists } from './useGuestLists';

type Props = {
  id?: string;
};

type RegisterGuestList = Partial<GuestList>;

type UseGuestList = {
  guestList?: GuestList;
  guestListId?: string;
  loading?: boolean;
  loaded?: boolean;
  registerGuestList: (props: RegisterGuestList) => void;
  updateGuestList: (props: RegisterGuestList) => void;
};

export const useGuestList = (props: Props = {}): UseGuestList => {
  const { id } = props;
  const [loading, setLoading] = useState<boolean>();
  const firestore = useFirestore();
  const { userId } = useAuthentication();
  const { loaded } = useGuestLists();
  const guestList = useSelector(guestListSelector(id || ''));

  const guestListId = guestList?.id || id;

  const registerGuestList = useCallback(
    async ({ ...values }: RegisterGuestList) => {
      setLoading(true);
      await firestore.collection('/guestLists').add({
        ...values,
        created_by: userId,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      setLoading(false);
    },
    [firestore, userId],
  );

  const updateGuestList = useCallback(
    async ({ ...values }: RegisterGuestList) => {
      if (!guestList || !guestListId) {
        return;
      }
      setLoading(true);
      await firestore
        .collection('/guestLists')
        .doc(guestListId)
        .update({ ...values, updated_at: new Date().getTime() });
      setLoading(false);
      return;
    },
    [guestList, guestListId, firestore],
  );

  useEffect(() => {
    if (!guestListId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'guestLists',
      };

      if (id) {
        query.doc = id;
      }
      firestore.get(query);
    }
  }, [firestore, guestListId, id]);

  return {
    guestList,
    loaded,
    loading,
    guestListId,
    updateGuestList,
    registerGuestList,
  };
};
