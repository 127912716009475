import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { EditVenue } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const EditVenueScreen: React.FC<Props> = ({ match }) => {
  const { venue: slugOrId } = match.params;

  return (
    <PageWrapper title="Modifica Club">
      {slugOrId && <EditVenue slugOrId={slugOrId} />}
    </PageWrapper>
  );
};

export default EditVenueScreen;
