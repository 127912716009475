import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import {
  getVideoIdFromYoutubeLink,
  getVideoStartTimeIfAny,
} from '../../../utils/ui/youtubeLinkValidation';
import { Flex, Spacer, YoutubeIFrame } from '../../atoms';
import { LabeledBox } from '../../molecule';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  cbFunctionOnSave: Function;
  cbFunctionOnDelete: Function;
  videoExistsInDb: boolean;
};

const YoutubeUrlDialog: React.FC<Props> = ({
  open,
  setOpen,
  title,
  cbFunctionOnSave,
  cbFunctionOnDelete,
  videoExistsInDb,
}) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [validUrl, setValidUrl] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [videoId, setVideoId] = useState('');

  const deleteEveryData = useCallback(() => {
    setError('');
    setVideoId('');
    setMinutes(0);
    setSeconds(0);
    setValidUrl(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setUrl('');
    deleteEveryData();
  }, [deleteEveryData, setOpen]);

  const handleUrlCheck = useCallback(async () => {
    deleteEveryData();
    const ytVideoId = await getVideoIdFromYoutubeLink(url);
    if (ytVideoId) {
      const startTime = getVideoStartTimeIfAny(url);
      setVideoId(ytVideoId);
      setMinutes(Math.floor(startTime / 60));
      setSeconds(startTime - Math.floor(startTime / 60) * 60);
      setValidUrl(true);
    } else {
      setError(t('general.provideValidUrl'));
    }
  }, [deleteEveryData, t, url]);

  const handleSave = useCallback(() => {
    const videoUri =
      minutes || seconds
        ? `${videoId}?start=${minutes * 60 + seconds}`
        : videoId;
    cbFunctionOnSave(videoUri);
    setOpen(false);
    setUrl('');
    deleteEveryData();
  }, [cbFunctionOnSave, deleteEveryData, minutes, seconds, setOpen, videoId]);

  const handleDelete = useCallback(() => {
    cbFunctionOnDelete();
    setOpen(false);
    setUrl('');
    deleteEveryData();
  }, [cbFunctionOnDelete, deleteEveryData, setOpen]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          style={isMobile ? { maxWidth: '100%' } : { minWidth: '600px' }}
        >
          <TextField
            size="small"
            fullWidth
            error={!!error}
            label={url.length ? t('general.youtubeUrl') : ''}
            placeholder={t('general.copyYoutubeUrl')}
            variant="outlined"
            value={url}
            onChange={e => setUrl(e.target.value)}
            helperText={error ? error : t('general.checkUrlDisclaimer')}
          />
          {validUrl && (
            <>
              <Spacer height="30px" />
              <LabeledBox label={t('general.videoPreview')}>
                <>
                  <YoutubeIFrame
                    videoUri={`${videoId}?start=${minutes * 60 + seconds}`}
                    title={t('general.videoPreview')}
                  />
                  <Spacer height="15px" />
                  <LabeledBox label={t('shared.startTime')}>
                    <Box display="flex" mx={2}>
                      <TextField
                        size="small"
                        type="number"
                        fullWidth
                        label={t('shared.minutes')}
                        variant="outlined"
                        value={minutes}
                        onChange={e =>
                          +e.target.value && setMinutes(+e.target.value)
                        }
                        style={{ marginRight: 20 }}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        label={t('shared.seconds')}
                        variant="outlined"
                        value={seconds}
                        onChange={e =>
                          +e.target.value && setSeconds(+e.target.value)
                        }
                      />
                    </Box>
                  </LabeledBox>
                </>
              </LabeledBox>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Flex justifyContent="space-between">
            {videoExistsInDb ? (
              <Button
                onClick={handleDelete}
                className={globalStyles.errorColor}
              >
                {t('shared.deleteVideo')}
              </Button>
            ) : (
              <Box />
            )}
            <Box>
              <Button onClick={handleClose} color="primary">
                {t('shared.cancel')}
              </Button>
              <Button onClick={handleUrlCheck} color="primary">
                {t('general.checkUrl')}
              </Button>
              {validUrl && (
                <Button onClick={handleSave} color="primary">
                  {t('shared.save')}
                </Button>
              )}
            </Box>
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};

YoutubeUrlDialog.displayName = 'YoutubeUrlDialog';

export default React.memo(YoutubeUrlDialog);
