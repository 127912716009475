import { Typography, Divider } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FollowUs } from '..';
import { useGlobalStyles } from '../../../hooks';
import { Flex, Spacer } from '../../atoms';

type Props = {};

const FollowUsSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  return (
    <>
      <Flex flexDirection="column" just>
        <Spacer height="50px" />
        <Typography variant="h5">{t('shared.followUs')}</Typography>
        <Spacer height="20px" />
        <Divider className={globalStyles.fullWidth} />
        <Spacer height="20px" />
        <div>
          <FollowUs />
        </div>
        <Spacer height="100px" />
      </Flex>
    </>
  );
};

FollowUsSection.displayName = 'FollowUsSection';

export default React.memo(FollowUsSection);
