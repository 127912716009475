import React, { useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  AppBar,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery, useNewsCategory } from '../../../hooks';
import NewsCategoryForm from '../NewsCategoryForm';
import { Form } from '../NewsCategoryForm/NewsCategoryForm';

type Props = {
  toggleUpdateDialog: Function;
  updateDialog: boolean;
};

const RegisterNewsCategory: React.FC<Props> = ({
  toggleUpdateDialog,
  updateDialog,
}) => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const { registerNewsCategory } = useNewsCategory();
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: 'Description required',
      }));
      return;
    }
    setCustomErrors({});
    registerNewsCategory({ ...values });
    onToggleUpdateDialog();
  };

  const onToggleUpdateDialog = useCallback(() => toggleUpdateDialog(), [
    toggleUpdateDialog,
  ]);

  const renderDialogTitle = useMemo(
    () => (
      <Box
        paddingX={isMobile && 1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {t('newsCategory.title')}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onToggleUpdateDialog}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    ),
    [isMobile, onToggleUpdateDialog, t],
  );

  return (
    <Dialog
      open={updateDialog}
      onClose={onToggleUpdateDialog}
      fullScreen={isMobile}
    >
      {isMobile ? (
        <AppBar variant="outlined">{renderDialogTitle}</AppBar>
      ) : (
        <DialogTitle>{renderDialogTitle}</DialogTitle>
      )}
      <Box p={2} paddingTop={isMobile && 10}>
        <Grid container justify="space-around">
          <Grid item xs={12} sm={9} md={12}>
            <NewsCategoryForm onSubmit={onSubmit} customErrors={customErrors} />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

RegisterNewsCategory.displayName = 'RegisterNewsCategory';

export default React.memo(RegisterNewsCategory);
