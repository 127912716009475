import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
// import { THRESHOLD } from '../../constants';
import {
  photoAlbumsByUserSelector,
  photoAlbumsListSelector,
  photoAlbumsSelector,
  photoGalleriesListSelector,
  photoGalleriesSelector,
  populatedPhotoAlbumsListSelector,
  populatedPhotoAlbumsSelector,
  populatedAlbumsListByUserSelector,
  photoAlbumsByUserAndEventSelector,
} from '../../store/selectors/photoAlbums';
import {
  PhotoAlbum,
  PhotoGallery,
  PopulatedPhotoAlbum,
} from '../../store/types';
import { useAuthentication } from '../auth';
import { filteredPhotoGalleriesSelector } from './../../store/selectors/photoAlbums/index';

type Props = {
  searchFor?: string;
  selectedDate?: { day: number; month: number; year: number } | null;
  selectedVenueIds?: string[];
  selectedArtistIds?: string[];
  selectedEventIds?: string[];
  page?: number;
  myAlbums?: boolean;
  eventId?: string;
  currentUser?: string;
};

type UsePhotoAlbums = {
  photoAlbums: { [key: string]: PhotoAlbum };
  populatedAlbums: { [key: string]: PopulatedPhotoAlbum };
  photoAlbumsList: PhotoAlbum[];
  photoAlbumsByUser: PhotoAlbum[];
  photoAlbumsByUserAndEvent: PhotoAlbum[];
  photoGalleries: { [key: string]: PhotoGallery };
  photoGalleriesList: PhotoGallery[];
  loaded: boolean;
  populatedAlbumsListByUser: PopulatedPhotoAlbum[];
  getPhotoAlbumsByEvent: (eventId: string) => PopulatedPhotoAlbum[];
  filteredPhotoGalleries: { [key: string]: PhotoGallery };
  filteredPhotoGalleriesByPage: {
    photoGalleries: PhotoGallery[];
    totalResults: number;
  };
};

export const usePhotoAlbums = (filters: Props = {}): UsePhotoAlbums => {
  // const { page = 1 } = filters;
  const { userId } = useAuthentication();
  filters.currentUser = userId;
  const photoAlbums = useSelector(photoAlbumsSelector);
  const photoAlbumsList = useSelector(photoAlbumsListSelector);
  const photoAlbumsByUser = useSelector(photoAlbumsByUserSelector);
  const photoAlbumsByUserAndEvent = useSelector(
    photoAlbumsByUserAndEventSelector(filters?.eventId),
  );
  const populatedAlbums = useSelector(populatedPhotoAlbumsSelector);
  const populatedAlbumsList = useSelector(populatedPhotoAlbumsListSelector);
  const populatedAlbumsListByUser = useSelector(
    populatedAlbumsListByUserSelector(userId || ''),
  );
  const photoGalleries = useSelector(photoGalleriesSelector);
  const photoGalleriesList = useSelector(photoGalleriesListSelector);
  const filteredPhotoGalleries = useSelector(
    filteredPhotoGalleriesSelector(filters),
  );

  const filteredPhotoGalleriesByPage = {
    photoGalleries: Object.values(filteredPhotoGalleries).sort(
      (a, b) => new Date(b?.date!).getTime() - new Date(a?.date!).getTime(),
    ),
    // .slice(
    //   THRESHOLD * (page - 1),
    //   THRESHOLD * page,
    // ) || [],
    totalResults: Object.values(filteredPhotoGalleries)?.length || 0,
  };

  const loaded = isLoaded(photoAlbums);

  const getPhotoAlbumsByEvent = useCallback(
    eventId => populatedAlbumsList?.filter(album => album.eventId === eventId),
    [populatedAlbumsList],
  );

  const query: ReduxFirestoreQuerySetting = {
    collection: 'photoAlbums',
    limit: 99,
  };

  if (filters.selectedEventIds && filters.selectedEventIds.length) {
    query.where = [['eventId', 'in', filters.selectedEventIds]];
  }

  if (filters.myAlbums) {
    query.where = ['created_by', '==', userId];
  }

  useFirestoreConnect(query);

  return {
    loaded,
    populatedAlbums,
    photoAlbums,
    photoAlbumsList,
    getPhotoAlbumsByEvent,
    photoAlbumsByUser,
    photoAlbumsByUserAndEvent,
    populatedAlbumsListByUser,
    photoGalleries,
    photoGalleriesList,
    filteredPhotoGalleries,
    filteredPhotoGalleriesByPage,
  };
};
