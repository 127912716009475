import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import {
  DeleteForeverTwoTone,
  Star,
  StarBorderTwoTone,
  CameraOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useAuthentication, useMedia } from '../../../hooks';
import { Slider } from '../../molecule';

const useStyles = makeStyles({
  dialogWrapper: {
    width: 300,
    maxWidth: '100%',
  },
  sliderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
  },
  updateChip: {
    position: 'absolute',
    top: 10,
    right: 5,
    height: 'min-content',
  },
  deleteChip: {
    position: 'absolute',
    bottom: 10,
    right: 5,
    height: 'min-content',
  },
});

type Props = {
  onAddNew?: () => void;
  open: boolean;
  toggleOpen: () => void;
  disableDelete?: boolean;
  onChange?: (photo: string) => void;
  selectLabel?: string;
  selectedLabel?: string;
};

const MyPictureDialog: React.FC<Props> = ({
  onAddNew,
  open,
  toggleOpen,
  disableDelete,
  onChange,
  selectLabel,
  selectedLabel,
}) => {
  const { deleteMedia } = useMedia();
  const { t } = useTranslation();
  const style = useStyles();
  const {
    updateProfile,
    profilePictures,
    profilePicture,
  } = useAuthentication();

  const deletePhoto = useCallback(
    (photo: string) => {
      console.log(profilePictures);
      toggleOpen();
      const newProfilePictures = [...profilePictures].filter(p => p !== photo);
      console.log(newProfilePictures);
      updateProfile({
        preferredProfilePicture: newProfilePictures[0],
        profilePictures: newProfilePictures,
      });
      deleteMedia(photo);
    },
    [deleteMedia, profilePictures, toggleOpen, updateProfile],
  );

  const setAsProfilePicture = useCallback(
    (photo: string) => {
      toggleOpen();
      if (onChange) {
        onChange(photo);
        return;
      }
      updateProfile({
        preferredProfilePicture: photo,
      });
    },
    [onChange, toggleOpen, updateProfile],
  );

  const renderItem = useCallback(
    photo => {
      const isProfilePicture = profilePicture === photo;
      return (
        <Box key={photo}>
          <Box className={style.sliderWrapper}>
            <Chip
              color="primary"
              size={undefined}
              className={style.updateChip}
              onClick={() => setAsProfilePicture(photo)}
              icon={isProfilePicture ? <Star /> : <StarBorderTwoTone />}
              label={
                isProfilePicture
                  ? selectedLabel || t('profile.profilePicture')
                  : selectLabel || t('profile.useAsProfilePicture')
              }
            />
            {!disableDelete && (
              <Chip
                color="primary"
                size={undefined}
                className={style.deleteChip}
                onClick={() => deletePhoto(photo)}
                icon={<DeleteForeverTwoTone />}
                label={t('profile.deletePhoto')}
              />
            )}
            <img src={photo} alt={photo} />
          </Box>
        </Box>
      );
    },
    [
      deletePhoto,
      disableDelete,
      profilePicture,
      selectLabel,
      selectedLabel,
      setAsProfilePicture,
      style,
      t,
    ],
  );

  return (
    <Dialog open={open} onClose={toggleOpen} maxWidth="md">
      <DialogTitle>{t('profile.yourPhotos')}</DialogTitle>
      <DialogContent>
        <Box className={style.dialogWrapper}>
          <Slider items={1} pagination paginationPosition="below" equalHeight>
            {profilePictures
              .sort(a => (a === profilePicture ? -1 : 0))
              .map(renderItem)}
          </Slider>
        </Box>
      </DialogContent>
      {onAddNew && (
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onAddNew}
            fullWidth
          >
            <CameraOutlined /> {t('profile.addNewPhoto')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default React.memo(MyPictureDialog);
