import { Divider } from '@material-ui/core';
import React from 'react';
import { Spacer } from '../../atoms';

type Props = {
  space: string | number;
};

const DividerWithSpace: React.FC<Props> = ({ space }) => {
  return (
    <>
      <Spacer height={space} />
      <Divider />
      <Spacer height={space} />
    </>
  );
};

DividerWithSpace.displayName = 'DividerWithSpace';

export default React.memo(DividerWithSpace);
