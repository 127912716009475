import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  useAuthentication,
  useGlobalStyles,
  useMediaQuery,
} from '../../../hooks';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { Emoji, LoadingCover, Spacer } from '../../atoms';
import { LabeledBox } from '../../molecule';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { useSendEmail } from '../../../hooks/email';
import useStyle from './style';

type Props = {};

const BecomePhotographer: React.FC<Props> = () => {
  const { profile, loading, userId } = useAuthentication();
  const globalStyle = useGlobalStyles();
  const history = useHistory();
  const [termsDialog, toggleTermsDialog] = useToggleState(false);
  const [accepted, setAccepted] = useState(false);
  const { isMobile } = useMediaQuery();
  const { sendEmail, loading: sending, success, error } = useSendEmail();
  const style = useStyle();

  const sendRequest = useCallback(() => {
    sendEmail({
      to: 'it@tilllate.world',
      subject: 'Richiesta registrazione fotografo!',
      content1: `<p>Ciao amministratore,</p>
				<p>l'utente ${profile.firstName && profile.lastName} con id ${userId}</p>
				<p>Ha richiesto di diventare fotografo!</p>`,
      content2:
        'Per confirmerare la richiesta inoltra questa email a it@tilllate.world!',
      shortText: 'Richiesta registrazione fotografo!',
      text: `Ciao amministratore, l'utente ${
        profile.firstName && profile.lastName
      } con id ${userId} Ha richiesto di diventare fotografo!</p>`,
    });
  }, [profile, sendEmail, userId]);

  if (profile?.roles?.includes('photographer')) {
    history.push('/profile');
  }

  if (success) {
    return (
      <div className={globalStyle.relative}>
        {loading && <LoadingCover />}
        <img
          src={defaultCover}
          className={isMobile ? style.coverMobile : style.cover}
          alt="cover"
          id="cover"
        />
        <Spacer height="20px" />
        <Typography variant="h2" color="error" align="center">
          <Emoji emoji="✅" />
        </Typography>
        <Typography>
          La tua richiesta è stata inviata con successo!
          <br />
          Sarai presto contattato da un nostro operatore per confermare il tuo
          profilo fotografo.
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className={globalStyle.relative}>
        {loading && <LoadingCover />}
        <img
          src={defaultCover}
          className={isMobile ? style.coverMobile : style.cover}
          alt="cover"
          id="cover"
        />
        <Spacer height="20px" />
        <Typography variant="h2" color="error" align="center">
          <Emoji emoji="❗️" />
        </Typography>
        <Typography>
          Ops, qualcosa è andato storto durante l'invio della tua richiesta.
          <br /> Ti invitiamo a riprovare più tardi o a metterti in contatto con
          noi per completare la procedura.
        </Typography>
      </div>
    );
  }

  return (
    <div className={globalStyle.relative}>
      {loading && <LoadingCover />}
      <img
        src={defaultCover}
        className={isMobile ? style.coverMobile : style.cover}
        alt="cover"
        id="cover"
      />
      <Spacer height="20px" />
      <Typography>
        Diventando un fotografo potrai caricare su Tilllate.world le foto
        scattate da te degli eventi ai quali hai partecipato.
        <br />
        Potrai creare i tuoi album, scegliere le foto di copertina e molto
        altro... cosa aspetti? Diventa un fotografo su Tilllate.
      </Typography>
      <Spacer height="20px" />
      <LabeledBox label="Diventa fotografo!">
        <>
          <Box display="flex" alignItems="center">
            <Checkbox
              size="small"
              checked={accepted}
              onChange={e => setAccepted(e.target.checked)}
            />
            <Typography>
              Dichiaro di avere letto ed accettato i{' '}
              <Typography
                component="span"
                color="primary"
                onClick={toggleTermsDialog}
                className={globalStyle.textLink}
              >
                termini e condizioni
              </Typography>{' '}
              <br />e di richiedere di diventare un fotografo per Tilllate.word
            </Typography>
          </Box>
          <Spacer height="20px" />
          <Button
            size="small"
            color="primary"
            disabled={!accepted || sending}
            disableElevation
            onClick={sendRequest}
            variant="contained"
          >
            {sending ? <CircularProgress size="20px" /> : 'Invia richiesta'}
          </Button>
        </>
      </LabeledBox>
      <Dialog open={termsDialog} maxWidth="lg">
        <DialogTitle>Termini e condizioni</DialogTitle>
        <DialogContent>
          <Typography>
            Inoltrando la tua richiesta per diventare fotografo accetti i
            seguenti termini e condizioni di utilizzo del servizio.
            <br />
            <br />
            Tutte le foto, immagini e video devono appartenere a te, devi
            disporre di tutte le autorizzazioni e liberatorie dei soggetti
            ripresi in esse (foto e video).
            <br />
            <br />
            Non sono ammesse foto e video contenenti soggetti o scene di nudo,
            atti o scene di violenza o con riferimenti a droghe.
            <br />
            <br />
            Caricando le foto su Tilllate.world ti assumi la responsabilità di
            ciò che carichi, Tilllate non si assume alcuna responsabilità ne la
            proprpietà di qualsiasi foto caricata sul sito Tillllate.world.
            <br />
            <br />
            Eseguiremo periodici controlli sulla qualità delle foto e video,
            facendo attenzione che queste rispettino il regolamento e le linee
            guida di Tilllate.world.
            <br />
            <br />
            Qualora ci dovessero essere dei reclami di proprietà intelletuale
            delle foto o video, Tilllate si assume la libertà di eliminiare tali
            foto o video e di eseguire tutti i controlli necessari per
            verificare che le linee guida sianon state rispetate.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={toggleTermsDialog}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BecomePhotographer.displayName = 'BecomePhotographer';

export default React.memo(BecomePhotographer);
