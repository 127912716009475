import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useDebounce from '../../../hooks/ui/useDebounce';
import { FilterOptionType } from '../../../store/types';

type Props = {
  options: FilterOptionType[];
  setIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSearchFor: React.Dispatch<React.SetStateAction<string>>;
  loaded: boolean;
  placeholder: string;
};

const collectSelectedIds = (selectedItems: FilterOptionType[]) => {
  return Array.from(new Set(selectedItems.map(item => item.id)));
};

const FilterAutocompleteOptions: React.FC<Props> = ({
  options,
  setIds,
  setSearchFor,
  placeholder,
}) => {
  const [input, setInput] = useState<string>('');
  const debouncedSearchFor = useDebounce(input.trim(), 300);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    setSearchFor(debouncedSearchFor);
  }, [debouncedSearchFor, setSearchFor]);

  return (
    <Grid item md={12}>
      <Autocomplete
        size="small"
        multiple
        open={input.length >= 1}
        id={`multiple-limit-tags-${placeholder}`}
        options={options.filter(o => !selectedIds.includes(o.id))}
        onInputChange={(_, v) => {
          setInput(v);
        }}
        getOptionLabel={(option: FilterOptionType) => option && option?.name}
        onChange={(_, selectedItems) => {
          const selecteds = collectSelectedIds(
            selectedItems as FilterOptionType[],
          );
          setIds(selecteds);
          setSelectedIds(selecteds);
        }}
        popupIcon={null}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
            placeholder={placeholder}
          />
        )}
      />
    </Grid>
  );
};

FilterAutocompleteOptions.displayName = 'FilterAutocompleteOptions';

export default React.memo(FilterAutocompleteOptions);
