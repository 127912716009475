import { makeStyles, Theme } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';

type StyleProps = {
  bg?: string;
};
export default makeStyles<Theme, StyleProps>(theme => ({
  cropperWrapper: {
    position: 'relative',
    height: 0,
    paddingTop: '25%',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    borderRadius: theme.spacing(0.5),
  },
  confirmBtn: {
    backgroundColor: green['600'],
    color: theme.palette.secondary.light,
    position: 'absolute',
    right: 5,
    bottom: 5,
  },
  root: ({ bg }) => ({
    '&:not(.MuiDropzoneArea-active)': {
      backgroundColor: grey['200'],
      backgroundImage: bg ? `url("${bg}")` : undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      '& .MuiDropzoneArea-textContainer': {
        display: bg ? 'none' : undefined,
      },
    },
    height: 0,
    paddingTop: '25%',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
  }),
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.4,
  },
}));
