import React, { useState, useEffect, useMemo } from 'react';
import { IonToast } from '@ionic/react';
import { capitalize } from '../../../store/utils/ui';

type Props = {
  message: string | { [key: string]: string[] };
};

const ErrorToast: React.FC<Props> = ({ message }) => {
  const [toast, setToast] = useState(false);

  useEffect(() => {
    if (message) {
      setToast(true);
    }
  }, [message]);

  const errorMessage = useMemo(() => {
    if (!message) {
      return null;
    }
    if (typeof message === 'object') {
      return Object.keys(message)
        .map(key => `${capitalize(key)}: ${message[key]}`)
        .join('\n');
    }
    return message;
  }, [message]);

  return (
    <IonToast
      isOpen={toast}
      onDidDismiss={() => setToast(false)}
      duration={5000}
      message={errorMessage || ''}
      position="bottom"
      color="danger"
    />
  );
};

ErrorToast.displayName = 'ErrorToast';

export default React.memo(ErrorToast);
