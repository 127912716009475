import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PhotoAlbum, PhotoAlbumCover } from '..';
import { useGlobalStyles } from '../../../hooks';
import { usePhotoAlbum } from '../../../hooks/photoAlbums';
import { LoadingCover, Spacer } from '../../atoms';
import { PageActions } from '../../molecule';

type Props = {
  id: string;
};

const PhotoAlbumScreen: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const { loading, photoAlbum, isAdmin } = usePhotoAlbum({ id });

  const onEditAlbum = useCallback(() => {
    history.push(`/edit-photo-album/${id}`);
  }, [history, id]);

  const renderActions = useCallback(
    containerClassName => {
      return (
        isAdmin && (
          <PageActions
            containerClassName={containerClassName}
            actions={[
              {
                label: t('photoAlbums.modifyAlbum'),
                action: onEditAlbum,
                icon: <Edit style={{ width: 18 }} />,
              },
            ]}
          />
        )
      );
    },
    [isAdmin, onEditAlbum, t],
  );

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover unbox />}
      <PhotoAlbumCover
        renderActions={renderActions}
        cover={photoAlbum?.coverUrl}
      />
      <Box p={2}>
        <Spacer height="20px" />
        <PhotoAlbum images={photoAlbum?.images} />
        <Spacer height="20px" />
      </Box>
    </div>
  );
};

PhotoAlbumScreen.displayName = 'PhotoAlbumScreen';

export default React.memo(PhotoAlbumScreen);
