import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import LoginBox from '../LoginBox';

type Props = {
  title?: string;
  text?: string;
  showRegister?: boolean;
  open: boolean;
  onClose: () => void;
  onLoginCallback?: () => void;
};

const LoginDialog: React.FC<Props> = ({
  title,
  text,
  open,
  onClose,
  showRegister,
  onLoginCallback: onLoginCallbackProp,
}) => {
  const { t } = useTranslation();
  const onLoginCallback = useCallback(() => {
    onClose();
    if (onLoginCallbackProp) {
      onLoginCallbackProp();
    }
  }, [onClose, onLoginCallbackProp]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title || t('shared.login')}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <LoginBox
          width="100%"
          showRegister={showRegister}
          onLoginCallback={onLoginCallback}
        />
      </DialogContent>
    </Dialog>
  );
};

LoginDialog.displayName = 'LoginDialog';

export default React.memo(LoginDialog);
