import React from 'react';
import { Typography } from '@material-ui/core';
import { TypographyTypeMap } from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import DividerButton from '../DividerButton';
import { HtmlDecode, Spacer } from '../../atoms';
import { useGlobalStyles } from '../../../hooks';

type Props = {
  showMoreLabel?: string;
  showLessLabel?: string;
  content: string;
  limit?: number;
  typographyProps?: TypographyTypeMap;
};

const ExpandableText: React.FC<Props> = ({
  showMoreLabel,
  showLessLabel,
  content = '',
  limit,
  typographyProps = {},
}) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [expanded, toggleExpanded] = useToggleState(false);
  const limitedContent = content.substr(0, limit) + '...';
  const showMore: string = showMoreLabel || t('shared.showMore');
  const showLess: string = showLessLabel || t('shared.showLess');
  const label = expanded ? showLess : showMore;
  const canExpand = limit && content.length > limit;
  return (
    <>
      <Typography
        className={expanded || !canExpand ? globalStyle.preWrap : undefined}
        style={{ wordBreak: 'break-word' }}
        {...typographyProps}
      >
        {expanded || !canExpand ? (
          <HtmlDecode>{content}</HtmlDecode>
        ) : (
          <HtmlDecode>{limitedContent}</HtmlDecode>
        )}
      </Typography>
      <Spacer height="20px" />
      {canExpand && <DividerButton onClick={toggleExpanded} label={label} />}
    </>
  );
};

ExpandableText.displayName = 'ExpandableText';

export default React.memo(ExpandableText);
