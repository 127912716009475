import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
  LightgalleryProvider,
  LightgalleryItem,
  useLightgallery,
} from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';
import { useLocation } from 'react-router';
import { useGlobalStyles } from '../../../hooks';
import { usePhotoAlbums } from '../../../hooks/photoAlbums';
import { PopulatedPhotoAlbum, User } from '../../../store/types';
import { DividerWithSpace, LabeledBox, ReportAbuse } from '../../molecule';
import { GlobalShareContext } from '../../../App';
import useStyle from './styles';

type Props = {
  eventId: string;
  album?: any;
};
const OpenButtonWithHook = () => {
  const { openGallery } = useLightgallery();
  const [done, setDone] = useState(false);
  const { hash } = useLocation();
  useEffect(() => {
    if (!done) {
      setDone(true);
      const slide = hash?.split('slide=')?.[1];
      if (slide) {
        setTimeout(() => {
          openGallery('gr', Number(slide));
        }, 2000);
      }
    }
  }, [done, hash, openGallery]);
  return null;
};
const PhotoGallery: React.FC<Props> = ({ eventId }) => {
  const { getPhotoAlbumsByEvent } = usePhotoAlbums({
    selectedEventIds: [eventId],
  });
  const { setGlobalShareOpen } = useContext(GlobalShareContext);
  const [photoAlbums, setPhotoAlbums] = useState<PopulatedPhotoAlbum[]>([]);
  const [photoGallery, setPhotoGallery] = useState<string[]>();

  const photographersList: User[] = Array.from(
    photoAlbums?.reduce((list, album) => {
      if (album.author) {
        list.add(album.author);
      }
      return list;
    }, new Set<User>()) ?? [],
  );

  const globalStyles = useGlobalStyles();
  const style = useStyle();

  useEffect(() => {
    if (eventId) {
      setPhotoAlbums(getPhotoAlbumsByEvent(eventId));
    }
  }, [eventId, getPhotoAlbumsByEvent]);

  useEffect(() => {
    if (photoAlbums?.length) {
      setPhotoGallery(
        photoAlbums.reduce(
          (a: string[], b) => [...a, ...((b.images || []) as string[])],
          [],
        ),
      );
    }
  }, [photoAlbums]);

  if (!photoAlbums?.length) {
    return null;
  }

  return (
    <>
      <LabeledBox label="Gallery">
        <>
          <Box textAlign="center">
            <>
              <div className={globalStyles.mb1}>
                <Typography variant="caption">Photographers</Typography>
                <ReportAbuse
                  category="events"
                  item={{
                    type: 'Event',
                    id: eventId,
                  }}
                />
              </div>
              {photographersList?.length <= photoAlbums?.length &&
                photographersList?.map((photographer, i) => (
                  <Chip
                    key={`photographer-${i}`}
                    color="primary"
                    className={[globalStyles.mh1, style.chip].join(' ')}
                    avatar={
                      <Avatar
                        src={
                          photographer?.fbProfilePicture ||
                          photographer?.photoUrl ||
                          photographer?.avatarUrl
                        }
                        sizes="200"
                        alt={photographer.displayName}
                      >
                        {photographer?.displayName?.[0]?.[0]}
                      </Avatar>
                    }
                    label={photographer.displayName}
                  />
                ))}
            </>
          </Box>
          <DividerWithSpace space="10px" />
          <div className={style.photoWrapper}>
            <LightgalleryProvider
              onAfterOpen={() => setGlobalShareOpen(true)}
              onCloseAfter={() => setGlobalShareOpen(false)}
              plugins={['lg-hash.js']}
              portalElementSelector="#root"
              lightgallerySettings={{
                selector: '#root .react_lightgallery_gallery',
              }}
            >
              {photoGallery?.map((image, i) => (
                <LightgalleryItem
                  group="gr"
                  src={image}
                  thumb={image}
                  key={image}
                >
                  <div className={globalStyles.relative} key={image}>
                    <img src={image} className={style.photo} alt={`img-${i}`} />
                  </div>
                </LightgalleryItem>
              ))}
              {photoGallery?.length && <OpenButtonWithHook />}
            </LightgalleryProvider>
          </div>
        </>
      </LabeledBox>
    </>
  );
};

PhotoGallery.displayName = 'PhotoGallery';

export default React.memo(PhotoGallery);
