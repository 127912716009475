import React from 'react';
import styled from 'styled-components';

type Props = {
  flexDirection?: 'row' | 'column';
  justifyContent?: 'start' | 'end' | 'center';
  alignContent?: 'start' | 'end' | 'center';
  alignItems?: 'start' | 'end' | 'center';
  height?: string;
  width?: string;
  wrap?: boolean;
};

const Flex = styled.div<Props>`
  display: flex;
  min-height: ${({ height = '100%' }) => height};
  min-width: ${({ width = '100%' }) => width};
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  align-content: ${({ alignContent = 'center' }) => alignContent};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
`;

Flex.displayName = 'Flex';

export default React.memo(Flex);
