import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../store/types';
import { CardWidget, Slider } from '../../molecule';
import EventCardWidget from '../EventCardWidget';
import { Emoji } from '../../atoms';

type Props = {
  items: number;
  loading?: boolean;
  events?: Event[];
};

const TopEventsCarousel: React.FC<Props> = ({ items, events, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Slider items={items} pagination paginationPosition="below">
        {new Array(items).fill(true).map((_, i) => (
          <div key={i}>
            <CardWidget.Skeleton withLogo withActions />
          </div>
        ))}
      </Slider>
    );
  }

  if (!loading && !events?.length) {
    return (
      <Box textAlign="center" padding={2}>
        <Typography variant="h2">
          <Emoji emoji="🤷‍♂️" />
        </Typography>
        {t('event.noEvents')}
      </Box>
    );
  }

  return (
    <>
      <Slider items={items} pagination paginationPosition="below" equalHeight>
        {events?.map(event => (
          <div key={event.id}>
            <EventCardWidget event={event} />
          </div>
        ))}
      </Slider>
    </>
  );
};

TopEventsCarousel.displayName = 'TopEventsCarousel';

export default React.memo(TopEventsCarousel);
