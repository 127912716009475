import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  card: {
    background: 'black',
  },
  dateWrapper: {
    height: 65,
    width: 65,
    position: 'absolute',
    left: theme.spacing(0.5),
    transform: 'translateY(-50%)',
    top: '50%',
  },
  CardMedia: {
    height: 75,
    opacity: '0.4',
  },
  pageTitle: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    fontWeight: 900,
    left: 80,
    fontSize: 13,
    lineHeight: '18px',
    color: theme.palette.secondary.light,
    paddingRight: 10,
  },
}));
