import React, { useCallback } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram } from '@material-ui/icons';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { Spacer } from '../../atoms';
import { Venue } from '../../../store/types';
import { GoogleMap } from '../../molecule';
import { checkIcon } from '../../../constants';
type Props = {
  venue?: Venue;
};

const VenueDetails: React.FC<Props> = ({ venue }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const renderField = useCallback(
    (title, content) => (
      <>
        <Typography>
          <b>{title}: </b>
          <br />
          {content}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [],
  );

  const renderName = useCallback(
    venue => (
      <>
        <Typography>
          <b>{t('venue.name')}: </b>
          <br />
          {venue?.name}{' '}
          {venue?.isVerified ? (
            <img src={checkIcon} alt="Verified" width="15" />
          ) : null}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [t],
  );
  return (
    <Box
      className={globalStyle.flex}
      justifyContent="center"
      alignItems="center"
      flexDirection={isMobile ? 'column-reverse' : 'column'}
    >
      {venue?.fullAddress && (
        <GoogleMap location={JSON.parse(venue?.fullAddress)} />
      )}
      <Spacer height="10px" />
      <Paper variant="outlined" className={globalStyle.size100}>
        <Box p={2}>
          {renderName(venue)}
          {renderField(t('venue.address'), venue?.address)}
          {renderField(t('venue.city'), venue?.city)}
          {renderField(t('venue.region'), t(`shared.regions.${venue?.region}`))}
          {venue?.website && renderField(t('venue.website'), venue?.website)}
          {renderField(
            t('venue.musicStyles'),
            venue?.musicStyles?.map(music => (
              <Chip
                component="span"
                key={music}
                label={t(`shared.musicStyles.${music}`)}
                color="secondary"
                variant="outlined"
                size="small"
                className={[globalStyle.mr1, globalStyle.mb1].join(' ')}
              />
            )),
          )}
          {renderField(
            t('venue.followUs'),
            <>
              {venue?.facebook && (
                <IconButton onClick={() => window.open(venue.facebook)}>
                  <Facebook />
                </IconButton>
              )}
              {venue?.instagram && (
                <IconButton onClick={() => window.open(venue.instagram)}>
                  <Instagram />
                </IconButton>
              )}
            </>,
          )}
        </Box>
      </Paper>
    </Box>
  );
};

VenueDetails.displayName = 'VenueDetails';

export default React.memo(VenueDetails);
