import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewsItem as TNewsItem, NewsCategory } from '../../../store/types';
import { FormField } from '../../molecule';
import { Spacer } from '../../atoms';
import { useAuthentication, useMediaQuery } from '../../../hooks';
import { RegisterNewsCategory } from '..';
import RegisterNewsCategoryBtn from '../RegisterNewsCategory/RegisterNewsCategoryBtn';
import { useToggleState } from '../../../hooks/ui/useToggleState';

export type Form = TNewsItem;

type Props = {
  newsItem?: Form;
  editorValue: string;
  setEditorValue: any;
  onSubmit: (values: Form) => void;
  customErrors?: Partial<Form>;
  newsCategories: NewsCategory[];
};

const NewsForm: React.FC<Props> = ({
  onSubmit,
  customErrors,
  editorValue,
  setEditorValue,
  newsItem,
  newsCategories,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const [updateDialog, toggleUpdateDialog] = useToggleState(false);
  const { profile } = useAuthentication();

  const { handleSubmit, register, errors, control } = useForm<Form>({
    defaultValues: { description: '', ...newsItem },
  });

  const categories = newsCategories.map(cat =>
    Object.assign({}, { id: cat.id, value: cat.name }),
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            name="title"
            label={t('newsItem.name')}
            register={register}
            required
            error={errors.title?.message}
          />
          {!isMobile && <Spacer height="10px" />}
        </Grid>
        <Grid item xs={12}>
          {profile?.roles?.includes('admin') && (
            <RegisterNewsCategoryBtn toggleUpdateDialog={toggleUpdateDialog} />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="newsCategories"
            label={t('newsItem.newsCategories')}
            type="checkbox"
            langDomain="newsCategories"
            options={categories}
            defaultOptions={newsItem?.newsCategories}
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="description"
            label={t('newsItem.description')}
            type="editor"
            required
            initialValue={editorValue}
            control={control}
            setValue={setEditorValue}
            register={register}
            error={customErrors?.description}
          />
          <Spacer height="20px" />
        </Grid>
        <Grid container justify="space-around">
          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
          >
            {newsItem ? t('newsItem.edit') : t('newsItem.goLive')}
          </Button>
        </Grid>
      </Grid>
      <Spacer height="40px" />
      <RegisterNewsCategory
        toggleUpdateDialog={toggleUpdateDialog}
        updateDialog={updateDialog}
      />
    </form>
  );
};

NewsForm.displayName = 'NewsForm';

export default React.memo(NewsForm);
