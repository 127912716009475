import { useHistory } from 'react-router';
import React, { useCallback } from 'react';
import { Assignment, Edit, ExpandLess, ExpandMore } from '@material-ui/icons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  fade,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import GuestListDetails from '../GuestListDetails';
import { GuestList as GuestListType } from '../../../../store/types';
import GuestListForm from '../../GuestListForm';
import { useToggleState } from '../../../../hooks/ui/useToggleState';
import { useGuestList, useMediaQuery } from '../../../../hooks';
import { openPdf, downloadPdf } from '../../../../utils/pdf';
import { createDocumentDefinition } from './utils/createGuestListDocumentDefinition';
import { usePdfCreation } from './hooks/usePdfCreation';

type Props = {
  guestList: GuestListType;
  isAdmin?: boolean;
  isRegistered?: boolean;
  canRegisterGuestList?: boolean;
  registeredToDifferentList: boolean;
  match: {
    params: { [key: string]: string };
  };
};

const useStyle = makeStyles(theme => ({
  wrapperMobile: {
    paddingRight: 0,
  },
  actionsWrapperMobile: {
    width: '100%',
    position: 'static',
    transform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderTop: `1px solid ${fade(theme.palette.primary.main, 0.2)}`,
  },
}));

const GuestList: React.FC<Props> = ({
  guestList,
  isAdmin,
  isRegistered,
  match,
  canRegisterGuestList,
  registeredToDifferentList,
}) => {
  const { event: slugOrId } = match.params;
  const { isMobile } = useMediaQuery();
  const style = useStyle();
  const { t } = useTranslation();
  const [open, toggleDetails] = useToggleState(false);
  const [updateDialog, toggleUpdateDialog] = useToggleState(false);
  const { updateGuestList } = useGuestList({ id: guestList.id });
  const { generatePdfData } = usePdfCreation({ guestList });
  const history = useHistory();

  const onSubmit = async (values: any) => {
    updateGuestList({ ...values });
    toggleUpdateDialog();
  };

  const goToConfPage = useCallback(() => {
    history.push(`/events/${slugOrId}/confirmation`);
  }, [history, slugOrId]);

  const viewPdf = useCallback(async () => {
    const pdfData = await generatePdfData();
    const documentDefinition = createDocumentDefinition(pdfData);
    openPdf(documentDefinition);
  }, [generatePdfData]);

  const downloadAsPdf = useCallback(async () => {
    const pdfData = await generatePdfData();
    const documentDefinition = createDocumentDefinition(pdfData);
    downloadPdf(documentDefinition);
  }, [generatePdfData]);

  const canEditGuestList = canRegisterGuestList;

  return (
    <>
      <ListItem
        onClick={toggleDetails}
        button
        className={isMobile ? style.wrapperMobile : undefined}
      >
        <ListItemAvatar>
          <Avatar>
            <Assignment />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={guestList.name}
          secondary={
            isRegistered
              ? t('guestList.youAreRegistered')
              : t('guestList.registerForFree')
          }
        />
        <ListItemSecondaryAction
          className={isMobile ? style.actionsWrapperMobile : undefined}
        >
          {isAdmin && (
            <>
              <IconButton
                onClick={toggleUpdateDialog}
                disabled={!canEditGuestList}
              >
                <Edit />
              </IconButton>
              <IconButton onClick={viewPdf}>
                <PictureAsPdfIcon />
              </IconButton>
              <IconButton onClick={downloadAsPdf}>
                <GetAppIcon />
              </IconButton>
            </>
          )}
          {isRegistered && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={goToConfPage}
              size="small"
            >
              {t('guestList.confirmation')}
            </Button>
          )}
          <IconButton onClick={toggleDetails}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {!open && <Divider variant={!isMobile ? 'inset' : 'fullWidth'} />}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <GuestListDetails
          isRegistered={!!isRegistered}
          guestList={guestList}
          canRegisterGuestList={canRegisterGuestList}
          registeredToDifferentList={registeredToDifferentList}
        />
      </Collapse>
      <Dialog open={updateDialog} onClose={toggleUpdateDialog}>
        <DialogTitle>
          {t('guestList.editGuestList')} {guestList.name}
        </DialogTitle>
        <DialogContent>
          <GuestListForm onSubmit={onSubmit} guestList={guestList} />
        </DialogContent>
      </Dialog>
    </>
  );
};

GuestList.displayName = 'GuestList';

export default React.memo(GuestList);
