import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import { ReactReduxFirebaseProviderProps } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import store from './store';

const prod = process.env.NODE_ENV === 'production';

// Firebase Init
const firebaseConfig = {
  apiKey: prod
    ? 'AIzaSyDG79ZqwVtqKFy8UJTyfzTXfy569FW-kRg'
    : 'AIzaSyB8UkHaTCgI_h7WaiVvmRRUiTh_gZL6zPQ',
  authDomain: prod
    ? 'tilllate-bc8d1.firebaseapp.com'
    : 'tilllate-staging.firebaseapp.com',
  databaseURL: prod
    ? 'https://tilllate-bc8d1.firebaseio.com'
    : 'https://tilllate-staging.firebaseio.com',
  projectId: prod ? 'tilllate-bc8d1' : 'tilllate-staging',
  storageBucket: prod
    ? 'tilllate-bc8d1.appspot.com'
    : 'tilllate-staging.appspot.com',
  messagingSenderId: prod ? '565614641933' : '727906304389',
  appId: prod
    ? '1:565614641933:web:bcd13576286d9ecad0a644'
    : '1:727906304389:web:d605cbc68456be9b4984ec',
  measurementId: prod ? 'G-2GRQG1KVM2' : 'G-5RYYSMQCX6',
};

const rrfConfig = {
  userProfile: 'users_meta',
  useFirestoreForProfile: true,
};

export const rrfProps: ReactReduxFirebaseProviderProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

firebase.initializeApp(firebaseConfig);

export const Firebase = firebase;
export const Firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const analytics = firebase.analytics();
