import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LabeledBox, SectionHeader } from '../../molecule';
import { useEvents } from '../../../hooks';
import { Spacer } from '../../atoms';
import Item from './Item';

type Props = {
  limit?: number;
  unbox?: boolean;
  disableCover?: boolean;
};

const MyEvents: React.FC<Props> = ({ unbox, limit, disableCover }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { myEvents } = useEvents({
    myEvents: true,
  });

  const nextEvents = myEvents.filter(
    e => e?.timestamp && e?.timestamp > new Date().getTime(),
  );

  if (unbox) {
    return (
      <>
        {myEvents.length > 0 ? (
          <Box>
            <SectionHeader
              titleVariant="body1"
              title={t('profile.myNextEvents')}
            />
            {nextEvents.slice(0, limit).map(event => (
              <Item event={event} disableCover={disableCover} key={event.id} />
            ))}
            {limit && nextEvents.length > limit && (
              <>
                <Spacer height="8px" />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push({
                      pathname: '/profile-dashboard',
                      state: { selectedMenuItem: 'myEvents' },
                    })
                  }
                >
                  {t('event.allMyEvents')}
                </Button>
              </>
            )}
          </Box>
        ) : null}
      </>
    );
  }

  return (
    <>
      {myEvents.length > 0 ? (
        <LabeledBox label={t('profile.myEvents')}>
          <Grid container spacing={2}>
            {myEvents.slice(0, limit).map(event => (
              <Grid item xs={12} key={event.id}>
                <Item event={event} disableCover={disableCover} />
              </Grid>
            ))}
          </Grid>
        </LabeledBox>
      ) : null}
    </>
  );
};

MyEvents.displayName = 'MyEvents';

export default React.memo(MyEvents);
