import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAuthentication,
  useCompressedImage,
  useGlobalStyles,
  useVenue,
  useVerification,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import VenueForm from '../VenueForm';
import { Form } from '../VenueForm/VenueForm';
import { LoadingCover, Spacer } from '../../atoms';
import ResendVerificationEmailButton from '../ResendVerificationEmailButton';
import { multipleEmailCheck } from '../../../utils/ui/isEmail';

const RegisterVenue: React.FC = () => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, , setCompressCover] = useCompressedImage();
  const [logo, , setCompressLogo] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { registerVenue, loading } = useVenue();
  const { verified, profile } = useAuthentication();
  const { onPageCreationEmail } = useVerification();
  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    if (!values.fullAddress) {
      setCustomErrors(prev => ({
        ...prev,
        fullAddress: t('form.invalidAddress'),
      }));
      return;
    }
    if (values?.additionalEmails) {
      if (!multipleEmailCheck(values.additionalEmails)) {
        setCustomErrors(prev => ({
          ...prev,
          additionalEmails: [t('form.invalidEmails')],
        }));
        return;
      }
      let additionalEmails = values.additionalEmails.trim();
      if (additionalEmails.charAt(additionalEmails.length - 1) === ',') {
        additionalEmails = additionalEmails.slice(0, -1);
      }
      values = {
        ...values,
        additionalEmails: additionalEmails.split(','),
      };
    }

    setCustomErrors({});
    try {
      values = {
        ...values,
        email: profile.email,
      };
      const slug = await registerVenue({ cover, logo, ...values });
      const emailsToNotify = values?.additionalEmails
        ? [...values.additionalEmails, values.email]
        : [values.email];
      emailsToNotify.forEach(email => onPageCreationEmail(slug, email));
    } catch (error) {}
  };

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      {!verified ? (
        <ResendVerificationEmailButton />
      ) : (
        <>
          <PageCoverAndLogo.Form
            logo={logo}
            setLogo={setCompressLogo}
            cover={cover}
            setCover={setCompressCover}
          />
          <VenueForm onSubmit={onSubmit} customErrors={customErrors} />
        </>
      )}
    </div>
  );
};

RegisterVenue.displayName = 'RegisterVenue';

export default React.memo(RegisterVenue);
