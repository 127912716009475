import { Box, Grid } from '@material-ui/core';
import React from 'react';
import {
  Event,
  Organization,
  Venue,
  NewsItem,
  Artist,
  SearchResult,
  SearchCategoryTypes,
  SearchResults as SearchResultsT,
  PhotoGallery,
} from '../../../store/types';
import {
  EventsList,
  OrganizationsList,
  VenuesList,
  NewsList,
  ArtistsList,
  GalleryWidget,
} from '../../molecule';
import SearchResultList from './SearchResultList';

type Props = {
  results?: SearchResultsT | undefined;
  collection: SearchCategoryTypes;
  loaded?: boolean;
};

const SearchResults: React.FC<Props> = ({ results, collection, loaded }) => {
  const collections = {
    all: (
      <SearchResultList results={results as SearchResult[]} loading={!loaded} />
    ),
    futureEvent: <EventsList events={results as Event[]} loading={!loaded} />,
    pastEvent: <EventsList events={results as Event[]} loading={!loaded} />,
    venue: <VenuesList venues={results as Venue[]} loading={!loaded} />,
    organization: (
      <OrganizationsList
        organizations={results as Organization[]}
        loading={!loaded}
      />
    ),
    news: <NewsList news={results as NewsItem[]} loading={!loaded} />,
    artist: <ArtistsList artists={results as Artist[]} />,
    photoAlbum: () => {
      return (
        <Grid container spacing={2}>
          {results &&
            (results as PhotoGallery[]).map(gallery => (
              <Grid item xs={12} sm={6} key={gallery.id}>
                <GalleryWidget gallery={gallery} />
              </Grid>
            ))}
        </Grid>
      );
    },
  };

  if (loaded && (!results || !results.length)) {
    return <Box>There is no any results for this search</Box>;
  }

  return <Box>{collections[collection]}</Box>;
};

SearchResults.displayName = 'SearchResults';

export default React.memo(SearchResults);
