// import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { GuestListRegistration } from '../../types';
import {
  ChatRoom,
  DateRoom,
  DateRoomPlayer,
  Message,
} from '../../types/dateRooms';
import { tilldateConfigurationState } from '../selectors';

export const dateRoomsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: DateRoom } => firestore?.data?.dateRooms ?? {},
);

export const dateRoomsListSelector = createSelector(
  dateRoomsSelector,
  (rooms): DateRoom[] =>
    Object.entries(rooms || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const dateRoomByEventSelector = (eventId?: string) =>
  createSelector(
    dateRoomsListSelector,
    (roomsList): DateRoom | null =>
      roomsList?.find(room => room.eventId === eventId) ?? null,
  );

export const myDateRoomsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: DateRoom } =>
    firestore?.data?.myDateRooms ?? {},
);

export const myDateRoomsListSelector = createSelector(
  myDateRoomsSelector,
  (events): DateRoom[] =>
    Object.entries(events || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const selectedDateRoomSelector = createSelector(
  tilldateConfigurationState,
  tilldateState => tilldateState.selectedRoom,
);

export const myDateRoomsFilteredSelector = (
  guestListRegistrationsByUser: GuestListRegistration[],
) =>
  createSelector(myDateRoomsListSelector, myDateRooms =>
    myDateRooms?.filter((room: DateRoom) =>
      // dayjs().isBefore(room.end) &&
      // dayjs().isAfter(room.start) &&
      guestListRegistrationsByUser
        ?.find(gl => gl.eventId === room.eventId)
        ?.guests.every(g => g.accessed),
    ),
  );

export const dateRoomPlayersSelector = (roomId?: string) =>
  createSelector(
    firestoreState,
    (firestore): { [key: string]: DateRoomPlayer } =>
      firestore?.data?.[`dateRoomPlayers/${roomId}`] ?? {},
  );

export const dateRoomPlayersListSelector = (roomId?: string) =>
  createSelector(dateRoomPlayersSelector(roomId), (players): DateRoomPlayer[] =>
    Object.entries(players || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
  );

export const chatRoomsSelector = (roomId?: string) =>
  createSelector(
    firestoreState,
    (firestore): { [key: string]: ChatRoom } =>
      firestore?.data?.[`chatRooms/${roomId}`] ?? {},
  );

export const chatRoomsListSelector = (roomId?: string) =>
  createSelector(chatRoomsSelector(roomId), (rooms): ChatRoom[] =>
    Object.entries(rooms || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
  );

export const chatRoomMessagesSelector = (chatRoomId?: string) =>
  createSelector(
    firestoreState,
    (firestore): { [key: string]: Message } =>
      firestore?.data?.[`chatRoom/${chatRoomId}/messages`] ?? {},
  );

export const chatRoomMessagesListSelector = (chatRoomId?: string) =>
  createSelector(chatRoomMessagesSelector(chatRoomId), (chatRoom): Message[] =>
    Object.entries(chatRoom || {})
      .map(([id, value]) => ({
        ...value,
        id,
      }))
      .sort((a, b) => a.time - b.time),
  );
