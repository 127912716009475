import React, { createContext, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';

// import Home from "./views/Home";
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';
import { ThemeProvider } from '@material-ui/styles';
import { IonReactRouter } from '@ionic/react-router';
import LandingPage from './views/LandingPage';
import LandingThankYou from './views/LandingThankYou';
import LandingBusiness from './views/LandingBusiness';
import RegisterBusiness from './views/RegisterBusiness';
import it from './lang/it';
// import en from './lang/en';
import { ProtectedRoute } from './components/organisms';
import Login from './views/Login';
import {
  useAuthentication,
  useEvents,
  useGlobalStyle,
  usePhotoAlbums,
  useVenues,
} from './hooks';
import { Preloader } from './components/molecule';
import {
  GlobalShare,
  NotificationsContainer,
  ScrollRestoration,
} from './components/atoms';

import Profile from './views/Profile';
import Register from './views/Register';
import Homepage from './views/Homepage';
import Venues from './views/Venues';
import RegisterVenue from './views/RegisterVenue';
import Venue from './views/Venue';
import CreateEvent from './views/CreateEvent';
import EditVenue from './views/EditVenue';
import Event from './views/Event';
import EditEvent from './views/EditEvent';
import CreatePhotoAlbum from './views/CreatePhotoAlbum';
import PhotoAlbum from './views/PhotoAlbum';
import EditPhotoAlbum from './views/EditPhotoAlbum';
import EditOrganization from './views/EditOrganization';
import Organization from './views/Organization';
import Organizations from './views/Organizations';
import RegisterOrganization from './views/registerOrganization';
import RegisterArtist from './views/RegisterArtist';
import Artist from './views/Artist';
import Artists from './views/Artists';
import EditArtist from './views/EditArtist';
import RegisterNews from './views/RegisterNews';
import News from './views/News';
import NewsItem from './views/NewsItem';
import EditNewsItem from './views/EditNews';
import Category from './views/Category';
import ConfirmationPage from './views/ConfirmationPage';
import RegisterEventGuests from './views/RegisterEventGuests';
import Photos from './views/Photos';
import SearchPage from './views/SearchPage';
import ConfirmEmail from './views/ConfirmEmail/ConfirmEmail';
import Events from './views/Events';
import EditProfile from './views/EditProfile';
import ProfileDashboard from './views/ProfileDashboard';
import TillDateScreen from './views/TillDate';
import TillDateMatch from './views/TillDateMatch';
import TillDateProfile from './views/TillDateProfile';
import TillDateChat from './views/TillDateChat/TillDateChat';
import TillDateLikes from './views/TillDateLikes';
import 'react-toastify/dist/ReactToastify.css';
import themeTillDate from './themeTillDate';
import ActivateVenue from './views/ActivateVenue';
import BecomePhotographer from './views/BecomePhotographer';
import PrivacyPolicyPage from './views/PrivacyPolicy';
import Support from './views/Contact/Contact';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // lng: "en",
    debug: false,
    resources: {
      // en: {
      //   translation: en,
      // },
      it: {
        translation: it,
      },
    },
    fallbackLng: 'it',
    // supportedLngs: ['en', 'it'],
    supportedLngs: ['it'],
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
  });

dayjs.locale(i18next.language);

type GlobalStyle = {
  globalStyle: { [key: string]: any };
};

type TGlobalShare = {
  globalShareOpen: boolean;
  setGlobalShareOpen: any;
};

export const GlobalStyleContext = createContext<GlobalStyle>({
  globalStyle: {},
});

export const GlobalShareContext = createContext<TGlobalShare>({
  globalShareOpen: false,
  setGlobalShareOpen: null,
});

const App: React.FC = () => {
  const { isDeactivated } = useAuthentication();
  const globalStyle = useGlobalStyle();
  const { loaded: eventsLoaded } = useEvents();
  const { loaded: venuesLoaded } = useVenues();
  const { loaded: albumLoaded } = usePhotoAlbums();
  const [globalShareOpen, setGlobalShareOpen] = useState(false);

  if (isDeactivated) {
    return (
      <GlobalStyleContext.Provider value={{ globalStyle }}>
        <IonApp>
          <IonReactRouter>
            <ScrollRestoration>
              <IonRouterOutlet>
                <ProtectedRoute path="/" component={Profile} />
              </IonRouterOutlet>
            </ScrollRestoration>
          </IonReactRouter>
        </IonApp>
      </GlobalStyleContext.Provider>
    );
  }

  if (!eventsLoaded || !venuesLoaded || !albumLoaded) {
    return (
      <GlobalStyleContext.Provider value={{ globalStyle }}>
        <Preloader />
      </GlobalStyleContext.Provider>
    );
  }

  return (
    <GlobalStyleContext.Provider value={{ globalStyle }}>
      <GlobalShareContext.Provider
        value={{ globalShareOpen, setGlobalShareOpen }}
      >
        <IonApp>
          <IonReactRouter>
            <NotificationsContainer />
            <ScrollRestoration>
              <IonRouterOutlet>
                <Route exact path="/home" component={Homepage} />
                <Route path="/coming-soon" component={LandingPage} />
                <Route path="/events/:event" exact component={Event} />
                <Route path="/events" exact component={Events} />

                <Route path="/news/:newsitem" exact component={NewsItem} />
                <Route path="/news" exact component={News} />
                <Route path="/news/categories/:category" component={Category} />
                <Route path="/photos" exact component={Photos} />
                <Route path="/business" component={LandingBusiness} />
                <Route path="/search/:category" component={SearchPage} />
                <Route path="/search" component={SearchPage} />
                <Route path="/privacy-policy" component={PrivacyPolicyPage} />
                <Route path="/contact" component={Support} />
                <ProtectedRoute path="/confirm/:id" component={ConfirmEmail} />
                <Route
                  path="/register-your-business"
                  component={RegisterBusiness}
                />
                <Route path="/thank-you" component={LandingThankYou} />
                <ProtectedRoute path="/profile" component={Profile} />
                <ProtectedRoute
                  path="/profile-dashboard"
                  component={ProfileDashboard}
                />
                <ProtectedRoute path="/edit-profile" component={EditProfile} />
                <ProtectedRoute
                  path="/become-photographer"
                  component={BecomePhotographer}
                />
                <ProtectedRoute
                  path="/create-photo-album/:eventId"
                  component={CreatePhotoAlbum}
                  role="photographer"
                />
                <ProtectedRoute
                  path="/photo-album/:album"
                  component={PhotoAlbum}
                />
                <ProtectedRoute
                  path="/edit-photo-album/:album"
                  component={EditPhotoAlbum}
                  role="photographer"
                />
                <ProtectedRoute path="/create-event" component={CreateEvent} />
                <ProtectedRoute
                  path="/events/:event/confirmation"
                  component={ConfirmationPage}
                />
                <ProtectedRoute
                  path="/edit-events/:event"
                  component={EditEvent}
                />
                <ProtectedRoute path="/venues" exact component={Venues} />
                <ProtectedRoute path="/venues/:venue" component={Venue} />
                <ProtectedRoute
                  path="/venues/:venue/activate"
                  component={ActivateVenue}
                />
                <ProtectedRoute
                  path="/edit-venue/:venue"
                  component={EditVenue}
                />
                <ProtectedRoute
                  path="/register-venue"
                  component={RegisterVenue}
                />
                <ProtectedRoute
                  path="/organizations/:organization"
                  component={Organization}
                />
                <ProtectedRoute
                  path="/edit-organization/:organization"
                  component={EditOrganization}
                />
                <ProtectedRoute
                  path="/organizations"
                  exact
                  component={Organizations}
                />
                <ProtectedRoute
                  path="/register-organization"
                  component={RegisterOrganization}
                />
                <ProtectedRoute
                  path="/register-artist"
                  component={RegisterArtist}
                />
                <ProtectedRoute path="/artists/:artist" component={Artist} />
                <ProtectedRoute path="/artists" exact component={Artists} />
                <ProtectedRoute
                  path="/edit-artist/:artist"
                  component={EditArtist}
                />
                <ProtectedRoute
                  path="/register-news"
                  component={RegisterNews}
                  role="editor"
                />
                <ProtectedRoute
                  path="/edit-news/:newsItem"
                  component={EditNewsItem}
                  role="editor"
                />
                <ProtectedRoute
                  path="/register-guests/:event"
                  component={RegisterEventGuests}
                />
                <ThemeProvider theme={themeTillDate}>
                  <ProtectedRoute
                    path="/tilldate"
                    exact
                    component={TillDateScreen}
                  />
                  <ProtectedRoute
                    path="/tilldate-likes"
                    exact
                    component={TillDateLikes}
                  />
                  <ProtectedRoute
                    path="/tilldatematch"
                    exact
                    component={TillDateMatch}
                  />
                  <ProtectedRoute
                    path="/tilldateprofile"
                    exact
                    component={TillDateProfile}
                  />
                  <ProtectedRoute
                    path="/tilldate-chat/:dateRoomId/:chatRoomId"
                    exact
                    component={TillDateChat}
                  />
                </ThemeProvider>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route exact path="/" render={() => <Redirect to="/home" />} />
              </IonRouterOutlet>
            </ScrollRestoration>
          </IonReactRouter>
        </IonApp>
        {globalShareOpen && <GlobalShare />}
      </GlobalShareContext.Provider>
    </GlobalStyleContext.Provider>
  );
};

export default App;
