import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Divider,
  List,
  ListItem,
  makeStyles,
  Drawer,
  Typography,
} from '@material-ui/core';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { Logo, Spacer } from '../../atoms';
import { FooterMenuLink, SectionHeader } from '../../molecule';
import logo from '../../../assets/img/logo_w.png';
import { Link } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const useStyle = makeStyles({
  wrapper: { width: 400, maxWidth: '80vw', height: '100%' },
});

const FooterDrawerMenu: React.FC<Props> = ({ open, onClose, onOpen }) => {
  const style = useStyle();
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        className: globalStyle.bgSecondaryLight,
      }}
    >
      <Box
        className={[
          globalStyle.safeAreaBottom,
          globalStyle.safeAreaTop,
          globalStyle.bgSecondaryDark,
          globalStyle.flexColumn,
          style.wrapper,
        ].join(' ')}
        justifyContent="space-between"
      >
        <Box p={2}>
          <Box
            className={globalStyle.flex}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              className={globalStyle.flexColumn}
              justifyContent="center"
              textAlign="center"
            >
              <Logo logo={logo} width="80%" to="/home" />
            </Box>
          </Box>
          <Spacer height="16px" />
          <Divider className={globalStyle.bgLightOpaque} />
          <Spacer height="16px" />
          <Box>
            <List>
              <SectionHeader
                title="Menu"
                titleVariant="body1"
                variant="light"
              />
              <ListItem dense button>
                <FooterMenuLink title={t('general.venues')} to="/venues" />
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink
                  title={t('general.organizations')}
                  to="/organizations"
                />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink title={t('general.artists')} to="/artists" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              {/*
              <ListItem dense button>
                <FooterMenuLink title={t('general.contactUs')} to="/contact" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink title={t('general.aboutUs')} to="/about" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              */}
            </List>
          </Box>
        </Box>
      </Box>
      <AppBar
        position="relative"
        style={{ padding: isMobile ? '10px 0' : '0' }}
      >
        <Typography
          align="center"
          variant="subtitle1"
          style={{ fontSize: '0.8rem' }}
        >
          <Link to="/privacy-policy">Privacy policy - Terms of Use</Link>
        </Typography>
        <Spacer height="5px" />
        <Typography align="center" variant="subtitle2">
          All Rights Reserved.
        </Typography>
        <Spacer height="5px" />
      </AppBar>
    </Drawer>
  );
};

FooterDrawerMenu.displayName = 'FooterDrawerMenu';

export default React.memo(FooterDrawerMenu);
