import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useOrganizations } from '../../../hooks';
import { Spacer } from '../../atoms';
import { LabeledBox, SectionHeader } from '../../molecule';
import Item from './Item';

type Props = {
  limit?: number;
  unbox?: boolean;
  disableCover?: boolean;
};

const MyOrganizations: React.FC<Props> = ({ unbox, limit, disableCover }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { myOrganizationsList } = useOrganizations({
    myOrganizations: true,
  });

  if (unbox) {
    return (
      <>
        {myOrganizationsList.length > 0 ? (
          <Box>
            <SectionHeader
              titleVariant="body1"
              title={t('profile.myOrganizations')}
            />
            {myOrganizationsList.slice(0, limit).map(organization => (
              <Item
                organization={organization}
                disableCover={disableCover}
                key={organization.id}
              />
            ))}
            {limit && myOrganizationsList.length > limit && (
              <>
                <Spacer height="8px" />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push({
                      pathname: '/profile-dashboard',
                      state: { selectedMenuItem: 'myOrganizations' },
                    })
                  }
                >
                  {t('organization.allMyOrganizations')}
                </Button>
              </>
            )}
          </Box>
        ) : null}
      </>
    );
  }

  return (
    <>
      {myOrganizationsList.length > 0 ? (
        <LabeledBox label={t('profile.myOrganizations')}>
          <Grid container spacing={2}>
            {myOrganizationsList.slice(0, limit).map(organization => (
              <Grid item xs={12} key={organization.id}>
                <Item organization={organization} disableCover={disableCover} />
              </Grid>
            ))}
          </Grid>
        </LabeledBox>
      ) : null}
    </>
  );
};

MyOrganizations.displayName = 'MyOrganizations';

export default React.memo(MyOrganizations);
