import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  DateSelector,
  FilterAutocompleteOptions,
  FilterCategory,
  GalleryWidget,
  MobileFilterDialog,
  PageWrapper,
  SearchBar,
} from '../../components/molecule';
import {
  useGlobalStyles,
  usePhotoAlbums,
  useMediaQuery,
  useVenues,
  useArtists,
  useEvents,
} from '../../hooks';
import useDebounce from '../../hooks/ui/useDebounce';
import { Emoji } from '../../components/atoms';
import { createFilterOptionsArray } from '../../utils/ui/createFilterOptionsArray';

const Photos = () => {
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const [searchFor, setSearchFor] = useState('');
  const debouncedSearchFor = useDebounce(searchFor.trim(), 300);
  const [selectedDate, setSelectedDate] = useState<{
    day: number;
    month: number;
    year: number;
  } | null>(null);
  const [artistsState, setArtistsState] = useState<string[]>([]);
  const [venuesState, setVenuesState] = useState<string[]>([]);
  const [eventsState, setEventsState] = useState<string[]>([]);

  const [searchForVenues, setSearchForVenues] = useState('');
  const { filteredVenues, loaded: loadedVenues } = useVenues({
    searchFor: searchForVenues,
  });

  const [searchForArtists, setSearchForArtists] = useState('');
  const { filteredArtists, loaded: loadedArtists } = useArtists({
    searchFor: searchForArtists,
  });

  const [searchForEvents, setSearchForEvents] = useState('');
  const { filteredEvents, loaded: loadedEvents } = useEvents({
    searchFor: searchForEvents,
  });

  const [page, setPage] = useState(1);
  const {
    filteredPhotoGalleriesByPage: {
      photoGalleries,
      // totalResults
    },
    // loaded,
  } = usePhotoAlbums({
    searchFor: debouncedSearchFor,
    selectedDate,
    selectedVenueIds: venuesState,
    selectedArtistIds: artistsState,
    selectedEventIds: eventsState,
    page,
  });

  useEffect(() => {
    setPage(1);
  }, [
    debouncedSearchFor,
    selectedDate,
    artistsState,
    venuesState,
    eventsState,
  ]);
  // const loadMoreGalleries = useCallback(() => {
  //   setPage(page + 1);
  // }, [page]);

  const renderFilter = useCallback(() => {
    return (
      <Grid container justify="center" style={{ marginTop: 40 }}>
        <Grid item xs={12}>
          <FilterCategory category={t('general.search').slice(0, -3)}>
            <SearchBar
              label={t('event.searchFor')}
              searchText={searchFor}
              setSearchText={setSearchFor}
            />
          </FilterCategory>
        </Grid>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} md={12}>
            <FilterCategory category={t('event.date')}>
              <DateSelector date={selectedDate} setDate={setSelectedDate} />
            </FilterCategory>
          </Grid>
        </Grid>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} md={12}>
            <FilterCategory category={t('event.artists')}>
              <FilterAutocompleteOptions
                options={createFilterOptionsArray(filteredArtists)}
                setIds={setArtistsState}
                setSearchFor={setSearchForArtists}
                loaded={loadedArtists !== undefined && loadedArtists}
                placeholder={t('artist.searchFor')}
              />
            </FilterCategory>
          </Grid>
          <Grid item xs={12} md={12}>
            <FilterCategory category={t('venue.pageTitle')}>
              <FilterAutocompleteOptions
                options={createFilterOptionsArray(filteredVenues)}
                setIds={setVenuesState}
                setSearchFor={setSearchForVenues}
                loaded={loadedVenues !== undefined && loadedVenues}
                placeholder={t('venue.search')}
              />
            </FilterCategory>
          </Grid>
          <Grid item xs={12} md={12}>
            <FilterCategory category={t('general.events')}>
              <FilterAutocompleteOptions
                options={createFilterOptionsArray(filteredEvents)}
                setIds={setEventsState}
                setSearchFor={setSearchForEvents}
                loaded={loadedEvents !== undefined && loadedEvents}
                placeholder={t('event.searchFor')}
              />
            </FilterCategory>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    filteredArtists,
    filteredEvents,
    filteredVenues,
    loadedArtists,
    loadedEvents,
    loadedVenues,
    searchFor,
    selectedDate,
    t,
  ]);

  return (
    <PageWrapper title="Photo galleries">
      <Box paddingY={2}>
        <Grid
          container
          spacing={2}
          justify="center"
          className={globalStyles.ph2}
        >
          {isMobile ? (
            <MobileFilterDialog
              renderFilter={renderFilter}
              label={t('photoAlbums.filter')}
            />
          ) : (
            <Grid item md={3}>
              {renderFilter()}
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            {!photoGalleries?.length ? (
              <Box textAlign="center" padding={2}>
                <Typography variant="h2">
                  <Emoji emoji="🤷‍♂️" />
                </Typography>
                {t('photoAlbums.noAlbums')}
              </Box>
            ) : (
              <Grid container spacing={2}>
                {photoGalleries.map(gallery => (
                  <Grid item xs={12} md={6} key={gallery.id}>
                    <GalleryWidget gallery={gallery} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default Photos;
