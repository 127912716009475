import React from 'react';
import { useTheme } from '@material-ui/core';
import { DashboardHeader } from '../../components/molecule';
import logo from '../../assets/img/logo_w.png';
import { Flex, Spacer } from '../../components/atoms';
import bg from '../../assets/img/bg-business.jpg';
import TextArea from './TextArea';

const LandingBusiness = () => {
  const theme = useTheme();

  return (
    <div
      className="container"
      style={{
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DashboardHeader logo={logo} hideMenu />
      <Spacer height="40px" />
      <Flex alignItems="start" w={12} flexDirection="column">
        <TextArea />
      </Flex>
      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          height: 5,
          width: '100%',
          margin: '50px 0 0',
        }}
      />
      <div
        style={{
          paddingTop: 50,
          paddingLeft: 50,
          paddingRight: 50,
          margin: '0 auto',
          paddingBottom: 100,
          background: `url(${bg}) top center`,
          backgroundSize: '500px',
          height: '100%',
          textAlign: 'center',
          flexGrow: 1,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <img src={logo} alt="Tilllate" />
      </div>
    </div>
  );
};

export default LandingBusiness;
