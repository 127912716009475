import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { CardListWidget, InfiniteScroller, VirtualizedList } from '..';
import { Artist } from '../../../store/types';
import Item from './Item';

type Props = {
  artists: Artist[];
  loading?: boolean;
  loadMore?: () => void;
  totalResults?: number;
  page?: number;
};

const ArtistsList: React.FC<Props> = ({
  artists,
  loading,
  loadMore,
  totalResults,
  page,
}) => {
  const renderItem = useCallback(
    (artist: Artist) => <Item artist={artist} />,
    [],
  );

  if (loading) {
    return (
      <Box marginTop={3}>
        <VirtualizedList
          data={new Array(3).fill(true)}
          renderItem={() => <CardListWidget.Skeleton withLogo withActions />}
        />
      </Box>
    );
  }
  return (
    <Box marginTop={3}>
      {loadMore && totalResults && page !== undefined ? (
        <InfiniteScroller
          data={artists}
          renderItem={renderItem}
          loadMore={loadMore}
          totalResults={totalResults}
          page={page}
        />
      ) : (
        <VirtualizedList data={artists} renderItem={renderItem} />
      )}
    </Box>
  );
};

ArtistsList.displayName = 'ArtistsList';

export default React.memo(ArtistsList);
