import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFirestoreQuerySetting, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { useMedia, useOrganizations } from '..';
import { organizationSelector } from '../../store/selectors/organizations';
import { Organization } from '../../store/types';
import { findSlug } from '../../utils/firebase';
import { useAuthentication } from '../auth';

type Props = {
  slugOrId?: string;
};

type RegisterOrganization = Partial<Organization> & {
  cover?: File[] | string;
  logo?: File[] | string;
};

type UseOrganization = {
  organization: Organization;
  organizationId?: string;
  loading?: boolean;
  loaded?: boolean;
  registerOrganization: (props: RegisterOrganization) => Promise<string>;
  updateOrganization: (props: RegisterOrganization) => void;
  isAdmin: boolean;
};

export const useOrganization = (props: Props = {}): UseOrganization => {
  const { slugOrId } = props;
  const [loading, setLoading] = useState<boolean>();
  const history = useHistory();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { uploadMedia, getMediaData, deleteMedia } = useMedia();
  const { loaded } = useOrganizations();
  const organization = useSelector(organizationSelector(slugOrId || ''));

  const organizationId = organization?.id;

  const isAdmin =
    organization?.created_by === userId ||
    organization?.admins?.includes(userId) ||
    profile?.roles?.includes('admin');

  const registerOrganization = useCallback(
    async ({ cover, logo, ...values }: RegisterOrganization) => {
      let coverUrl: string | null = null;
      let logoUrl: string | null = null;
      setLoading(true);
      const slug = await findSlug(values.name!, '/organizations');
      const pageRef = await firestore.collection('/organizations').add({
        ...values,
        created_by: userId,
        slug,
        isVerified: false,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      if ((!cover || !cover[0]) && (!logo || !logo[0])) {
        setLoading(false);
        history.push(`/organizations/${slug}`);
        return slug;
      }

      if (cover && cover[0]) {
        const coverRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: cover[0],
        });
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      if (logo && logo[0]) {
        const logoRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: logo[0],
        });
        const logoMedia = await getMediaData(logoRef.path);
        logoUrl = logoMedia?.url || null;
      }

      await firestore
        .collection('/organizations')
        .doc(pageRef.id)
        .update({ logoUrl, coverUrl });
      setLoading(false);
      history.push(`/organizations/${slug}`);
      return slug;
    },
    [firestore, getMediaData, history, uploadMedia, userId],
  );

  const updateOrganization = useCallback(
    async ({ cover, admins, logo, ...values }: RegisterOrganization) => {
      if (!organization || !organizationId) {
        return;
      }
      let coverUrl: string | null = organization.coverUrl || '';
      let logoUrl: string | null = organization.logoUrl || '';
      setLoading(true);
      await firestore
        .collection('/organizations')
        .doc(organizationId)
        .update({ ...values, admins });
      if ((!cover || !cover[0]) && (!logo || !logo[0])) {
        setLoading(false);
        return;
      }
      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/pages/${organizationId}`,
          file: cover[0],
        });
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      if (Array.isArray(logo) && logo[0]) {
        const oldLogo = logoUrl;
        const logoRef = await uploadMedia({
          path: `/images/pages/${organizationId}`,
          file: logo[0],
        });
        if (oldLogo) {
          await deleteMedia({ url: oldLogo });
        }
        const logoMedia = await getMediaData(logoRef.path);
        logoUrl = logoMedia?.url || null;
      }
      await firestore
        .collection('/organizations')
        .doc(organizationId)
        .update({ logoUrl, coverUrl, updated_at: new Date().getTime() });
      setLoading(false);

      history.push(`/organizations/${organization.slug}`);
    },
    [
      deleteMedia,
      firestore,
      getMediaData,
      history,
      uploadMedia,
      organization,
      organizationId,
    ],
  );

  useEffect(() => {
    if (!organizationId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'clubs',
      };

      if (slugOrId) {
        query.where = [['slug', '==', slugOrId]];
      }
      firestore.get(query);
    }
  }, [organizationId, slugOrId, firestore]);

  return {
    organization,
    organizationId,
    loading,
    loaded,
    registerOrganization,
    updateOrganization,
    isAdmin,
  };
};
