import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { chatRoomMessagesListSelector } from '../../store/selectors/dateRooms';
import { useAuthentication } from '../auth';
import { useSendNotification } from '../notifications/useSendNotification';
import useDateRoomPlayers from './useDateRoomPlayers';

type UseChat = {
  dateRoomId?: string;
  chatRoomId?: string;
};

const useChat = ({ dateRoomId, chatRoomId }: UseChat) => {
  const firestore = useFirestore();
  const { userId } = useAuthentication();
  const { dateRoomPlayers } = useDateRoomPlayers({ dateRoomId });
  const { sendNotification } = useSendNotification();
  const chatRoomMessages = useSelector(
    chatRoomMessagesListSelector(chatRoomId),
  );

  const sendMessage = useCallback(
    async message => {
      await firestore
        .collection('dateRooms')
        .doc(dateRoomId)
        .collection('chatRooms')
        .doc(chatRoomId)
        .collection('messages')
        .add({
          fromId: userId,
          from: dateRoomPlayers[userId]?.nickname,
          time: new Date().getTime(),
          text: message,
        });
      const to = Object.values(dateRoomPlayers).find(u => u.userId !== userId)
        ?.userId;
      if (to) {
        await sendNotification({
          to,
          title: `Nuovo messaggio da ${dateRoomPlayers[userId]?.nickname}`,
          body: message.substr(0, 100),
          onClick: `/tilldate-chat/${dateRoomId}/${chatRoomId}`,
        });
      }
    },
    [
      chatRoomId,
      dateRoomId,
      dateRoomPlayers,
      firestore,
      sendNotification,
      userId,
    ],
  );

  useEffect(() => {
    if (dateRoomId && userId) {
      firestore.setListener({
        collection: 'dateRooms',
        doc: dateRoomId,
        subcollections: [
          {
            collection: 'chatRooms',
            doc: chatRoomId,
            subcollections: [{ collection: 'messages' }],
          },
        ],
        storeAs: `chatRoom/${chatRoomId}/messages`,
      });
    }
  }, [firestore, userId, dateRoomId, chatRoomId]);

  return { sendMessage, chatRoomMessages };
};

export default useChat;
