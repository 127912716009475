import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  makeStyles,
  Popover,
  Slide,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionProps } from '@material-ui/core/transitions';
import { useMediaQuery } from '../../../hooks';
import { REGIONS } from '../../../constants';
import useLocalStorage from '../../../hooks/ui/useLocalStorage';
import { selectRegion } from '../../../store/actions/configurations/selectRegion';
import { RootState } from '../../../store/reducers';
import { ItalyMap } from '..';
import { setLocation } from './setLocation';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  popover: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

type Props = {
  open: boolean;
  target?: HTMLElement | null;
  onClose: () => void;
};

const LocationFilter: React.FC<Props> = ({ open, target, onClose }) => {
  const { isMobile } = useMediaQuery();
  const classes = useStyles();
  const { state: regionInLS, setState: setRegionInLS } = useLocalStorage<
    typeof REGIONS[0] | null
  >('region');
  const dispatch = useDispatch();
  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const openPopOver = useCallback((currentTarget: HTMLElement) => {
    setAnchorEl(currentTarget);
  }, []);

  const closePopOver = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openedPopOver = Boolean(anchorEl);
  const popOverId = openedPopOver ? 'region-filter-popover' : undefined;

  useEffect(() => {
    if (regionInLS && region === undefined) {
      dispatch(selectRegion(regionInLS));
      setRegionInLS(regionInLS);
      return;
    }
    if (region !== undefined) {
      dispatch(selectRegion(region));
      setRegionInLS(region);
      return;
    }
    if (region === undefined && regionInLS === undefined) {
      setLocation()
        .then(res => {
          if (res === null && regionInLS !== null) {
            dispatch(selectRegion(regionInLS));
          } else {
            dispatch(selectRegion(res));
            setRegionInLS(res);
          }
        })
        .catch(() => {
          dispatch(selectRegion(null));
        });
    }
  }, [dispatch, region, regionInLS, setRegionInLS]);

  useEffect(() => {
    if (open) {
      if (isMobile) {
        openDialog();
      } else {
        if (target) {
          openPopOver(target);
        }
      }
    } else {
      closeDialog();
      closePopOver();
    }
  }, [
    closeDialog,
    closePopOver,
    isMobile,
    open,
    openDialog,
    openPopOver,
    target,
  ]);

  const mobileMap = useMemo(() => {
    return (
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="map-filter-dialog"
        aria-describedby="map-filter-dialog"
        style={{ display: 'none' }}
      >
        <DialogContent>
          <ItalyMap onChange={onClose} region={region} zoom="1" />
        </DialogContent>
      </Dialog>
    );
  }, [dialogOpen, onClose, region]);

  const desktopMap = useMemo(
    () => (
      <Popover
        id={popOverId}
        open={openedPopOver}
        anchorEl={anchorEl}
        onClose={onClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <ItalyMap onChange={onClose} region={region} zoom="0.5" />
        </Box>
      </Popover>
    ),
    [popOverId, openedPopOver, anchorEl, onClose, classes.popover, region],
  );

  return isMobile ? mobileMap : desktopMap;
};

LocationFilter.displayName = 'LocationFilter';

export default React.memo(LocationFilter);
