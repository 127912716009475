import React from 'react';
import { DeleteForever } from '@material-ui/icons';
import {
  ListItem,
  IconButton,
  Grid,
  List,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GuestList as GuestListType } from '../../../../store/types';
import { LabeledBox } from '../../../molecule';
import { useGlobalStyles } from '../../../../hooks';
import { Spacer } from '../../../atoms';

type Props = {
  guestList: GuestListType;
  onDelete?: () => void;
};

const GuestListPreview: React.FC<Props> = ({ guestList, onDelete }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();

  return (
    <>
      <Box
        className={globalStyle.flex}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" className={globalStyle.bold}>
          {guestList.name}
        </Typography>
        <IconButton onClick={onDelete}>
          <DeleteForever />
        </IconButton>
      </Box>
      <Divider />
      <Spacer height="20px" />
      <Grid container spacing={2}>
        <Grid item md={6}>
          <LabeledBox
            label={t('guestList.conditions')}
            boxProps={{ height: '100%' }}
          >
            <List dense>
              {guestList.conditions?.map(condition => (
                <ListItem key={condition.val}>
                  <Typography>{condition.val}</Typography>
                </ListItem>
              ))}
            </List>
          </LabeledBox>
        </Grid>
        <Grid item md={6}>
          <LabeledBox
            label={t('guestList.benefits')}
            boxProps={{ height: '100%' }}
          >
            <List dense>
              {guestList.benefits?.map(benefit => (
                <ListItem key={benefit.val}>
                  <Typography>{benefit.val}</Typography>
                </ListItem>
              ))}
            </List>
          </LabeledBox>
        </Grid>
      </Grid>
    </>
  );
};

GuestListPreview.displayName = 'GuestListPreview';

export default React.memo(GuestListPreview);
