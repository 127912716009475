import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  card: {
    background: 'black',
  },
  logo: {
    height: 65,
    width: 65,
    position: 'absolute',
    left: theme.spacing(0.5),
    transform: 'translateY(-50%)',
    top: '50%',
    border: `${theme.spacing(0.5)}px solid #fff`,
    backgroundColor: theme.palette.secondary.main,
  },
  CardMedia: {
    height: 75,
    opacity: '0.4',
  },
  pageTitle: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    fontWeight: 700,
    left: 80,
    fontSize: 18,
    lineHeight: '18px',
    color: theme.palette.secondary.light,
    paddingRight: 10,
  },
}));
