import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

type StyleProps = {
  bg?: string;
};

export const useCoverStyle = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ bg }) => ({
    '&:not(.MuiDropzoneArea-active)': {
      backgroundColor: grey['200'],
      backgroundImage: bg ? `url(${bg})` : undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      '& .MuiDropzoneArea-textContainer': {
        display: bg ? 'none' : undefined,
      },
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
  }),
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.4,
  },
}));
export const useLogoStyle = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ bg }) => ({
    '&:not(.MuiDropzoneArea-active)': {
      backgroundColor: grey['200'],
      backgroundImage: bg ? `url(${bg})` : undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      '& .MuiDropzoneArea-textContainer': {
        display: bg ? 'none' : undefined,
      },
    },
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    position: 'absolute',
    bottom: 20,
    width: 150,
    height: 150,
    display: 'block',
    minHeight: 0,
    left: 30,
  }),
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.4,

    '& .MuiDropzoneArea-text': {
      fontSize: 15,
      marginTop: 15,
      marginBottom: 15,
    },
    '& .MuiDropzoneArea-icon': {
      width: 30,
      height: 30,
    },
  },
}));
export const useStyle = makeStyles(theme => ({
  cover: {
    height: 320,
    width: '100%',
    objectFit: 'cover',
  },
  coverMobile: {
    height: 170,
    width: '100%',
    objectFit: 'cover',
  },
  logo: {
    position: 'absolute',
    bottom: 20,
    width: 150,
    height: 150,
    display: 'block',
    left: 30,
    border: '2px solid #fff',
    borderRadius: 3,
    backgroundColor: theme.palette.secondary.light,
  },
  ctaSection: {
    position: 'absolute',
    right: 5,
    bottom: 45,
  },
  ctaSectionMobile: {
    position: 'absolute',
    right: 5,
    bottom: 45,
    width: 200,
  },
}));
