import React, { useCallback } from 'react';
import { Button, Chip } from '@material-ui/core';
import { ShareOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Artist } from '../../../store/types';
import { CardListWidget, ShareButton } from '..';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';

type Props = {
  artist: Artist;
  label?: string;
};

const Item: React.FC<Props> = ({ label, artist }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const { slug } = artist || {};
  const goTo = useCallback(() => history.push(`/artists/${slug}`), [
    history,
    slug,
  ]);
  return (
    <CardListWidget
      label={label}
      onClick={goTo}
      coverUrl={artist.coverUrl}
      logoUrl={artist.logoUrl}
      title={artist.name}
      cardActions={[
        {
          type: 'primary',
          label: t('event.future'),
          onClick: () => history.push(`artists/${artist.slug}#future-events`),
        },
        {
          type: 'secondary',
          label: t('event.past'),
          onClick: () => history.push(`artists/${artist.slug}#past-events`),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tilllate.world/artists/${artist.slug}`}
              renderButton={openPopOver => (
                <Button
                  className={globalStyle.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={e => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
    >
      {artist.musicStyles?.length ? (
        artist.musicStyles
          .slice(0, isMobile ? 3 : 4)
          .map((music: any) => (
            <Chip
              component="span"
              key={`${artist.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
            />
          ))
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(' ')}
        />
      )}
    </CardListWidget>
  );
};

Item.displayName = 'Item';

export default React.memo(Item);
