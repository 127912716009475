import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestore,
} from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { Event, GuestList, Venue } from '../../store/types';
import { findSlug } from '../../utils/firebase';
import { useAuthentication } from '../auth';
import { useGuestList } from '../guestLists';
import { useMedia } from '../media';
import { useOrganizations } from '../organizations';
import { populatedEventSelector } from '../../store/selectors/events';

type Props = {
  slugOrId?: string;
  id?: string;
};

type RegisterEvent = Partial<Event> & {
  cover?: File[] | string;
};

type RegisterEventWithGuestLists = RegisterEvent & {
  initialGuestLists: GuestList[];
};

type UseEvent = {
  event: Event;
  eventId?: string;
  loading?: boolean;
  loaded?: boolean;
  registerEvent: (props: RegisterEvent) => Promise<{ slug: any; id: string }>;
  registerEventWithGuestLists: (
    props: RegisterEventWithGuestLists,
  ) => Promise<{ slug: any; id: string }>;
  updateEvent: (props: RegisterEvent) => void;
  associatedVenue?: Venue;
  isAdmin: boolean;
  canUpdateEvent: () => boolean | undefined;
  canCreateAlbum: boolean | undefined;
};

export const useEvent = (props: Props = {}): UseEvent => {
  const { slugOrId, id } = props;
  const [loading, setLoading] = useState<boolean>();
  const firestore = useFirestore();
  const { userId, profile: user } = useAuthentication();
  const { registerGuestList } = useGuestList();
  const { uploadMedia, getMediaData, deleteMedia } = useMedia();
  const history = useHistory();
  const { filteredOrganizations: organizationsByUser } = useOrganizations({
    userId,
  });
  const organizationsByUserIds = organizationsByUser?.map((o: any) => o.id);
  const event = useSelector(populatedEventSelector(slugOrId || '')) as Event;

  const eventId = event?.id;

  const { venue: associatedVenue } = event || {};
  const isAdmin = !!(
    event?.created_by === userId ||
    event?.admins?.includes(userId) ||
    event?.venue?.created_by === userId ||
    event?.venue?.admins?.includes(userId) ||
    user?.roles?.includes('admin')
  );

  const canUpdateEvent = useCallback(() => {
    const organizationsList =
      event && event.organizations && Object.entries(event.organizations);
    return (
      isAdmin ||
      organizationsList?.some(
        o => organizationsByUserIds?.includes(o[0]) && o[1] === 'canEdit',
      )
    );
  }, [event, isAdmin, organizationsByUserIds]);
  const canCreateAlbum =
    ((user?.roles?.includes('photographer') &&
      event?.photographers?.includes(userId)) ||
      isAdmin) &&
    event?.endDate
      ? new Date().getTime() > new Date(event?.endDate).getTime()
      : new Date().getTime() >
        new Date(event?.date).getTime() + 18 * 3600 * 1000;

  const registerEvent = useCallback(
    async ({ cover, date, endDate, ...values }: RegisterEvent) => {
      let coverUrl: string | null = null;

      setLoading(true);
      const formattedDate = dayjs(date, 'D MMMM YYYY @ HH:mm', 'it').toString();
      const formattedEndDate = dayjs(
        endDate,
        'D MMMM YYYY @ HH:mm',
        'it',
      ).toString();
      const timestamp = dayjs(date, 'D MMMM YYYY @ HH:mm', 'it').valueOf();
      const slug = await findSlug(values.name!, '/events');
      const eventRef = await firestore.collection('/events').add({
        ...values,
        endDate: formattedEndDate,
        date: formattedDate,
        timestamp,
        created_by: userId,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
        slug,
      });

      if (!cover || !cover[0]) {
        setLoading(false);
        return { slug, id: eventRef.id };
      }

      const coverRef = await uploadMedia({
        path: `/images/events/${eventRef.id}`,
        file: cover[0],
      });
      const coverMedia = await getMediaData(coverRef.path);
      coverUrl = coverMedia?.url || null;

      await firestore
        .collection('/events')
        .doc(eventRef.id)
        .update({ coverUrl });
      setLoading(false);

      return { slug, id: eventRef.id };
    },
    [firestore, getMediaData, uploadMedia, userId],
  );

  const registerEventWithGuestLists = useCallback(
    async ({
      cover,
      initialGuestLists,
      ...values
    }: RegisterEventWithGuestLists) => {
      const { id, slug } = await registerEvent({ cover, ...values });
      for (const guestList of initialGuestLists) {
        await registerGuestList({ ...guestList, eventId: id });
      }
      return { id, slug };
    },
    [registerEvent, registerGuestList],
  );

  const updateEvent = useCallback(
    async ({ cover, date, endDate, ...values }: RegisterEvent) => {
      if (!event || !eventId) {
        return;
      }
      const newEvent: RegisterEvent = {
        ...values,
        updated_at: new Date().getTime(),
      };
      const formattedDate = dayjs(date, 'D MMMM YYYY @ HH:mm', 'it').toString();
      const timestamp = dayjs(date, 'D MMMM YYYY @ HH:mm', 'it').valueOf();
      const formattedEndDate = dayjs(
        endDate,
        'D MMMM YYYY @ HH:mm',
        'it',
      ).toString();

      if (date) {
        newEvent.date = formattedDate;
        newEvent.timestamp = timestamp;
      }

      if (endDate) {
        newEvent.endDate = formattedEndDate;
      }

      let coverUrl: string | null = event.coverUrl || '';
      setLoading(true);
      await firestore.collection('/events').doc(eventId).update(newEvent);
      if (!cover || !cover[0]) {
        setLoading(false);
        history.push(`/events/${event.slug}`);
        return;
      }
      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/events/${eventId}`,
          file: cover[0],
        });
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      await firestore.collection('/events').doc(eventId).update({ coverUrl });
      setLoading(false);

      history.push(`/events/${event.slug}`);
    },
    [
      event,
      eventId,
      firestore,
      history,
      uploadMedia,
      getMediaData,
      deleteMedia,
    ],
  );

  const loaded = isLoaded(event);

  useEffect(() => {
    if (!eventId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'events',
      };

      if (slugOrId) {
        query.where = [['slug', '==', slugOrId]];
      }

      if (id) {
        query.doc = id;
      }
      firestore.get(query);
    }
  }, [slugOrId, id, firestore, eventId]);

  return {
    event,
    loaded,
    loading,
    eventId,
    isAdmin,
    updateEvent,
    registerEvent,
    associatedVenue,
    registerEventWithGuestLists,
    canUpdateEvent,
    canCreateAlbum,
  };
};
