import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';

type Props = {};

const InfoSection: React.FC<Props> = () => {
  return (
    <IonGrid>
      <IonRow className="ion-align-items-center ">
        <IonCol
          sizeLg="6"
          className="ion-justify-content-center ion-align-items-center"
        >
          <p>
            Cras non dolor. Phasellus a est. Praesent ac sem eget est egestas
            volutpat. Pellentesque dapibus hendrerit tortor. Suspendisse nisl
            elit, rhoncus eget, elementum ac, condimentum eget, diam. <br />
            <br />
            Aenean commodo ligula eget dolor. Aenean commodo ligula eget dolor.
            Pellentesque auctor neque nec urna. Cras varius. Phasellus magna.
            Nullam cursus lacinia erat.
          </p>
        </IonCol>
        <IonCol size="6" className="ion-justify-content-center">
          <p>
            Cras non dolor. Phasellus a est. Praesent ac sem eget est egestas
            volutpat. Pellentesque dapibus hendrerit tortor. Suspendisse nisl
            elit, rhoncus eget, elementum ac, condimentum eget, diam. <br />
            <br />
            Aenean commodo ligula eget dolor. Aenean commodo ligula eget dolor.
            Pellentesque auctor neque nec urna. Cras varius. Phasellus magna.
            Nullam cursus lacinia erat.
          </p>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

InfoSection.displayName = 'InfoSection';

export default React.memo(InfoSection);
