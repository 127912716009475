import { makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

type StyleProps = {
  bg?: string;
};

export const useCoverStyle = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ bg }) => ({
    '&:not(.MuiDropzoneArea-active)': {
      backgroundColor: grey['200'],
      backgroundImage: bg ? `url("${bg}")` : undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      '& .MuiDropzoneArea-textContainer': {
        display: bg ? 'none' : undefined,
      },
    },
    height: 0,
    paddingTop: '25%',
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
  }),
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.4,
  },
}));
export const useStyle = makeStyles({
  cover: {
    height: 400,
    width: '100%',
    objectFit: 'cover',
  },
  ctaSection: {
    position: 'absolute',
    right: 5,
    bottom: 20,
  },
});
