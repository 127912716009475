import { useIonViewWillEnter } from '@ionic/react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { ErrorOutlineTwoTone } from '@material-ui/icons';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { Spacer } from '../../components/atoms';
import { DateSelector } from '../../components/molecule';
import MyPicturesDialog from '../../components/organisms/UpdateProfilePicture/MyPicturesDialog';
import { useAuthentication, useGlobalStyles } from '../../hooks';
import useDateRoomPlayer from '../../hooks/dateRooms/useDateRoomPlayer';
import useDateRoomPlayers from '../../hooks/dateRooms/useDateRoomPlayers';
import useMyDateRooms from '../../hooks/dateRooms/useMyDateRooms';
import { useToggleState } from '../../hooks/ui/useToggleState';
import { getAgeByDate } from '../../utils';
import GenderSelector from './GenderSelector';
import { useStyles } from './styles';

const genders = [
  {
    emoji: '♂',
    text: 'Uomini',
  },
  {
    emoji: '♀️',
    text: 'Donne',
  },
  {
    emoji: '⚥',
    text: 'Entrambi',
  },
];

type Props = {
  setIsEntrance: React.Dispatch<React.SetStateAction<boolean>>;
};

const TillDateEntrance: React.FC<Props> = ({ setIsEntrance }) => {
  const { selectedRoom } = useMyDateRooms();
  const { updateDateRoomPlayer } = useDateRoomPlayer({
    dateRoom: selectedRoom || null,
  });
  const { dateRoomPlayers } = useDateRoomPlayers({
    dateRoomId: selectedRoom?.id,
  });
  const {
    userId,
    profile,
    profilePicture,
    initials,
    updateProfile,
  } = useAuthentication();
  const myPlayerProfile = dateRoomPlayers[userId];
  const [open, toggleOpen] = useToggleState(false);
  const [nickname, setNickname] = useState(profile.nicknameTillDate ?? '');
  const [preferredGender, setPreferredGender] = useState(
    profile?.preferredGenderTillDate ?? '',
  );
  const [birthday, setBirthday] = useState<{
    day: number;
    month: number;
    year: number;
  } | null>(
    profile?.birthday
      ? {
          day: new Date(profile.birthday).getDate() - 1,
          month: new Date(profile.birthday).getMonth(),
          year: new Date(profile.birthday).getFullYear(),
        }
      : null,
  );
  const [gender, setGender] = useState(profile?.gender ?? '');
  const [photo, setPhoto] = useState(profilePicture);
  const globalStyle = useGlobalStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles();
  const firestore = useFirestore();
  const [checkingNickname, setCheckingNickname] = useState(false);
  const [nicknameExists, setNicknameExists] = useState(false);

  useEffect(() => {
    setPhoto(profilePicture);
  }, [profilePicture]);

  const handleAccess = useCallback(async () => {
    if (birthday && preferredGender && nickname && gender) {
      let date = new Date(birthday.year, birthday.month, birthday.day + 1);
      await updateDateRoomPlayer(userId, {
        active: true,
        nickname,
        preferredGender,
        gender,
        profilePicture: photo ?? null,
        profilePictures: profile?.profilePictures ?? [],
        age: getAgeByDate(date),
      });
      dayjs.locale('it');
      const birthdayInItalianFormat = dayjs(date).format('D MMMM YYYY');
      await updateProfile({
        nicknameTillDate: nickname,
        preferredGenderTillDate: preferredGender,
        birthday: birthdayInItalianFormat,
        gender,
      });
      setIsEntrance(false);
    }
  }, [
    birthday,
    gender,
    nickname,
    photo,
    preferredGender,
    profile,
    setIsEntrance,
    updateDateRoomPlayer,
    updateProfile,
    userId,
  ]);

  const goToProfile = useCallback(() => history.push('/profile'), [history]);

  const nicknameAdornment = useMemo(() => {
    if (checkingNickname) {
      return <CircularProgress size="15px" />;
    }
    return nicknameExists ? (
      <ErrorOutlineTwoTone fontSize="small" color="error" />
    ) : undefined;
  }, [checkingNickname, nicknameExists]);

  const verifyNickname = useCallback(
    newNickname => {
      if (newNickname) {
        setCheckingNickname(true);
        setNicknameExists(false);
        firestore
          .collection('users_meta')
          .where('nicknameTillDate', '==', newNickname)
          .get()
          .then(res => {
            if (res.size && newNickname !== profile?.nicknameTillDate) {
              setNicknameExists(true);
            }
          })
          .finally(() => setCheckingNickname(false));
      }
    },
    [firestore, profile],
  );

  useEffect(() => {
    verifyNickname(nickname);
  }, [nickname, verifyNickname]);

  useEffect(() => {
    setPhoto(profilePicture);
    setNickname(profile?.nicknameTillDate ?? '');
  }, [profilePicture, profile]);

  useIonViewWillEnter(() => {
    if (myPlayerProfile && myPlayerProfile?.active) {
      history.push('/tilldate');
    }
  }, [history, myPlayerProfile]);

  if (!myPlayerProfile) {
    return null;
  }

  return (
    <>
      <Typography
        variant="body1"
        align="center"
        className={globalStyle.tilldateMainColor}
      >
        {t('tilldate.entranceNotification')}
      </Typography>
      <Spacer height="30px" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="75%"
        >
          <Avatar
            src={photo}
            variant="square"
            className={[globalStyle.size100, styles.avatar].join(' ')}
          >
            <Typography variant="h2" className={globalStyle.bold}>
              {initials}
            </Typography>
          </Avatar>
          <Spacer height="30px" />
          <Typography align="center" className={styles.fontSize10}>
            {t('tilldate.photoDisclaimer')}
          </Typography>
          <Spacer height="5px" />
          <Button
            onClick={toggleOpen}
            variant="outlined"
            color="primary"
            fullWidth
          >
            {t('tilldate.changePicture')}
          </Button>
          <Spacer height="10px" />
          <Button
            onClick={goToProfile}
            variant="outlined"
            color="primary"
            fullWidth
          >
            {t('tilldate.uploadNewPhoto')}
          </Button>
          <MyPicturesDialog
            disableDelete
            selectLabel={t('profile.useThisPhoto')}
            selectedLabel={t('profile.usedPhoto')}
            open={open}
            toggleOpen={toggleOpen}
          />
          <Spacer height="20px" />
          <TextField
            variant="outlined"
            color="primary"
            label={t('profile.nickname')}
            value={nickname}
            InputProps={{ endAdornment: nicknameAdornment }}
            helperText={nicknameExists ? 'Nickname already taken' : undefined}
            error={nicknameExists}
            fullWidth
            size="small"
            onChange={e => setNickname(e.target.value)}
          />
          <Typography align="center" className={styles.smallDisclaimer}>
            {t('tilldate.nicknameDisclaimer')}
          </Typography>

          {!profile?.gender && (
            <>
              <Spacer height="30px" />
              <Typography>{t('profile.gender')}</Typography>
              <Spacer height="15px" />
              <GenderSelector
                genders={genders.slice(0, 2)}
                selectedGender={gender}
                setSelectedGender={setGender}
                myGender
              />
            </>
          )}
          {!profile?.birthday && (
            <>
              <Spacer height="30px" />
              <DateSelector
                label={t('profile.birthday')}
                date={birthday}
                setDate={setBirthday}
              />
            </>
          )}
          <Spacer height="30px" />
          <Typography>{t('tilldate.preferredGender')}</Typography>
          <Spacer height="15px" />
          <GenderSelector
            genders={genders}
            selectedGender={preferredGender}
            setSelectedGender={setPreferredGender}
          />
          <Spacer height="30px" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="90%"
        >
          <Button
            variant="contained"
            fullWidth
            color="primary"
            disabled={
              !nickname ||
              !preferredGender ||
              !birthday ||
              !gender ||
              nicknameExists
            }
            onClick={handleAccess}
            className={styles.entranceButton}
          >
            {t('tilldate.readyToGo')}
          </Button>
          <Typography align="center" className={styles.smallDisclaimer}>
            {t('tilldate.entranceAttention')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TillDateEntrance;
