import { Chip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles } from '../../../hooks';
import { Venue } from '../../../store/types';
import CardListWidget from '../../molecule/CardListWidget';

type Props = {
  venue: Venue;
  disableCover?: boolean;
};

const MyVenueItem: React.FC<Props> = ({ venue, disableCover }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();

  return (
    <CardListWidget
      onClick={() => history.push(`/venues/${venue?.slug}`)}
      logoUrl={venue.logoUrl}
      coverUrl={venue?.coverUrl}
      disableCover={disableCover}
      title={venue?.name}
      subtitle={venue.address}
      cardActions={[
        {
          type: 'primary',
          label: t('event.future'),
          onClick: () => history.push(`venues/${venue?.slug}#future-events`),
        },
        {
          type: 'secondary',
          label: t('event.past'),
          onClick: () => history.push(`venues/${venue?.slug}#past-events`),
        },
      ]}
    >
      {venue.musicStyles?.length ? (
        <>
          {venue.musicStyles.slice(0, 2).map(music => (
            <Chip
              component="span"
              key={`${venue.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
            />
          ))}
          {venue.musicStyles.length > 2 && (
            <Chip
              variant="outlined"
              size="small"
              component="span"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
              label={`+ ${venue.musicStyles.length - 2}`}
            />
          )}
        </>
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(' ')}
        />
      )}
    </CardListWidget>
  );
};

MyVenueItem.displayName = 'MyVenueItem';

export default React.memo(MyVenueItem);
