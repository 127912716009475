import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles, useArtist, useCompressedImage } from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import ArtistForm from '../ArtistForm';
import { Form } from '../ArtistForm/ArtistForm';
import { LoadingCover, Spacer } from '../../atoms';

const RegisterArtist: React.FC = () => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const { t } = useTranslation();
  const [cover, , setCompressCover] = useCompressedImage();
  const [logo, , setCompressLogo] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const { registerArtist, loading } = useArtist();

  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    setCustomErrors({});
    registerArtist({ cover, logo, ...values });
  };

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <PageCoverAndLogo.Form
        logo={logo}
        setLogo={setCompressLogo}
        cover={cover}
        setCover={setCompressCover}
      />
      <ArtistForm onSubmit={onSubmit} customErrors={customErrors} />
    </div>
  );
};

RegisterArtist.displayName = 'RegisterArtist';

export default React.memo(RegisterArtist);
