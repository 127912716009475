import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { NewsItem } from '../../types';

type FilteredNewsItemsSelector = {
  category?: string;
  searchFor?: string;
  selectedCategoryIds?: string[];
  currentUser?: string;
};

export const newsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: NewsItem } => firestore?.data?.news ?? {},
);

export const newsListSelector = createSelector(
  newsSelector,
  (news): NewsItem[] =>
    Object.entries(news || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const topNewsSelector = createSelector(
  newsListSelector,
  (news): NewsItem[] =>
    news
      .filter(el => el.createdOn)
      .sort(
        (a, b) =>
          new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
      ),
);

export const newsItemSelector = (slugOrId: string) =>
  createSelector(
    newsSelector,
    newsListSelector,
    (news, newsList): NewsItem =>
      news[slugOrId] || newsList.find(v => v.slug === slugOrId),
  );

export const filteredNewsSelector = ({
  category,
  searchFor,
  selectedCategoryIds,
  currentUser,
}: FilteredNewsItemsSelector) =>
  createSelector(newsListSelector, (newsList): NewsItem[] => {
    const filtered =
      newsList?.filter(n => {
        if (category) {
          return n.newsCategories?.includes(category);
        }
        if (searchFor || selectedCategoryIds) {
          const textFilter =
            searchFor &&
            (n.title.toLowerCase().includes(searchFor.toLowerCase()) ||
              n.description.toLowerCase().includes(searchFor.toLowerCase()));
          let categoryFilter: boolean | undefined;
          if (selectedCategoryIds && selectedCategoryIds.length) {
            const categoriesOnNews = n?.newsCategories && n.newsCategories;
            categoryFilter =
              categoriesOnNews &&
              categoriesOnNews?.some(cat => selectedCategoryIds?.includes(cat));
          }
          // Check if the current user reported for abuse
          let reportedForAbuseByUser = false;

          if (currentUser && n.reported_by) {
            reportedForAbuseByUser = n.reported_by.includes(currentUser);
          }

          return (
            (!searchFor || textFilter) &&
            (!selectedCategoryIds?.length || categoryFilter) &&
            !reportedForAbuseByUser
          );
        }
        return true;
      }) ?? [];
    return filtered;
  });
