// eslint-disable-next-line no-useless-escape
const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;

export const getVideoIdFromYoutubeLink = async (url: string) => {
  const parsed = url.match(youtube_regex);
  if (await validYoutubeVideoId(parsed?.[2] || '')) {
    return parsed?.[2];
  }
  return '';
};

export const getVideoStartTimeIfAny = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (error) {
    return 0;
  }

  const queryParams = new URLSearchParams(url.search);
  let startTime = 0;
  const timeFromT =
    queryParams.get('t') && parseInt(queryParams.get('t') || '');
  const timeFromStart =
    queryParams.get('start') && parseInt(queryParams.get('start') || '');
  if (timeFromT) {
    startTime = timeFromT;
  }
  if (timeFromStart) {
    startTime = timeFromStart;
  }
  return startTime;
};

async function validYoutubeVideoId(id: string) {
  return new Promise((resolve, reject) => {
    let validId = false;
    var img = new Image();
    img.src = 'http://img.youtube.com/vi/' + id + '/mqdefault.jpg';
    img.onload = () => {
      if (checkThumbnail(img.width)) {
        validId = true;
      }
      resolve(validId);
    };
  });
}

function checkThumbnail(width: number) {
  //a mq thumbnail has width of 320.
  //if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
  if (width === 120) {
    return false;
  }
  return true;
}
