import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles, usePhotoAlbums, useEvents } from '../../../hooks';
import { LabeledBox } from '../../molecule';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { Event } from '../../../store/types';
import useStyle from './style';

type Props = {};

const MyMissingPhotoAlbums: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const style = useStyle();
  const [missingAlbums, setMissingAlbums] = useState<Event[]>([]);
  const { photoAlbumsByUser } = usePhotoAlbums();
  const { eventsPhotographerIsMe: events } = useEvents();

  useEffect(() => {
    const eventsWithNoPhotoAlbum: Event[] = [];
    const existingPhotoAlbumEventIds = photoAlbumsByUser.map(p => p.eventId);
    events.forEach(
      e =>
        !existingPhotoAlbumEventIds.includes(e.id) &&
        eventsWithNoPhotoAlbum.push(e),
    );
    setMissingAlbums(eventsWithNoPhotoAlbum);
  }, [photoAlbumsByUser, events]);

  return (
    <>
      {missingAlbums.length ? (
        <LabeledBox label={t('profile.myMissingPhotoAlbums')}>
          <Grid container spacing={2}>
            {missingAlbums.map(event => (
              <Grid item lg={6} xs={12} key={event.id}>
                <Card variant="outlined" className={style.root}>
                  <Box display="flex">
                    <CardMedia image={defaultCover} className={style.cover} />
                    <CardContent>
                      <Typography className={globalStyle.ellipsis}>
                        <b>{t('event.name')}:</b> {event?.name}
                      </Typography>
                      <Typography>
                        <b>{t('event.date')}:</b>{' '}
                        {dayjs(event?.date).format('DD MMM YYYY')}
                      </Typography>
                      <Box marginTop="10px">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            history.push({
                              pathname: `/create-photo-album/${event.id}`,
                            })
                          }
                        >
                          {t('general.uploadPhotos')}
                        </Button>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </LabeledBox>
      ) : null}
    </>
  );
};

MyMissingPhotoAlbums.displayName = 'MyMissingPhotoAlbums';

export default React.memo(MyMissingPhotoAlbums);
