import { useCallback, useMemo } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useAuthentication } from '../auth';
import useChatRoom from './useChatRoom';
import useDateRoomPlayer from './useDateRoomPlayer';
import useDateRoomPlayers from './useDateRoomPlayers';
import useMyDateRooms from './useMyDateRooms';

const useMyDateRoomPlayer = () => {
  const { userId } = useAuthentication();
  const { selectedRoom } = useMyDateRooms();
  const { id } = selectedRoom || {};
  const firestore = useFirestore();
  const { updateDateRoomPlayer } = useDateRoomPlayer({
    dateRoom: selectedRoom || null,
  });
  const { dateRoomPlayers } = useDateRoomPlayers({
    dateRoomId: selectedRoom?.id,
  });
  const { registerChatRoom, deactivateChatRoom } = useChatRoom({
    dateRoom: selectedRoom,
  });
  const myPlayerProfile = dateRoomPlayers[userId];

  const liked = useMemo(() => new Set(myPlayerProfile?.liked ?? []), [
    myPlayerProfile,
  ]);
  const disliked = useMemo(() => new Set(myPlayerProfile?.disliked ?? []), [
    myPlayerProfile,
  ]);
  const matches = useMemo(() => new Set(myPlayerProfile?.matches ?? []), [
    myPlayerProfile,
  ]);

  const addMatches = useCallback(
    async playerId => {
      const player = await firestore
        .collection('dateRooms')
        .doc(id)
        .collection('players')
        .doc(playerId)
        .get();
      if (player?.data()?.liked?.includes(userId)) {
        updateDateRoomPlayer(userId, {
          matches: firestore.FieldValue.arrayUnion(playerId),
        });
        updateDateRoomPlayer(playerId, {
          matches: firestore.FieldValue.arrayUnion(userId),
        });
        registerChatRoom(playerId);
      }
    },
    [firestore, registerChatRoom, id, updateDateRoomPlayer, userId],
  );

  const removeMatches = useCallback(
    playerId => {
      updateDateRoomPlayer(userId, {
        matches: firestore.FieldValue.arrayRemove(playerId),
      });
      updateDateRoomPlayer(playerId, {
        matches: firestore.FieldValue.arrayRemove(userId),
      });
      deactivateChatRoom(playerId);
    },
    [deactivateChatRoom, firestore.FieldValue, updateDateRoomPlayer, userId],
  );

  const addPlayerToLikes = useCallback(
    async playerId => {
      updateDateRoomPlayer(userId, {
        liked: firestore.FieldValue.arrayUnion(playerId),
      });
      addMatches(playerId);
    },
    [addMatches, firestore.FieldValue, updateDateRoomPlayer, userId],
  );

  const removePlayerFromLikes = useCallback(
    playerId => {
      updateDateRoomPlayer(userId, {
        liked: firestore.FieldValue.arrayRemove(playerId),
      });
      removeMatches(playerId);
    },
    [firestore.FieldValue, removeMatches, updateDateRoomPlayer, userId],
  );

  const addPlayerToDislikes = useCallback(
    playerId => {
      updateDateRoomPlayer(userId, {
        disliked: firestore.FieldValue.arrayUnion(playerId),
      });
    },
    [firestore.FieldValue, updateDateRoomPlayer, userId],
  );

  const removePlayerFromDislikes = useCallback(
    playerId => {
      updateDateRoomPlayer(userId, {
        disliked: firestore.FieldValue.arrayRemove(playerId),
      });
    },
    [firestore.FieldValue, updateDateRoomPlayer, userId],
  );

  const toggleLikes = useCallback(
    playerId => {
      if (liked.has(playerId)) {
        removePlayerFromLikes(playerId);
        return;
      }
      removePlayerFromDislikes(playerId);
      addPlayerToLikes(playerId);
    },
    [addPlayerToLikes, liked, removePlayerFromDislikes, removePlayerFromLikes],
  );

  const toggleDislikes = useCallback(
    playerId => {
      if (disliked.has(playerId)) {
        removePlayerFromDislikes(playerId);
        return;
      }
      removePlayerFromLikes(playerId);
      addPlayerToDislikes(playerId);
    },
    [
      addPlayerToDislikes,
      disliked,
      removePlayerFromDislikes,
      removePlayerFromLikes,
    ],
  );

  return {
    myPlayerProfile,
    addPlayerToLikes,
    addPlayerToDislikes,
    toggleLikes,
    toggleDislikes,
    liked,
    disliked,
    matches,
    removePlayerFromLikes,
  };
};

export default useMyDateRoomPlayer;
