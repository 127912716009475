import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewsCategories } from '../newsCategories';
import { FilterCheckboxType } from './../../store/types/general';

const createCheckboxObject = <
  T extends {
    name: string;
  }
>(
  list: {
    [key: string]: T;
  },
  loaded: boolean | undefined,
): FilterCheckboxType => {
  if (!loaded || !list) {
    return {};
  }
  const object = {};
  Object.entries(list).forEach(
    ([id, values]) =>
      (object[id] = {
        value: false,
        name: values?.name,
      }),
  );
  return object;
};

const useCheckboxOptions = () => {
  const { t } = useTranslation();
  const { newsCategories, loaded: newsCategoriesLoaded } = useNewsCategories();

  const createMusicStyleObject = (
    musicStyles: string[],
  ): FilterCheckboxType => {
    const object = {};
    musicStyles.forEach(
      style =>
        (object[style] = {
          value: false,
          name: t(`shared.musicStyles.${style}`),
        }),
    );
    return object;
  };

  const createNewsCategoriesCheckboxes = useCallback(() => {
    return createCheckboxObject(newsCategories, newsCategoriesLoaded);
  }, [newsCategories, newsCategoriesLoaded]);

  return {
    createMusicStyleObject,
    createNewsCategoriesCheckboxes,
  };
};

export default useCheckboxOptions;
