import { IonProgressBar } from '@ionic/react';
import { Box, Button, Typography, Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../hooks';
import { scorePassword } from '../../../utils';
import { Spacer } from '../../atoms';
import FormField from '../../molecule/FormField';
import { useVerification } from '../../../hooks';
type Form = {
  email: string;
  password: string;
  confirm: string;
};

const UpdatePasswordBox: React.FC = () => {
  const {
    profile: { email },
    updatePassword,
  } = useAuthentication();
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { handleSubmit, errors, register, watch } = useForm<Form>({
    defaultValues: { email },
  });
  const { t } = useTranslation();
  const cutOff = 8;
  const { sendPasswordUpdateEmail } = useVerification();
  const validatePassword = useCallback(
    (password: string) => {
      if (password.length < 10 || password.length >= 15) {
        return t('form.tooShort');
      }
      if (scorePassword(password) < cutOff) {
        return t('form.tooShort');
      }
      return password === watch('confirm') || t('form.doesntMatch');
    },
    [watch, t],
  );

  const onSubmit = useCallback(
    async (values: any) => {
      setSuccess(null);
      setError(null);
      try {
        const res = await updatePassword(values);
        setSuccess(res);
        sendPasswordUpdateEmail(values.email);
      } catch (e) {
        setError(e.message);
      }
    },
    [sendPasswordUpdateEmail, updatePassword],
  );

  return (
    <Box maxWidth="100%" margin="auto">
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Spacer height="10px" />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <FormField
                disabled
                name="email"
                label="Email"
                type="email"
                register={register}
                error={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormField
                name="password"
                label="Password"
                type="password"
                register={register}
                validate={validatePassword}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormField
                name="confirm"
                label={t('shared.confirmPassword')}
                type="password"
                register={register}
                validate={validatePassword}
                error={errors.password?.message}
              />
            </Grid>
          </Grid>
          <Spacer height="10px" />
          <IonProgressBar
            value={scorePassword(watch('password')) / 100}
            color={
              scorePassword(watch('password')) > cutOff
                ? 'success'
                : scorePassword(watch('password')) > cutOff / 2
                  ? 'warning'
                  : 'danger'
            }
          />
          <Spacer height="10px" />
          <Typography variant="body2" color="error" align="center">
            {error && t(error)}
          </Typography>
          <Typography variant="body2" align="center">
            {success && t(success)}
          </Typography>
          <Spacer height="10px" />
          <Button variant="outlined" color="primary" type="submit" fullWidth>
            {t('shared.updatePassword')}
          </Button>
        </form>
        <Spacer height="10px" />
        {/*
            This section can be used for linking other providers to the profile https://firebase.google.com/docs/auth/web/account-linking
          <Flex flexDirection={isMobile ? 'column' : 'row'}>
            <SocialLoginButton provider="facebook" scopes={['email']} />
            <Spacer width="10px" height="10px" />
            <SocialLoginButton provider="twitter" />
            <Spacer width="10px" height="10px" />
            <SocialLoginButton provider="google" />
          </Flex>
          <Spacer height="20px" /> */}
      </Box>
    </Box>
  );
};

UpdatePasswordBox.displayName = 'UpdatePasswordBox';

export default React.memo(UpdatePasswordBox);
