import React, { useCallback, useEffect, useState } from 'react';
import { Paper, Box, Typography, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Error } from '@material-ui/icons';
import { useVerification, useAuthentication } from '../../../hooks';
import { Emoji, Spacer } from '../../atoms';
import { LabeledBox } from '../../molecule';

type Props = {};

const ResendVerificationEmailButton: React.FC<Props> = () => {
  const {
    resendVerificationEmail,
    sendVerificationSms,
    verifyPhoneNumber,
  } = useVerification();
  const {
    isProfileComplete,
    emailVerified,
    phoneVerified,
    profile,
  } = useAuthentication();
  const [phoneResponse, setPhoneResponse] = useState<boolean>();
  const [linkSent, setLinkSent] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [otp, setOtp] = useState<number>();
  const { t } = useTranslation();
  const history = useHistory();
  const goToEdit = useCallback(() => history.push('/edit-profile'), [history]);
  const canBeVerified = isProfileComplete;

  const handleSendLink = useCallback(() => {
    resendVerificationEmail();
    setLinkSent(true);
  }, [resendVerificationEmail]);

  const handleSendSms = useCallback(() => {
    sendVerificationSms();
    setSmsSent(true);
  }, [sendVerificationSms]);

  const handleVerifyPhone = useCallback(() => {
    if (otp) {
      const { success } = verifyPhoneNumber(otp);
      setPhoneResponse(success);
    }
  }, [otp, verifyPhoneNumber]);

  useEffect(() => {
    if (linkSent) {
      setTimeout(() => {
        setLinkSent(false);
      }, 60000);
    }
  }, [linkSent]);

  useEffect(() => {
    if (linkSent) {
      setTimeout(() => {
        setSmsSent(false);
      }, 60000);
    }
  }, [linkSent]);

  return (
    <>
      <Paper>
        <Box p={2} textAlign="center">
          <Typography variant="h2" color="error" align="center">
            <Emoji emoji="❗️" />
          </Typography>
          <Typography variant="h4">
            <b style={{ fontWeight: 900 }}>Ops...</b>
          </Typography>
          <Spacer height="20px" />
          <Typography variant="body2">{t('shared.notVerified')} </Typography>
          {!canBeVerified && (
            <Typography variant="body2">
              {t('shared.profileIncomplete')}{' '}
            </Typography>
          )}
          <Spacer height="20px" />
          {!canBeVerified ? (
            <Button variant="outlined" size="small" onClick={goToEdit}>
              {t('profile.completeProfileAndVerify')}
            </Button>
          ) : (
            <Box display="flex" alignItems="center" flexDirection="column">
              <Button
                variant="outlined"
                size="small"
                onClick={handleSendLink}
                disabled={linkSent || emailVerified}
              >
                {linkSent
                  ? t('shared.verificationResent')
                  : emailVerified
                  ? t('profile.emailVerified')
                  : t('shared.resendVerification', { email: profile.email })}
              </Button>
              <Spacer height="20px" width="20px" />
              <Button
                variant="outlined"
                size="small"
                onClick={handleSendSms}
                disabled={smsSent || phoneVerified || phoneResponse}
              >
                {phoneVerified || phoneResponse
                  ? t('profile.phoneVerified')
                  : smsSent
                  ? t('shared.verificationSmsResent')
                  : t('shared.sendVerificationSms', { number: profile.phone })}
              </Button>
              <Spacer height="20px" width="20px" />
              {smsSent && !phoneVerified && !phoneResponse && (
                <LabeledBox label="Verifica SMS" boxProps={{ width: '250px' }}>
                  <Box p={1}>
                    <Typography variant="caption">
                      Inserisci il codice ricevuto per SMS
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      color="primary"
                      size="small"
                      fullWidth
                      value={otp || ''}
                      onChange={e => setOtp(Number(e?.target?.value))}
                      InputProps={{
                        endAdornment:
                          !phoneResponse && phoneResponse !== undefined ? (
                            <Error color="error" />
                          ) : undefined,
                      }}
                    />
                    <Spacer height="10px" width="20px" />
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      size="small"
                      fullWidth
                      disabled={!otp || String(otp).length < 6}
                      onClick={handleVerifyPhone}
                    >
                      Verifica
                    </Button>
                  </Box>
                </LabeledBox>
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

ResendVerificationEmailButton.displayName = 'ResendVerificationEmailButton';

export default React.memo(ResendVerificationEmailButton);
