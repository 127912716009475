import { makeStyles, Theme } from '@material-ui/core';

type StyleProps = {
  photo?: string;
  size?: number;
};

export default makeStyles<Theme, StyleProps>(theme => ({
  avatar: {
    backgroundImage: ({ photo }) => `url(${photo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  },
  avatarSmall: {
    marginTop: 1,
    marginBottom: 1,
    width: ({ size = 22 }) => size,
    height: ({ size = 22 }) => size,
    backgroundColor: '#535353',
    color: theme.palette.primary.main,
    fontSize: '0.9rem',
    fontWeight: 600,
  },
}));
