import { useCallback, useState } from 'react';

type ToggleStateHookResult = [boolean, () => void, () => void, () => void];

export function useToggleState(
  initialState: boolean = false,
): ToggleStateHookResult {
  const [state, setState] = useState(initialState);

  const toggleOn = useCallback(() => {
    setState(true);
  }, []);

  const toggleOff = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState(currentState => !currentState);
  }, []);

  return [state, toggle, toggleOn, toggleOff];
}
