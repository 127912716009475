import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  EditTwoTone,
  FlareTwoTone,
  NewReleasesTwoTone,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  EventCardWidget,
  PageCoverAndLogo,
  VenueDetails,
} from '../../components/organisms';
import {
  useEvents,
  useVenue,
  useMediaQuery,
  useGlobalStyle,
} from '../../hooks';
import { Emoji, LoadingCover, Spacer } from '../../components/atoms';
import { Event } from '../../store/types';
import {
  TabsWithPath,
  EventsList,
  ExpandableText,
  LabeledBox,
  PageActions,
  PageWrapper,
  VenueActivationDialog,
} from '../../components/molecule';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const Venue: React.FC<Props> = ({ match }) => {
  const { venue: slugOrId } = match.params;
  const [events, setEvents] = useState<Event[]>([]);
  const [activateDialog, setActivateDialog] = useState(false);
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyle();
  const { venue, loading, isAdmin } = useVenue({ slugOrId });
  const {
    getEventsByVenue,
    getFutureEventsByVenue,
    getPastEventsByVenue,
  } = useEvents();
  const { slug, id } = venue || {};
  const history = useHistory();
  const futureEvents = id && getFutureEventsByVenue(id);
  const pastEvents = id && getPastEventsByVenue(id);
  const onCreateEvent = useCallback(() => {
    history.push({ pathname: '/create-event', state: { venueId: venue?.id } });
  }, [history, venue]);

  const onEditVenue = useCallback(() => {
    history.push(`/edit-venue/${slug}`);
  }, [history, slug]);

  const onActivate = useCallback(() => {
    setActivateDialog(true);
  }, []);

  const renderActions = useCallback(
    containerClassName => {
      const actions = [
        {
          label: t('event.create'),
          action: onCreateEvent,
          icon: <EditTwoTone style={{ width: 18 }} />,
        },
        {
          label: t('venue.editClub'),
          action: onEditVenue,
          icon: <NewReleasesTwoTone style={{ width: 18 }} />,
        },
      ];
      if (venue.created_by === 'BOT') {
        actions.push({
          label: 'Invia link di attivazione',
          action: onActivate,
          icon: <FlareTwoTone style={{ width: 18 }} />,
        });
      }
      return (
        isAdmin && (
          <PageActions
            containerClassName={containerClassName}
            actions={actions}
          />
        )
      );
    },
    [isAdmin, onActivate, onCreateEvent, onEditVenue, t, venue],
  );

  useEffect(() => {
    if (id) {
      setEvents(getEventsByVenue(id));
    }
  }, [getEventsByVenue, id]);

  const renderTabs = useCallback(
    containerClassName => {
      const homeTab = (
        <>
          <LabeledBox label={t('venue.description')}>
            <ExpandableText content={venue?.description} limit={250} />
          </LabeledBox>
          <Spacer height="20px" />
          <LabeledBox label={t('venue.nextEvents')}>
            <Grid container spacing={2}>
              {events.map((event, idx) => (
                <Grid
                  item
                  md={6}
                  key={`${event?.slug}-${idx}`}
                  className={globalStyle.fullWidth}
                >
                  <EventCardWidget event={event} />
                </Grid>
              ))}
            </Grid>
          </LabeledBox>
        </>
      );

      const nextEventsTab =
        futureEvents && futureEvents.length ? (
          <EventsList events={futureEvents} />
        ) : (
          <Box textAlign="center" padding={2}>
            <Typography variant="h2">
              <Emoji emoji="🤷‍♂️" />
            </Typography>
            {t('event.noEvents')}
          </Box>
        );
      const pastEventsTab =
        pastEvents && pastEvents.length ? (
          <EventsList events={pastEvents} />
        ) : (
          <Box textAlign="center" padding={2}>
            <Typography variant="h2">
              <Emoji emoji="🤷‍♂️" />
            </Typography>
            {t('event.noEvents')}
          </Box>
        );
      return (
        <TabsWithPath
          containerClassName={containerClassName}
          tabs={[
            {
              label: t('venue.home'),
              content: homeTab,
            },
            {
              label: t('venue.nextEvents'),
              content: nextEventsTab,
              hash: '#future-events',
            },
            {
              label: t('venue.pastEvents'),
              content: pastEventsTab,
              hash: '#past-events',
            },
          ]}
        />
      );
    },
    [events, futureEvents, globalStyle.fullWidth, pastEvents, t, venue],
  );

  if (!venue) {
    history.push('/venues');
  }

  return (
    <>
      {loading && <LoadingCover />}
      <VenueActivationDialog
        open={activateDialog}
        venue={venue}
        onClose={() => setActivateDialog(false)}
      />
      <PageWrapper boxed={false}>
        <PageCoverAndLogo
          logo={venue?.logoUrl}
          cover={venue?.coverUrl}
          renderActions={renderActions}
          venueId={venue.id}
        />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 3}>
              <VenueDetails venue={venue} />
            </Grid>
            <Grid item xs={isMobile ? 12 : 9}>
              {renderTabs('')}
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
    </>
  );
};

export default Venue;
