import { Fab } from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import React, { useCallback } from 'react';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { useGlobalStyles } from '../../../hooks';
import { LabeledBox } from '../../molecule';
import Form from './Form';
import { useStyle } from './styles';

type Props = {
  images?: (File | string)[];
  setImages?: (images: File[]) => void;
  setCompressImages?: (images: File[]) => void;
  setImagesToDelete?: React.Dispatch<React.SetStateAction<string[]>>;
};

const PhotoAlbum: React.FC<Props> = ({
  images,
  setImages,
  setCompressImages,
  setImagesToDelete,
}) => {
  const globalStyles = useGlobalStyles();
  const style = useStyle();

  const onDeleteImage = useCallback(
    ind => {
      const newImages = Array.from(images as File[]);
      newImages.splice(ind, 1);
      if (setImages) {
        setImages(newImages);
      }
      if (
        images?.[ind] &&
        typeof images[ind] === 'string' &&
        setImagesToDelete
      ) {
        // it's a saved image to be deleted
        setImagesToDelete(prev => [...prev, images[ind] as string]);
      }
    },
    [images, setImages, setImagesToDelete],
  );

  const renderActions = useCallback(
    ind => {
      return (
        <Fab
          onClick={() => onDeleteImage(ind)}
          size="small"
          className={style.fab}
          color="secondary"
        >
          <Remove />
        </Fab>
      );
    },
    [onDeleteImage, style.fab],
  );

  const renderImage = useCallback(
    image => {
      const imageUrl =
        typeof image === 'string' ? image : image && URL.createObjectURL(image);
      return (
        <img
          src={imageUrl || defaultCover}
          className={style.photo}
          alt={`img-${imageUrl}`}
        />
      );
    },
    [style.photo],
  );

  return (
    <LabeledBox label="Gallery">
      <>
        {setCompressImages && (
          <Form setImages={setCompressImages} images={images} />
        )}
        <div className={style.photoWrapper}>
          {images?.map((image, ind) => (
            <div className={globalStyles.relative} key={`photo-${ind}`}>
              {renderImage(image)}
              {setImages && renderActions && renderActions(ind)}
            </div>
          ))}
        </div>
      </>
    </LabeledBox>
  );
};

PhotoAlbum.displayName = 'PhotoAlbum';

export default React.memo(PhotoAlbum);
