import { GuestListRegistration } from '../../../../../store/types';
import { sortArrayOfStrings, sortObjectByValues } from '../../../../../utils';
import {
  createFourColumnCenteredLayout,
  drawHorizontalLine,
  createSomeEmptySpace,
  createText,
  createImage,
} from '../../../../../utils/pdf';

type TableCell = any;
type TableRow = TableCell[];
type GuestListTable = TableRow[];

export const createGuestListTable = (
  registrations: GuestListRegistration[],
) => {
  const guestListTable: any = [];
  sortObjectByValues(registrations, 'name').forEach(
    (reg: GuestListRegistration) => {
      /**
       * Guests table:
       * registrant.name | guest | guest | guest
       *                 | guest | guest | guest
       */
      const names: GuestListTable = [[], [], [], []];
      names[0][0] = createText(reg.name, ['size10', 'bold', 'italics']);
      sortArrayOfStrings(reg.guests.map(guest => guest.name)).forEach(
        (guest: string, id: number) => {
          if (id === 0 || id % 3 === 0) {
            names[1].push(createText(guest, ['size10']));
          } else if (id === 1 || id === 4 || id === 7) {
            names[2].push(createText(guest, ['size10']));
          } else if (id === 2 || id === 5 || id === 8) {
            names[3].push(createText(guest, ['size10']));
          }
        },
      );
      guestListTable.push(createSomeEmptySpace(['size5']));
      guestListTable.push(createFourColumnCenteredLayout(names));
      guestListTable.push(createSomeEmptySpace(['size5']));
      guestListTable.push(drawHorizontalLine(520, 0.3));
    },
  );
  return {
    stack: guestListTable,
    unbreakable: true,
    width: 520,
  };
};

export const createOrganizations = (
  organizations: { logoImage: unknown | undefined; name: string }[],
) => {
  return organizations.map(org => {
    return [
      createImage(org.logoImage, 50, 50),
      createSomeEmptySpace(['size5']),
      createText(org.name, ['size8', 'italics']),
      createSomeEmptySpace(['size5']),
    ];
  });
};

export const createLinesFromBenefitsOrConditions = (
  values: { val: string }[] | undefined,
) => {
  let string = '';
  if (values) {
    values.forEach(value => {
      string += `${value.val}\n`;
    });
  } else {
    string = '-';
  }
  return string;
};
