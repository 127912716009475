import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LabeledBox } from '../../../molecule';
import { GuestList, GuestListRegistration } from '../../../../store/types';
import { Flex, HtmlDecode, Spacer } from '../../../atoms';
import {
  useAuthentication,
  useGlobalStyles,
  useGuestListRegistrations,
} from '../../../../hooks';

type Props = {
  isRegistered: boolean;
  guestList: GuestList;
  canRegisterGuestList: boolean | undefined;
  registeredToDifferentList: boolean; // Check if the user is already registered to another list (only one registration is allowed)
};

const GuestListDetails: React.FC<Props> = ({
  isRegistered,
  guestList,
  canRegisterGuestList,
  registeredToDifferentList,
}) => {
  const { id, eventId, name } = guestList;
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [existingRegistrations, setExistingRegistrations] = useState<
    GuestListRegistration[]
  >([]);
  const {
    registerGuestListRegistration,
    getUserExistingRegistrationsByEvent,
  } = useGuestListRegistrations({ eventId });
  const {
    userId,
    profile: { displayName },
    verified,
  } = useAuthentication();

  // We are disabling multiple guests for single registration now!
  // const [guests, setGuests] = useState<number>();
  // const [openGuestNamesDialog, setOpenGuestNamesDialog] = useState<boolean>(
  //   false,
  // );
  // const changeGuests = useCallback(
  //   e => setGuests(Math.min(Math.abs(Math.floor(e.target.value)), 10) || 1),
  //   [],
  // );

  const [verificationPopUp, setVerificationPopUp] = useState(false);

  const triggerVerification = () => {
    setVerificationPopUp(true);
  };

  useEffect(() => {
    verified && setVerificationPopUp(false);
  }, [verified]);

  const history = useHistory();
  const goToProfile = useCallback(() => history.push('/profile'), [history]);

  const register = useCallback(
    (guestNames: string[]) => {
      if (!id || !eventId) {
        return;
      }
      registerGuestListRegistration({
        id: existingRegistrations[0]?.id,
        guestListId: id,
        guestListName: name,
        eventId,
        userId,
        name: displayName,
        guests: [
          { name: displayName, accessed: false },
          ...guestNames.map(guest =>
            Object.assign({}, { name: guest, accessed: false }),
          ),
        ],
      });
    },
    [
      displayName,
      eventId,
      existingRegistrations,
      id,
      name,
      registerGuestListRegistration,
      userId,
    ],
  );

  useEffect(() => {
    if (eventId) {
      setExistingRegistrations(getUserExistingRegistrationsByEvent(eventId));
    }
  }, [getUserExistingRegistrationsByEvent, guestList, eventId]);

  return (
    <>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <LabeledBox
              label={t('guestList.conditions')}
              boxProps={{ height: '100%' }}
            >
              <List dense>
                {guestList.conditions?.map(condition => (
                  <ListItem key={condition.val}>
                    <Typography>{condition.val}</Typography>
                  </ListItem>
                ))}
              </List>
            </LabeledBox>
          </Grid>
          <Grid item md={4} xs={12}>
            <LabeledBox
              label={t('guestList.benefits')}
              boxProps={{ height: '100%' }}
            >
              <List dense>
                {guestList.benefits?.map(benefit => (
                  <ListItem key={benefit.val}>
                    <Typography>{benefit.val}</Typography>
                  </ListItem>
                ))}
              </List>
            </LabeledBox>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box
              className={globalStyle.flexColumn}
              justifyContent="space-between"
              height="100%"
            >
              <Typography className={globalStyle.textSmall} align="center">
                <HtmlDecode>
                  {!isRegistered
                    ? t('guestList.registerInfo', { displayName })
                    : t('guestList.modifyRegisterInfo', { displayName })}
                </HtmlDecode>
              </Typography>
              <Spacer height="5px" />
              {/*
                We are disabling multiple guests for single registration now!
              <TextField
                variant="outlined"
                type="number"
                size="small"
                label={t('guestList.guestsNumber')}
                value={
                  guests || existingRegistrations?.[0]?.guests?.length || 1
                }
                onChange={changeGuests}
                fullWidth
                inputProps={{ min: 1, max: 10 }}
                disabled={canRegisterGuestList ? false : true}
              /> */}
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                disabled={
                  !canRegisterGuestList || isRegistered || verificationPopUp
                }
                onClick={verified ? () => register([]) : triggerVerification}
              >
                {t('guestList.registerToGuestList', { displayName })}
              </Button>
              {!canRegisterGuestList && (
                <Typography align="center" color="error">
                  {t('guestList.cantRegister')}
                </Typography>
              )}
            </Box>
          </Grid>
          <Flex justifyContent="start" className={globalStyle.mh1}>
            <WarningIcon
              color="primary"
              className={[globalStyle.textSmall, globalStyle.mr1].join(' ')}
            />
            <Typography className={globalStyle.textSmall} color="primary">
              {t('guestList.disclaimer')}
            </Typography>
          </Flex>
          <Spacer height="10px" />
          {verificationPopUp && (
            <Flex
              justifyContent="center"
              flexDirection="column"
              className={globalStyle.mh1}
            >
              <Typography className={globalStyle.textSmall} color="error">
                {t('guestList.needToVerify')}
              </Typography>
              <Spacer height="5px" />
              <Button
                color="secondary"
                variant="outlined"
                onClick={goToProfile}
              >
                {t('guestList.verifyYourAccount')}
              </Button>
            </Flex>
          )}
        </Grid>
      </Box>
      {/*
        We are disabling multiple guests for single registration now, so no need for this dialog
      <AddGuestsDialog
        open={openGuestNamesDialog}
        onClose={() => setOpenGuestNamesDialog(false)}
        guestList={
          isRegistered
            ? [
              displayName,
              ...existingRegistrations[0].guests.map(guest => guest.name),
            ]
            : [displayName]
        }
        numberOfGuests={
          guests || existingRegistrations?.[0]?.guests?.length || 1
        }
        register={register}
      /> */}
    </>
  );
};

GuestListDetails.displayName = 'GuestListDetails';

export default React.memo(GuestListDetails);
