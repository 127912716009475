import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  toggleButton: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  badge: {
    backgroundColor: '#fc6560',
  },
}));
