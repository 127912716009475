import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { ORGANIZATION_RULES } from '../../constants';
import {
  eventsListSelector,
  eventsSelector,
  filteredEventsSelector,
  myEventsSelector,
  populatedEventsListSelector,
  populatedEventsSelector,
  populatedFutureEventsSelector,
  populatedMainEventsSelector,
  populatedPastEventsSelector,
  populatedPremiumEventsSelector,
  suggestedEventsSelector,
  eventsPhotographerIsMeSelector,
} from '../../store/selectors/events';
import { Event } from '../../store/types';
import { THRESHOLD } from '../../constants';
import { useAuthentication } from '..';

type Props = {
  region?: string | undefined;
  searchFor?: string;
  musicStyles?: string[];
  future?: boolean;
  past?: boolean;
  myEvents?: boolean;
  selectedOrganizationIds?: string[];
  selectedVenueIds?: string[];
  selectedDate?: { day: number; month: number; year: number } | null;
  selectedArtistIds?: string[];
  page?: number;
  currentUser?: string;
};

type UseEvents = {
  events: { [key: string]: Event };
  eventsList: Event[];
  loaded: boolean;
  mainEvents: Event[];
  premiumEvents: Event[];
  futureEvents: Event[];
  pastEvents: Event[];
  suggestedEvents: Event[];
  populatedEvents: { [key: string]: Event };
  populatedEventsList: Event[];
  myEvents: Event[];
  getFutureEventsByVenue: (venueId: string) => Event[];
  getPastEventsByVenue: (venueId: string) => Event[];
  getEventsByVenue: (venueId: string) => Event[];
  getEventsByOrganization: (venueId: string) => Event[];
  getEventsByArtist: (venueId: string) => Event[];
  filteredEvents: Event[];
  searchedEvents: (text: string, time?: 'past' | 'future') => Event[];
  getFutureEventsByArtist: (artistId: string) => Event[];
  getPastEventsByArtist: (artistId: string) => Event[];
  filteredEventsByPage: { events: Event[]; totalResults: number };
  eventsPhotographerIsMe: Event[];
};

export const useEvents = (filters: Props = {}): UseEvents => {
  const currentUser = useAuthentication().userId;
  filters.currentUser = currentUser;
  const { page = 1 } = filters;
  const events = useSelector(eventsSelector);
  const eventsList = useSelector(eventsListSelector);
  const futureEvents = useSelector(populatedFutureEventsSelector);
  const pastEvents = useSelector(populatedPastEventsSelector);
  const populatedEvents = useSelector(populatedEventsSelector);
  const populatedEventsList = useSelector(populatedEventsListSelector);
  const suggestedEvents = useSelector(suggestedEventsSelector(filters.region));

  const filteredEvents: Event[] = useSelector(filteredEventsSelector(filters));
  const mainEvents = useSelector(populatedMainEventsSelector(filters));
  const premiumEvents = useSelector(populatedPremiumEventsSelector(filters));
  const myEvents = useSelector(myEventsSelector);
  const eventsPhotographerIsMe = useSelector(eventsPhotographerIsMeSelector);

  const filteredEventsByPage = {
    events:
      filteredEvents.slice(THRESHOLD * (page - 1), THRESHOLD * page) || [],
    totalResults: filteredEvents?.length || 0,
  };

  const loaded = isLoaded(events);

  const getFutureEventsByVenue = useCallback(
    venueId => futureEvents.filter(event => event.venueId === venueId),
    [futureEvents],
  );
  const getPastEventsByVenue = useCallback(
    venueId => pastEvents.filter(event => event.venueId === venueId),
    [pastEvents],
  );
  const getFutureEventsByArtist = useCallback(
    artistId =>
      futureEvents.filter(event =>
        Object.keys(event.artists || {}).includes(artistId),
      ),
    [futureEvents],
  );
  const getPastEventsByArtist = useCallback(
    artistId =>
      pastEvents.filter(event =>
        Object.keys(event.artists || {}).includes(artistId),
      ),
    [pastEvents],
  );

  const searchedEvents = useCallback(
    (text: string, time?: 'past' | 'future') => {
      if (eventsList) {
        return eventsList.filter(event => {
          if (text && !time) {
            return event?.name?.toLowerCase()?.startsWith(text.toLowerCase());
          }
          if (text && time === 'past') {
            return (
              event.name.toLowerCase().startsWith(text.toLowerCase()) &&
              new Date().getTime() > new Date(event.date).getTime()
            );
          }
          if (text && time === 'future') {
            return (
              event.name.toLowerCase().startsWith(text.toLowerCase()) &&
              new Date().getTime() < new Date(event.date).getTime()
            );
          }
          return false;
        });
      }
      return [];
    },
    [eventsList],
  );

  const getEventsByVenue = useCallback(
    venueId => eventsList?.filter(event => event.venueId === venueId) ?? [],
    [eventsList],
  );

  const getEventsByOrganization = useCallback(
    id =>
      eventsList?.filter(
        ({ organizations }) =>
          organizations &&
          organizations[id] &&
          ORGANIZATION_RULES.includes(organizations[id]),
      ) ?? [],
    [eventsList],
  );
  const getEventsByArtist = useCallback(
    artistId =>
      eventsList?.filter(event =>
        Object.keys(event.artists || {}).includes(artistId),
      ) ?? [],
    [eventsList],
  );

  const query: ReduxFirestoreQuerySetting = {
    collection: 'events',
    orderBy: ['timestamp', 'desc'],
    limit: 99,
  };

  useFirestoreConnect(query);

  return {
    events,
    loaded,
    populatedEvents,
    populatedEventsList,
    eventsList: eventsList || [],
    getEventsByVenue,
    getEventsByOrganization,
    suggestedEvents,
    myEvents,
    mainEvents: mainEvents.length ? mainEvents : filteredEvents.slice(0, 5),
    premiumEvents: premiumEvents.length
      ? premiumEvents
      : filteredEvents.slice(5, 10),
    futureEvents,
    pastEvents,
    filteredEvents,
    getEventsByArtist,
    searchedEvents,
    getFutureEventsByVenue,
    getPastEventsByVenue,
    getFutureEventsByArtist,
    getPastEventsByArtist,
    filteredEventsByPage,
    eventsPhotographerIsMe,
  };
};
