import React, { useCallback } from 'react';
import { Button, Chip } from '@material-ui/core';
import { ShareOutlined, VerifiedUser } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Venue } from '../../../store/types';
import { CardListWidget, ShareButton } from '..';
import { useGlobalStyles } from '../../../hooks';

type Props = {
  venue: Venue;
  label?: string;
};

const Item: React.FC<Props> = ({ label, venue }) => {
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();
  const { slug } = venue || {};
  const history = useHistory();
  const goTo = useCallback(() => history.push(`/venues/${slug}`), [
    history,
    slug,
  ]);

  return (
    <CardListWidget
      label={label}
      onClick={goTo}
      logoUrl={venue.logoUrl}
      coverUrl={venue.coverUrl}
      title={venue.name}
      subtitle={venue.address}
      cardActions={[
        {
          type: 'primary',
          label: t('event.future'),
          onClick: () => history.push(`venues/${venue.slug}#future-events`),
        },
        {
          type: 'secondary',
          label: t('event.past'),
          onClick: () => history.push(`venues/${venue.slug}#past-events`),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tilllate.world/venues/${venue.slug}`}
              renderButton={openPopOver => (
                <Button
                  className={globalStyle.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={e => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
      renderTopIcons={
        venue.isVerified && (
          <CardListWidget.Icon
            icon={<VerifiedUser />}
            label={t('venue.verified')}
            activeLabel={t('venue.verified')}
            active
          />
        )
      }
    >
      {venue.musicStyles?.length ? (
        <>
          {venue.musicStyles.slice(0, 2).map(music => (
            <Chip
              component="span"
              key={`${venue.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
            />
          ))}
          {venue.musicStyles.length > 2 && (
            <Chip
              variant="outlined"
              size="small"
              component="span"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
              label={`+ ${venue.musicStyles.length - 2}`}
            />
          )}
        </>
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(' ')}
        />
      )}
    </CardListWidget>
  );
};

Item.displayName = 'Item';

export default React.memo(Item);
