// import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { selectRoom } from '../../store/actions/tilldateConfiguration/selectRoom';
import {
  myDateRoomsFilteredSelector,
  selectedDateRoomSelector,
} from '../../store/selectors/dateRooms';
import { useGuestListRegistrations } from '../guestListRegistrations';

const useMyDateRooms = () => {
  const dispatch = useDispatch();
  const { guestListRegistrationsByUser = [] } = useGuestListRegistrations({
    myRegistrations: true,
  });

  const myDateRooms = useSelector(
    myDateRoomsFilteredSelector(guestListRegistrationsByUser),
  );

  const selectedRoom = useSelector(selectedDateRoomSelector);

  const query: ReduxFirestoreQuerySetting = {
    collection: 'dateRooms',
    storeAs: 'myDateRooms',
    // where: [['end', '>', dayjs().valueOf()]],
  };

  useEffect(() => {
    if (myDateRooms.length) {
      dispatch(selectRoom(myDateRooms[0])); // for now just take the first, the user should be able to select
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(myDateRooms)]);

  useFirestoreConnect(query);
  return { myDateRooms, selectedRoom };
};

export default useMyDateRooms;
