import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  cardMedia: { height: 280 },
  crush: {
    border: `${theme.spacing(0.5)}px solid`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontWeight: 900,
    transform: 'rotate(-20deg)',
    color: 'green',
    fontFamily:
      '-apple-system, system-ui, "Helvetica Neue", Roboto, sans-serif',
    opacity: 0.8,
  },
  trash: {
    border: `${theme.spacing(0.5)}px solid`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontWeight: 900,
    transform: 'rotate(20deg)',
    color: '#ad0505',
    fontFamily:
      '-apple-system, system-ui, "Helvetica Neue", Roboto, sans-serif',
    opacity: 0.8,
  },
  swipe: {
    position: 'absolute',
    width: '100%',
    top: 0,
    '&:nth-child(1n+1)': {
      transform: 'rotate(2deg)',
    },
    '&:nth-child(1n+2)': {
      transform: 'rotate(-2deg)',
    },
  },
}));
