import { IonProgressBar, isPlatform } from '@ionic/react';
import {
  Box,
  Paper,
  Button,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { SocialLoginButton } from '..';
import { useMediaQuery, useVerification } from '../../../hooks';
import { authErrorSelector } from '../../../store/selectors';
import { scorePassword } from '../../../utils';
import { Spacer, Flex } from '../../atoms';
import FormField from '../../molecule/FormField';
import { analytics } from '../../../rrf.config';

type Form = {
  email: string;
  name: string;
  password: string;
  confirm: string;
};

const RegisterBox: React.FC = () => {
  const { handleSubmit, errors, register, watch } = useForm<Form>();
  const authError = useSelector(authErrorSelector);
  const [loading, setLoading] = useState(false);
  const firebase = useFirebase();
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const history = useHistory();
  const goToLogin = useCallback(() => history.push('/login'), [history]);
  const cutOff = 8;
  const { sendAccountCreationEmail } = useVerification();
  const isCapacitor = isPlatform('capacitor');
  const validatePassword = useCallback(
    (password: string) => {
      if (password.length < 10 || password.length >= 15) {
        return t('form.tooShort');
      }
      if (scorePassword(password) < cutOff) {
        return t('form.tooShort');
      }
      return password === watch('confirm') || t('form.doesntMatch');
    },
    [watch, t],
  );

  const onSubmit = (values: any) => {
    const { email, password, name: displayName } = values;
    setLoading(true);
    firebase
      .createUser(
        { email, password },
        {
          email,
          displayName,
          created_at: new Date().getTime(),
          updated_at: new Date().getTime(),
        },
      )
      .then(_usr => {
        const newId = firebase.auth().currentUser?.uid;
        sendAccountCreationEmail(email, newId);
        analytics.logEvent('sign_up', { method: 'email' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box width={500} maxWidth="100%" margin="auto">
      <Paper variant="outlined">
        <Box padding={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              name="name"
              label="Nome"
              register={register}
              error={errors.name?.message}
            />
            <Spacer height="10px" />
            <FormField
              name="email"
              label="Email"
              type="email"
              register={register}
              error={errors.email?.message}
            />
            <Spacer height="10px" />
            <FormField
              name="password"
              label="Password"
              type="password"
              register={register}
              validate={validatePassword}
            />
            <Spacer height="10px" />
            <FormField
              name="confirm"
              label="Conferma Password"
              type="password"
              register={register}
              validate={validatePassword}
              error={errors.password?.message}
            />
            <Spacer height="10px" />
            <IonProgressBar
              value={scorePassword(watch('password')) / 100}
              color={
                scorePassword(watch('password')) > cutOff
                  ? 'success'
                  : scorePassword(watch('password')) > cutOff / 2
                  ? 'warning'
                  : 'danger'
              }
            />
            <Spacer height="10px" />
            <Typography variant="body2" color="error">
              {authError && authError.message}
            </Typography>
            <Spacer height="10px" />
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              fullWidth
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size="20px" />
              ) : (
                t('shared.createAccount')
              )}
            </Button>
          </form>
          <Spacer height="10px" />
          <Flex flexDirection={isMobile ? 'column' : 'row'}>
            <SocialLoginButton provider="facebook" scopes={['email']} />
            {!isCapacitor ? (
              <>
                <Spacer width="10px" height="10px" />
                <SocialLoginButton provider="twitter" />
              </>
            ) : null}
            <Spacer width="10px" height="10px" />
            <SocialLoginButton provider="google" />
            <Spacer width="10px" height="10px" />
            {isCapacitor && (isPlatform('ios') || isPlatform('ipad')) ? (
              <>
                <Spacer width="10px" height="10px" />
                <SocialLoginButton provider="apple.com" />
              </>
            ) : null}
          </Flex>
          <Typography
            variant="body2"
            color="primary"
            style={{ fontSize: '0.8rem', marginTop: '5px' }}
          >
            *{t('shared.privacyRegisterAgreement')}
            <Link style={{ textDecoration: 'underline' }} to="privacy-policy">
              {t('shared.privacyTerms')}
            </Link>
          </Typography>
          <Spacer height="20px" />
          <Divider variant="middle" />
          <Spacer height="20px" />
          <Button variant="outlined" fullWidth onClick={goToLogin}>
            {t('shared.alreadyRegistered')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

RegisterBox.displayName = 'RegisterBox';

export default React.memo(RegisterBox);
