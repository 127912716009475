import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollRestoration extends React.Component {
  componentDidUpdate(prevProps: any) {
    if ((this.props as any).location.pathname !== prevProps.location.pathname) {
      document?.querySelector('ion-content')?.scrollToTop();
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollRestoration as any);
