import React from 'react';
import { useAuthentication, useGlobalStyles } from '../../../hooks';
import { LoadingCover } from '../../atoms';
import ProfileForm from '../ProfileForm';

type Props = {};

const EditProfile: React.FC<Props> = () => {
  const { profile, loading } = useAuthentication();
  const globalStyle = useGlobalStyles();

  if (!profile.email) {
    return null;
  }

  return (
    <div className={globalStyle.relative}>
      {loading && <LoadingCover />}
      <ProfileForm profile={profile} />
    </div>
  );
};

EditProfile.displayName = 'EditProfile';

export default React.memo(EditProfile);
