import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import {
  newsCategoriesListSelector,
  newsCategoriesSelector,
} from '../../store/selectors/newsCategories';
import { NewsCategory } from '../../store/types';

type useNewsCategories = {
  newsCategories: { [key: string]: NewsCategory };
  newsCategoriesList: NewsCategory[];
  loaded?: boolean;
};

export const useNewsCategories = (): useNewsCategories => {
  const newsCategories = useSelector(newsCategoriesSelector);
  const newsCategoriesList = useSelector(newsCategoriesListSelector);

  const loaded = isLoaded(newsCategories);

  useFirestoreConnect({
    collection: 'newsCategories',
  });

  return { newsCategories, loaded, newsCategoriesList };
};
