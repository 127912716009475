import { Paper } from '@material-ui/core';
import React from 'react';
import { useGlobalStyles } from '../../../hooks';
import { ShareButton } from '../../molecule';

type Props = {};

const GlobalShare: React.FC<Props> = () => {
  const globalStyle = useGlobalStyles();
  return (
    <Paper variant="outlined" className={globalStyle.share}>
      <ShareButton.Share url={window.location.href} iconSize={32} />
    </Paper>
  );
};

GlobalShare.displayName = 'GlobalShare';

export default React.memo(GlobalShare);
