import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { EditEvent } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const EditEventScreen: React.FC<Props> = ({ match }) => {
  const { event: slugOrId } = match.params;

  return (
    <PageWrapper title="Modifica Evento">
      {slugOrId && <EditEvent slugOrId={slugOrId} />}
    </PageWrapper>
  );
};

export default EditEventScreen;
