import { Box, Button, ButtonTypeMap, Divider } from '@material-ui/core';
import React from 'react';
import { useGlobalStyles } from '../../../hooks';

type Props = {
  label: string;
  buttonProps?: ButtonTypeMap;
  onClick: () => void;
};

const DividerButton: React.FC<Props> = ({ label, buttonProps, onClick }) => {
  const globalStyle = useGlobalStyles();
  return (
    <Box
      className={globalStyle.flex}
      justifyContent="center"
      alignItems="center"
    >
      <Divider className={globalStyle.flexGrow} />
      <Button variant="text" size="small" onClick={onClick} {...buttonProps}>
        {label}
      </Button>
      <Divider className={globalStyle.flexGrow} />
    </Box>
  );
};

DividerButton.displayName = 'DividerButton';

export default React.memo(DividerButton);
