import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  width?: string;
  height?: string;
  flex?: boolean;
};

const Spacer = styled.div<Props>`
  min-width: ${({ width = '0px' }) => width};
  min-height: ${({ height = '0px' }) => height};
  ${({ flex }) => {
    return (
      flex &&
      css`
        display: flex;
        flex-grow: 1;
      `
    );
  }}
`;

Spacer.displayName = 'Spacer';

export default React.memo(Spacer);
