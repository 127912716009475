import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box } from '@material-ui/core';
import {
  EventOutlined,
  PhotoCameraOutlined,
  PinDropOutlined,
} from '@material-ui/icons';
import {
  useGlobalStyles,
  useMediaQuery,
  usePhotoAlbums,
  useVenues,
} from '../../../hooks';
import { Spacer } from '../../atoms';
import { Slider } from '../../molecule';
import TopSearchWidget from '../TopSearchWidget';
//import defaultBg from '../../../assets/img/hp_1.png';
import useStyle from './style';

type Props = {
  items?: number;
};

const TopStories: React.FC<Props> = ({ items = 6 }) => {
  const { isMobile } = useMediaQuery();
  const style = useStyle({ isMobile });
  const { populatedAlbums, loaded } = usePhotoAlbums();
  const [loading, setLoading] = useState(true);
  const [selectedPhotos, setSelectedPhotos] = useState<any[]>([]);
  const { length } = selectedPhotos;
  const { venues } = useVenues();
  const globalStyle = useGlobalStyles();
  const history = useHistory();

  const pickRandomPhoto = useCallback((): void => {
    if (!length) {
      const arr: any = [];
      const photos: any = [];
      let limit = Object.values(populatedAlbums).reduce(
        (s, p) => s + (p?.images?.length ?? 0),
        0,
      );
      while (items && limit) {
        const x = Math.floor(
          Math.random() * Object.values(populatedAlbums).length,
        );
        const album = Object.values(populatedAlbums)[x];
        /*
        if (!album?.images?.length || !album?.author || !album?.event) {
          limit--;
          continue;
        }
        const y = Math.floor(Math.random() * album.images.length);
        */
        const photo = album.coverUrl;

        if (photos.includes(photo)) {
          limit--;
          continue;
        }
        photos.push(photo);
        arr.push({
          author: album.author,
          event: album.event,
          venueId: album?.event?.venueId,
          src: photo,
        });
        limit--;
        items--;
      }
      setSelectedPhotos(arr);
      setLoading(false);
    }
  }, [items, populatedAlbums, length]);

  useEffect(() => {
    if (populatedAlbums && loaded) {
      pickRandomPhoto();
    }
  }, [pickRandomPhoto, populatedAlbums, loaded]);

  return (
    <Box className={style.wrapper}>
      <Slider items={1} pagination navigation>
        {/*} <Box
          style={{ backgroundImage: `url(${defaultBg})` }}
          className={style.slideWrapper}
          key={defaultBg}
        >
          <Box className={style.slideInfoBox}>
            <Box
              className={[globalStyle.flex, style.slideInfoBoxItem].join(' ')}
              alignItems="center"
            >
              <span>
                Tilllate.world è un marchio conosciuto in tutto il mondo per i
                servizi offerti agli attori della vita notturna Famoso per i
                suoi eventi e per i migliori club che fanno parte del circuito
                Tilllate.world.
              </span>
            </Box>
          </Box>
          <Box className={style.bottomBar} />
        </Box>*/}
        {selectedPhotos?.map(selectedPhoto => (
          <Box
            style={{
              backgroundImage: `url(${selectedPhoto?.src})`,
              cursor: 'pointer',
            }}
            className={style.slideWrapper}
            key={selectedPhoto.src}
            onClick={() => {
              history.push(`events/${selectedPhoto.event.slug}`);
            }}
          >
            <Box
              style={{ opacity: loading ? 1 : 0 }}
              className={style.slideLoading}
            />

            <Box className={style.slideInfoBox}>
              <Box
                className={[globalStyle.flex, style.slideInfoBoxItem].join(' ')}
                alignItems="center"
              >
                <PhotoCameraOutlined fontSize="small" />
                <Spacer width="8px" />
                <span>{selectedPhoto?.author?.displayName}</span>
              </Box>
              <Box
                paddingLeft={1}
                className={[globalStyle.flex, style.slideInfoBoxItem].join(' ')}
                alignItems="center"
              >
                <EventOutlined fontSize="small" />
                <Spacer width="8px" />
                <span>
                  {selectedPhoto?.event?.name},{' '}
                  {selectedPhoto?.event?.date &&
                    dayjs(selectedPhoto?.event?.date).format('DD MMM')}
                </span>
              </Box>
              <Box
                paddingLeft={1}
                className={[globalStyle.flex, style.slideInfoBoxItem].join(' ')}
                alignItems="center"
              >
                <PinDropOutlined fontSize="small" />
                <Spacer width="8px" />
                <span>{venues[selectedPhoto.venueId]?.name}</span>
              </Box>
            </Box>
            <Box className={style.bottomBar} />
          </Box>
        ))}
      </Slider>
      <TopSearchWidget />
    </Box>
  );
};

TopStories.displayName = 'TopStories';

export default React.memo(TopStories);
