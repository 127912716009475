import { fade, makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  paginationBelow: {
    '& > .swiper-container > .swiper-pagination': {
      position: 'static',
      margin: '10px auto',
      transform: 'none!important',
    },
  },
  pagination: {
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.primary.main,
    },
  },
  navigation: {
    '& .swiper-button-next': { right: 0 },
    '& .swiper-button-prev': { left: 0 },
    '& .swiper-button-next, & .swiper-button-prev': {
      height: '100%',
      marginTop: 0,
      top: 0,
      backgroundColor: fade(theme.palette.secondary.dark, 0),

      '&:after': {
        fontSize: 20,
        color: theme.palette.secondary.light,
      },
    },
    '&.withPagination': {
      '& .swiper-button-next, & .swiper-button-prev': {
        height: 'calc(100% - 32px)',
      },
    },
  },
  equalHeight: {
    '& .swiper-slide': {
      height: 'auto',
    },
  },
}));
