import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { NewsCategory } from '../../types';

export const newsCategoriesSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: NewsCategory } =>
    firestore?.data?.newsCategories ?? {},
);

export const newsCategoriesListSelector = createSelector(
  newsCategoriesSelector,
  (newsCategories): NewsCategory[] =>
    Object.entries(newsCategories || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const newsCategorySelector = (slugOrId: string) =>
  createSelector(
    newsCategoriesSelector,
    newsCategoriesListSelector,
    (newsCategories, newsCategoriesList): NewsCategory =>
      newsCategories[slugOrId] ||
      newsCategoriesList.find(o => o.slug === slugOrId),
  );
