import { useCallback, useEffect, useState } from 'react';

const useLocalStorage = <T>(key: string) => {
  const [state, setState] = useState<T | undefined>(() => {
    const exValue = localStorage.getItem(key);
    if (exValue) {
      try {
        return JSON.parse(exValue) as T;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  const storageWatcher = useCallback(
    (e: StorageEvent) => {
      if (e?.newValue) {
        setState(currState => {
          const newDat = JSON.parse(e.newValue || 'null');
          return newDat === state ? newDat : currState;
        });
      }
    },
    [state],
  );

  useEffect(() => {
    window.addEventListener('storage', storageWatcher);

    return () => {
      window.removeEventListener('storage', storageWatcher);
    };
  }, [state, storageWatcher]);

  return { state, setState };
};

export default useLocalStorage;
