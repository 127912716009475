import { ActionType, createReducer } from 'typesafe-actions';
import { RootState } from '..';
import { selectRoom } from '../../actions/tilldateConfiguration/selectRoom';
import { selectRoomCase } from './selectRoom';

const initialState = {
  selectedRoom: undefined,
};

export const tilldateConfigurationReducer = createReducer<
  RootState['tilldateConfiguration'],
  ActionType<typeof selectRoom>
>(initialState).handleAction(selectRoom, selectRoomCase);
