import React, { useEffect, useState } from 'react';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PageWrapper } from '../../components/molecule';
import { Spacer } from '../../components/atoms';
import { useAuthentication, useVenue } from '../../hooks';

const useStyles = makeStyles({
  successSign: {
    color: '#00A663',
    fontSize: 100,
  },
  errorSign: {
    color: '#CE2029',
    fontSize: 100,
  },
  result: {
    fontWeight: 900,
    letterSpacing: '0.5px',
  },
});

type Props = {};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ConfirmEmail: React.FC<Props> = () => {
  const history = useHistory();
  const styles = useStyles();
  const { t } = useTranslation();
  const queryString = useQuery();
  const { venue: slugOrId } = useParams<{ [key: string]: string }>();
  const { venue, updateVenue } = useVenue({ slugOrId });
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState('');
  const { userId } = useAuthentication();

  useEffect(() => {
    if (!status) {
      if (!venue) {
        setStatus(false);
        setMessage(t('venue.venueNotExistInDb'));
        return;
      }
      if (venue.created_by !== 'BOT') {
        setStatus(false);
        setMessage(t('venue.venueHasOwner'));
        return;
      }

      const encodedIdFromUrl = queryString.get('key');
      if (!encodedIdFromUrl) {
        setStatus(false);
        setMessage(t('venue.incorrectLink'));
        return;
      }
      const encodedVenueId = window.btoa(venue?.id || '');

      if (encodedVenueId === encodedIdFromUrl) {
        updateVenue({ ...venue, created_by: userId });
        setStatus(true);
        setMessage(t('venue.successfulActivation'));
      } else {
        setStatus(false);
        setMessage(t('venue.incorrectLink'));
      }
    }
  }, [queryString, status, t, updateVenue, userId, venue]);

  return (
    <PageWrapper title={t('venue.activate')}>
      <Box p={2}>
        <Paper>
          <Box p={2} textAlign="center">
            <Typography color="error" align="center">
              {status ? (
                <CheckCircleIcon className={styles.successSign} />
              ) : (
                <ErrorIcon className={styles.errorSign} />
              )}
            </Typography>
            <Typography variant="h4" className={styles.result}>
              {status
                ? t('general.successfulActivation')
                : t('general.failedActivation')}
            </Typography>
            <Spacer height="20px" />
            {message && <Typography variant="body1">{message}</Typography>}
            <Spacer height="30px" />
            {status && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/venues/${venue.slug}`)}
              >
                {t('general.goToYourVenue')}
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
    </PageWrapper>
  );
};

export default ConfirmEmail;
