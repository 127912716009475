import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Venue } from '../../../store/types';
import { CardListWidget, InfiniteScroller } from '..';
import VirtualizedList from '../VirtualizedList';
import Item from './Item';

type Props = {
  venues: Venue[];
  loading?: boolean;
  loadMore?: () => void;
  totalResults?: number;
  page?: number;
};

const VenuesList: React.FC<Props> = ({
  venues,
  loading,
  loadMore,
  totalResults,
  page,
}) => {
  const renderItem = useCallback((venue: Venue) => <Item venue={venue} />, []);

  if (loading) {
    return (
      <Box marginTop={3}>
        <VirtualizedList
          data={new Array(3).fill(true)}
          renderItem={() => <CardListWidget.Skeleton withLogo withActions />}
        />
      </Box>
    );
  }
  return (
    <Box marginTop={3}>
      {loadMore && totalResults && page !== undefined ? (
        <InfiniteScroller
          data={venues}
          renderItem={renderItem}
          loadMore={loadMore}
          totalResults={totalResults}
          page={page}
        />
      ) : (
        <VirtualizedList data={venues} renderItem={renderItem} />
      )}
    </Box>
  );
};

VenuesList.displayName = 'VenuesList';

export default React.memo(VenuesList);
