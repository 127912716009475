import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Button, IconButton, List, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { qrCode } from 'ionicons/icons';
import dayjs from 'dayjs';
import { LabeledBox, SearchBar } from '../../molecule';
import {
  useGuestListRegistrations,
  useEvent,
  useGlobalStyles,
} from '../../../hooks';
import { sortObjectByValues } from '../../../utils/ui';
import { GuestListRegistration } from '../../../store/types';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { Emoji, Spacer } from '../../atoms';
import useDateRoom from '../../../hooks/dateRooms/useDateRoom';
import useDateRoomPlayer from '../../../hooks/dateRooms/useDateRoomPlayer';
import SingleRegisterEventGuests from './SingleRegisterEventGuests';
import QRDialog from './QRDialog';

type Props = {
  slugOrId: string;
};

const RegisterEventGuests: React.FC<Props> = ({ slugOrId }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [openQrDialog, toggleQrDialog] = useToggleState(false);
  const [searchedText, setSearchedText] = useState<string>();
  const { eventId, event } = useEvent({ slugOrId });
  const { dateRoom, registerDateRoom } = useDateRoom({ event });
  const {
    registerDateRoomPlayer,
    unregisterDateRoomPlayer,
  } = useDateRoomPlayer({ dateRoom });

  const {
    getEveryRegistrationsByEvent,
    updateGuestListRegistration,
  } = useGuestListRegistrations({ eventId });
  const [guestListRegistrations, setGuestListRegistrations] = useState<
    GuestListRegistration[]
  >([]);

  useEffect(() => {
    if (eventId) {
      const eventGuestLists = getEveryRegistrationsByEvent(eventId);
      setGuestListRegistrations(sortObjectByValues(eventGuestLists, 'name'));
    }
  }, [eventId, getEveryRegistrationsByEvent]);

  const filteredGuestListRegistrations = useMemo(() => {
    if (!searchedText) {
      return guestListRegistrations;
    }
    return guestListRegistrations.filter(
      gl =>
        gl.id?.indexOf(searchedText) !== -1 ||
        gl.name?.toLowerCase().indexOf(searchedText.toLowerCase()) !== -1,
    );
  }, [guestListRegistrations, searchedText]);

  const onAccessedGuest = useCallback(
    (guestListRegistration: GuestListRegistration, guestName: string) => {
      const guestListRegistrationId = guestListRegistration.id || '';

      const updatedGuestListRegistration = {
        ...guestListRegistration,
        guests: guestListRegistration.guests.map(guest =>
          guest.name !== guestName
            ? guest
            : { ...guest, accessed: !guest.accessed },
        ),
      };

      setGuestListRegistrations(guestLists =>
        guestLists.map(gl => {
          if (gl.id === updatedGuestListRegistration.id) {
            return updatedGuestListRegistration;
          }
          return gl;
        }),
      );

      updateGuestListRegistration(
        guestListRegistrationId,
        updatedGuestListRegistration,
      );
    },
    [updateGuestListRegistration],
  );

  const onAccessedAll = useCallback(
    async (checked: boolean, guestListRegistration: GuestListRegistration) => {
      const guestListRegistrationId = guestListRegistration.id || '';
      const updatedGuestListRegistration = {
        ...guestListRegistration,
        guests: guestListRegistration.guests.map(guest => ({
          ...guest,
          accessed: checked,
        })),
      };

      setGuestListRegistrations(guestLists =>
        guestLists.map(gl => {
          if (gl.id === updatedGuestListRegistration.id) {
            return updatedGuestListRegistration;
          }
          return gl;
        }),
      );

      updateGuestListRegistration(
        guestListRegistrationId,
        updatedGuestListRegistration,
      );

      if (checked) {
        registerDateRoomPlayer(guestListRegistration.userId);
      } else {
        unregisterDateRoomPlayer(guestListRegistration.userId);
      }
    },
    [
      registerDateRoomPlayer,
      unregisterDateRoomPlayer,
      updateGuestListRegistration,
    ],
  );

  if (event?.timestamp && event.timestamp > dayjs().add(3, 'hour').valueOf()) {
    return (
      <Box className={globalStyle.mv3}>
        <LabeledBox
          label="Attenzione!"
          boxProps={{
            marginBottom: 2,
          }}
        >
          <Box>
            <Typography align="center" variant="h3">
              <Emoji emoji="👮‍♂️" />
            </Typography>
            <Typography align="center">
              Puoi iniziare a registrare nuovi utenti al tuo evento a partire da
              tre ore prima dell'orario di inizio (
              {dayjs(event.timestamp).format('DDD MM, HH:mm')})!
            </Typography>
          </Box>
        </LabeledBox>
      </Box>
    );
  }

  return (
    <>
      <Box className={globalStyle.mv3}>
        {!dateRoom && (
          <LabeledBox
            label="Attenzione!"
            boxProps={{
              marginBottom: 2,
            }}
          >
            <Box>
              <Typography variant="caption">
                Attenzione: devi aprire la dateroom PRIMA di registrare gli
                utenti alla serata. Le registrazioni effettuate prima
                dell'apertura della dateroom non avranno accesso alla
                funzionalità!
              </Typography>
              <Spacer height="10px" />
              <Button
                onClick={registerDateRoom}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Apri la date room
              </Button>
            </Box>
          </LabeledBox>
        )}
        <SearchBar
          label={t('guestList.searchRegistration')}
          searchText={searchedText}
          setSearchText={setSearchedText}
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleQrDialog}>
                <IonIcon icon={qrCode} />
              </IconButton>
            ),
          }}
        />
        <QRDialog
          toggleQrDialog={toggleQrDialog}
          openQrDialog={openQrDialog}
          guestListRegistrations={guestListRegistrations}
          onAccessedAll={onAccessedAll}
        />
        <Spacer height="20px" />
        <LabeledBox label={t('guestList.registeredPersons')}>
          <List>
            {filteredGuestListRegistrations.map(guestListRegistration => (
              <SingleRegisterEventGuests
                guestListRegistration={guestListRegistration}
                onAccessedGuest={onAccessedGuest}
                onAccessedAll={onAccessedAll}
                key={guestListRegistration.id}
              />
            ))}
          </List>
        </LabeledBox>
      </Box>
    </>
  );
};

RegisterEventGuests.displayName = 'RegisterEventGuests';

export default React.memo(RegisterEventGuests);
