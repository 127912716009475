import React from 'react';
import { Typography } from '@material-ui/core';
import { Flex, Spacer } from '../../components/atoms';

const TextArea = () => {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: '20px 40px' }}
      >
        <Typography
          variant="h3"
          align="center"
          style={{ color: 'white', fontWeight: 700 }}
        >
          Grazie per esserti registrato
        </Typography>
        <Spacer height="50px" />
        <Typography
          variant="h5"
          align="center"
          style={{ color: 'white', fontWeight: 700 }}
        >
          Un membro del nostro staff verificherà manualmente
          <br />
          la tua richiesta e ti contatteremo al più presto!
        </Typography>
      </Flex>
    </>
  );
};

export default TextArea;
