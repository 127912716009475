import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

type Props = {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string;
  icon?: JSX.Element;
  active?: boolean;
  className?: string;
  disableRipple?: boolean;
  tillDate?: boolean;
};

const useStyle = makeStyles(theme => ({
  box: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.secondary.light,
  },
  button: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 0,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  buttonActive: {
    boxShadow: ({ tillDate }: any) =>
      `0px -2px 0px ${tillDate ? '#fd5f59' : theme.palette.primary.main} inset`,
    opacity: 1,
  },
}));

const MenuItem: React.FC<Props> = ({
  href,
  onClick,
  label,
  icon,
  active,
  className,
  disableRipple,
  tillDate,
}) => {
  const style = useStyle({ tillDate });
  const { pathname } = useLocation();
  const history = useHistory();
  const isActive = (href && pathname.indexOf(href) > -1) || active;
  const goTo = useCallback(() => href && history.push(href), [history, href]);
  return (
    <Button
      disableRipple={disableRipple}
      onClick={href ? goTo : onClick}
      className={[
        style.button,
        isActive ? style.buttonActive : '',
        className,
      ].join(' ')}
    >
      <Box className={style.box}>
        {icon}
        <Typography variant="caption">{label}</Typography>
      </Box>
    </Button>
  );
};

MenuItem.displayName = 'MenuItem';

export default React.memo(MenuItem);
