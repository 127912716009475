import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  div {
    width: 100%;
    word-break: break-word;
  }

  blockquote {
    max-width: 100%;
    margin: 0;
  }
`;

type Props = {
  children: string;
  className?: string;
};

const HtmlDecode: React.FC<Props> = ({ children, className }) => {
  return (
    <Span
      dangerouslySetInnerHTML={{ __html: children }}
      className={className ? className : ''}
    />
  );
};

HtmlDecode.displayName = 'HtmlDecode';

export default React.memo(HtmlDecode);
