import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  widgetWrapper: {
    position: 'absolute',
    left: theme.spacing(4),
    width: `calc(100% - ${theme.spacing(8)}px)`,
    bottom: -5,
    background: theme.palette.secondary.light,
    zIndex: 99,
    overflow: 'visible',
  },
  toggleGroupWrapper: {
    position: 'absolute',
    left: ({ isMobile }: any) => (isMobile ? '50%' : undefined),
    transform: ({ isMobile }: any) =>
      isMobile ? 'translate(-50%, -130%)' : 'translateY(-130%)',
  },
  toggleButton: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
