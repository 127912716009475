import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { GuestList } from '../../types';

type FilteredNewsItemsSelector = {
  category?: string;
  searchFor?: string;
  selectedCategoryIds?: string[];
};

export const guestListsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: GuestList } =>
    firestore?.data?.guestLists ?? {},
);

export const guestListsListSelector = createSelector(
  guestListsSelector,
  (guestLists): GuestList[] =>
    Object.entries(guestLists || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const guestListSelector = (id: string) =>
  createSelector(guestListsSelector, (guestLists): GuestList => guestLists[id]);

export const guestListsByEventIdListSelector = (eventId: string) =>
  createSelector(guestListsListSelector, (guestListsList): GuestList[] =>
    guestListsList.filter(guestList => guestList?.eventId === eventId),
  );
