import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../../molecule';

type Props = {};

const SearchOrganizationsBar: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>();
  return (
    <SearchBar
      label={t('organization.search')}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

SearchOrganizationsBar.displayName = 'SearchOrganizationsBar';

export default React.memo(SearchOrganizationsBar);
