import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SearchCategoryTypes } from '../../../store/types';

const useStyles = makeStyles(theme => ({
  outerBox: {
    marginTop: 20,
    height: '58vh',
  },
  filter: {
    fontWeight: 'bold',
    fontSize: 24,
    textDecoration: 'underline',
  },
  box: {
    padding: 12,
    cursor: 'pointer',
    marginBottom: '2px',
    width: '100%',
    borderRadius: '10px',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    fontWeight: 'bold',
  },
}));

type Props = {
  categories: {
    [key in SearchCategoryTypes]: {
      name: string;
    };
  };
  selectedCategory: SearchCategoryTypes;
  searchFor: string | null;
};

const SearchCategories: React.FC<Props> = ({
  categories,
  selectedCategory,
  searchFor,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.outerBox}>
      <Typography
        className={classes.filter}
        variant="h6"
        color="textSecondary"
        component="h5"
      >
        {t('general.filters')}
      </Typography>
      {Object.entries(categories).map(([key, value]) => (
        <Box
          key={key}
          className={
            !(selectedCategory === key)
              ? classes.box
              : [classes.box, classes.selected].join(' ')
          }
          onClick={() =>
            searchFor
              ? history.push(`/search/${key}?q=${searchFor}`)
              : history.push(`/search/${key}`)
          }
        >
          <Typography
            variant="body1"
            color="textSecondary"
            className={!(selectedCategory === key) ? '' : classes.selected}
            component="h5"
          >
            {value.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

SearchCategories.displayName = 'SearchCategories';

export default React.memo(SearchCategories);
