import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useGlobalStyles } from '../../../hooks';
import useStyle from './styles';

type Props = {};

const CardWidgetSkeleton: React.FC<Props> = () => {
  const globalStyle = useGlobalStyles();
  const style = useStyle();
  return (
    <Card variant="outlined" className={style.card}>
      <CardContent>
        <Box className={globalStyle.flex}>
          <Box className={globalStyle.mr3}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="55px"
              height="55px"
            />
          </Box>
          <Box
            className={[globalStyle.flexColumn, globalStyle.flexGrow].join(' ')}
          >
            <Skeleton animation="wave" width="70%">
              <Typography variant="h5">.</Typography>
            </Skeleton>
            <Skeleton animation="wave" width="80%">
              <Typography variant="body1">.</Typography>
            </Skeleton>
          </Box>
        </Box>
      </CardContent>
      <Skeleton animation="wave" variant="rect" height={200} width="100%" />
      <div />
    </Card>
  );
};

CardWidgetSkeleton.displayName = 'CardWidgetSkeleton';

export default React.memo(CardWidgetSkeleton);
