import { DateRoomPlayer } from '../../../store/types/dateRooms';

export const isPreferredGenderMatch = (
  myProfile: DateRoomPlayer,
  otherProfile: DateRoomPlayer,
) => {
  if (myProfile.preferredGender === 'Entrambi') {
    return (
      otherProfile.preferredGender === myProfile.gender ||
      otherProfile.preferredGender === 'Entrambi'
    );
  }
  if (myProfile.preferredGender === otherProfile.gender) {
    return (
      otherProfile.preferredGender === myProfile.gender ||
      otherProfile.preferredGender === 'Entrambi'
    );
  }
  return false;
};
