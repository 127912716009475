import React, { useCallback } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram } from '@material-ui/icons';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { Spacer } from '../../atoms';
import { Organization } from '../../../store/types';
import { GoogleMap } from '../../molecule';
import { checkIcon } from '../../../constants';

type Props = {
  organization?: Organization;
};

const OrganizationDetails: React.FC<Props> = ({ organization }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const renderField = useCallback(
    (title, content) => (
      <>
        <Typography>
          <b>{title}: </b>
          <br />
          {content}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [],
  );
  const renderName = useCallback(
    organization => (
      <>
        <Typography>
          <b>{t('organization.name')}: </b>
          <br />
          {organization?.name}{' '}
          {organization?.isVerified ? (
            <img src={checkIcon} alt="Verified" width="15" />
          ) : null}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [t],
  );
  return (
    <Box
      className={globalStyle.flex}
      justifyContent="center"
      alignItems="center"
      flexDirection={isMobile ? 'column-reverse' : 'column'}
    >
      {organization?.fullAddress && (
        <GoogleMap location={JSON.parse(organization?.fullAddress)} />
      )}
      <Spacer height="30px" />
      <Paper variant="outlined" className={globalStyle.size100}>
        <Box p={2}>
          {renderName(organization)}
          {renderField(t('organization.address'), organization?.address)}
          {renderField(t('organization.city'), organization?.city)}
          {renderField(
            t('organization.region'),
            t(`shared.regions.${organization?.region}`),
          )}
          {organization?.website &&
            renderField(t('organization.website'), organization?.website)}
          {renderField(
            t('organization.musicStyles'),
            organization?.musicStyles?.map(music => (
              <Chip
                component="span"
                key={music}
                label={t(`shared.musicStyles.${music}`)}
                color="secondary"
                variant="outlined"
                size="small"
                className={[globalStyle.mr1, globalStyle.mb1].join(' ')}
              />
            )),
          )}
          {renderField(
            t('organization.followUs'),
            <>
              {organization?.facebook && (
                <IconButton onClick={() => window.open(organization.facebook)}>
                  <Facebook />
                </IconButton>
              )}
              {organization?.instagram && (
                <IconButton onClick={() => window.open(organization.instagram)}>
                  <Instagram />
                </IconButton>
              )}
            </>,
          )}
        </Box>
      </Paper>
    </Box>
  );
};

OrganizationDetails.displayName = 'OrganizationDetails';

export default React.memo(OrganizationDetails);
