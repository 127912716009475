import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { DoneAllOutlined, ReportProblemOutlined } from '@material-ui/icons';
import { IonIcon } from '@ionic/react';
import { qrCode } from 'ionicons/icons';
import { GuestListRegistration } from '../../../store/types';
import { useGlobalStyles } from '../../../hooks';
import { Spacer } from '../../atoms';

type Props = {
  guestListRegistrations: GuestListRegistration[];
  onAccessedAll: (
    checked: boolean,
    registration: GuestListRegistration,
  ) => void;
  openQrDialog: boolean;
  toggleQrDialog: () => void;
};

const QRDialog: React.FC<Props> = ({
  guestListRegistrations,
  onAccessedAll,
  openQrDialog,
  toggleQrDialog,
}) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [
    scannedRegistration,
    setScannedRegistration,
  ] = useState<GuestListRegistration | null>();
  const [error, setError] = useState<string | null>();
  const [qrValidationResponse, setQrValidationResponse] = useState<
    string | null
  >();

  const handleScan = useCallback(
    (id: any) => {
      if (id) {
        const registration = guestListRegistrations.find(
          gl => id && gl.id && gl.id === id,
        );
        if (registration) {
          setScannedRegistration(registration);
        } else {
          setError(t('guestList.qrDialog.notValid'));
          setScannedRegistration(null);
        }
      }
    },
    [guestListRegistrations, t],
  );

  const handleError = useCallback((err: any) => {
    setError(JSON.stringify(err));
  }, []);

  const resetScan = useCallback(() => {
    setQrValidationResponse(null);
    setError(null);
    setScannedRegistration(null);
  }, []);

  const handleClose = useCallback(() => {
    resetScan();
    toggleQrDialog();
  }, [resetScan, toggleQrDialog]);

  useEffect(() => {
    if (scannedRegistration) {
      if (scannedRegistration?.guests.every(guest => guest.accessed)) {
        setError(t('guestList.qrDialog.alreadyAccessed'));
        return;
      }
      onAccessedAll(true, scannedRegistration);
      setQrValidationResponse(t('guestList.qrDialog.accessRegistered'));
    }
  }, [onAccessedAll, scannedRegistration, t]);

  return (
    <Dialog open={openQrDialog} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Typography variant="h6" className={globalStyle.flexCenter}>
          {t('guestList.qrDialog.registerGuests')}
          <Spacer width="10px" />
          <IonIcon icon={qrCode} />
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400, maxWidth: '100%' }}>
        {error && (
          <>
            <Typography
              variant="h5"
              color="error"
              className={globalStyle.bold}
              align="center"
            >
              <ReportProblemOutlined color="error" fontSize="large" />
              <Spacer height="10px" />
              {error}
            </Typography>
          </>
        )}
        {!error && qrValidationResponse && (
          <>
            <Typography
              variant="h5"
              className={globalStyle.bold}
              style={{ color: 'green' }}
              align="center"
            >
              <DoneAllOutlined fontSize="large" style={{ color: 'green' }} />
              <Spacer height="10px" />
              {qrValidationResponse}
            </Typography>
          </>
        )}
        {!error && !qrValidationResponse && (
          <QrReader
            delay={500}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            showViewFinder
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={toggleQrDialog}
          fullWidth
          variant="outlined"
          color="primary"
        >
          {t('guestList.qrDialog.close')}
        </Button>
        <Button
          onClick={resetScan}
          fullWidth
          variant="contained"
          disableElevation
          color="primary"
        >
          {t('guestList.qrDialog.scanNew')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

QRDialog.displayName = 'QRDialog';

export default React.memo(QRDialog);
