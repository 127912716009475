import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export default responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'Raleway',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    breakpoints: {
      // I added 1 extra pixel to these breakpoints,
      // otherwise at breakpoints when we use the useMediaQuery hook
      // together with material ui breakpoints can results bad design
      values: {
        xs: 0,
        sm: 600,
        md: 769,
        lg: 1225,
        xl: 1825,
      },
    },
    palette: {
      primary: {
        light: '#fc837f',
        main: '#FC6560',
        dark: '#b04643',
      },
      secondary: {
        main: grey['800'],
        light: grey['50'],
        dark: grey['900'],
      },
    },
    overrides: {
      MuiCardHeader: {
        content: {
          maxWidth: '100%',
          overflow: 'hidden',
        },
      },
      MuiChip: {
        labelSmall: {
          lineHeight: 20,
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: '#ffffff',
        },
        notchedOutline: {
          border: '1px solid',
          borderColor: '#FC6560 !important',
          '&:hover': {
            border: '1px solid #FC6560',
            borderColor: '#FC6560',
          },
        },
      },
      MuiButton: {
        containedPrimary: {
          color: '#ffffff',
        },
      },
    },
  }),
);
