import { Profile } from '../../types';

export function getPreferredProfilePicture(
  profile: Profile,
): string | undefined {
  return (
    profile.preferredProfilePicture ||
    profile.fbProfilePicture ||
    profile.photoUrl ||
    profile.avatarUrl
  );
}
