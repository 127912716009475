import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Italia from '../../../assets/img/italy_map/cartina_italia.jpg';
import { REGIONS } from '../../../constants';
import { selectRegion } from '../../../store/actions/configurations/selectRegion';

const useStyles = makeStyles({
  wrapper: {
    zoom: ({ zoom }: any) => zoom,
  },
  map: {
    height: 451,
    width: 390,
    objectFit: 'cover',
    paddingLeft: 10,
    paddingTop: 0,
    transform: 'rotate(2deg)',
  },
  path: {
    cursor: 'pointer',
  },
});

type Props = {
  onChange: () => void;
  region?: typeof REGIONS[0] | null;
  zoom?: string;
};

const ItalyMap: React.FC<Props> = ({ onChange, region, zoom = '1' }) => {
  const classes = useStyles({ zoom });
  const [src, setSrc] = useState(Italia);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => setSrc((region && region.img) || Italia), [region]);

  const handleClick = useCallback(
    (selectedRegion: typeof REGIONS[0]) => {
      selectedRegion.id === region?.id
        ? dispatch(selectRegion(null))
        : dispatch(selectRegion(selectedRegion));
      setTimeout(onChange, 300);
    },
    [dispatch, onChange, region],
  );

  return (
    <div className={classes.wrapper}>
      <img
        id="mappaitalia"
        useMap="#Map"
        src={src}
        alt="Italia"
        className={classes.map}
      />
      <map id="Map" name="Map">
        {REGIONS.map(r => (
          <area
            key={r.id}
            shape="poly"
            className={classes.path}
            coords={r.coords}
            onClick={() => handleClick(r)}
            alt={t(`shared.regions.${r.id}`)}
            title={t(`shared.regions.${r.id}`)}
          />
        ))}
      </map>
    </div>
  );
};

ItalyMap.displayName = 'ItalyMap';

export default React.memo(ItalyMap);
