import {
  Box,
  Collapse,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  collapseInfo: {
    fontWeight: 700,
    lineHeight: 1,
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

type Props = {
  options: { value: string; name: string }[];
  setState?: React.Dispatch<React.SetStateAction<string>>;
  visibleOptionsNumber?: number;
  btnSize?: 'small' | 'medium';
};

const FilterRadioOptions: React.FC<Props> = ({
  options,
  setState,
  visibleOptionsNumber = 4,
  btnSize = 'small',
}) => {
  const [showMore, setShowMore] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (setState) {
      setState(selectedValue);
    }
  }, [selectedValue, setState]);

  const renderRadioBtn = useCallback(
    (value: string, label: string) => (
      <Box key={value + Math.random()}>
        <FormControlLabel
          value={value}
          control={<Radio color="primary" size={btnSize} />}
          label={label}
        />
      </Box>
    ),
    [btnSize],
  );

  return (
    <>
      {options.length ? (
        <>
          <RadioGroup value={selectedValue} onChange={handleChange}>
            {options
              .slice(0, visibleOptionsNumber)
              .map((values: { value: string; name: string }) => {
                return renderRadioBtn(values.value, values.name);
              })}
            {Object.keys(options).length > visibleOptionsNumber && (
              <>
                {!showMore && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    component="span"
                    onClick={() => setShowMore(true)}
                    className={classes.collapseInfo}
                  >
                    {t('shared.showMore')}
                  </Box>
                )}
                <Collapse in={showMore}>
                  {options
                    .slice(visibleOptionsNumber)
                    .map((values: { value: string; name: string }) => {
                      return renderRadioBtn(values.value, values.name);
                    })}
                  {showMore && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      component="span"
                      onClick={() => setShowMore(false)}
                      className={classes.collapseInfo}
                    >
                      {t('shared.showLess')}
                    </Box>
                  )}
                </Collapse>
              </>
            )}
            {selectedValue &&
              renderRadioBtn('', t('profile.privacySettings.none'))}
          </RadioGroup>
        </>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
};

FilterRadioOptions.displayName = 'FilterRadioOptions';

export default React.memo(FilterRadioOptions);
