import { useIonViewWillEnter } from '@ionic/react';
import { Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router';
import { PhotoAlbum, PhotoAlbumCover } from '..';
import {
  useCompressedImage,
  useCompressedImages,
  useEvent,
  useGlobalStyles,
  useGuestListRegistrations,
  useUsers,
  useVerification,
} from '../../../hooks';
import { usePhotoAlbum, usePhotoAlbums } from '../../../hooks/photoAlbums';
import { LoadingCover, Spacer } from '../../atoms';

type Props = {};

interface ParamType {
  eventId: string;
}

const RegisterPhotoAlbum: React.FC<Props> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [cover, , setCompressCover] = useCompressedImage();
  const [
    images,
    setImages,
    setCompressImages,
    progress,
  ] = useCompressedImages();
  const [canSave, setCanSave] = useState(false);
  const { eventId } = useParams<ParamType>();
  const { event, canCreateAlbum } = useEvent({
    slugOrId: eventId,
  });
  const [contentSaved, setContentSaved] = useState(false);
  const globalStyles = useGlobalStyles();
  const { registerPhotoAlbum, loading } = usePhotoAlbum();
  const { photoAlbumsByUserAndEvent } = usePhotoAlbums();
  const { getEveryRegistrationsByEvent } = useGuestListRegistrations();
  const [guestNames, setGuestNames] = useState<string[]>([]);
  const { usersByNames: registeredUsers } = useUsers({
    names: guestNames,
  });
  const { onPhotoAlbumCreationEmail } = useVerification();
  useIonViewWillEnter(() => {
    if (!event || !canCreateAlbum) {
      history.push('/home');
    }
    if (photoAlbumsByUserAndEvent.length) {
      history.push(`/events/${event.id}`);
    }
  }, []);

  useEffect(() => {
    if (event?.id) {
      const registrations = getEveryRegistrationsByEvent(event.id);
      const names: string[] = [];
      registrations.forEach(reg =>
        reg.guests.forEach(user => names.push(user.name)),
      );
      setGuestNames(names);
    }
  }, [event, getEveryRegistrationsByEvent]);

  const handleSave = useCallback(async () => {
    if (eventId) {
      await registerPhotoAlbum({ eventId, images, cover });
      setContentSaved(true);
      registeredUsers.forEach(user =>
        onPhotoAlbumCreationEmail(
          user.email,
          user.displayName,
          event?.name,
          eventId,
        ),
      );
      history.push(`/events/${event.id}`);
    }
  }, [
    cover,
    event,
    eventId,
    history,
    images,
    onPhotoAlbumCreationEmail,
    registerPhotoAlbum,
    registeredUsers,
  ]);

  useEffect(() => {
    setCanSave(!!eventId && !!images.length && !!cover);
    setContentSaved(false);
  }, [cover, eventId, images.length]);

  return (
    <>
      <div className={globalStyles.relative}>
        {loading && <LoadingCover />}
        {!!progress && <LoadingCover progress={progress} />}
        <Prompt
          when={!!(images.length && !contentSaved)}
          message={t('photoAlbums.exit')}
        />
        <Spacer height="10px" />
        <Typography
          color="textPrimary"
          variant="h4"
          align="center"
          className={globalStyles.bold}
        >
          Event: {event?.name}
        </Typography>
        <Spacer height="10px" />
        <PhotoAlbumCover.Form setCover={setCompressCover} cover={cover} />
        <PhotoAlbum
          images={images}
          setImages={setImages}
          setCompressImages={setCompressImages}
        />
        <Spacer height="20px" />
        <Button
          onClick={handleSave}
          variant="outlined"
          color="primary"
          disabled={!canSave}
        >
          {t('shared.save')}
        </Button>
        <Spacer height="20px" />
      </div>
    </>
  );
};

RegisterPhotoAlbum.displayName = 'RegisterPhotoAlbum';

export default React.memo(RegisterPhotoAlbum);
