import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useGlobalStyles } from '../../../hooks';
import useStyle from './styles';

type Props = {
  withLogo?: boolean;
  withActions?: boolean;
};

const CardWidgetSkeleton: React.FC<Props> = ({ withLogo, withActions }) => {
  const style = useStyle();
  const globalStyle = useGlobalStyles();
  return (
    <Card variant="outlined" className={style.card}>
      <Skeleton
        animation="wave"
        variant="rect"
        height={180}
        width={180}
        className={style.cardMedia}
      />
      <Box
        width="100%"
        className={globalStyle.flexColumn}
        justifyContent="space-between"
      >
        <CardHeader
          title={
            <Skeleton animation="wave" width="70%">
              <Typography variant="h5">.</Typography>
            </Skeleton>
          }
          subheader={
            <Skeleton animation="wave" width="80%">
              <Typography variant="body1">.</Typography>
            </Skeleton>
          }
          avatar={
            withLogo ? (
              <Skeleton
                animation="wave"
                variant="rect"
                className={style.logo}
                height={60}
                width={60}
              />
            ) : undefined
          }
          className={[globalStyle.max100].join(' ')}
          titleTypographyProps={{
            variant: 'h6',
            className: globalStyle.ellipsis,
          }}
          subheaderTypographyProps={{
            variant: 'body2',
            className: globalStyle.ellipsis,
          }}
        />
        <CardContent className={style.content}>
          <Box style={{ display: 'flex', marginRight: 5 }}>
            <Skeleton
              animation="wave"
              width={60}
              height={24}
              style={{
                transform: 'none',
                borderRadius: '24px',
                marginRight: 5,
              }}
            />
            <Skeleton
              animation="wave"
              width={60}
              height={24}
              style={{
                transform: 'none',
                borderRadius: '24px',
                marginRight: 5,
              }}
            />
            <Skeleton
              animation="wave"
              width={60}
              height={24}
              style={{
                transform: 'none',
                borderRadius: '24px',
                marginRight: 5,
              }}
            />
          </Box>
        </CardContent>
        {withActions && (
          <Box>
            <Divider />
            <CardActions className={globalStyle.mh1}>
              <Skeleton
                animation="wave"
                variant="text"
                height={32}
                width="100%"
                style={{ transform: 'none' }}
              />
            </CardActions>
          </Box>
        )}
      </Box>
      <div />
    </Card>
  );
};

CardWidgetSkeleton.displayName = 'CardWidgetSkeleton';

export default React.memo(CardWidgetSkeleton);
