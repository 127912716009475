import React from 'react';
import styled from 'styled-components';

const GridBreak = styled.div`
  width: 100%;
`;

GridBreak.displayName = 'GridBreak';

export default React.memo(GridBreak);
