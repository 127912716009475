import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useVenues } from '../../../hooks';
import { Event, GuestList } from '../../../store/types';
import { Spacer } from '../../atoms';
import {
  FormField,
  LabeledBox,
  SelectAdmins,
  SelectPhotographers,
} from '../../molecule';
import { MUSIC_STYLES } from '../../../constants';
import CreateGuestListDialog from '../GuestLists/CreateGuestListDialog/CreateGuestListDialog';
import GuestListPreview from '../GuestLists/GuestListPreview';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { getDateByNDaysAgo } from '../../../utils';
import SelectOrganizations from './SelectOrganiations';
import SelectArtists from './SelectArtists';

export type Form = Event;

type Props = {
  event?: Form;
  onSubmit: (values: Form, guestLists?: GuestList[]) => void;
  customErrors?: Partial<Form>;
};

const EventForm: React.FC<Props> = ({ onSubmit, customErrors, event }) => {
  const { t } = useTranslation();
  const { organizations, artists } = event || {};
  const [disclaimer, setDisclaimer] = useState<string>();
  const [guestLists, setGuestLists] = useState<GuestList[]>([]);
  const [selectedOrgs, setSelectedOrgs] = useState<Event['organizations']>();
  const [selectedArts, setSelectedArts] = useState<Event['artists']>();
  const [openCreateGuestList, toggleCreateGuestList] = useToggleState(false);
  const [confirm, setConfirm] = useState(false);
  const [showGuestLists, setShowGuestLists] = useState(true);
  const { handleSubmit, register, errors, control, setValue } = useForm<Form>({
    defaultValues: { photographers: [], admins: [], minAge: 18, ...event },
  });
  const { filteredVenues, canCreateEvent } = useVenues({ myVenues: true });
  const [admins, setAdmins] = useState<string[]>([]);
  const [photographers, setPhotographers] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(
    event ? new Date(event?.date) : undefined,
  );

  const onShowGuestLists = useCallback(
    (date: Date) => {
      if (date) {
        setShowGuestLists(new Date(date).getTime() >= new Date().getTime());
        setStartDate(new Date(date));
        setValue('endDate', undefined);
      }
    },
    [setValue],
  );

  const onCreateGuestLists = useCallback(
    values => {
      console.log(guestLists);
      setGuestLists([...guestLists, values]);
    },
    [guestLists],
  );

  const onDeleteGuestList = useCallback(
    i => {
      const newGL = Array.from(guestLists || []);
      newGL.splice(i, 1);
      setGuestLists(newGL);
    },
    [guestLists],
  );

  const handleFormSubmit = handleSubmit(values => {
    if (!event && showGuestLists && !guestLists?.length) {
      setConfirm(true);
      return;
    }
    onSubmit(
      {
        admins,
        photographers,
        ...values,
        organizations: {
          ...(values.organizations || {}),
          ...(selectedOrgs || {}),
        },
        artists: {
          ...(values.artists || {}),
          ...(selectedArts || {}),
        },
      },
      guestLists,
    );
  });

  const handleFormSubmitWithNoGl = handleSubmit(values => {
    setConfirm(false);
    onSubmit(
      {
        admins,
        photographers,
        ...values,
        organizations: {
          ...(values.organizations || {}),
          ...(selectedOrgs || {}),
        },
        artists: {
          ...(values.artists || {}),
          ...(selectedArts || {}),
        },
      },
      guestLists,
    );
  });

  const toggleDisclaimer = useCallback(
    e => {
      parseInt(e.target.value) < 18
        ? setDisclaimer(t('form.ageDisclaimer'))
        : setDisclaimer('');
    },
    [t],
  );

  const handleAddAGuestlist = useCallback(() => {
    setConfirm(false);
    toggleCreateGuestList();
  }, [toggleCreateGuestList]);

  useEffect(() => {
    if (organizations) {
      setSelectedOrgs(organizations);
    }
  }, [organizations, setSelectedOrgs]);

  useEffect(() => {
    if (artists) {
      setSelectedArts(artists);
    }
  }, [artists, setSelectedArts]);

  const selectAdmins = (adminsList: string[]) => {
    setAdmins(adminsList);
  };

  const selectPhotographers = (photographersList: string[]) => {
    setPhotographers(photographersList);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Dialog open={confirm}>
          <DialogTitle>Attenzione</DialogTitle>
          <DialogContent>
            Stai creando un evento senza nessuna guestlist!
            <br />
            Così facendo gli utenti di Tilllate non saranno in grado di
            registrarsi al tuo evento, <br />
            Come vuoi procdere?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleFormSubmitWithNoGl}
            >
              Procedi senza Guestlist
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleAddAGuestlist}
            >
              Crea Guestlist ora!
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <FormField
              name="venueId"
              type="select"
              label={t('event.venue')}
              defaultValue={!event ? '' : undefined}
              options={filteredVenues.map(v => ({
                id: v?.id!,
                value: v?.name!,
              }))}
              register={register}
              control={control}
              required
              error={errors.venueId?.message}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormField
              name="name"
              label={t('event.name')}
              register={register}
              required
              error={errors.name?.message}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormField
              type="datetime"
              name="date"
              label={t('event.startDate')}
              register={register}
              control={control}
              required
              error={customErrors?.date}
              cbFunction={onShowGuestLists}
              minDate={getDateByNDaysAgo(5)}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormField
              type="datetime"
              name="endDate"
              label={t('event.endDate')}
              register={register}
              control={control}
              required
              error={customErrors?.endDate}
              minDate={startDate}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormField
              name="minAge"
              type="number"
              defaultValue="18"
              label={t('event.minAge')}
              onChange={toggleDisclaimer}
              helperText={disclaimer}
              register={register}
              required
              error={errors.minAge?.message}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormField
              name="musicStyles"
              label={t('event.musicStyles')}
              type="checkbox"
              langDomain="shared.musicStyles"
              options={MUSIC_STYLES}
              defaultOptions={event?.musicStyles}
              register={register}
              error={customErrors?.musicStyles?.[0]}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectAdmins
              selectAdmins={selectAdmins}
              defaultAdmins={event?.admins}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SelectPhotographers
              selectPhotographers={selectPhotographers}
              defaultPhotographers={event?.photographers}
            />
          </Grid>
          {!event && showGuestLists && (
            <>
              <Spacer height="20px" />
              <Grid item md={12} xs={12}>
                <LabeledBox label="GuestLists" highlight>
                  <>
                    {guestLists?.length ? (
                      guestLists.map((guestList, i) => (
                        <GuestListPreview
                          key={i}
                          guestList={guestList}
                          onDelete={() => onDeleteGuestList(i)}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                </LabeledBox>
              </Grid>
            </>
          )}
          <Grid item md={12} xs={12}>
            <LabeledBox label={t('general.assignOrganizations')}>
              <SelectOrganizations
                register={register}
                selectedOrgs={selectedOrgs}
                setSelectedOrgs={setSelectedOrgs}
              />
            </LabeledBox>
          </Grid>
          <Grid item md={12} xs={12}>
            <LabeledBox label={t('general.assignArtists')}>
              <SelectArtists
                register={register}
                selectedArts={selectedArts}
                setSelectedArts={setSelectedArts}
              />
            </LabeledBox>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormField
              name="description"
              label={t('event.description')}
              type="textarea"
              required
              control={control}
              setValue={setValue}
              register={register}
              error={customErrors?.description}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid container justify="space-around">
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="primary"
              disabled={!canCreateEvent}
            >
              {event ? t('event.edit') : t('event.goLive')}
            </Button>
          </Grid>
        </Grid>
        <Spacer height="30px" />
      </form>
      {!event && (
        <CreateGuestListDialog
          open={openCreateGuestList}
          onClose={toggleCreateGuestList}
          onSubmit={onCreateGuestLists}
        />
      )}
    </>
  );
};

EventForm.displayName = 'EventForm';

export default React.memo(EventForm);
