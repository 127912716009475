import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from '@material-ui/core';
import React from 'react';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { useGlobalStyles } from '../../../hooks';
import { Event } from '../../../store/types';
import DateWidget from '../DateWidget';
import useStyle from './style';

type Props = {
  event: Event;
  onClick?: () => void;
};

const EventHeaderWidget: React.FC<Props> = ({ event, onClick }) => {
  const style = useStyle();
  const globalStyles = useGlobalStyles();
  const cover = (event?.coverUrl && event?.coverUrl) || defaultCover;
  return (
    <Card
      className={[
        globalStyles.relative,
        globalStyles.notRounded,
        style.card,
      ].join(' ')}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia
          image={cover}
          title={event.name}
          className={style.CardMedia}
        />
        <Box className={style.dateWrapper}>
          <DateWidget date={event.date} />
        </Box>
        <Typography className={style.pageTitle}>{event.name}</Typography>
      </CardActionArea>
    </Card>
  );
};

EventHeaderWidget.displayName = 'EventHeaderWidget';

export default React.memo(EventHeaderWidget);
