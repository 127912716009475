import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useVerification } from '../../hooks/verification';
import { PageWrapper } from '../../components/molecule';
import { Emoji, Spacer } from '../../components/atoms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const ConfirmEmail: React.FC<Props> = ({ match }) => {
  const { id } = match.params;
  const { t } = useTranslation();
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState('');
  const { verifyEmail } = useVerification({ id });

  useEffect(() => {
    verifyEmail().then(data => {
      setStatus(data.success);
      setMessage(data.message);
    });
    return () => {};
  }, [verifyEmail]);

  return (
    <PageWrapper title={t('emails.accounts.verification.emailVerification')}>
      <Box p={2}>
        <Paper>
          <Box p={2} textAlign="center">
            <Typography variant="h2" color="error" align="center">
              <Emoji emoji={status ? '✅' : '❗️'} />
            </Typography>
            <Typography variant="h4">
              <b style={{ fontWeight: 900 }}>{status ? message : 'Ops...'}</b>
            </Typography>
            <Spacer height="20px" />
            {!status && <Typography variant="body2">{message}</Typography>}
          </Box>
        </Paper>
      </Box>
    </PageWrapper>
  );
};

export default ConfirmEmail;
