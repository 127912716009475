import {
  personCircle,
  logOut,
  calendar,
  newspaper,
  camera,
  map,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useAuthentication } from '../auth';

export const useMenuList = () => {
  const { isAuthenticated, logout } = useAuthentication();
  const history = useHistory();

  const actions = [
    {
      text: 'News',
      icon: newspaper,
      handler: (): void => {
        history.push('/news');
      },
    },
    {
      text: 'Events',
      icon: calendar,
      handler: (): void => {
        history.push('/events');
      },
    },
    {
      text: 'Photos',
      icon: camera,
      handler: (): void => {
        history.push('/photos');
      },
    },
    {
      text: 'Regione',
      icon: map,
      handler: (): void => {
        history.push('/regione');
      },
    },
    {
      text: 'Login',
      icon: personCircle,
      hidden: isAuthenticated,
      handler: (): void => {
        history.push('/login');
      },
    },
    {
      text: 'Profilo',
      icon: personCircle,
      hidden: !isAuthenticated,
      handler: (): void => {
        history.push('/profile');
      },
    },
    {
      text: 'Logout',
      icon: logOut,
      hidden: !isAuthenticated,
      handler: () => logout(),
    },
  ];

  return actions;
};
