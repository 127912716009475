import { ContentColumns } from 'pdfmake/interfaces';
import { createRectWithLabel, createLabeledBox } from '../../../utils';

const SMALL_BOX_REL_HEIGHT_CORRECTION = 10;
const BIG_BOX_REL_HEIGHT_CORRECTION = 15;
const EVENT_NAME_LINE_HEIGHT = 13;

export const drawQrCode = (size: number, label: string): ContentColumns => {
  return {
    columns: [
      {
        stack: [
          {
            canvas: createRectWithLabel(size, size, label),
          },
          {
            columns: [
              {
                width: size - 20,
                noWrap: true,
                height: size - 20,
                image: 'qrCode',
              },
            ],
            relativePosition: {
              x: 10,
              y: -size + SMALL_BOX_REL_HEIGHT_CORRECTION,
            },
          },
          {
            columns: [
              {
                noWrap: true,
                text: label,
                fontSize: 7,
                bold: true,
              },
            ],
            relativePosition: {
              x: 15,
              y: -size - 4.5,
            },
          },
        ],
      },
    ],
  };
};

const createTextInLabeledBox = (text: string) => {
  return [
    {
      noWrap: false,
      text: text,
      fontSize: 9,
    },
  ];
};

export const createLabeledBoxWithComplexContent = (
  width: number,
  height: number,
  label: string,
  content: any,
): ContentColumns => {
  return createLabeledBox(
    width,
    height,
    SMALL_BOX_REL_HEIGHT_CORRECTION,
    label,
    content,
  );
};

export const createDetailBoxWithTitle = (
  width: number,
  height: number,
  label: string,
  subBoxWidth1: number,
  subBoxWidth2: number,
  subBoxHeight1: number,
  subBoxHeight2: number,
  subLabel1: string,
  subLabel2: string,
  value1: string,
  value2: string,
  title: string,
  numberOfTitleLines: number,
): ContentColumns => {
  return {
    columns: [
      {
        stack: [
          {
            canvas: createRectWithLabel(width, height, label),
          },
          {
            columns: [
              {
                stack: [{ text: title, bold: true }],
                width,
                alignment: 'center',
              },
            ],
            relativePosition: {
              x: 10,
              y: -height + BIG_BOX_REL_HEIGHT_CORRECTION,
            },
          },
          {
            columns: [
              createLabeledBox(
                subBoxWidth1,
                subBoxHeight1,
                SMALL_BOX_REL_HEIGHT_CORRECTION,
                subLabel1,
                createTextInLabeledBox(value1),
              ),
              createLabeledBox(
                subBoxWidth2,
                subBoxHeight2,
                SMALL_BOX_REL_HEIGHT_CORRECTION,
                subLabel2,
                createTextInLabeledBox(value2),
              ),
            ],
            relativePosition: {
              x: 10,
              y: -height + 34 + EVENT_NAME_LINE_HEIGHT * numberOfTitleLines,
            },
          },
          {
            columns: [
              {
                noWrap: true,
                text: label,
                fontSize: 8,
                bold: true,
              },
            ],
            relativePosition: {
              x: 15,
              y: -height - 4.5,
            },
          },
        ],
      },
    ],
  };
};

export const createBoxWithTwoSubBoxes = (
  width: number,
  height: number,
  label: string,
  subBoxWidth1: number,
  subBoxWidth2: number,
  subBoxHeight1: number,
  subBoxHeight2: number,
  subLabel1: string,
  subLabel2: string,
  value1: string,
  value2: string,
): ContentColumns => {
  const content = [
    createLabeledBox(
      subBoxWidth1,
      subBoxHeight1,
      SMALL_BOX_REL_HEIGHT_CORRECTION,
      subLabel1,
      createTextInLabeledBox(value1),
    ),
    createLabeledBox(
      subBoxWidth2,
      subBoxHeight2,
      SMALL_BOX_REL_HEIGHT_CORRECTION,
      subLabel2,
      createTextInLabeledBox(value2),
    ),
  ];
  return createLabeledBox(
    width,
    height,
    BIG_BOX_REL_HEIGHT_CORRECTION,
    label,
    content,
  );
};
