import { makeStyles } from '@material-ui/core';

export default makeStyles({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bar: {
    width: 250,
    marginTop: 20,
    maxWidth: '80%',
    height: 1,
    boxShadow: '0px 0px 10px inset #121212',
  },
  logo: { width: 250, maxWidth: '80%' },
});
