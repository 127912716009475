import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNews, useNewsCategory, useGlobalStyles } from '../../hooks';
import { PageWrapper, NewsList } from '../../components/molecule';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const Category: React.FC<Props> = ({ match }) => {
  const { t } = useTranslation();
  const { category: slugOrId } = match.params;
  const globalStyle = useGlobalStyles();

  const { newsCategory: category } = useNewsCategory({
    slugOrId: slugOrId,
  });

  const { filteredNews: news } = useNews({
    category: category?.id,
  });
  return (
    <PageWrapper title={`${t('news.category')}: ${slugOrId}`}>
      {news.length ? (
        <NewsList news={news} />
      ) : (
          <Typography align="center" className={globalStyle.mv3}>
            {t('news.noNews')}
          </Typography>
        )}
    </PageWrapper>
  );
};

export default Category;
