import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCompressedImage,
  useEditGuard,
  useGlobalStyles,
  useVenue,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import VenueForm from '../VenueForm';
import { Form } from '../VenueForm/VenueForm';
import { LoadingCover, Spacer } from '../../atoms';
import { multipleEmailCheck } from '../../../utils/ui/isEmail';

type Props = {
  slugOrId: string;
};

const EditVenue: React.FC<Props> = ({ slugOrId }) => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, setCover, setCompressCover] = useCompressedImage();
  const [logo, setLogo, setCompressLogo] = useCompressedImage();
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const { updateVenue, loading, venue } = useVenue({ slugOrId });
  useEditGuard(venue);

  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    if (!values.fullAddress) {
      setCustomErrors(prev => ({
        ...prev,
        fullAddress: t('form.invalidAddress'),
      }));
      return;
    }
    if (values?.additionalEmails) {
      if (!multipleEmailCheck(values.additionalEmails)) {
        setCustomErrors(prev => ({
          ...prev,
          additionalEmails: [t('form.invalidEmails')],
        }));
        return;
      }
      let additionalEmails = values.additionalEmails.trim();
      if (additionalEmails.charAt(additionalEmails.length - 1) === ',') {
        additionalEmails = additionalEmails.slice(0, -1);
      }
      values = {
        ...values,
        additionalEmails: additionalEmails.split(','),
      };
    }

    setCustomErrors({});
    updateVenue({ cover, logo, ...values });
  };

  useEffect(() => {
    if (venue) {
      setLogo(venue?.logoUrl || []);
      setCover(venue?.coverUrl || []);
    }
  }, [setCover, setLogo, venue]);

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <PageCoverAndLogo.Form
        logo={logo}
        setLogo={setCompressLogo}
        cover={cover}
        setCover={setCompressCover}
      />
      {venue && (
        <VenueForm
          onSubmit={onSubmit}
          customErrors={customErrors}
          venue={venue}
        />
      )}
    </div>
  );
};

EditVenue.displayName = 'EditVenue';

export default React.memo(EditVenue);
