import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import {
  useNewsItem,
  useNewsCategories,
  useCompressedImage,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import NewsForm from '../NewsForm';
import { Form } from '../NewsForm/NewsForm';
import { LoadingCover, Spacer } from '../../atoms';

const RegisterNews: React.FC = () => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, , setCompressCover] = useCompressedImage();
  const [editorValue, setEditorValue] = useState('');
  const { registerNewsItem, loading } = useNewsItem();
  const { newsCategoriesList: newsCategories } = useNewsCategories();

  const onSubmit = async (values: any) => {
    if (!editorValue) {
      setCustomErrors(prev => ({
        ...prev,
        description: 'Description required',
      }));
      return;
    }
    setCustomErrors({});
    registerNewsItem({ cover, ...values, description: editorValue });
  };

  return (
    <Box position={'relative'}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <PageCoverAndLogo.Form cover={cover} setCover={setCompressCover} />
      <NewsForm
        onSubmit={onSubmit}
        customErrors={customErrors}
        newsCategories={newsCategories}
        editorValue={editorValue}
        setEditorValue={setEditorValue}
      />
    </Box>
  );
};

RegisterNews.displayName = 'RegisterNews';

export default React.memo(RegisterNews);
