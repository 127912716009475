import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  error?: string | null;
  label: string;
  defaultOptions?: string[];
  options?: { id: string; value: string }[];
  name: string;
  langDomain?: string;
  register: any;
  required?: boolean;
  fieldClassName?: string;
};

const CheckboxField: React.FC<Props> = ({
  error,
  label,
  defaultOptions,
  options,
  langDomain,
  name,
  register,
  required,
  fieldClassName,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      border={1}
      p={2}
      borderRadius={'4px'}
      style={{ backgroundColor: '#fff' }}
      borderColor={theme.palette.primary.main}
      className={fieldClassName}
    >
      <FormControl required error={!!error} component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup row>
          {options?.map(option => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  defaultChecked={defaultOptions?.includes(option.id)}
                />
              }
              value={option.id}
              label={
                langDomain
                  ? t(`${langDomain}.${option.value}`, {
                    defaultValue:
                      option.value.substr(0, 1).toUpperCase() +
                      option.value.substr(1),
                  })
                  : option.value
              }
              name={`${name}`}
              inputRef={register({
                required: required ? (t('form.required') as string) : undefined,
              })}
            />
          ))}
        </FormGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

CheckboxField.displayName = 'CheckboxField';

export default React.memo(CheckboxField);
