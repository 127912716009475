import React from 'react';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Spacer } from '../../atoms';
import useStyle from './styles';

type Props = {
  withLogo?: boolean;
  withActions?: boolean;
};

const CardWidgetSkeleton: React.FC<Props> = ({ withLogo, withActions }) => {
  const style = useStyle();
  return (
    <Card variant="outlined" className={style.card}>
      <Skeleton animation="wave" variant="rect" height={140} width="100%" />
      {withLogo && (
        <Skeleton
          animation="wave"
          variant="rect"
          className={style.logo}
          style={{ position: 'absolute' }}
          height={80}
        />
      )}
      <CardContent>
        <Skeleton animation="wave" width="70%">
          <Typography variant="h5">.</Typography>
        </Skeleton>
        <Skeleton animation="wave" width="80%">
          <Typography variant="body1">.</Typography>
        </Skeleton>
        <Spacer height="16px" />
        <Box style={{ display: 'flex', marginRight: 5 }}>
          <Skeleton
            animation="wave"
            width={60}
            height={24}
            style={{ transform: 'none', borderRadius: '24px', marginRight: 5 }}
          />
          <Skeleton
            animation="wave"
            width={60}
            height={24}
            style={{ transform: 'none', borderRadius: '24px', marginRight: 5 }}
          />
          <Skeleton
            animation="wave"
            width={60}
            height={24}
            style={{ transform: 'none', borderRadius: '24px', marginRight: 5 }}
          />
        </Box>
      </CardContent>
      {withActions && (
        <CardActions>
          <Skeleton
            animation="wave"
            variant="text"
            height={32}
            width="100%"
            style={{ transform: 'none' }}
          />
        </CardActions>
      )}
      <div />
    </Card>
  );
};

CardWidgetSkeleton.displayName = 'CardWidgetSkeleton';

export default React.memo(CardWidgetSkeleton);
