import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Box } from '@material-ui/core';
import {
  ChatTwoTone,
  RecentActorsTwoTone,
  ThumbsUpDownTwoTone,
} from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router';
import { useGlobalStyles } from '../../../hooks';
import useChatRoom from '../../../hooks/dateRooms/useChatRoom';
import { DateRoom } from '../../../store/types/dateRooms';
import useStyle from './style';

type Props = {
  selectedRoom?: DateRoom;
};

const TillDateNavigator: React.FC<Props> = ({ selectedRoom }) => {
  const style = useStyle();
  const globalStyle = useGlobalStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [currentScreen, setCurrentScreen] = useState<string>(pathname);
  const { newChatRooms } = useChatRoom({ dateRoom: selectedRoom });
  const onChangeScreen = useCallback(
    (screen: string) => {
      if (screen) {
        history.push(screen);
      }
    },
    [history],
  );

  useEffect(() => {
    setCurrentScreen(pathname);
  }, [pathname]);

  return (
    <Box
      className={globalStyle.flex}
      alignItems="center"
      justifyContent="space-between"
    >
      <ToggleButtonGroup
        exclusive
        size="small"
        color="primary"
        value={currentScreen}
        onChange={(e, v) => onChangeScreen(v)}
        className={globalStyle.bgSecondaryLight}
      >
        <ToggleButton
          value="/tilldatematch"
          aria-label="events"
          className={style.toggleButton}
        >
          <Badge
            badgeContent={newChatRooms.length}
            color="secondary"
            classes={{
              badge: style.badge,
            }}
          >
            <ChatTwoTone />
          </Badge>
        </ToggleButton>
      </ToggleButtonGroup>

      <Box>
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={currentScreen}
          onChange={(e, v) => onChangeScreen(v)}
          className={globalStyle.bgSecondaryLight}
        >
          <ToggleButton
            value="/tilldate"
            aria-label="events"
            className={style.toggleButton}
          >
            <RecentActorsTwoTone />
          </ToggleButton>
          <ToggleButton
            value="/tilldate-likes"
            aria-label="events"
            className={style.toggleButton}
          >
            <ThumbsUpDownTwoTone />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

TillDateNavigator.displayName = 'TillDateNavigator';

export default React.memo(TillDateNavigator);
