import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Box, Chip, Grid, Typography, Divider } from '@material-ui/core';
import { NewReleasesTwoTone } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { PageActions, PageWrapper } from '../../components/molecule';
import { useNewsItem, useGlobalStyles, useNewsCategories } from '../../hooks';
import { LoadingCover, Spacer, HtmlDecode } from '../../components/atoms';
import { EventCover } from '../../components/organisms';
import useStyle from './styles';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const NewsItem: React.FC<Props> = ({ match }) => {
  const { newsitem: slugOrId } = match.params;
  const globalStyle = useGlobalStyles();
  const { newsCategories } = useNewsCategories();
  const { t } = useTranslation();
  const { newsItem, loading, isAdmin } = useNewsItem({
    slugOrId,
  });
  const { slug } = newsItem || {};
  const history = useHistory();
  const style = useStyle();

  const goToCategory = useCallback((to: any) => history.push(to), [history]);

  const onEditNews = useCallback(() => {
    history.push(`/edit-news/${slug}`);
  }, [history, slug]);

  const renderActions = useCallback(
    containerClassName => {
      return (
        isAdmin && (
          <PageActions
            containerClassName={containerClassName}
            actions={[
              {
                label: t('news.edit'),
                action: onEditNews,
                icon: (
                  <NewReleasesTwoTone className={style.NewReleasesTwoTone} />
                ),
              },
            ]}
          />
        )
      );
    },
    [isAdmin, onEditNews, style.NewReleasesTwoTone, t],
  );

  return (
    <>
      {loading && <LoadingCover />}
      <PageWrapper boxed={false}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <EventCover
                cover={newsItem?.coverUrl}
                renderActions={renderActions}
                item={newsItem}
                category="news"
              />
              <Typography
                color="textPrimary"
                variant="h3"
                align="center"
                className={[globalStyle.bold, globalStyle.mv2].join(' ')}
              >
                {newsItem?.title}
              </Typography>
              {newsItem?.newsCategories?.map(id => (
                <Chip
                  component="span"
                  key={id}
                  label={newsCategories && newsCategories?.[id]?.name}
                  color="secondary"
                  variant="outlined"
                  size="medium"
                  className={globalStyle.mr1}
                  onClick={() =>
                    goToCategory(`/news/categories/${newsCategories[id].slug}`)
                  }
                />
              ))}
              <Divider className={globalStyle.mv3} />
              <Spacer height="20px" />

              {newsItem && (
                <Typography align="left">
                  <HtmlDecode>{newsItem.description}</HtmlDecode>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Divider className={globalStyle.mv3} />
      </PageWrapper>
    </>
  );
};

export default NewsItem;
