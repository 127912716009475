import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { PhotoAlbumScreen } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};
const CreateEventScreen: React.FC<Props> = ({ match }) => {
  const { album: id } = match.params;
  return (
    <PageWrapper boxed={false}>
      <PhotoAlbumScreen id={id} />
    </PageWrapper>
  );
};

export default CreateEventScreen;
