import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { EditNewsItem } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const EditNewsItemScreen: React.FC<Props> = ({ match }) => {
  const { newsItem: slugOrId } = match.params;

  return (
    <PageWrapper title="Edit News">
      {slugOrId && <EditNewsItem slugOrId={slugOrId} />}
    </PageWrapper>
  );
};

export default EditNewsItemScreen;
