import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFirestoreQuerySetting, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { useMedia, useNews } from '..';
import { newsItemSelector } from '../../store/selectors/news';
import { NewsItem } from '../../store/types';
import { findSlug } from '../../utils/firebase';
import { useAuthentication } from '../auth';

type Props = {
  slugOrId?: string;
};

type RegisterNewsItem = Partial<NewsItem> & {
  cover?: File[] | string;
};

type UseNewsItem = {
  newsItem: NewsItem;
  newsId?: string;
  loading?: boolean;
  loaded?: boolean;
  registerNewsItem: (props: RegisterNewsItem) => void;
  updateNewsItem: (props: RegisterNewsItem) => void;
  isAdmin: boolean;
};

export const useNewsItem = (props: Props = {}): UseNewsItem => {
  const { slugOrId } = props;
  const [loading, setLoading] = useState<boolean>();
  const history = useHistory();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { uploadMedia, getMediaData, deleteMedia } = useMedia();
  const { loaded } = useNews();
  const newsItem = useSelector(newsItemSelector(slugOrId || ''));

  const newsId = newsItem?.id;

  const isAdmin =
    newsItem?.created_by === userId ||
    newsItem?.admins?.includes(userId) ||
    profile?.roles?.includes('admin') ||
    profile?.roles?.includes('superEditor');

  const registerNewsItem = useCallback(
    async ({ cover, ...values }: RegisterNewsItem) => {
      let coverUrl: string | null = null;
      setLoading(true);
      const slug = await findSlug(values.title!, '/news');
      const pageRef = await firestore.collection('/news').add({
        ...values,
        created_by: userId,
        slug,
        createdOn: new Date().toUTCString(),
        timestamp: new Date().getTime(),
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      if (!cover || !cover[0]) {
        setLoading(false);
        history.replace('/news');
        history.push(`/news/${slug}`);
        return;
      }

      if (cover && cover[0]) {
        const coverRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: cover[0],
        });
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }

      await firestore.collection('/news').doc(pageRef.id).update({ coverUrl });
      setLoading(false);
      history.replace('/news');
      history.push(`/news/${slug}`);
    },
    [firestore, getMediaData, history, uploadMedia, userId],
  );

  const updateNewsItem = useCallback(
    async ({ cover, ...values }: RegisterNewsItem) => {
      if (!newsItem || !newsId) {
        return;
      }
      let coverUrl: string | null = newsItem.coverUrl || '';
      setLoading(true);
      await firestore
        .collection('/news')
        .doc(newsId)
        .update({ ...values, updated_at: new Date().getTime() });
      if (!cover || !cover[0]) {
        setLoading(false);
        return;
      }
      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/pages/${newsId}`,
          file: cover[0],
        });
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      await firestore.collection('/news').doc(newsId).update({ coverUrl });
      setLoading(false);

      history.replace('/news');
      history.push(`/news/${newsItem.slug}`);
    },
    [
      deleteMedia,
      firestore,
      getMediaData,
      history,
      uploadMedia,
      newsItem,
      newsId,
    ],
  );

  useEffect(() => {
    if (!newsId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'news',
      };

      if (slugOrId) {
        query.where = [['slug', '==', slugOrId]];
      }
      firestore.get(query);
    }
  }, [slugOrId, firestore, newsId]);

  return {
    newsItem,
    newsId,
    loading,
    loaded,
    registerNewsItem,
    updateNewsItem,
    isAdmin,
  };
};
