import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  .info {
    margin-top: 5px;
    font-size: 0.87rem;
  }

  a {
    color: blue;
    text-decoration: underline;
  }

  p {
    margin: 3px 0;
  }
`;
