import React, { useCallback } from 'react';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { ShareOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { CardWidget, ShareButton, Slider } from '../../molecule';
import {
  useVenues,
  useGlobalStyles,
  useAbuseFilter,
  useAuthentication,
} from '../../../hooks';
import { RootState } from '../../../store/reducers';
import { Emoji } from '../../atoms';
import { Venue } from '../../../store/types';

type Props = {
  items?: number;
};

const ClubsCarousel: React.FC<Props> = ({ items }) => {
  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const { userId } = useAuthentication();
  const { filteredVenues = [], loaded } = useVenues({ region: region?.id });
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const goToVenue = useCallback(
    (slug: string) => history.push(`/venues/${slug}`),
    [history],
  );

  if (!loaded) {
    return (
      <Slider items={items} pagination paginationPosition="below">
        {new Array(items).fill(true).map((_, i) => (
          <div key={i}>
            <CardWidget.Skeleton withLogo withActions />
          </div>
        ))}
      </Slider>
    );
  }

  if (loaded && !filteredVenues?.length) {
    return (
      <Box textAlign="center" padding={2}>
        <Typography variant="h2">
          <Emoji emoji="🤷‍♂️" />
        </Typography>
        {t('venue.noClubs')}
      </Box>
    );
  }

  return (
    <>
      <Slider items={items} pagination paginationPosition="below" equalHeight>
        {useAbuseFilter
          .filterAbuseReportsSync(filteredVenues, userId)
          .slice(0, 6)
          .map((venue: Venue) => (
            <div key={venue.id}>
              <CardWidget
                title={venue.name}
                subtitle={venue.address ?? ' - '}
                coverUrl={venue.coverUrl}
                logoUrl={venue.logoUrl}
                renderTopIcons={
                  venue.isVerified && (
                    <CardWidget.Icon
                      icon={<VerifiedUserIcon />}
                      label={t('venue.verified')}
                      activeLabel={t('venue.verified')}
                      active
                    />
                  )
                }
                onClick={() => goToVenue(venue.slug)}
                cardActions={[
                  {
                    type: 'primary',
                    label: t('event.future'),
                    onClick: () => goToVenue(`${venue.slug}#future-events`),
                  },
                  {
                    type: 'secondary',
                    label: t('event.past'),
                    onClick: () => goToVenue(`${venue.slug}#past-events`),
                  },
                  {
                    render: () => (
                      <ShareButton
                        url={`https://tilllate.world/events/${venue.slug}`}
                        renderButton={openPopOver => (
                          <Button
                            className={globalStyles.iconButton}
                            size="small"
                            color="primary"
                            disableElevation
                            onClick={e => openPopOver(e)}
                          >
                            <ShareOutlined fontSize="small" />
                          </Button>
                        )}
                      />
                    ),
                  },
                ]}
              >
                {venue.musicStyles?.length ? (
                  venue.musicStyles
                    .slice(0, 4)
                    .map(music => (
                      <Chip
                        component="span"
                        key={`${venue.id}-${music}`}
                        label={t(`shared.musicStyles.${music}`)}
                        variant="outlined"
                        size="small"
                        className={[
                          globalStyles.mr1,
                          globalStyles.mb1,
                          globalStyles.chipMobile,
                        ].join(' ')}
                      />
                    ))
                ) : (
                  <Chip
                    component="span"
                    label={t('shared.musicStyles.noStyle')}
                    variant="outlined"
                    size="small"
                    className={[
                      globalStyles.mr1,
                      globalStyles.mb1,
                      globalStyles.chipMobile,
                    ].join(' ')}
                  />
                )}
              </CardWidget>
            </div>
          ))}
      </Slider>
    </>
  );
};

ClubsCarousel.displayName = 'ClubsCarousel';

export default React.memo(ClubsCarousel);
