import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { Organization } from '../../types';
import { authSelector } from '../selectors';

type FilteredOrganizationSelector = {
  region?: string | undefined;
  searchFor?: string;
  musicStyles?: string[];
  userId?: string;
  isVerified?: boolean;
};

export const organizationsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: Organization } =>
    firestore?.data?.organizations ?? {},
);

export const organizationsListSelector = createSelector(
  organizationsSelector,
  (organizations): Organization[] =>
    Object.entries(organizations || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const myOrganizationsSelector = createSelector(
  organizationsListSelector,
  authSelector,
  (organizations, auth) => {
    return organizations.filter(
      o => o?.created_by === auth.uid || o?.admins?.includes(auth.uid),
    );
  },
);

export const organizationSelector = (slugOrId: string) =>
  createSelector(
    organizationsSelector,
    organizationsListSelector,
    (organizations, organizationsList): Organization =>
      organizations[slugOrId] ||
      organizationsList.find(o => o.slug === slugOrId),
  );

export const filteredOrganizationsSelector = ({
  searchFor,
  region,
  musicStyles,
  userId,
  isVerified,
}: FilteredOrganizationSelector) =>
  createSelector(
    organizationsListSelector,
    (organizationsList): Organization[] => {
      const filtered =
        organizationsList?.filter(org => {
          if (searchFor && !musicStyles) {
            return org.name.toLowerCase().includes(searchFor.toLowerCase());
          }
          if (region || searchFor || musicStyles || isVerified) {
            const regionFilter = region && region === org.region;
            const textFilter =
              searchFor &&
              (org.name.toLowerCase().includes(searchFor.toLowerCase()) ||
                org.description
                  .toLowerCase()
                  .includes(searchFor.toLowerCase()));
            const verifiedFilter = isVerified && org?.isVerified;
            const musicStyleFilter =
              musicStyles &&
              musicStyles.length &&
              org.musicStyles?.some(style => musicStyles?.includes(style));

            return (
              (!region || regionFilter) &&
              (!searchFor || textFilter) &&
              (!isVerified || verifiedFilter) &&
              (!musicStyles?.length || musicStyleFilter)
            );
          }
          if (userId) {
            return org.created_by === userId || org.admins?.includes(userId);
          }
          return true;
        }) ?? [];
      return filtered;
    },
  );
