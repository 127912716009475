import { LinearProgress } from '@material-ui/core';
import React from 'react';
import logo from '../../../assets/img/logo_w.png';
import useStyle from './style';

type Props = {};

const Preloader: React.FC<Props> = () => {
  const style = useStyle();
  return (
    <div className={style.wrapper}>
      {logo && (
        <>
          <img src={logo} className={style.logo} alt="Tilllate World" />
          <LinearProgress className={style.bar} />
        </>
      )}
    </div>
  );
};

Preloader.displayName = 'Preloader';

export default React.memo(Preloader);
