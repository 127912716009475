import { isPlatform } from '@ionic/core';
import { REGIONS } from '../../../constants';
import { loadScript } from '../../../utils/ui';

export const setLocation = (): Promise<{
  id: string;
  coords: string;
  img: string;
} | null> => {
  return new Promise((resolve, reject) => {
    let platform: String;

    if (isPlatform('ios') || isPlatform('ipad')) {
      platform = 'AIzaSyB3pNjaEMlMoqRO-c-e9fbtxFlFrui4Mjg';
    } else if (isPlatform('android')) {
      platform = 'AIzaSyBLUUqTWwK8nhnI8TkspNsX_WOkbYtAQok';
    } else {
      platform = process.env.REACT_APP_GOOGLE_API_KEY!;
    }

    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${platform}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    document.querySelector('#google-maps')!.addEventListener('load', () => {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latlng = new (window as any).google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );
          const geocoder = new (window as any).google.maps.Geocoder();

          geocoder.geocode(
            { latLng: latlng },
            function (results: any[], status: any) {
              let cityInfo, regionInfo: any, country;
              if (status === (window as any).google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                  let indice = 0;
                  for (let j = 0; j < results.length; j++) {
                    if (results[j].types[0] === 'locality') {
                      indice = j;
                      break;
                    }
                  }

                  for (
                    let i = 0;
                    i < results[indice].address_components.length;
                    i++
                  ) {
                    if (
                      results[indice].address_components[i].types[0] ===
                      'locality'
                    ) {
                      cityInfo = results[indice].address_components[i];
                    }
                    if (
                      results[indice].address_components[i].types[0] ===
                      'administrative_area_level_1'
                    ) {
                      regionInfo = results[indice].address_components[i];
                    }
                    if (
                      results[indice].address_components[i].types[0] ===
                      'country'
                    ) {
                      country = results[indice].address_components[i];
                    }
                  }

                  if (country?.short_name !== 'IT') {
                    resolve(null);
                    console.warn('User is outside Italy');
                  } else {
                    if (regionInfo) {
                      const detectedRegion = REGIONS.find(
                        r => r.id === regionInfo.long_name.toLowerCase(),
                      );

                      if (detectedRegion) {
                        resolve(detectedRegion);
                      } else {
                        console.warn('Region is not detected');
                        resolve(null);
                      }
                    }
                    if (cityInfo) {
                    }
                  }
                } else {
                  console.warn('Can not detect your location');
                  resolve(null);
                }
              } else {
                console.warn('Can not detect your location');
                resolve(null);
              }
            },
          );
        },
        function (error) {
          console.warn('Something went wrong during location detection');
          resolve(null);
        },
      );
    });
  });
};
