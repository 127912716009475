import { Avatar, Box, Chip } from '@material-ui/core';
import React from 'react';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';

type Props = {
  containerClassName?: string;
  actions: { label: string; action: () => void; icon: JSX.Element }[];
};

const PageActions: React.FC<Props> = ({ containerClassName, actions }) => {
  const globalStyles = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  return (
    <Box className={containerClassName}>
      {actions.map((action, i) => (
        <Chip
          key={`pageactions-${i}`}
          onClick={action.action}
          label={action.label}
          className={[globalStyles.mr1, isMobile ? globalStyles.mb1 : ''].join(
            ' ',
          )}
          avatar={<Avatar>{action.icon}</Avatar>}
        />
      ))}
    </Box>
  );
};

PageActions.displayName = 'PageActions';

export default React.memo(PageActions);
