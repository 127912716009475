import React from 'react';
import {
  Box,
  Button,
  Collapse,
  Divider,
  makeStyles,
  Drawer,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  MyEvents,
  MyVenues,
  MyGuestListRegistrations,
  UserAvatar,
  MyOrganizations,
} from '..';
import { useAuthentication, useGlobalStyles } from '../../../hooks';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { Spacer } from '../../atoms';
import { RegionSelect, SectionHeader } from '../../molecule';
import LoginBox from '../LoginBox';

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const useStyle = makeStyles({
  wrapper: { width: 400, maxWidth: '80vw', height: '100%' },
});

const ProfileDrawerMenu: React.FC<Props> = ({ open, onClose }) => {
  const style = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const [loginVisible, toggleLogin] = useToggleState(true);
  const { isAuthenticated, profile, logout } = useAuthentication();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        className: globalStyle.bgSecondaryLight,
      }}
    >
      <Box
        className={[
          globalStyle.safeAreaBottom,
          globalStyle.safeAreaTop,
          globalStyle.bgSecondaryLight,
          globalStyle.flexColumn,
          style.wrapper,
        ].join(' ')}
        justifyContent="space-between"
      >
        <Box p={2}>
          <Box
            className={globalStyle.flex}
            alignItems="center"
            justifyContent="start"
          >
            <UserAvatar.Small size={60} />
            <Box
              paddingLeft={2}
              height={60}
              className={globalStyle.flexColumn}
              justifyContent="space-between"
            >
              {isAuthenticated && (
                <>
                  <Typography variant="body1">{profile.displayName}</Typography>
                  <Link to={{ pathname: '/profile' }}>
                    <Button size="small" variant="outlined" color="primary">
                      {t('profile.viewProfile')}
                    </Button>
                  </Link>
                </>
              )}

              {!isAuthenticated && (
                <Box
                  height={60}
                  className={globalStyle.flex}
                  alignItems="center"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => toggleLogin()}
                  >
                    {t('shared.loginNow')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {!isAuthenticated && (
            <Collapse in={loginVisible}>
              <Box>
                <Spacer height="16px" />
                <Divider />
                <Spacer height="16px" />
                <LoginBox small onLoginCallback={toggleLogin} />
              </Box>
            </Collapse>
          )}
          <Spacer height="16px" />
          <Divider />
          <Spacer height="16px" />
          <Box>
            <SectionHeader
              titleVariant="body1"
              title={t('general.forResults')}
            />
            <RegionSelect />
          </Box>
          <Spacer height="16px" />
          <Divider />
          {isAuthenticated && (
            <>
              <Spacer height="16px" />
              <MyEvents limit={1} disableCover unbox />
              <Spacer height="16px" />
              <Divider />
              <Spacer height="16px" />
              <MyGuestListRegistrations limit={1} unbox disableCover />
              <Spacer height="16px" />
              <Divider />
              <Spacer height="16px" />
              <MyVenues limit={1} unbox disableCover />
              <Spacer height="16px" />
              <Divider />
              <Spacer height="16px" />
              <MyOrganizations limit={1} unbox disableCover />
              <Spacer height="16px" />
              <Divider />
            </>
          )}
          {profile?.roles?.includes('photographer') && (
            <>
              <Spacer height="16px" />
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push({
                      pathname: '/profile-dashboard',
                      state: { selectedMenuItem: 'myPhotoAlbums' },
                    })
                  }
                >
                  {t('profile.myPhotoAlbums')}
                </Button>
              </Box>
            </>
          )}
        </Box>

        {isAuthenticated && (
          <Box p={2}>
            <Button
              size="small"
              variant="outlined"
              color="default"
              fullWidth
              onClick={logout}
            >
              {t('shared.logout')}
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

ProfileDrawerMenu.displayName = 'ProfileDrawerMenu';

export default React.memo(ProfileDrawerMenu);
