import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, Grid, Paper, Typography } from '@material-ui/core';
import { NewReleasesTwoTone, PhotoAlbum, HowToReg } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { isEmpty } from 'react-redux-firebase';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import {
  ExpandableText,
  LabeledBox,
  PageActions,
  PageWrapper,
  ShareButton,
} from '../../components/molecule';
import {
  useEvent,
  useGlobalStyles,
  useGuestLists,
  useMediaQuery,
  usePhotoAlbums,
} from '../../hooks';
import { LoadingCover, Spacer, YoutubeIFrame } from '../../components/atoms';
import {
  EventCover,
  EventDetails,
  GuestLists,
  YoutubeUrlDialog,
} from '../../components/organisms';
import PhotoGallery from '../../components/organisms/PhotoGallery';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const Event: React.FC<Props> = ({ match }) => {
  const { event: slugOrId } = match.params;
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const {
    event,
    loading,
    loaded,
    isAdmin,
    canUpdateEvent,
    canCreateAlbum,
    updateEvent,
  } = useEvent({
    slugOrId,
  });
  const { guestListsList } = useGuestLists({ event });
  const globalStyle = useGlobalStyles();
  const { slug } = event || {};
  const history = useHistory();
  const { photoAlbumsByUserAndEvent } = usePhotoAlbums({
    eventId: event?.id,
  });
  const [videoUrlDialogOpen, setVideoUrlDialogOpen] = useState(false);

  const alreadyHavePhotoAlbum = !!photoAlbumsByUserAndEvent.length;
  const areGuestListsVisible =
    isAdmin ||
    (new Date(event?.date).getTime() > new Date().getTime() &&
      !!guestListsList.length);

  const onEditEvent = useCallback(() => {
    history.push(`/edit-events/${slug}`);
  }, [history, slug]);

  const onCreateAlbum = useCallback(() => {
    if (alreadyHavePhotoAlbum) {
      history.push(`/photo-album/${photoAlbumsByUserAndEvent[0].id}`);
    } else {
      history.push(`/create-photo-album/${event.id}`);
    }
  }, [alreadyHavePhotoAlbum, event.id, history, photoAlbumsByUserAndEvent]);

  const onRegisterGuests = useCallback(() => {
    history.push(`/register-guests/${slug}`);
  }, [history, slug]);

  const onAddYoutubeUrl = useCallback(() => {
    setVideoUrlDialogOpen(true);
  }, []);

  const saveVideo = useCallback(
    (videoUri: string) => {
      const updatedEvent = { videoUri };
      updateEvent(updatedEvent);
    },
    [updateEvent],
  );

  const deleteVideo = useCallback(() => {
    updateEvent({ videoUri: '' });
  }, [updateEvent]);

  const renderActions = useCallback(
    containerClassName => {
      const actions = [];
      if (isAdmin || canUpdateEvent()) {
        actions.push({
          label: event?.videoUri
            ? t('shared.changeVideo')
            : t('shared.addVideo'),
          action: onAddYoutubeUrl,
          icon: <VideoLibraryIcon style={{ width: 18 }} />,
        });
      }
      if (canCreateAlbum && !alreadyHavePhotoAlbum) {
        actions.push({
          label: t('guestList.createAlbum'),
          action: onCreateAlbum,
          icon: <PhotoAlbum style={{ width: 18 }} />,
        });
      }
      if (alreadyHavePhotoAlbum) {
        actions.push({
          label: t('photoAlbums.checkAlbum'),
          action: onCreateAlbum,
          icon: <PhotoAlbum style={{ width: 18 }} />,
        });
      }
      if (isAdmin || canUpdateEvent()) {
        actions.push({
          label: t('guestList.editEvent'),
          action: onEditEvent,
          icon: <NewReleasesTwoTone style={{ width: 18 }} />,
        });
      }
      if (isAdmin && guestListsList.length) {
        actions.push({
          label: t('guestList.registerGuests'),
          action: onRegisterGuests,
          icon: <HowToReg style={{ width: 18 }} />,
        });
      }
      return (
        <PageActions
          containerClassName={containerClassName}
          actions={actions}
        />
      );
    },
    [
      alreadyHavePhotoAlbum,
      canCreateAlbum,
      canUpdateEvent,
      event,
      guestListsList.length,
      isAdmin,
      onAddYoutubeUrl,
      onCreateAlbum,
      onEditEvent,
      onRegisterGuests,
      t,
    ],
  );

  useEffect(() => {
    if (loaded && isEmpty(event)) {
      history.push('/events');
    }
  }, [history, event, loading, loaded]);

  useEffect(() => {
    if (slug) {
      document.querySelector('ion-content')?.scrollToTop(100);
    }
  }, [slug]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{event.name} - Tilllate World</title>
        <link rel="canonical" href={window.location.href} />
        {/* <!--  Essential META Tags --> */}

        <meta property="og:title" content={`${event.name} - Tilllate World`} />
        <meta
          property="og:description"
          content={`${event.description.substr(0, 20)} - Tilllate World`}
        />
        <meta property="og:image" content={event?.coverUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* <!--  Non-Essential, But Recommended --> */}

        <meta property="og:site_name" content="Tilllate World" />
        <meta
          name="twitter:image:alt"
          content={`${event.name} - Tilllate World`}
        />
      </Helmet>
      {loading && <LoadingCover />}
      <PageWrapper boxed={false}>
        <Box p={2}>
          <Grid
            container
            spacing={2}
            direction={isMobile ? 'column-reverse' : 'row'}
          >
            <Grid item md={3}>
              <EventDetails event={event} />
            </Grid>
            <Grid item md={9}>
              <EventCover
                cover={event?.coverUrl}
                renderActions={renderActions}
                item={event}
                category="events"
              />
              <Typography
                color="textPrimary"
                variant="h3"
                align="center"
                className={[globalStyle.bold, globalStyle.mv2].join(' ')}
              >
                {event?.name}
              </Typography>
              <Typography
                color="textPrimary"
                variant="h5"
                align="center"
                className={[globalStyle.bold, globalStyle.mv2].join(' ')}
              >
                {event?.date &&
                  dayjs(event?.date).format('DD MMM YYYY, @ HH:mm')}
              </Typography>
              {event?.musicStyles?.map(music => (
                <Chip
                  component="span"
                  key={music}
                  label={t(`shared.musicStyles.${music}`)}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  className={globalStyle.mr1}
                />
              ))}
              <Spacer height="30px" />
              <Paper variant="outlined">
                <ShareButton.Share
                  url={`https://denxer.com/events/${slug}`}
                  iconSize={32}
                />
              </Paper>
              <Spacer height="30px" />
              <LabeledBox label={t('event.description')}>
                <ExpandableText content={event?.description} limit={250} />
              </LabeledBox>
              {areGuestListsVisible && (
                <>
                  <Spacer height="50px" />
                  <GuestLists event={event} isAdmin={isAdmin} match={match} />
                </>
              )}
              {event?.videoUri && (
                <>
                  <Spacer height="50px" />
                  <LabeledBox label={t('event.video')}>
                    <YoutubeIFrame
                      videoUri={event.videoUri}
                      title={`${event.name}-video`}
                    />
                  </LabeledBox>
                </>
              )}
              <Spacer height="50px" />
              {event?.id && <PhotoGallery eventId={event?.id} />}
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
      <YoutubeUrlDialog
        open={videoUrlDialogOpen}
        setOpen={setVideoUrlDialogOpen}
        title={t('event.addVideoToEvent')}
        cbFunctionOnSave={saveVideo}
        cbFunctionOnDelete={deleteVideo}
        videoExistsInDb={!!event?.videoUri}
      />
    </>
  );
};

export default Event;
