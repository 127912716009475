import React, { useCallback, useMemo } from 'react';
import { Button, Chip } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HowToReg, ShareOutlined } from '@material-ui/icons';
import dayjs from 'dayjs';
import { Event } from '../../../store/types';
import {
  useGlobalStyles,
  useGuestListRegistrations,
  useGuestLists,
  useVenues,
} from '../../../hooks';
import { CardWidget, DateWidget, ShareButton } from '../../molecule';

type Props = {
  event: Event;
};

const EventCardWidget: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyles = useGlobalStyles();
  const { venues } = useVenues();
  const { guestListsList } = useGuestLists({
    event,
  });
  const { guestListRegistrationsByUser } = useGuestListRegistrations({
    myRegistrations: true,
  });
  const goToEvent = useCallback(
    (slug: string) => history.push(`/events/${slug}`),
    [history],
  );
  const canRegister = useMemo(
    () =>
      guestListsList?.length &&
      event?.timestamp &&
      event?.timestamp > dayjs().valueOf(),
    [event, guestListsList],
  );

  return (
    <CardWidget
      title={event.name}
      subtitle={`
                ${venues[event.venueId]?.name}, ${venues[event.venueId]?.city}`}
      coverUrl={event.coverUrl}
      renderLogo={<DateWidget date={event.date} />}
      date={event.date}
      renderTopIcons={
        !!guestListsList.length && (
          <CardWidget.Icon
            icon={<HowToReg />}
            label={t('event.registerToGuestList')}
            activeLabel={t('event.registeredToGuestList')}
            active={
              !!guestListRegistrationsByUser.find(gl => gl.eventId === event.id)
            }
          />
        )
      }
      onClick={() => goToEvent(event.slug)}
      cardActions={[
        {
          type: 'primary',
          label: canRegister
            ? t('event.registerToEvent')
            : t('event.viewEvent'),
          onClick: () =>
            history.push(
              `/events/${event.slug}${canRegister ? '#guestlist' : ''}`,
            ),
        },
        {
          type: 'secondary',
          label: t('event.viewVenue'),
          onClick: () => history.push(`/venues/${venues[event.venueId].slug}`),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tilllate.world/events/${event.slug}`}
              renderButton={openPopOver => (
                <Button
                  className={globalStyles.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={e => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
    >
      {event.musicStyles?.length ? (
        <>
          {event.musicStyles.slice(0, 2).map(music => (
            <Chip
              component="span"
              key={`${event.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyles.mr1,
                globalStyles.mb1,
                globalStyles.chipMobile,
              ].join(' ')}
            />
          ))}
          {event.musicStyles.length > 2 && (
            <Chip
              variant="outlined"
              size="small"
              component="span"
              className={[
                globalStyles.mr1,
                globalStyles.mb1,
                globalStyles.chipMobile,
              ].join(' ')}
              label={`+ ${event.musicStyles.length - 2}`}
            />
          )}
        </>
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyles.mr1,
            globalStyles.mb1,
            globalStyles.chipMobile,
          ].join(' ')}
        />
      )}
    </CardWidget>
  );
};

EventCardWidget.displayName = 'EventCardWidget';

export default React.memo(EventCardWidget);
