import { Chip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles } from '../../../hooks';
import { Organization } from '../../../store/types';
import CardListWidget from '../../molecule/CardListWidget';

type Props = {
  organization: Organization;
  disableCover?: boolean;
};

const MyOrganizationItem: React.FC<Props> = ({
  organization,
  disableCover,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();

  return (
    <CardListWidget
      onClick={() => history.push(`/organizations/${organization?.slug}`)}
      logoUrl={organization.logoUrl}
      coverUrl={organization?.coverUrl}
      disableCover={disableCover}
      title={organization.name}
      subtitle={organization.address}
      cardActions={[
        {
          type: 'primary',
          label: t('event.future'),
          onClick: () => history.push(`organizations/${organization?.slug}`),
        },
        {
          type: 'secondary',
          label: t('event.past'),
          onClick: () => history.push(`organizations/${organization?.slug}`),
        },
      ]}
    >
      {organization.musicStyles?.length ? (
        <>
          {organization.musicStyles.slice(0, 2).map(music => (
            <Chip
              component="span"
              key={`${organization.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
            />
          ))}
          {organization.musicStyles.length > 2 && (
            <Chip
              variant="outlined"
              size="small"
              component="span"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
              label={`+ ${organization.musicStyles.length - 2}`}
            />
          )}
        </>
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(' ')}
        />
      )}
    </CardListWidget>
  );
};

MyOrganizationItem.displayName = 'MyOrganizationItem';

export default React.memo(MyOrganizationItem);
