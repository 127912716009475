import React, { useCallback } from 'react';
import { Button, Chip } from '@material-ui/core';
import { ShareOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NewsItem } from '../../../store/types';
import { CardListWidget, DateWidget, ShareButton } from '..';
import {
  useGlobalStyles,
  useMediaQuery,
  useNewsCategories,
} from '../../../hooks';
import { textShortener, htmlStriper } from '../../../utils';

type Props = {
  newsItem: NewsItem;
  label?: string;
};

const Item: React.FC<Props> = ({ label, newsItem }) => {
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useMediaQuery();
  const { newsCategories } = useNewsCategories();

  const goToCategory = useCallback(
    (e: any, to?: any) => {
      e.stopPropagation();
      history.push(to);
    },
    [history],
  );

  const goToNews = useCallback(
    slug => {
      history.push(`/news/${slug}`);
    },
    [history],
  );

  return (
    <CardListWidget
      label={label}
      renderLogo={<DateWidget date={newsItem.createdOn} hideTime />}
      coverUrl={newsItem.coverUrl}
      title={newsItem.title}
      subtitle={textShortener(htmlStriper(newsItem.description), 50)}
      cardActions={[
        {
          type: 'primary',
          label: t('news.readMore'),
          onClick: () => goToNews(newsItem.slug),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tilllate.world/news/${newsItem.slug}`}
              renderButton={openPopOver => (
                <Button
                  className={globalStyle.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={e => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
    >
      {newsCategories &&
        newsItem.newsCategories
          ?.slice(0, isMobile ? 3 : 4)
          ?.map(id => (
            <Chip
              component="span"
              key={id}
              label={newsCategories?.[id]?.name}
              color="secondary"
              variant="outlined"
              size="small"
              className={[globalStyle.mr1, globalStyle.chipMobile].join(' ')}
              onClick={(e: any) =>
                goToCategory(
                  e,
                  `/news/categories/${newsCategories?.[id]?.slug}`,
                )
              }
            />
          ))}
    </CardListWidget>
  );
};

Item.displayName = 'Item';

export default React.memo(Item);
