import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export function firebaseState(state: RootState): RootState['firebase'] {
  return state.firebase;
}

export function firestoreState(state: RootState): RootState['firestore'] {
  return state.firestore;
}

export function configurationState(
  state: RootState,
): RootState['configuration'] {
  return state.configuration;
}

export function tilldateConfigurationState(
  state: RootState,
): RootState['tilldateConfiguration'] {
  return state.tilldateConfiguration;
}

export const firestoreSelector = createSelector(firestoreState, state => state);

export const authSelector = createSelector(firebaseState, state => state.auth);

export const isAuthenticatedSelector = createSelector(
  authSelector,
  auth => auth && !auth.isEmpty,
);

export const profileSelector = createSelector(
  firebaseState,
  state => state.profile,
);

export const userIdSelector = createSelector(authSelector, auth => auth.uid);

export const authIsLoadedSelector = createSelector(
  authSelector,
  auth => auth.isLoaded,
);

export const authErrorSelector = createSelector(
  firebaseState,
  (state): { message: string; code: string } | null => state.authError,
);

export const inAppHistorySelector = createSelector(
  configurationState,
  state => state.inAppHistory,
);
