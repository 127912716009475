import { useCallback, useState } from 'react';
import { functions } from '../../rrf.config';
import { useAuthentication } from '../auth';

type SendSms = {
  to?: string;
  from?: string;
  text: string;
};

type UseSendSms = {
  loading: boolean;
  success: boolean;
  error: boolean;
  sendSms: (params: SendSms) => void;
};

export const useSendSms = (): UseSendSms => {
  const send = functions.httpsCallable('sendSms');
  const {
    profile: { phone },
  } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const sendSms = useCallback(
    ({ to = phone, from = 'Tilllate', text }) => {
      setLoading(true);
      send({ to, from, text })
        .then(res => {
          if (!res.data.error) {
            setSuccess(true);
          } else {
            setSuccess(false);
            setError(true);
            console.error(res);
          }
        })
        .catch(err => {
          setError(true);
          console.error(err);
        })
        .finally(() => setLoading(false));
    },
    [phone, send],
  );
  return { loading, success, error, sendSms };
};
