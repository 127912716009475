import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { chatRoomsListSelector } from '../../store/selectors/dateRooms';
import { DateRoom } from '../../store/types/dateRooms';
import { useAuthentication } from '../auth';
import useDateRoomPlayers from './useDateRoomPlayers';

type UseChatRoom = {
  dateRoom?: DateRoom;
};

const useChatRoom = ({ dateRoom }: UseChatRoom) => {
  const firestore = useFirestore();
  const { userId } = useAuthentication();
  const { id } = dateRoom || {};
  const { dateRoomPlayers } = useDateRoomPlayers({ dateRoomId: id });
  const chatRooms = useSelector(chatRoomsListSelector(id));
  const newChatRooms = chatRooms.filter(
    room => !room.openedBy.includes(userId),
  );

  const registerChatRoom = useCallback(
    async playerId => {
      if (id) {
        const userRooms = await firestore
          .collection('dateRooms')
          .doc(id)
          .collection('chatRooms')
          .where('users', 'array-contains', userId)
          .get();
        const existingRoom = await userRooms.docs.find(room =>
          room.data().users.includes(playerId),
        );
        if (!existingRoom) {
          const newRoomRef = await firestore
            .collection('dateRooms')
            .doc(id)
            .collection('chatRooms')
            .add({
              active: true,
              users: [userId, playerId],
              openedBy: [],
              usersData: {
                [userId]: dateRoomPlayers[userId],
                [playerId]: dateRoomPlayers[playerId],
              },
            });
          return newRoomRef;
        } else {
          await firestore
            .collection('dateRooms')
            .doc(id)
            .collection('chatRooms')
            .doc(existingRoom.id)
            .update({
              openedBy: [],
              active: true,
            });
        }
        return existingRoom;
      }
    },
    [id, firestore, userId, dateRoomPlayers],
  );

  const deactivateChatRoom = useCallback(
    async playerId => {
      if (id) {
        const userRooms = await firestore
          .collection('dateRooms')
          .doc(id)
          .collection('chatRooms')
          .where('users', 'array-contains', userId)
          .get();
        const existingRoom = await userRooms.docs.find(room =>
          room.data().users.includes(playerId),
        );
        if (existingRoom) {
          await firestore
            .collection('dateRooms')
            .doc(id)
            .collection('chatRooms')
            .doc(existingRoom.id)
            .update({
              active: false,
            });
        }
      }
    },
    [id, firestore, userId],
  );

  const registerOpenedChatRoom = useCallback(
    async playerId => {
      if (id) {
        const userRooms = await firestore
          .collection('dateRooms')
          .doc(id)
          .collection('chatRooms')
          .where('users', 'array-contains', userId)
          .get();
        const existingRoom = await userRooms.docs.find(room =>
          room.data().users.includes(playerId),
        );
        if (existingRoom) {
          await firestore
            .collection('dateRooms')
            .doc(id)
            .collection('chatRooms')
            .doc(existingRoom.id)
            .update({
              openedBy: [...existingRoom.data().openedBy, userId],
            });
        }
      }
    },
    [id, firestore, userId],
  );

  useEffect(() => {
    if (id && userId) {
      firestore.setListener({
        collection: 'dateRooms',
        doc: id,
        subcollections: [
          {
            collection: 'chatRooms',
            where: [
              ['users', 'array-contains', userId],
              ['active', '==', true],
            ],
          },
        ],
        storeAs: `chatRooms/${id}`,
      });
    }
  }, [id, firestore, userId]);

  return {
    dateRoom,
    registerChatRoom,
    deactivateChatRoom,
    chatRooms,
    newChatRooms,
    registerOpenedChatRoom,
  };
};

export default useChatRoom;
