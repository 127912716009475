import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { THRESHOLD } from '../../constants';
import {
  filteredOrganizationsSelector,
  organizationsListSelector,
  organizationsSelector,
  myOrganizationsSelector,
} from '../../store/selectors/organizations';
import { Organization } from '../../store/types';

type Props = {
  region?: string;
  userId?: string;
  searchFor?: string | '';
  idsOrgs?: string[];
  isVerified?: boolean | undefined;
  musicStyles?: string[];
  page?: number;
  myOrganizations?: boolean;
};

type useOrganizations = {
  organizations: { [key: string]: Organization };
  organizationsList: Organization[];
  filteredOrganizations: Organization[];
  loaded?: boolean;
  findOrganizations: (orgIDs: string[]) => [string, Organization][];
  selectedOrgnaizations: Organization[];
  searchedOrganizations: (text: string) => Organization[];
  filteredOrganizationsByPage: {
    organizations: Organization[];
    totalResults: number;
  };
  myOrganizationsList: Organization[];
};

export const useOrganizations = (props: Props = {}): useOrganizations => {
  const {
    region,
    userId,
    searchFor,
    idsOrgs,
    isVerified,
    musicStyles,
    page = 1,
  } = props;
  const organizations = useSelector(organizationsSelector);
  const organizationsList = useSelector(organizationsListSelector);
  const filteredOrganizations = useSelector(
    filteredOrganizationsSelector({
      region,
      searchFor,
      isVerified,
      musicStyles,
      userId,
    }),
  );
  const myOrganizationsList = useSelector(myOrganizationsSelector);

  const filteredOrganizationsByPage = {
    organizations:
      filteredOrganizations.slice(THRESHOLD * (page - 1), THRESHOLD * page) ||
      [],
    totalResults: filteredOrganizations?.length || 0,
  };

  const selectedOrgnaizations =
    organizationsList?.filter(org => {
      if (idsOrgs?.length && org.id) {
        return idsOrgs.includes(org?.id);
      }
      return false;
    }) ?? [];

  const loaded = isLoaded(organizations);

  const findOrganizations = useCallback(
    (orgIDs: string[]) =>
      Object.entries(organizations).filter(([id, org]) => {
        if (orgIDs.includes(id)) {
          return org;
        }
        return false;
      }),
    [organizations],
  );

  const searchedOrganizations = useCallback(
    (text: string) => {
      return (
        organizationsList?.filter(organization => {
          if (text) {
            return organization.name
              .toLowerCase()
              .startsWith(text.toLowerCase());
          }
          return false;
        }) ?? []
      );
    },
    [organizationsList],
  );

  useFirestoreConnect({
    collection: 'organizations',
    limit: 99,
  });

  return {
    organizations,
    loaded,
    organizationsList: organizationsList || [],
    filteredOrganizations,
    findOrganizations,
    selectedOrgnaizations,
    searchedOrganizations,
    filteredOrganizationsByPage,
    myOrganizationsList,
  };
};
