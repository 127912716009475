import { populate } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { authSelector, firestoreState } from '..';
import {
  PhotoAlbum,
  PhotoGallery,
  PopulatedPhotoAlbum,
} from './../../types/photoAlbums/general';

type FilteredPhotoGalleriesSelector = {
  searchFor?: string;
  selectedDate?: { day: number; month: number; year: number } | null;
  selectedVenueIds?: string[];
  selectedArtistIds?: string[];
  selectedEventIds?: string[];
  currentUser?: string;
};

const groupPhotoGalleries = (populatedAlbums: {
  [key: string]: PopulatedPhotoAlbum;
}): {
  [key: string]: PhotoGallery;
} =>
  Object.values(populatedAlbums || []).reduce((galleries, album) => {
    if (album.eventId && album.event) {
      if (galleries[album.eventId]) {
        if (album.created_by) {
          galleries[album.eventId].created_by.push(album.created_by);
        }
        if (album.coverUrl) {
          galleries[album.eventId].coverUrl.push(album.coverUrl);
        }
        if (album.images && album.images.length) {
          galleries[album.eventId].images = [
            ...galleries[album.eventId].images,
            ...album.images,
          ];
        }
      } else {
        galleries[album.eventId] = {
          ...album.event,
          id: album.eventId,
          images: album?.images || [],
          created_by: album.created_by ? [album.created_by] : [],
          coverUrl: album.coverUrl ? [album.coverUrl] : [],
        };
      }
    }
    return galleries;
  }, {});

export const photoAlbumsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: PhotoAlbum } => firestore?.data?.photoAlbums,
);

export const photoAlbumsListSelector = createSelector(
  photoAlbumsSelector,
  (photoAlbum): PhotoAlbum[] =>
    Object.entries(photoAlbum || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const photoAlbumSelector = (id: string) =>
  createSelector(
    photoAlbumsSelector,
    (photoAlbums): PhotoAlbum => photoAlbums?.[id],
  );

export const photoAlbumsByUserSelector = createSelector(
  firestoreState,
  authSelector,
  (firestore, auth) =>
    (firestore?.ordered?.photoAlbums || []).filter(
      album => album.created_by === auth.uid,
    ),
);

export const photoAlbumsByUserAndEventSelector = (
  eventId: string | undefined,
) =>
  createSelector(
    firestoreState,
    authSelector,
    (firestore, auth) =>
      (firestore?.ordered?.photoAlbums || []).filter(
        album =>
          eventId && album.created_by === auth.uid && album.eventId === eventId,
      ) ?? [],
  );

export const populatedPhotoAlbumsSelector = createSelector(
  firestoreState,
  (
    firestore,
  ): {
    [key: string]: PopulatedPhotoAlbum;
  } =>
    populate(firestore, 'photoAlbums', [
      { child: 'created_by', root: 'users_meta', childAlias: 'author' },
      { child: 'eventId', root: 'events', childAlias: 'event' },
    ]) || {},
);

export const populatedPhotoAlbumsListSelector = createSelector(
  populatedPhotoAlbumsSelector,
  (populatedPhotoAlbums): PopulatedPhotoAlbum[] =>
    Object.entries(populatedPhotoAlbums || {}).map(([key, val]: any[]) => ({
      ...val,
      id: key,
    })),
);

export const populatedAlbumsListByUserSelector = (userId: string) =>
  createSelector(
    populatedPhotoAlbumsListSelector,
    (populatedPhotoAlbums): PopulatedPhotoAlbum[] =>
      populatedPhotoAlbums.filter(
        album => userId && album?.created_by === userId,
      ),
  );

export const filteredPhotoAlbumsSelector = ({
  searchFor,
  selectedDate,
  selectedVenueIds,
  selectedArtistIds,
  selectedEventIds,
  currentUser,
}: FilteredPhotoGalleriesSelector) =>
  createSelector(populatedPhotoAlbumsSelector, (populatedPhotoAlbums = {}) => {
    const filtered = Object.entries(populatedPhotoAlbums)
      .filter(([_, album]) => {
        let textFilter: boolean | undefined,
          dateFilter: boolean | undefined,
          venuesFilter: boolean | undefined,
          artistsFilter: boolean | undefined,
          eventsFilter: boolean | undefined;
        if (searchFor) {
          textFilter =
            album?.event?.name
              .toLowerCase()
              .includes(searchFor.toLowerCase()) ||
            album?.event?.description
              .toLowerCase()
              .includes(searchFor.toLowerCase());
        }
        if (selectedDate && album?.event?.date) {
          const eventDate = new Date(album.event.date);
          dateFilter =
            selectedDate.day === eventDate.getDate() &&
            selectedDate.month === eventDate.getMonth() &&
            selectedDate.year === eventDate.getFullYear();
        }
        if (selectedVenueIds && selectedVenueIds.length) {
          venuesFilter = selectedVenueIds.includes(album?.event?.venueId);
        }
        if (selectedEventIds && selectedEventIds.length && album?.eventId) {
          eventsFilter = selectedEventIds.includes(album.eventId);
        }
        if (selectedArtistIds && selectedArtistIds.length) {
          const artistsOnEvent =
            album?.event?.artists && Object.keys(album.event.artists);
          artistsFilter =
            artistsOnEvent &&
            artistsOnEvent?.some(artist => selectedArtistIds?.includes(artist));
        }
        // Check if the current user reported for abuse a clubs in the list
        let reportedForAbuseByUser = false;

        if (currentUser && album.reported_by) {
          reportedForAbuseByUser = album.reported_by.includes(currentUser);
        }
        return (
          (!searchFor || textFilter) &&
          (!selectedDate || dateFilter) &&
          (!selectedArtistIds?.length || artistsFilter) &&
          (!selectedVenueIds?.length || venuesFilter) &&
          (!selectedEventIds?.length || eventsFilter) &&
          !reportedForAbuseByUser
        );
      })
      .reduce((r, [key, values]) => ({ ...r, [key]: values }), {});
    return filtered;
  });

export const photoGalleriesSelector = createSelector(
  populatedPhotoAlbumsSelector,
  populatedAlbums => populatedAlbums && groupPhotoGalleries(populatedAlbums),
);

export const photoGalleriesListSelector = createSelector(
  photoGalleriesSelector,
  (photoGalleries): PhotoGallery[] =>
    photoGalleries ? Object.values(photoGalleries) : [],
);

export const filteredPhotoGalleriesSelector = (
  filters: FilteredPhotoGalleriesSelector,
) =>
  createSelector(filteredPhotoAlbumsSelector(filters), filteredPhotoAlbums =>
    groupPhotoGalleries(filteredPhotoAlbums),
  );
