import React, { useCallback } from 'react';
import {
  Card,
  CardMedia,
  CardHeader,
  Chip,
  CardActionArea,
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import { PhotoLibrary } from '@material-ui/icons';
import { useHistory } from 'react-router';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { useGlobalStyles, useVenues } from '../../../hooks';
import DateWidget from '../DateWidget';
import AlbumGallery from '../../organisms/PhotoGallery/AlbumGallery';
import { PhotoGallery } from '../../../store/types';
import useStyle from './styles';

type Props = {
  gallery?: PhotoGallery;
  displayFlex?: boolean;
};

const GalleryWidget: React.FC<Props> = ({ gallery, displayFlex }) => {
  const style = useStyle();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const { venues } = useVenues();
  const { slug: eventSlug } = gallery || {};
  const goToEvent = useCallback(
    () => eventSlug && history.push(`/events/${eventSlug}`),
    [eventSlug, history],
  );
  return (
    <Card
      variant="outlined"
      className={[style.card, displayFlex && style.container].join(' ')}
    >
      <CardActionArea onClick={goToEvent}>
        <CardHeader
          title={gallery?.name}
          subheader={
            gallery?.venueId &&
            `
        ${venues[gallery?.venueId]?.name}, ${venues[gallery?.venueId]?.city}`
          }
          avatar={<DateWidget date={gallery?.date || ''} hideTime />}
          className={[globalStyle.max100, displayFlex && style.alignSelf].join(
            ' ',
          )}
          titleTypographyProps={{
            variant: 'h6',
            className: globalStyle.ellipsis,
          }}
          subheaderTypographyProps={{
            variant: 'body2',
            className: globalStyle.ellipsis,
          }}
        />
        <CardMedia
          className={[style.cardMedia, style.cardMediaWithIcons].join(' ')}
        >
          <AlbumGallery
            album={gallery?.coverUrl || [defaultCover]}
            hideLightgallery
            pagination={false}
            navigation={false}
            height={200}
          />
          <Box className={style.topIcons}>
            <Chip
              color="primary"
              size="small"
              className={globalStyle.bold}
              label={gallery?.images.length}
              icon={<PhotoLibrary fontSize="small" />}
            />
          </Box>
        </CardMedia>
      </CardActionArea>
    </Card>
  );
};

GalleryWidget.displayName = 'GalleryWidget';

export default React.memo(GalleryWidget);
