import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  useEditGuard,
  useNewsItem,
  useNewsCategories,
  useCompressedImage,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import NewsForm from '../NewsForm';
import { Form } from '../NewsForm/NewsForm';
import { LoadingCover, Spacer } from '../../atoms';

type Props = {
  slugOrId: string;
};

const EditNewsItem: React.FC<Props> = ({ slugOrId }) => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, setCover, setCompressCover] = useCompressedImage();
  const { updateNewsItem, loading, newsItem } = useNewsItem({ slugOrId });
  const { newsCategoriesList: newsCategories } = useNewsCategories();
  const [editorValue, setEditorValue] = useState(newsItem?.description || '');

  useEditGuard(newsItem);

  const onSubmit = async (values: any) => {
    if (!editorValue) {
      setCustomErrors(prev => ({
        ...prev,
        description: 'Description required',
      }));
      return;
    }
    setCustomErrors({});
    updateNewsItem({ cover, ...values, description: editorValue });
  };

  useEffect(() => {
    if (newsItem) {
      setCover(newsItem?.coverUrl || []);
    }
  }, [newsItem, setCover]);

  return (
    <Box position={'relative'}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <PageCoverAndLogo.Form cover={cover} setCover={setCompressCover} />
      {newsItem && (
        <NewsForm
          onSubmit={onSubmit}
          customErrors={customErrors}
          newsItem={newsItem}
          newsCategories={newsCategories}
          editorValue={editorValue}
          setEditorValue={setEditorValue}
        />
      )}
    </Box>
  );
};

EditNewsItem.displayName = 'EditNewsItem';

export default React.memo(EditNewsItem);
