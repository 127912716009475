const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const isEmail = (email: string) => emailRegex.test(email);

export const multipleEmailCheck = (emailsSeparatedWithComa: string) => {
  let emailsString = emailsSeparatedWithComa.trim();
  // if last character is coma, I won't invalidate it
  if (emailsString.charAt(emailsString.length - 1) === ',') {
    emailsString = emailsString.slice(0, -1);
  }
  const emails: string[] = emailsString
    .split(',')
    .map((email: string) => email.trim());
  let isValid = true;
  emails.forEach(email => {
    if (!isEmail(email)) {
      isValid = false;
    }
  });
  return isValid;
};
