import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  PageWrapper,
  OrganizationsList,
  FilterCategory,
  SearchBar,
  RegionSelect,
  FilterCheckboxOptions,
  MobileFilterDialog,
} from '../../components/molecule';
import { useGlobalStyles, useOrganizations, useMediaQuery } from '../../hooks';
import { RootState } from '../../store/reducers';
import useCheckboxOptions from '../../hooks/ui/useCheckboxOptions';
import { MUSIC_STYLES } from '../../constants';
import { createCheckboxOptions, getSelectedCheckboxes } from '../../utils';
import useDebounce from '../../hooks/ui/useDebounce';
import { Emoji, Flex } from '../../components/atoms';

const Organizations = () => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const history = useHistory();
  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText.trim(), 300);
  const { createMusicStyleObject } = useCheckboxOptions();
  const [musicStylesState, setMusicStylesState] = useState(
    createMusicStyleObject(MUSIC_STYLES),
  );
  const [isVerified, setIsVerified] = useState(
    createCheckboxOptions([
      {
        keyValue: 'verified',
        initialValue: false,
        name: t('organization.isVerified'),
      },
    ]),
  );
  const [page, setPage] = useState(1);
  const {
    filteredOrganizationsByPage: { organizations, totalResults },
    loaded,
  } = useOrganizations({
    region: region?.id,
    searchFor: debouncedSearchText,
    musicStyles: getSelectedCheckboxes(musicStylesState),
    isVerified: isVerified?.verified?.value,
    page,
  });

  useEffect(() => {
    setPage(1);
  }, [region, debouncedSearchText, musicStylesState, isVerified]);

  const loadMoreOrganizations = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const goToRegister = useCallback(
    () => history.push('/register-organization'),
    [history],
  );

  const renderCreateButton = useMemo(() => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={goToRegister}
        className={globalStyle.mt2}
      >
        {t('organization.register')}
      </Button>
    );
  }, [globalStyle.mt2, goToRegister, t]);

  const renderFilter = useCallback(() => {
    return (
      <Grid
        container
        justify="space-around"
        spacing={2}
        className={globalStyle.mt3}
      >
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.search').slice(0, -3)}>
            <SearchBar
              label={t('organization.search')}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('organization.region')}>
            <RegionSelect />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('venue.musicStyles')}>
            <FilterCheckboxOptions
              state={musicStylesState}
              setState={setMusicStylesState}
              title={t('venue.musicStyles')}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.verification')}>
            <FilterCheckboxOptions
              state={isVerified}
              setState={setIsVerified}
              title={t('general.verification')}
            />
          </FilterCategory>
        </Grid>
      </Grid>
    );
  }, [globalStyle.mt3, isVerified, musicStylesState, searchText, t]);

  return (
    <PageWrapper title="Organizations">
      <Grid container spacing={2}>
        {isMobile ? (
          <MobileFilterDialog
            renderFilter={renderFilter}
            label={t('organization.filter')}
          />
        ) : (
            <Grid item xs={12} md={3}>
              {renderFilter()}
              <Flex alignItems="center" height="40px">
                {renderCreateButton}
              </Flex>
            </Grid>
          )}
        <Grid item xs={12} md={9}>
          {loaded && !organizations.length ? (
            <Box textAlign="center" padding={2}>
              <Typography variant="h2">
                <Emoji emoji="🤷‍♂️" />
              </Typography>
              {t('organization.notFound')}
            </Box>
          ) : (
              <OrganizationsList
                organizations={organizations}
                loading={!loaded}
                loadMore={loadMoreOrganizations}
                totalResults={totalResults}
                page={page}
              />
            )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Organizations;
