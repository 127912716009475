import { Box, makeStyles, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import { Message } from '../../store/types/dateRooms';

const useStyle = makeStyles(theme => ({
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ myMessage }: any) => (myMessage ? 'flex-end' : 'flex-start'),
    marginBottom: theme.spacing(1),
  },
  textWrapper: {
    maxWidth: '70%',
    padding: theme.spacing(0.5),
    backgroundColor: ({ myMessage }: any) =>
      myMessage ? 'rgb(252 101 96 / 40%)' : 'rgb(230 230 230)',
    borderRadius: ({ myMessage }: any) =>
      myMessage ? '3px 0 3px 3px' : '0px 3px 3px 3px',
  },
}));

type Props = {
  message: Message;
  myMessage: boolean;
};
const MessageItem: React.FC<Props> = ({ message, myMessage }) => {
  const style = useStyle({ myMessage });
  return (
    <Box className={style.messageWrapper}>
      <Box className={style.textWrapper}>
        <Typography variant="caption" component="div">
          <b>{message.from}</b>
        </Typography>
        <Typography variant="caption">{message.text}</Typography>
      </Box>
      <Typography variant="caption">
        {dayjs(message.time).format('HH:mm')}
      </Typography>
    </Box>
  );
};

export default React.memo(MessageItem);
