import { ActionType } from 'typesafe-actions';
import { RootState } from '..';
import {
  addToInAppHistory,
  updateInAppHistory,
} from '../../actions/configurations/inAppHistory';

export const updateInAppHistoryCase = (
  state: RootState['configuration'],
  action: ActionType<typeof updateInAppHistory>,
): RootState['configuration'] => ({
  ...state,
  inAppHistory: action.payload,
});

export const addToInAppHistoryCase = (
  state: RootState['configuration'],
  action: ActionType<typeof addToInAppHistory>,
): RootState['configuration'] => ({
  ...state,
  inAppHistory: [...state.inAppHistory, action.payload],
});
