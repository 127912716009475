import React, { useCallback } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DividerWithSpace, GoogleMap, LabeledBox } from '../../molecule';
import { Spacer } from '../../atoms';
import { Event, GuestListRegistration } from '../../../store/types';

type Props = {
  event: Event;
  guestListRegistration: GuestListRegistration;
  qrCode: string | undefined;
};

const RegistrationDetails: React.FC<Props> = ({
  event,
  guestListRegistration: { id },
  qrCode,
}) => {
  const { t } = useTranslation();
  const { venue } = event;

  const renderField = useCallback(
    (title, content) => (
      <>
        <Typography>
          <b>{title}: </b>
          <br />
          {content}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [],
  );

  return (
    <>
      {qrCode && (
        <>
          <LabeledBox label="QR Code">
            <>
              <Spacer height="8px" />
              <img src={qrCode} width="100%" alt={id} />
              <Spacer height="8px" />
            </>
          </LabeledBox>
          <Spacer height="20px" />
        </>
      )}
      <LabeledBox
        label={t('event.clubInfo')}
        boxProps={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <>
          {venue?.fullAddress && (
            <GoogleMap location={JSON.parse(venue?.fullAddress)} />
          )}
          <DividerWithSpace space="10px" />
          {renderField(t('venue.name'), venue?.name)}
          {renderField(t('venue.address'), venue?.address)}
          {renderField(t('venue.city'), venue?.city)}
          {renderField(t('venue.region'), t(`shared.regions.${venue?.region}`))}
        </>
      </LabeledBox>
    </>
  );
};

RegistrationDetails.displayName = 'RegistrationDetails';

export default React.memo(RegistrationDetails);
