import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { RegisterEventGuests } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const RegisterEventGuestsScreen: React.FC<Props> = ({ match }) => {
  const { event: slugOrId } = match.params;

  return (
    <PageWrapper title="Registra Ospiti">
      {slugOrId && <RegisterEventGuests slugOrId={slugOrId} />}
    </PageWrapper>
  );
};

export default RegisterEventGuestsScreen;
