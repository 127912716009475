export default {
  shared: {
    login: 'Accedi',
    logout: 'Esci',
    passwordForgotten: 'Password dimenticata?',
    resetPassword: 'Recupera password',
    confirmPassword: 'Conferma Password',
    notVerified:
      'Per poter accedere a queste funzioni devi avere verificato la tua email ed il tuo numero di telefono.',
    profileIncomplete:
      'Per poter procedere alla verifica devi prima completare il tuo profilo.',
    resendVerification:
      "Ricevi link di verifica per email all'indirizzo {{email}}",
    sendVerificationSms: 'Ricevi SMS di verifica al numero {{number}}',
    verificationResent: 'Link inviato, controlla la tua email!',
    verificationSmsResent: 'SMS inviato, controlla il telefono!',
    firstTime: 'Prima volta? Crea il tuo account',
    followUs: 'Seguici sui social',
    createAccount: 'Crea Account',
    updatePassword: 'Modifica password',
    loginNow: "Hai già un account?\nEffettua l'accesso",
    alreadyRegistered: "Già registrato? Effettua l'accesso",
    direction: 'Ottieni indicazioni',
    edit: 'Modifica',
    confirm: 'Conferma',
    addVideo: 'Aggiungi video',
    registerVenue: 'Registra club',
    registerOrganization: 'Registra organizzazione',
    changeVideo: 'Cambia video',
    deleteVideo: 'Elimina video',
    save: 'Salva',
    cancel: 'Annulla',
    minutes: 'Minuti',
    seconds: 'Secondi',
    startTime: 'Ora di inizio',
    showMore: 'Mostra altro',
    showLess: 'Mostra meno',
    showAll: 'Vedi tutto',
    share: 'Condividere',
    photos: 'Foto',
    video: 'Video',
    applyFilter: 'Applica',
    closeSearchOpts: 'Chiudi Opzioni di ricerca',
    selectDate: 'Seleziona data...',
    privacyRegisterAgreement: 'Registrando un account accetti la nostra ',
    privacyTerms: "Privacy Policy - Termini e Condizioni d'uso",
    organization: {
      noEvents: 'Nessun evento di questa organizzazione ancora',
      uploadCover: 'Aggiungi una foto di copertina alla tua organizzazione',
      uploadLogo: 'Aggiungi una foto del logo alla tua organizzazione',
    },
    regions: {
      abruzzo: 'Abruzzo',
      basilicata: 'Basilicata',
      calabria: 'Calabria',
      campania: 'Campania',
      'emilia-romagna': 'Emilia Romagna',
      'friuli-venezia-giulia': 'Friuli-Venezia Giulia',
      lazio: 'Lazio',
      liguria: 'Liguria',
      lombardia: 'Lombardia',
      marche: 'Marche',
      molise: 'Molise',
      piemonte: 'Piemonte',
      puglia: 'Puglia',
      sardegna: 'Sardegna',
      sicilia: 'Sicilia',
      toscana: 'Toscana',
      'trentino-alto-adige': 'Trentino-Alto Adige',
      umbria: 'Umbria',
      'valle-d-aosta': "Valle d'Aosta",
      veneto: 'Veneto',
    },
    allItaly: 'Tutta Italia',
    musicStyles: {
      'hip-hop': 'Hip Hop',
      'r-n-b': 'RnB',
      rock: 'Rock',
      dance: 'Dance',
      classic: 'Classic',
      'jazz-and-blues': 'Jazz & Blues',
      reggaeton: 'Reggaeton',
      chillout: 'Chillout',
      '70-80-90': "'70 '80 '90",
      noStyle: 'Generico',
    },
    organizationsRules: {
      canEdit: 'Può modificare',
      assigned: 'Assegna',
    },
    newsCategories: {
      Opinions: 'Opinioni',
      Business: 'Affari',
      Sports: 'Gli sport',
      Culture: 'Cultura',
      World: 'Mondo',
      Curious: 'Curiosi',
      Technologies: 'Tecnologie',
      noStyle: 'Generico',
    },
    artistsRules: {
      assigned: 'Assegna',
    },
  },
  general: {
    uploadCover: 'Carica qui la tua immagine di copertina',
    uploadLogo: 'Carica qui il tuo logo',
    uploadPhotos: 'Carica le foto adesso',
    export: 'Esporta come PDF',
    unknown: 'Ignoto',
    venue: 'Club',
    venues: 'Clubs',
    event: 'Evento',
    events: 'Eventi',
    male: 'Uomo',
    female: 'Donna',
    organization: 'Organizzazione',
    news: 'Notizia',
    photo: 'Foto',
    artist: 'Artista',
    allResults: 'Vedi tutti i risultati',
    forResults: 'Vedi risultati per...',
    search: 'Cerca...',
    all: 'Ogni categoria',
    timePeriod: 'Periodo di tempo',
    futureEvents: 'Eventi futuri',
    pastEvents: 'Eventi passati',
    goToNextEvents: 'Vai ai prossimi eventi',
    photoAlbums: 'Album fotografici',
    filters: 'Filtri',
    searchFor: 'Cerca risultati per &#8222;<i><b>{{searchFor}}</b></i>&#8221;',
    admins: 'Assegna amministratori',
    organizations: 'Organizzatori',
    artists: 'Artisti',
    photographers: 'Assegna fotografi',
    adminEmail: 'Inserisci l"email dell"amministratore',
    photographerEmail: 'Inserisci l"email del fotografo',
    pickAdmins: 'Scegli uno o più amministratori',
    yes: 'Sì',
    no: 'No',
    verification: 'Verifica',
    category: 'Categoria',
    definePermission: 'Definisci i permessi',
    assignOrganizations: 'Assegna organizzazioni',
    assignArtists: 'Assegna artisti',
    selectArtistInfo: 'Seleziona artisti per il tuo evento',
    selectOrganizersInfo: 'Seleziona organizzatori per il tuo evento',
    addGuestList: 'Aggiungi una guestlist al tuo evento',
    goToYourVenue: 'Vai al tuo locale',
    successfulActivation: 'Attivazione riuscita',
    failedActivation: 'Attivazione fallita',
    videoPreview: 'Anteprima del video',
    youtubeUrl: 'URL di Youtube',
    copyYoutubeUrl: "Copia qui l'URL del tuo video Youtube",
    provideValidUrl: 'Fornisci un URL video di YouTube valido',
    checkUrl: 'Controlla URL',
    checkUrlDisclaimer:
      "Se si modifica l'URL nel campo, fare nuovamente clic sul pulsante 'Controlla URL'",
  },
  landingPage: {
    stayTuned: 'Resta aggiornato e ottieni accesso VIP ',
    tilllateClubbing: 'alle serate sponsorizzate tilllate',
    thankYou:
      'Grazie per esserti registrato,<br />adesso non perderai mai nessun aggiornamento',
    loginWith: 'Accedi con',
  },
  landingPageBusiness: {
    stayTuned:
      "Sei l'amministratore di un club o di un'organizzazione di eventi?",
    tilllateClubbing: 'Accedi subito per registrare la tua attività',
    loginWith: 'Accedi con',
  },
  social: {
    followUs: 'Seguici su {{social}}',
    email: "Scrivici un'email",
    loginWith: 'Accedi con',
    appleSign: 'Continua con Apple',
    googleSign: 'Continua con Google',
    facebookSign: 'Continua con Facebook',
  },
  form: {
    required: 'Campo richiesto',
    additionalEmailsDisclaimer: 'Puoi fornire più email separate da virgola',
    ageDisclaimer:
      "Hai selezionato un'età minima inferiore ai 18 anni, salvando e pubblicando l'evento confermi di essere a conoscenza che i minori potranno accedere al tuo evento",
    invalidEmail: 'Email non valida',
    invalidEmails: 'Tutte le email dovrebbero essere valide',
    invalidUrl: "L'URL inserito non è valido",
    invalidAddress: 'Seleziona un indirizzo valido',
    doesntMatch: 'La password non corrisponde',
    tooShort: 'La password deve essere lunga tra 10 e 15 caratteri',
    tooWeak:
      'La password è troppo debole, prova ad inserire caratteri speciali, numeri e lettere',
    invalidFacebook: 'URL Facebook non valido',
    invalidInstagram: 'URL Instagram non valido',
    descriptionRequired: 'Descrizione richiesta',
    dateRequired: 'Data richiesta',
    endDateRequired: 'Data di fine obbligatoria',
    wrongEndDate: 'La data di fine deve essere successiva alla data di inizio',
    musicStyleRequired: 'Necessario per selezionare almeno 1 stile musicale',
  },
  profile: {
    name: 'Nome',
    displayName: 'Nome',
    firstName: 'Nome',
    lastName: 'Cognome',
    age: 'Età',
    status: 'Stato',
    gender: 'Genere',
    photographer: 'Sei un fotografo?',
    preferredMusicLabel: 'Musica preferita',
    privacySettingsLabel: 'Consensi Privacy',
    verifyProfile: 'Verifica del profilo',
    completeProfileAndVerify:
      'Completa il profilo per verificare il tuo account!',
    profileVerified: 'Profilo verificato ✅',
    emailVerified: 'Email verificata ✅',
    phoneVerified: 'N. di Telefono verificato ✅',
    photographerVerified: 'Fotografo verificato ✅',
    becomePhotographer: 'Diventa fotografo 📷',
    emailSent: 'Email inviata',
    infoSettingsLabel: 'Informazioni',
    authSettingsLabel: 'Autenticazione',
    passwordUpdated: 'Password modificata con successo!',
    nickname: 'Nickname',
    city: 'Città',
    instagram: 'Instagram',
    facebook: 'Facebook',
    phone: 'Telefono',
    birthday: 'Data di nascita',
    aboutMe: 'Su di me',
    followMe: 'Trovami sui social',
    editProfile: 'Modifica profilo',
    backToProfile: 'Torna al profilo',
    updateProfile: 'Aggiorna profilo',
    viewProfile: 'Visualizza profilo',
    verifyEmail: 'Verifica email',
    verifyPhone: 'Verifica N. Telefono',
    suggestedEvents: 'Eventi consigliati',
    latestPhotos: 'Ultime foto',
    myPhotoAlbums: 'I miei photo album',
    myGuestLists: 'Le mie liste degli ospiti',
    myUploadedPhotoAlbums: 'I miei album fotografici caricati',
    myMissingPhotoAlbums: 'I miei album fotografici mancanti',
    myPastGuestLists: 'Le mie liste degli ospiti passate',
    myFutureGuestLists: 'Le mie future liste degli ospiti',
    myEvents: 'I miei eventi',
    myVenues: 'I miei club',
    myOrganizations: 'Le mie organizzazioni',
    myNextEvents: 'I miei prossimi eventi',
    deleteProfile: 'Elimina profilo',
    warning: 'ATTENZIONE!',
    deactivated: 'Profilo disattivato',
    resetPasswordEmailSent:
      "Ti abbiamo inviato un'email con un link per poter resettare la password.",
    needsToReactivate:
      'Il tuo profilo risulta disattivato, per poterlo utilizzare in Tilllate devi prima riattivarlo',
    reactivateMyProfile: 'Riattiva il mio profilo',
    sureDelete:
      'Sei sicuro di volere cancellare il tuo account in modo irriversibile e non recuperabile?\nQuesta azione non è reversibile e cancella il tuo account in modo definitivo. Perderai tutti i tuoi amici su tilllate.world!',
    deactivateInstead:
      'Invece di cancellare il tuo account hai la possibilità di disattivarlo a tempo indeterminato!\nIn questo modo potrai recuperare tutte le tue foto e i tuoi amici quando deciderai di tornare su tilllate.world',
    dontDelete: 'Non cancellare',
    wantDeactivate: 'Voglio disattivare il mio account',
    deleteForEver: 'Cancella il mio account in modo definitivo',
    yesDelete: 'Sì voglio cancellare il mio account',
    reauthenticate:
      "Per ragioni di sicurezza ti chiediamo di effettuare nuovamente l'accesso per eliminare il tuo account in modo definito",
    reactivateProfile: 'Riattiva profilo',
    backToEditProfile: 'Torna a modifica',
    statusOptions: {
      single: 'Single',
      engaged: 'Impegnato/a',
      fiance: 'Fidanzato/a ufficialmente',
      married: 'Sposato',
      reserved: 'Preferisco non specificare',
    },
    privacySettings: {
      photoTag: 'Tag sulle foto',
      city: 'Città',
      region: 'Regione',
      status: 'Stato',
      age: 'Età',
      facebook: 'Facebook',
      instagram: 'Instagram',
      none: 'Nessuno',
      friends: 'Amici',
      everyone: 'Tutti',
    },
    dashboard: 'Dashboard profilo',
    profilePicture: 'Foto profilo',
    useAsProfilePicture: 'Usa come foto profilo',
    deletePhoto: 'Elimina foto',
    addNewPhoto: 'Aggiungi foto',
    yourPhotos: 'Le tue foto',
    useThisPhoto: 'Usa questa foto',
    usedPhoto: 'Foto utilizzata',
  },
  venue: {
    pageTitle: 'Club',
    name: 'Nome',
    address: 'Indirizzo',
    region: 'Regione',
    city: 'Città',
    admins: 'Amministratori',
    description: 'Descrizione',
    goLive: 'Pubblica',
    edit: 'Salva',
    linkFacebook: 'Link completo alla pagina Facebook',
    linkInstagram: 'Link completo alla pagina Instagram',
    website: 'Sito web',
    phone: 'N. di Telefono',
    email: 'Indirizzi email aggiuntivi',
    followUs: 'Segui club sui social',
    musicStyles: 'Generi musicali',
    verified: 'Club certificato',
    noClubs: 'Nessun club',
    noClubsFound: 'Nessun club trovato',
    reservedInformation:
      'Informazione riservata, non sarà visbile pubblicamente',
    importConsent: {
      'import-events': 'Importa eventi',
      'import-albums': 'Importa album',
    },
    importConsentLabel:
      'Attivando le funzioni di importazione automatica dai il consenso a tilllate.world di importare in modo automatizzato eventi e/o foto dalla vostra pagina facebook per aumentare la portata della pubblicità fatta in modo gratuito ai vostri eventi.',
    home: 'Home',
    nextEvents: 'Eventi futuri',
    pastEvents: 'Eventi passati',
    search: 'Cerca Clubs',
    nameVenue: 'Nome club...',
    register: 'Registra ora il tuo club!',
    filter: 'Filtra clubs',
    editClub: 'Modifica club',
    allMyVenues: 'Tutti i miei club',
    activate: 'Attivazione della sede',
    createVenue: 'Crea club',
    venueNotExistInDb:
      'Questa sede non è presente nel nostro database. Il collegamento di attivazione non è corretto. Si prega di contattare....',
    venueHasOwner:
      'Questo club non può essere assegnato a te perché ha già un proprietario. Si prega di contattare....',
    incorrectLink:
      'Il collegamento di attivazione non è corretto. Si prega di contattare....',
    successfulActivation:
      'Hai attivato con successo il tuo club. Da ora sei il proprietario del club.',
  },
  organization: {
    name: 'Nome',
    modify: 'Modifica Organizzazione',
    address: 'Indirizzo',
    region: 'Regione',
    city: 'Città',
    admins: 'Amministratori',
    description: 'Descrizione',
    goLive: 'Vai Online!',
    edit: 'Salva',
    linkFacebook: 'Link completo alla pagina Facebook',
    linkInstagram: 'Link completo alla pagina Instagram',
    website: 'Sito web',
    phone: 'N. di Telefono',
    email: 'Email diretta',
    followUs: "Segui l'organizzazione sui social",
    musicStyles: 'Generi musicali',
    showMore: 'Mostra altri organizzatori',
    reservedInformation:
      'Informazione riservata, non sarà visbile pubblicamente',
    importConsent: {
      'import-events': 'Importa eventi',
      'import-albums': 'Importa album',
    },
    importConsentLabel:
      'Attivando le funzioni di importazione automatica dai il consenso a tilllate.world di importare in modo automatizzato eventi e/o foto dalla vostra pagina facebook per aumentare la portata della pubblicità fatta in modo gratuito ai vostri eventi.',
    register: 'Registra ora la tua organizzazione!',
    notFound: 'Nessuna organizzazione trovata',
    search: 'Cerca organizzazione',
    info: 'Organizzazioni',
    isVerified: 'Organizzazione certificata',
    filter: 'Filtra organizzazioni',
    allMyOrganizations: 'Tutte le mie organizzazioni',
  },
  event: {
    create: 'Crea Evento',
    viewEvent: 'Vedi evento',
    registerToEvent: 'Registrati',
    event: 'Evento',
    viewConfirmation: 'Vedi conferma',
    confirmation: 'Conferma',
    viewVenue: 'Vedi club',
    shareEvent: 'Condividi',
    uploadCover: 'Carica qui la copertina del tuo evento',
    name: 'Nome evento',
    description: "Descrizione dell'evento",
    goLive: 'Pubblica',
    edit: 'Salva',
    venue: 'Club',
    eventInfo: 'Info evento',
    eventDetails: 'Dettagli evento',
    video: "Video dell'evento",
    website: 'Sito web',
    facebook: 'URL evento Facebook',
    instagram: 'URL pagina Instagram',
    allMyEvents: 'Tutti i miei eventi',
    date: 'Data',
    startDate: 'Data d"inizio',
    endDate: 'Data di fine',
    time: 'Orario di inizio',
    minAge: 'Età minima',
    organizations: 'Organizzatori',
    artists: 'Artisti',
    musicStyles: 'Generi musicali',
    clubInfo: 'Club info',
    associatedOrganizations: 'Organizzatori',
    comingEvents: 'Prossimi eventi',
    comingEventsIn: 'Prossimi eventi in {{region}}',
    noEvents: 'Nessun evento trovato',
    registerToGuestList: 'Iscriviti ad una GuestList',
    registeredToGuestList: 'Sei già iscritto a questo evento',
    future: 'Eventi futuri',
    past: 'Eventi passati',
    photoGallery: 'Foto gallery',
    photoGalleries: 'Foto gallery',
    noGalleries: 'Nessuna Foto gallery trovata',
    noEventsFound: 'Nessun evento trovato',
    cantRegister:
      'Sembra che tu non abbia ancora nessun club associato al tuo account.\nPer poter creare eventi devi avere club da poter associare, crea prima il tuo club.',
    canEditOrg:
      'Scegliendo "Può modificare" assegni automaticamente l"organizzazione',
    filter: 'Filtra eventi',
    searchFor: 'Cerca eventi',
    addVideoToEvent: 'Aggiungi video alla pagina del tuo evento',
  },
  guestList: {
    name: 'Nome GuestList',
    conditions: 'Condizioni',
    noConditions: 'Nessuna condizione',
    benefits: 'Benefici',
    noBenefits: 'Nessun vantaggio',
    registerInfo: 'Registrati a questa GuestList come <b>{{displayName}}</b>.',
    modifyRegisterInfo:
      'Risulti già registrato a questa GuestList a nome <b> {{displayName}} </b>.',
    cantRegister:
      'Non è possibile registrarsi o modificare l\'elenco di guest meno di 3 ore prima dell"evento',
    guestsNumber: 'N° Ospiti',
    registerToGuestList: 'Registrati alla GuestList',
    modifyRegistrationToGuestList: 'Modifica la tua registrazione',
    noRegistrations: 'Nessuna registratione trovata',
    addBenefit: 'Aggiungi beneficio',
    freeEntry: 'Ingresso gratuito',
    ticketReduction: 'Riduzione biglietto',
    addCondition: 'Aggiungi condizione',
    entranceAsSingle: 'Ingresso in ...',
    entranceAsCouple: 'Ingresso in coppia',
    createGuestList: 'Crea nuova GuestList',
    cantCreateGuestList:
      'È necessario selezionare almeno un vantaggio e una condizione per creare un elenco degli ospiti.',
    editGuestList: 'Modifica GuestList',
    edit: 'Modifica',
    goLive: 'Crea GuestList',
    addGuestNames: 'Aggiungi i nomi dei tuoi ospiti',
    guestName: "Nome dell'ospite",
    details: 'Dettagli prenotazione',
    removeGuest: 'Rimuovi ospite',
    addGuest: 'Aggiungi ospite',
    confirmed: 'Confermata',
    confirmation: 'Visualizza conferma',
    description: 'Descrizione delle Guestlist',
    quantity: 'Quantità',
    verifyYourAccount: 'Verifica il tuo account',
    needToVerify:
      'Per registrarti ad un evento devi prima verificare il tuo account.',
    registeringPerson: 'Persona registrata',
    registeredPersons: 'Ospiti registrati',
    guest: 'Ospite',
    guests: 'Ospiti',
    disclaimer: 'Si applicano tutte le condizioni e i benefici',
    direction: 'Prendere direzione',
    alreadyRegistered:
      'Sei registrato alla GuestList <b>{{guestListName}}</b> come <b>{{userName}}</b>,<br /> iscrivendoti ad una GuestList differente sovrascriverai la tua registrazione corrente.',
    removeFromYourGuestList:
      'Avevi <b> {{registeredGuestNumber}} </b> ospiti registrati, stai tentando di eseguire l aggiornamento a <b> {{updatedGuestNumber}} </b> ospiti, chi desideri rimuovere?',
    accessedGuests: 'Accessi effettuati',
    searchRegistration: 'Cerca per nome o ID',
    youAreRegistered: 'Sei registrato a questa GuestList',
    registerForFree: 'Iscriviti gratuitamente',
    allRegistrations: 'Tutte le registrationi',
    guestList: 'Elenco degli ospiti:',
    registerGuests: 'Registra ospiti',
    editEvent: 'Modifica evento',
    createAlbum: 'Crea Foto Album',
    qrDialog: {
      close: 'Chiudi',
      scanNew: 'Nuovo scan',
      alreadyAccessed: 'Accesso già effettuato!',
      accessRegistered: 'Accesso registrato',
      notValid: 'Codice non valido',
      registerGuests: 'Registra QRCode accessi',
    },
  },
  artist: {
    name: 'Nome',
    address: 'Indirizzo',
    region: 'Regione',
    city: 'Città',
    admins: 'Amministratori',
    description: 'Descrizione',
    goLive: 'Pubblica',
    edit: 'Modifica',
    modifyArtist: "Modifica l'artista",
    linkFacebook: 'Link completo alla pagina Facebook',
    linkInstagram: 'Link completo alla pagina Instagram',
    website: 'Sito web',
    phone: 'N. di Telefono',
    email: 'Email diretta',
    followUs: "Segui l'artista sui social",
    musicStyles: 'Generi musicali',
    noEvents: 'Nessun evento di questo artista ancora',
    reservedInformation:
      'Informazione riservata, non sarà visbile pubblicamente',
    importConsent: {
      'import-events': 'Importa eventi',
      'import-albums': 'Importa album',
    },
    importConsentLabel:
      'Attivando le funzioni di importazione automatica dai il consenso a tilllate.world di importare in modo automatizzato eventi e/o foto dalla vostra pagina facebook per aumentare la portata della pubblicità fatta in modo gratuito ai vostri eventi.',
    register: 'Registrati Artista',
    notFound: 'Nessun artista trovato',
    artists: 'Artisti',
    search: 'Cerca artista',
    showMore: 'Mostra di più',
    searchArtists: 'Cerca Artisti',
    bio: 'Bio',
    filter: 'Filtra artisti',
    searchFor: 'Cerca artisti',
    addVideo: 'Aggiungi video alla tua pagina',
  },
  newsItem: {
    name: 'Nome',
    region: 'Regione',
    admins: 'Amministratori',
    description: 'Descrizione',
    goLive: 'Pubblica',
    edit: 'Modifica',
    newsCategories: 'Categorie di notizie',
  },
  news: {
    noNews: 'Nessuna notizia ancora',
    create: 'Crea nuova notizia',
    edit: 'Modifica le notizie',
    category: 'Notizie per categoria',
    news: 'Novità',
    readMore: 'Leggi di più',
    search: 'Cerca notizie',
    filter: 'Filtra news',
  },
  newsCategory: {
    title: 'Crea categoria',
    name: 'Intestazione',
    description: 'Descrizione',
    goLive: 'Pubblica',
  },
  newsCategories: {
    Opinions: 'Opinioni',
    Business: 'Affari',
    Sports: 'Gli sport',
    Culture: 'Cultura',
    World: 'Mondo',
    Curious: 'Curiosi',
    Technologies: 'Tecnologie',
    Cars: 'Automobili',
  },
  emails: {
    accounts: {
      registration: {
        subject: 'Completa la tua registrazione a Tilllate World',
        content1: `<p>Ciao {{displayName}},</p>
        <p>Grazie per esserti registrato a Tilllate World! <br />Ti manca solo un piccolo passo! Clicca sul pulsante qui sotto per confermare la tua email!</p>`,
        ctaText: 'Conferma email',
        content2: '<p>Buon divertimento :)</p>',
        shortText:
          "Conferma la tua email per completare l'iscrizione a Tilllate World",
        text:
          'Ciao {{displayName}}, Grazie per esserti registrato a Tilllate World! <br />Ti manca solo un piccolo passo! Clicca sul link qui sotto per confermare la tua email! {{url}}',
      },
      updatePassword: {
        subject:
          'La password del tuo account Tilllate è stata cambiata con successo',
        content1: `<p>Ciao {{displayName}},</p>
        <p>Ti informiamo che la password del tuo account Tilllate è stata modificata con successo come da te richiesto!</p>`,
        ctaText: 'Accedi a Tilllate',
        content2:
          "<p>Attenzione: se non sei stato tu a modificare la password del tuo account ti consigliamo di richiedere immeddiatamente un nuovo cambio password effettuando la procedura di recupero password dalla pagina Login, e di contattarci per email all'indirizzo it@tilllate.world!</p>",
        shortText: 'Password modificata con successo',
        text:
          "Ciao {{displayName}}, Ti informiamo che la password del tuo account Tilllate è stata modificata con successo come da te richiesto! Attenzione: se non sei stato tu a modificare la password del tuo account ti consigliamo di richiedere immeddiatamente un nuovo cambio password effettuando la procedura di recupero password dalla pagina Login, e di contattarci per email all'indirizzo it@tilllate.world!",
      },
      resendVerification: {
        subject: 'Completa la tua registrazione a Tilllate World',
        content1: `<p>Ciao {{displayName}},</p>
        <p>Grazie per esserti registrato a Tilllate World! <br />Ti manca solo un piccolo passo! Clicca sul pulsante qui sotto per confermare la tua email!</p>`,
        ctaText: 'Conferma email',
        content2: '<p>Buon divertimento :)</p>',
        shortText:
          "Conferma la tua email per completare l'iscrizione a Tilllate World",
        text:
          'Ciao {{displayName}}, Grazie per esserti registrato a Tilllate World! <br />Ti manca solo un piccolo passo! Clicca sul link qui sotto per confermare la tua email! {{url}}',
      },
      verification: {
        emailVerification: 'Verifica email',
        error:
          'I tuoi link di conferma sembrano non funzionanti, contatta l amministratore',
        alreadyVerified: 'Email già verificata',
        success: 'Grazie per aver verificato la tua email',
      },
    },
    pages: {
      subject: 'La tua nuova pagina è stata creata correttamente!',
      content1: `<p>Ciao {{displayName}},</p>
        <p>Grazie per avere registrato una nuova pagina su Tilllate World! <br />La registrazione è avvenuta correttamente, puoi cliccare sul pulsante qui sotto per accedere alla pagina e modificarla in qualunque momento!</p>`,
      ctaText: 'Vai alla pagina',
      content2: '<p>Buon divertimento :)</p>',
      shortText: 'Pagina registrata correttamente su Tilllate World',
      text:
        'Ciao {{displayName}}, Grazie per avere registrato una nuova pagina su Tilllate World! <br />La registrazione è avvenuta correttamente, puoi cliccare sul pulsante qui sotto per accedere alla pagina e modificarla in qualunque momento! {{url}}',
    },
    events: {
      subject: 'Il tuo nuovo evento è stata creata correttamente!',
      content1: `<p>Ciao {{displayName}},</p>
        <p>Grazie per avere registrato un nuovo evento su Tilllate World! <br />La registrazione è avvenuta correttamente, puoi cliccare sul pulsante qui sotto per accedere alla pagina del tuo evento e modificarlo in qualunque momento!</p>`,
      ctaText: "Vai all'evento",
      content2: '<p>Buon divertimento :)</p>',
      shortText: 'Pagina evento correttamente su Tilllate World',
      text:
        'Ciao {{displayName}}, Grazie per avere registrato una nuova pagina su Tilllate World! <br />La registrazione è avvenuta correttamente, puoi cliccare sul pulsante qui sotto per accedere alla pagina e modificarla in qualunque momento! {{url}}',
    },
    organizations: {
      subject:
        'La tua nuova pagina organizzatore è stata creata correttamente!',
      content1: `<p>Ciao {{displayName}},</p>
        <p>Grazie per avere registrato una nuova pagina organizzatore su Tilllate World! <br />La registrazione è avvenuta correttamente, puoi cliccare sul pulsante qui sotto per accedere alla pagina e modificarla in qualunque momento!</p>`,
      ctaText: 'Vai alla pagina',
      content2: '<p>Buon divertimento :)</p>',
      shortText:
        'Pagina organizzatore registrata correttamente su Tilllate World',
      text:
        'Ciao {{displayName}}, Grazie per avere registrato una nuova pagina organizzatore su Tilllate World! <br />La registrazione è avvenuta correttamente, puoi cliccare sul pulsante qui sotto per accedere alla pagina e modificarla in qualunque momento! {{url}}',
    },
    photoAlbums: {
      subject: 'Il nuovo album fotografico è stato caricato!',
      content1: `<p>Ciao {{displayName}},</p>
        <p>Una nuova galleria fotografica è stata aggiunta a uno degli eventi a cui hai partecipato: <b>{{eventName}}</b></p>`,
      ctaText: 'Controllalo qui',
      content2: '<p>Buon divertimento :)</p>',
      shortText: 'È stata aggiunta una nuova galleria fotografica',
      text:
        'Ciao {{displayName}}, Una nuova galleria fotografica è stata aggiunta a uno degli eventi a cui hai partecipato: {{eventName}}. {{url}}',
    },
    guestListRegistration: {
      create: {
        subject: 'La tua registrazione è avvenuta con successo',
        content1: `<p>Ciao {{displayName}},</p>
        <p>La tua registrazione alla GuestList: <b>{{guestListName}}</b> per l'evento: <b>{{eventName}}</b> del <b>{{eventDate}}<b><br />
          è avvenuta con successo!</p>`,
        ctaText: 'Visualizza conferma',
        content2:
          "<p>Ricorda di portare la ricevuta di prenotazione con te presentarla all'ingresso del locale</p>",
        shortText: 'Conferma modifica alla GuestList: <b>{{guestListName}}</b>',
        text:
          "Ciao {{displayName}}, La tua registrazione alla GuestList: {{guestListName}} per l'evento: {{eventName}} del {{eventDate}} è avvenuta con successo!",
      },
      update: {
        subject: 'La tua registrazione è stata modificata',
        content1: `<p>Ciao {{displayName}},</p>
        <p>La tua modifica alla GuestList: <b>{{guestListName}}</b> per l'evento: <b>{{eventName}}</b> del <b>{{eventDate}}<b><br />
          è avvenuta con successo!</p>`,
        ctaText: 'Visualizza conferma',
        content2:
          "<p>Ricorda di portare la ricevuta di prenotazione con te presentarla all'ingresso del locale</p>",
        shortText: 'Conferma modifica alla GuestList: <b>{{guestListName}}</b>',
        text:
          "Ciao {{displayName}}, La tua modifica alla GuestList: {{guestListName}} per l'evento: {{eventName}} del {{eventDate}} è avvenuta con successo!",
      },
    },
  },
  photoAlbums: {
    noAlbums: 'Nessun album fotografico trovato',
    filter: 'Filtra gli album fotografici',
    checkAlbum: "Controlla l'album",
    exit:
      'Sei sicuro di voler uscire senza salvare? Le tue modifiche andranno perse!',
    modifyAlbum: 'Modifica Foto Album',
  },
  photoGallery: {
    noImages: 'Non hai ancora caricato nessuna immagine in questa gallery',
    clickHereToAdd: 'clicca qui per cominciare ad aggiungere foto.',
    addPhotos: 'Aggiungi foto',
    uploadPhotos: 'Carica qui le tue foto',
  },
  tilldate: {
    isSpamming:
      "Ops, sembra che tu stia insistendo un po' troppo.. Aspettiamo prima una risposta",
    entranceNotification:
      'Stai per entrare in tilldate, qui mettiamo tutti i disclaimer, le informazioni e i warnings, da qui non si passa più!',
    photoDisclaimer:
      "Questa è la tua foto principale, puoi cambiarla selezionandone un'altra o caricandone una nuova nel profilo.",
    changePicture: 'cambia foto',
    uploadNewPhoto: 'carica una nuova foto',
    nicknameDisclaimer:
      'Questo è il nickname che vedranno gli altri utenti, non verrà mai rivelato al di fuori della funzione tilldate!',
    entranceAttention:
      '*Attenzione, non potrai più modificare il tuo profilo fino alla prossima serata',
    readyToGo: 'Sono pronto per entrare!',
    preferredGender: 'Sesso preferito',
    noOtherProperPlayers:
      'Al momento non ci sono altri partecipanti. Per favore riprova più tardi ...',
  },
  'auth/requires-recent-login':
    "Errore. Questa operazione è delicata e richiede un'autenticazione recente. Accedi di nuovo prima di ritentare la richiesta.",
};
