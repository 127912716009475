import { Grid, Button, CircularProgress } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { useFirestore } from 'react-redux-firebase';
import { ErrorOutlineTwoTone } from '@material-ui/icons';
import { MUSIC_STYLES } from '../../../constants';
import { Spacer } from '../../atoms';
import { FormField, SectionHeader } from '../../molecule';
import { useAuthentication } from '../../../hooks';
import UpdatePasswordBox from '../UpdatePasswordBox';

type Props = {
  profile: any;
};

type Form = {
  nickname: string;
  firstName: string;
  lastName: string;
  city: string;
  status: string;
  instagram: string;
  facebook: string;
  phone: string;
  birthday: string;
  preferredMusic: string[];
  privacySettings: { [key: string]: string }[];
  photographer?: 'yes' | 'no';
  gender?: 'M' | 'F';
  aboutMe: string;
};

const ProfileForm: React.FC<Props> = ({ profile }) => {
  const { updateProfile } = useAuthentication();
  const history = useHistory();
  const firestore = useFirestore();
  const [checkingNickname, setCheckingNickname] = useState(false);
  const [nicknameExists, setNicknameExists] = useState(false);
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    setError,
    clearError,
  } = useForm<Form>({
    defaultValues: {
      // photographer: profile?.roles?.includes('photographer') ? 'yes' : 'no',
      ...profile,
      birthday: profile?.birthday
        ? dayjs(profile?.birthday).format('D MMMM YYYY')
        : null,
    },
  });
  const { t } = useTranslation();

  const onSubmit = async (values: any) => {
    await updateProfile(values);
    history.push('/profile');
  };

  const nicknameAdornment = useMemo(() => {
    if (checkingNickname) {
      return <CircularProgress size="15px" />;
    }
    return nicknameExists ? (
      <ErrorOutlineTwoTone fontSize="small" color="error" />
    ) : undefined;
  }, [checkingNickname, nicknameExists]);

  const verifyNickname = useCallback(
    e => {
      const newNickname = e?.target?.value;
      if (newNickname) {
        setCheckingNickname(true);
        setNicknameExists(false);
        clearError('nickname');
        firestore
          .collection('users_meta')
          .where('nickname', '==', newNickname)
          .get()
          .then(res => {
            if (res.size && newNickname !== profile?.nickname) {
              setNicknameExists(true);
              setError('nickname', {
                type: 'manual',
                message: 'Nickname already taken',
              });
            }
          })
          .finally(() => setCheckingNickname(false));
      }
    },
    [clearError, firestore, setError, profile],
  );

  return (
    <>
      <SectionHeader
        title={t('profile.authSettingsLabel')}
        titleVariant="h6"
        spaceBelow="8px"
      />
      <UpdatePasswordBox />
      <SectionHeader
        title={t('profile.infoSettingsLabel')}
        titleVariant="h6"
        spaceBelow="8px"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormField
              name="nickname"
              label={t('profile.nickname')}
              register={register}
              onChange={verifyNickname}
              endAdornment={nicknameAdornment}
              error={
                errors.nickname?.message ||
                (errors.nickname?.types?.message as string)
              }
            />
            <Spacer height="10px" />
            <FormField
              name="firstName"
              label={t('profile.firstName')}
              register={register}
              required
              error={errors.firstName?.message}
            />
            <Spacer height="10px" />
            <FormField
              name="lastName"
              label={t('profile.lastName')}
              register={register}
              required
              error={errors.lastName?.message}
            />
            <Spacer height="10px" />
            <FormField
              name="city"
              type="city"
              control={control}
              setValue={setValue}
              label={t('profile.city')}
              register={register}
              error={errors.city?.message}
              required
            />
            <Spacer height="10px" />
            <FormField
              name="instagram"
              label={t('profile.instagram')}
              register={register}
              error={errors.instagram?.message}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormField
              name="phone"
              type="phone"
              label={t('profile.phone')}
              register={register}
              error={errors.phone?.message}
              required
            />
            <Spacer height="10px" />
            <FormField
              name="birthday"
              label={t('profile.birthday') + ' *'}
              type="date"
              control={control}
              register={register}
              defaultValue={profile.birthday}
              maxDate={new Date()}
              required
              error={errors.birthday?.message}
            />
            <Spacer height="10px" />
            <FormField
              name="status"
              control={control}
              type="select"
              langDomain="profile.statusOptions"
              options={['single', 'married', 'engaged', 'fiance', 'reserved']}
              label={t('profile.status')}
              register={register}
              error={errors.status?.message}
              required
            />
            <Spacer height="10px" />
            <FormField
              name="gender"
              label={t('profile.gender')}
              type="select"
              langDomain="general"
              options={['male', 'female']}
              register={register}
              control={control}
              required
            />
            <Spacer height="10px" />
            <FormField
              name="facebook"
              label={t('profile.facebook')}
              register={register}
              error={errors.facebook?.message}
            />
            {/* Photographers now need to send a request
						<Spacer height="10px" />
            <FormField
              name="gender"
              label={t('profile.gender')}
              type="select"
              langDomain="general"
              options={['male', 'female']}
              register={register}
              control={control}
              required
            /> */}
          </Grid>
          <Grid item md={12}>
            <FormField
              name="preferredMusic"
              label={t('profile.preferredMusicLabel')}
              type="checkbox"
              langDomain="shared.musicStyles"
              options={MUSIC_STYLES}
              defaultOptions={profile.preferredMusic}
              register={register}
              required
            />
            <Spacer height="10px" />
            <SectionHeader
              title={t('profile.privacySettingsLabel')}
              titleVariant="h6"
              spaceBelow="8px"
            />
            <FormField
              name="privacySettings"
              label={t('profile.privacySettingsLabel')}
              type="radiogroup"
              defaultGroups={profile.privacySettings}
              langDomain="profile.privacySettings"
              groups={[
                /*{
                   id: 'photoTag', options: ['none', 'friends', 'everyone'] 
                },*/
                { id: 'city', options: ['none', 'friends', 'everyone'] },
                { id: 'region', options: ['none', 'friends', 'everyone'] },
                { id: 'status', options: ['none', 'friends', 'everyone'] },
                { id: 'age', options: ['none', 'friends', 'everyone'] },
                { id: 'facebook', options: ['none', 'friends', 'everyone'] },
                { id: 'instagram', options: ['none', 'friends', 'everyone'] },
              ]}
              register={register}
            />
            <Spacer height="10px" />
            <FormField
              name="aboutMe"
              label={t('profile.aboutMe')}
              type="textarea"
              register={register}
              error={errors.aboutMe?.message}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item md={12} container justify="space-around">
            <Button
              type="submit"
              variant="contained"
              disableElevation
              disabled={checkingNickname || nicknameExists}
              color="primary"
            >
              {t('profile.updateProfile')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Spacer height="20px" />
    </>
  );
};

ProfileForm.displayName = 'ProfileForm';

export default React.memo(ProfileForm);
