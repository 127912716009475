import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../../molecule';

type Props = {};

const SearchArtistsBar: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>();
  return (
    <SearchBar
      label={t('artists.search')}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

SearchArtistsBar.displayName = 'SearchArtistsBar';

export default React.memo(SearchArtistsBar);
