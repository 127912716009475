import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { THRESHOLD } from '../../constants';
import {
  filteredNewsSelector,
  newsListSelector,
  newsSelector,
  topNewsSelector,
} from '../../store/selectors/news';
import { NewsItem } from '../../store/types';
import { useAuthentication } from '../auth';

type Props = {
  category?: string;
  searchFor?: string;
  selectedCategoryIds?: string[];
  page?: number;
};

type useNews = {
  news: { [key: string]: NewsItem };
  newsList: NewsItem[];
  loaded?: boolean;
  filteredNews: NewsItem[];
  searchedNews: (text: string) => NewsItem[];
  topNews: NewsItem[];
  canCreateNews?: boolean;
  filteredNewsByPage: { news: NewsItem[]; totalResults: number };
};

export const useNews = (props: Props = {}): useNews => {
  const { category, searchFor, selectedCategoryIds, page = 1 } = props;
  const { profile, userId } = useAuthentication();
  const news = useSelector(newsSelector);
  const newsList = useSelector(newsListSelector);
  const topNews = useSelector(topNewsSelector);
  const filteredNews = useSelector(
    filteredNewsSelector({
      category,
      searchFor,
      selectedCategoryIds,
      currentUser: userId,
    }),
  );

  const filteredNewsByPage = {
    news: filteredNews.slice(THRESHOLD * (page - 1), THRESHOLD * page) || [],
    totalResults: filteredNews?.length || 0,
  };

  const searchedNews = useCallback(
    (text: string) => {
      return (
        newsList?.filter(n => {
          if (text) {
            return n.title.toLowerCase().startsWith(text.toLowerCase());
          }
          return false;
        }) ?? []
      );
    },
    [newsList],
  );

  const loaded = isLoaded(news);

  const canCreateNews = useMemo(
    () =>
      profile?.roles?.includes('editor') || profile?.roles?.includes('admin'),
    [profile],
  );

  useFirestoreConnect({
    collection: 'news',
    orderBy: ['timestamp', 'desc'],
    limit: 99,
  });

  return {
    news,
    loaded,
    newsList: newsList || [],
    filteredNews,
    searchedNews,
    topNews,
    canCreateNews,
    filteredNewsByPage,
  };
};
