import { makeStyles } from '@material-ui/core';

export default makeStyles({
  fab: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(30%, -30%)',
  },
});
