import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { VirtualizedList, CardListWidget, InfiniteScroller } from '..';
import { Event } from '../../../store/types';
import Item from './Item';

type Props = {
  events: Event[];
  loading?: boolean;
  loadMore?: () => void;
  totalResults?: number;
  page?: number;
};

const EventsList: React.FC<Props> = ({
  events,
  loading,
  loadMore,
  totalResults,
  page,
}) => {
  const renderItem = useCallback((event: Event) => <Item event={event} />, []);

  if (loading) {
    return (
      <Box marginTop={3}>
        <VirtualizedList
          data={new Array(3).fill(true)}
          renderItem={() => <CardListWidget.Skeleton withLogo withActions />}
        />
      </Box>
    );
  }
  return (
    <Box marginTop={3}>
      {loadMore && totalResults && page !== undefined ? (
        <InfiniteScroller
          data={events}
          renderItem={renderItem}
          loadMore={loadMore}
          totalResults={totalResults}
          page={page}
        />
      ) : (
        <VirtualizedList data={events} renderItem={renderItem} />
      )}
    </Box>
  );
};

EventsList.displayName = 'EventsList';

export default React.memo(EventsList);
