import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import TabPanel from './TabPanel';

type Props = {
  containerClassName?: string;
  tabs: {
    label: string;
    icon?: any;
    content: React.ReactChild | React.ReactNode;
    hash?: string;
  }[];
};

function ariaControlsGen(index: number) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function tabPanelsGen(
  value: number,
  tabContent: Array<React.ReactChild | React.ReactNode>,
) {
  return tabContent.map((tabCont, ind) => (
    <TabPanel value={value} index={ind} key={`${ind}-${ind}`}>
      {tabCont}
    </TabPanel>
  ));
}

const TabsWithPath: React.FC<Props> = ({ containerClassName, tabs }) => {
  const styles = useGlobalStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const { hash } = useLocation();
  const { isMobile } = useMediaQuery();

  const tabsButtonsGen = useCallback(
    (tabsList: { label: string; icon: any }[]) => {
      return tabsList.map((lab, ind) => (
        <Tab
          style={{ fontSize: isMobile ? 12 : undefined, padding: 0 }}
          label={lab.label}
          icon={lab.icon}
          key={ind}
          {...ariaControlsGen(ind)}
        />
      ));
    },
    [isMobile],
  );

  const handleChange = (event: any, newValue: any) => {
    if (tabs[newValue].hash) {
      history.push(tabs?.[newValue]?.hash ?? '#');
    } else {
      history.push('#');
    }
    setValue(newValue);
  };

  const tabButtons = tabsButtonsGen(
    tabs.map(tab => {
      return { label: tab.label, icon: tab.icon };
    }),
  );
  const tabPanels = tabPanelsGen(
    value,
    tabs.map(tab => tab.content),
  );

  useEffect(() => {
    const ind = tabs.findIndex(t => t.hash === hash);
    if (hash && ind > -1) {
      setValue(ind);
    }
  }, [hash, tabs]);

  return (
    <div className={[styles.flexGrow, containerClassName].join(' ')}>
      <AppBar position="static" variant="outlined" color="default">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs"
        >
          {tabButtons}
        </Tabs>
      </AppBar>

      {tabPanels}
    </div>
  );
};

TabsWithPath.displayName = 'TabsWithPath';

export default React.memo(TabsWithPath);
