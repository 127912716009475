import { Chip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles } from '../../../hooks';
import { PopulatedGuestListRegistration } from '../../../store/types';
import { DateWidget } from '../../molecule';
import CardListWidget from '../../molecule/CardListWidget/CardListWidget';

type Props = {
  registration: PopulatedGuestListRegistration;
  disableCover?: boolean;
};

const GuestListRegistrationItem: React.FC<Props> = ({
  registration,
  disableCover,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();

  return (
    <CardListWidget
      onClick={() =>
        history.push(`/events/${registration?.event?.slug}/confirmation`)
      }
      renderLogo={<DateWidget date={registration?.event?.date} hideTime />}
      coverUrl={registration?.event?.coverUrl}
      disableCover={disableCover}
      title={registration?.event?.name}
      subtitle={registration.guestListName}
      cardActions={[
        {
          type: 'primary',
          label: t('event.confirmation'),
          onClick: () =>
            history.push(`/events/${registration?.event?.slug}/confirmation`),
        },
        {
          type: 'secondary',
          label: t('event.event'),
          onClick: () => history.push(`/events/${registration?.event?.slug}`),
        },
      ]}
    >
      <Chip
        component="span"
        label={
          registration.guests?.length > 1
            ? `${registration.guests?.length} ${t('guestList.guests')}`
            : `${registration.guests?.length} ${t('guestList.guest')}`
        }
        variant="outlined"
        size="small"
        className={globalStyle.mr1}
      />
    </CardListWidget>
  );
};

GuestListRegistrationItem.displayName = 'GuestListRegistrationItem';

export default React.memo(GuestListRegistrationItem);
