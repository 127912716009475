import { Button, Dialog } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthentication } from '../../../hooks';
import useMyDateRooms from '../../../hooks/dateRooms/useMyDateRooms';
import useLocalStorage from '../../../hooks/ui/useLocalStorage';

type Props = {};

const GlobalDialog: React.FC<Props> = () => {
  // happy coding
  const [open, setOpen] = useState(false);
  const { userId } = useAuthentication();
  const { myDateRooms } = useMyDateRooms();
  const { state: seen, setState: setSeen } = useLocalStorage('seen');
  const handleAccess = useCallback(() => {
    setSeen('true');
    setOpen(false);
  }, [setSeen]);
  useEffect(() => {
    if (!seen || seen === 'false') {
      if (myDateRooms?.length) {
        setOpen(true);
      }
    }
  }, [myDateRooms, seen, userId]);
  return (
    <Dialog open={open}>
      <Button onClick={handleAccess}>access</Button>
    </Dialog>
  );
};

GlobalDialog.displayName = 'GlobalDialog';

export default React.memo(GlobalDialog);
