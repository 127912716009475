import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export const openPdf = (documentDefinition: TDocumentDefinitions) => {
  pdfMake.createPdf(documentDefinition).open();
};

export const downloadPdf = (documentDefinition: TDocumentDefinitions) => {
  pdfMake
    .createPdf(documentDefinition)
    .download(`${documentDefinition?.info?.title ?? 'tilllate.world'}.pdf`);
};
