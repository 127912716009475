import React from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../molecule';
import { useGuestListRegistrations } from '../../../hooks';
import { Emoji, Spacer } from '../../atoms';
import Item from './Item';

type Props = {
  limit?: number;
  unbox?: boolean;
  disableCover?: boolean;
  timePeriod?: 'past' | 'future';
};

const MyGuestListRegistrations: React.FC<Props> = ({
  unbox,
  limit,
  disableCover,
  timePeriod,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { guestListRegistrationsByUser } = useGuestListRegistrations({
    myRegistrations: true,
    timePeriod,
  });

  if (unbox) {
    return (
      <>
        <SectionHeader titleVariant="body1" title="GuestList" />
        {guestListRegistrationsByUser.length > 0 ? (
          <Box>
            {guestListRegistrationsByUser.slice(0, limit).map(registration => (
              <Item
                registration={registration}
                disableCover={disableCover}
                key={registration.id}
              />
            ))}
            {limit && guestListRegistrationsByUser.length > limit && (
              <>
                <Spacer height="8px" />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push({
                      pathname: '/profile-dashboard',
                      state: { selectedMenuItem: 'myFutureGuestLists' },
                    })
                  }
                >
                  {t('guestList.allRegistrations')}
                </Button>
              </>
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography variant="h2">
              <Emoji emoji="🤷‍♂️" />
            </Typography>
            <Typography>{t('guestList.noRegistrations')}</Typography>
            <Spacer height="8px" />
            <Button
              variant="outlined"
              onClick={() => history.push('/events')}
              size="small"
              color="primary"
              fullWidth
            >
              {t('general.goToNextEvents')}
            </Button>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <SectionHeader title={t('profile.myGuestLists')} linkUrl="/events" />
      {guestListRegistrationsByUser.length > 0 ? (
        <Box>
          <Grid container spacing={2}>
            {guestListRegistrationsByUser.slice(0, limit).map(registration => (
              <Grid item xs={12} key={registration.id}>
                <Item registration={registration} disableCover={disableCover} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box textAlign="center">
          <Typography variant="h2">
            <Emoji emoji="🤷‍♂️" />
          </Typography>
          <Typography>{t('guestList.noRegistrations')}</Typography>
          <Spacer height="8px" />
          <Button
            variant="outlined"
            onClick={() => history.push('/events')}
            size="small"
            color="primary"
            fullWidth
          >
            {t('general.goToNextEvents')}
          </Button>
        </Box>
      )}
    </>
  );
};

MyGuestListRegistrations.displayName = 'MyGuestListRegistrations';

export default React.memo(MyGuestListRegistrations);
