import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  singleImage: {
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '350px',
      objectFit: 'cover',
    },
  },
  bullets: {
    textAlign: 'center',
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    '& .bullet': {
      position: 'relative',
      display: 'inline-block',
      background: '#a8a8a8',
      borderRadius: '50%',
      width: '8px',
      height: '8px',
      zIndex: 14,
      margin: ' 0 5px',
      cursor: 'pointer',
      border: '2px solid transparent',
      transition: 'all .3s',
    },
    '& .active': {
      background: '#0095f6',
    },
    '& .small': {
      width: '6px',
      height: '6px',
    },
  },

  productLargeImageWrapper: {
    position: 'relative',
    '& .ht-swiper-button-nav': {
      visibility: 'hidden',
      opacity: 0,
      color: '#333',
      border: 'none',
      background: 'none',
      '& i': {
        fontSize: '40px',
      },
    },
    '& :hover': {
      '& .ht-swiper-button-nav': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    '& .react_lightgallery_item': {
      position: 'absolute',
      top: '10px',
      right: '30px',
      zIndex: '2',
      opacity: 0.3,
      '&:hover': {
        opacity: 1,
      },
      '& button': {
        padding: 0,
        border: 'none',
        background: 'none',
      },
    },
    '&slider': {
      '& .ht-swiper-button-nav': {
        fontSize: '50px',
        visibility: 'hidden',
        padding: 0,

        transition: '0.3s',

        opacity: 0,
        color: '#333',
        border: 'none',
        background: 'none',
      },

      '& :hover': {
        '& .ht-swiper-button-nav': {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },

    '& .single-image': {
      '& img': {
        width: '100%',
      },
    },
  },
  photo: {
    width: '100%',
    objectFit: 'cover',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 3,
    marginBottom: 10,
    cursor: 'pointer',
  },
  photoWrapper: {
    columnWidth: 200,
  },
  cover: {
    height: 320,
    width: '100%',
    objectFit: 'cover',
  },
  chip: {
    marginBottom: '10px',
  },
}));
