import { IonSpinner } from '@ionic/react';
import React from 'react';
import {
  fade,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Flex } from '..';

type Props = {
  unbox?: boolean;
  label?: string;
  progress?: number;
};

const useStyle = makeStyles(theme => ({
  bar: {
    minWidth: 250,
    marginTop: 20,
    maxWidth: '80%',
    height: 1,
    boxShadow: '0px 0px 10px inset #575757',
  },
  paper: {
    padding: 20,
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '50%',
  },
  wrapper: {
    position: 'absolute',
    display: 'flex',
    backgroundColor: fade(theme.palette.secondary.light, 0.8),
    zIndex: 999,
    marginRight: -16,
    marginLeft: -16,
    width: 'calc(100% + 32px)!important',
    maxWidth: 'calc(100% + 32px)!important',
  },
}));

const LoadingCover: React.FC<Props> = ({ label = 'Loading...', progress }) => {
  const style = useStyle();
  return (
    <Flex className={style.wrapper}>
      <Paper className={style.paper}>
        <IonSpinner name="bubbles" />
        <Typography color="secondary" variant="h5" display="block">
          {label}
        </Typography>
        <LinearProgress
          className={style.bar}
          variant={progress ? 'determinate' : 'indeterminate'}
          value={progress}
        />
      </Paper>
    </Flex>
  );
};

LoadingCover.displayName = 'LoadingCover';

export default React.memo(LoadingCover);
