import React, { useState } from 'react';
import { SearchBar } from '../../molecule';

type Props = {};

const SearchEventsBar: React.FC<Props> = () => {
  const [searchText, setSearchText] = useState<string>();
  return (
    <SearchBar
      label="Cerca il tuo evento"
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

SearchEventsBar.displayName = 'SearchEventsBar';

export default React.memo(SearchEventsBar);
