import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import {
  BlurOn,
  LibraryBooksTwoTone,
  MapTwoTone,
  PhotoLibraryTwoTone,
  ViewListRounded,
} from '@material-ui/icons';
import { useLocation } from 'react-router';
import {
  FooterDrawerMenu,
  ProfileDrawerMenu,
  UserAvatar,
} from '../../organisms';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { LocationFilter } from '..';
import { Logo } from '../../atoms';
import logoSmall from '../../../assets/img/logo_small_w.png';
import logoSmallTD from '../../../assets/img/logo_small_w_td.png';
import AccountButton from '../AccountButton';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import useMyDateRooms from '../../../hooks/dateRooms/useMyDateRooms';
import MenuItem from './MenuItem';

type Props = {
  tabBar?: boolean;
};

const useStyle = makeStyles(theme => ({
  homeButton: {
    opacity: 1,
  },
  homeLogo: {
    marginTop: -theme.spacing(4),
    border: `2px solid ${theme.palette.secondary.dark}`,
    borderRadius: '100%',
    background: theme.palette.secondary.dark,
    marginBottom: -theme.spacing(1),
    boxShadow: '0px 0px 5px black',
  },
}));

const Menu: React.FC<Props> = ({ tabBar }) => {
  const style = useStyle();
  const { isMobile } = useMediaQuery();
  const [
    openProfileDrawer,
    toggleOpenProfileDrawer,
    ,
    toggletoggleOpenProfileDrawerOff,
  ] = useToggleState(false);
  const [
    openFooterDrawer,
    toggleOpenFooterDrawer,
    ,
    toggletoggleOpenFooterDrawerOff,
  ] = useToggleState(false);
  const [openMapDialog, toggleOpenMapDialog] = useToggleState(false);
  const [target, setTarget] = useState<HTMLElement | null>();
  const globalStyle = useGlobalStyles();
  const location = useLocation();
  const { myDateRooms } = useMyDateRooms();

  const onCloseMapDialog = useCallback(() => {
    toggleOpenMapDialog();
    setTarget(null);
  }, [toggleOpenMapDialog]);

  const onOpenMapDialog = useCallback(
    e => {
      toggleOpenMapDialog();
      setTarget(e.currentTarget);
    },
    [toggleOpenMapDialog],
  );

  const mobileMenu = useMemo(
    () => (
      <>
        <>
          <Box paddingY={1} onClick={toggleOpenProfileDrawer}>
            <UserAvatar.Small size={38} />
          </Box>
          <ProfileDrawerMenu
            open={openProfileDrawer}
            onOpen={toggleOpenProfileDrawer}
            onClose={toggleOpenProfileDrawer}
          />
        </>
      </>
    ),
    [openProfileDrawer, toggleOpenProfileDrawer],
  );
  const desktopMenu = useMemo(
    () => (
      <>
        <MenuItem label="News" href="/news" icon={<LibraryBooksTwoTone />} />
        <MenuItem label="Events" href="/events" icon={<BlurOn />} />
        <MenuItem
          label="Photos"
          href="/photos"
          icon={<PhotoLibraryTwoTone />}
        />
        <MenuItem
          label="Region"
          onClick={onOpenMapDialog}
          icon={<MapTwoTone />}
        />
        <AccountButton
          open={openProfileDrawer}
          onOpen={toggleOpenProfileDrawer}
          onClose={toggleOpenProfileDrawer}
        />
        <LocationFilter
          open={openMapDialog}
          target={target}
          onClose={onCloseMapDialog}
        />
      </>
    ),
    [
      onCloseMapDialog,
      onOpenMapDialog,
      openMapDialog,
      openProfileDrawer,
      target,
      toggleOpenProfileDrawer,
    ],
  );

  const tabBarMenu = useMemo(
    () => (
      <Box className={globalStyle.flex} justifyContent="space-evenly">
        <MenuItem label="News" href="/news" icon={<LibraryBooksTwoTone />} />
        <MenuItem label="Events" href="/events" icon={<BlurOn />} />
        <MenuItem
          disableRipple
          className={style.homeButton}
          label={myDateRooms?.length ? 'TillDate' : 'Home'}
          icon={
            <Logo
              to={myDateRooms?.length ? '/tilldate' : '/'}
              logo={myDateRooms?.length ? logoSmallTD : logoSmall}
              width="50px"
              className={style.homeLogo}
            />
          }
        />
        <MenuItem
          label="Photos"
          href="/photos"
          icon={<PhotoLibraryTwoTone />}
        />
        <MenuItem
          label="Altro"
          onClick={toggleOpenFooterDrawer}
          icon={<ViewListRounded />}
        />
        <FooterDrawerMenu
          open={openFooterDrawer}
          onOpen={toggleOpenFooterDrawer}
          onClose={toggleOpenFooterDrawer}
        />
        <LocationFilter
          open={openMapDialog}
          target={target}
          onClose={onCloseMapDialog}
        />
      </Box>
    ),
    //eslint-disable-next-line
    [
      globalStyle.flex,
      myDateRooms,
      openFooterDrawer,
      style.homeButton,
      style.homeLogo,
      toggleOpenFooterDrawer,
    ],
  );

  useEffect(() => {
    if (location) {
      toggletoggleOpenProfileDrawerOff();
      toggletoggleOpenFooterDrawerOff();
      onCloseMapDialog();
    }
  }, [
    location,
    onCloseMapDialog,
    toggletoggleOpenFooterDrawerOff,
    toggletoggleOpenProfileDrawerOff,
  ]);

  if (tabBar) {
    return tabBarMenu;
  }

  return isMobile ? mobileMenu : desktopMenu;
};

Menu.displayName = 'Menu';

export default React.memo(Menu);
