import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  PageWrapper,
  NewsList,
  FilterCategory,
  SearchBar,
  FilterCheckboxOptions,
  MobileFilterDialog,
} from '../../components/molecule';
import { useNews, useGlobalStyles, useMediaQuery } from '../../hooks';
import useCheckboxOptions from '../../hooks/ui/useCheckboxOptions';
import { getSelectedCheckboxes } from '../../utils';
import useDebounce from '../../hooks/ui/useDebounce';
import { Flex } from '../../components/atoms';

const News = () => {
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const history = useHistory();
  const { createNewsCategoriesCheckboxes } = useCheckboxOptions();
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText.trim(), 300);
  const [categoriesState, setCategoriesState] = useState(
    createNewsCategoriesCheckboxes(),
  );
  const [page, setPage] = useState(1);
  const {
    filteredNewsByPage: { news, totalResults },
    loaded,
    canCreateNews,
  } = useNews({
    searchFor: debouncedSearchText,
    selectedCategoryIds: getSelectedCheckboxes(categoriesState),
    page,
  });

  const goToRegister = useCallback(() => history.push('/register-news'), [
    history,
  ]);

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchText, categoriesState]);

  const loadMoreNews = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    setCategoriesState(createNewsCategoriesCheckboxes());
  }, [createNewsCategoriesCheckboxes]);

  const renderCreateButton = useMemo(() => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={goToRegister}
        className={globalStyle.mt2}
      >
        {t('news.create')}
      </Button>
    );
  }, [globalStyle.mt2, goToRegister, t]);

  const renderFilter = useCallback(() => {
    return (
      <Grid
        container
        justify="space-around"
        spacing={2}
        className={globalStyle.mt3}
      >
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.search').slice(0, -3)}>
            <SearchBar
              label={t('news.search')}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.category')}>
            <FilterCheckboxOptions
              state={categoriesState}
              setState={setCategoriesState}
              title={t('general.category')}
            />
          </FilterCategory>
        </Grid>
      </Grid>
    );
  }, [categoriesState, globalStyle.mt3, searchText, t]);

  return (
    <PageWrapper title={t('news.news')}>
      <Grid container spacing={2}>
        {isMobile ? (
          <MobileFilterDialog
            renderFilter={renderFilter}
            label={t('news.filter')}
          />
        ) : (
          <Grid item xs={12} md={3}>
            {renderFilter()}
            {canCreateNews && (
              <Flex alignItems="center" height="40px">
                {renderCreateButton}
              </Flex>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          {loaded && !news.length ? (
            <Typography align="center" className={globalStyle.mv3}>
              {t('news.noNews')}
            </Typography>
          ) : (
            <NewsList
              news={news}
              loading={!loaded}
              loadMore={loadMoreNews}
              totalResults={totalResults}
              page={page}
            />
          )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default News;
