import { Grid } from '@material-ui/core';
import React from 'react';
import { ReportAbuse } from '../../molecule';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { useStyle } from './styles';

type Props = {
  cover?: string;
  renderActions?: (containerClassName?: string) => false | JSX.Element;
  item?: any;
  category: 'news' | 'events' | 'users_meta' | 'clubs';
};

const EventCover: React.FC<Props> = ({
  cover,
  renderActions,
  item,
  category,
}) => {
  const { isMobile } = useMediaQuery();
  const globalStyles = useGlobalStyles();
  const style = useStyle({ isMobile });
  return (
    <Grid item md={12} className={globalStyles.relative}>
      <img
        src={cover || defaultCover}
        className={isMobile ? style.coverMobile : style.cover}
        alt="cover"
      />
      {item ? <ReportAbuse category={category} item={item} /> : null}
      {renderActions && renderActions(style.ctaSection)}
    </Grid>
  );
};

EventCover.displayName = 'EventCover';

export default React.memo(EventCover);
