import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Directions, PinDrop } from '@material-ui/icons';
import { Box, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Spacer } from '../../atoms';
import mapStyles from './mapStyle';
import useStyle from './styles';

type Props = {
  location: {
    address: string;
    lat: number;
    lng: number;
  };
};

const GoogleMap: React.FC<Props> = ({ location }) => {
  const styles = useStyle();
  const { t } = useTranslation();
  const LocationPin = (_: any) => <PinDrop className={styles.pin} />;
  const addressLink = `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
    location?.address,
  )}`;
  return (
    <>
      <Box className={styles.wrapper}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '' }}
          defaultCenter={location}
          defaultZoom={14}
          options={{
            styles: mapStyles,
            zoomControl: false,
          }}
        >
          <LocationPin
            lat={location?.lat}
            lng={location?.lng}
            text={location?.address}
          />
        </GoogleMapReact>
      </Box>
      <Spacer height="15px" />
      <Grid justify="center" container item>
        <Button
          color="secondary"
          href={addressLink}
          target="_blank"
          variant="outlined"
          size="small"
          fullWidth
        >
          {t('shared.direction')}{' '}
          <Directions color="secondary" fontSize="small" />
        </Button>
      </Grid>
      <Spacer height="15px" />
    </>
  );
};

GoogleMap.displayName = 'GoogleMap';

export default React.memo(GoogleMap);
