import React, { useCallback } from 'react';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ShareOutlined } from '@material-ui/icons';
import { NewsItem } from '../../../store/types';
import { useGlobalStyles, useNewsCategories } from '../../../hooks';
import { CardWidget, ShareButton, Slider } from '../../molecule';
import { Emoji, HtmlDecode } from '../../atoms';
import { textShortener } from '../../../utils/ui';

type Props = {
  items: number;
  loading?: boolean;
  news?: NewsItem[];
};

const TopNewsCarousel: React.FC<Props> = ({ items, news, loading }) => {
  const history = useHistory();
  const globalStyles = useGlobalStyles();
  const { newsCategories } = useNewsCategories();
  const { t } = useTranslation();

  const goToNews = useCallback(
    (slug: string) => history.push(`/news/${slug}`),
    [history],
  );

  if (loading) {
    return (
      <Slider items={items} pagination paginationPosition="below">
        {new Array(items).fill(true).map((_, i) => (
          <div key={i}>
            <CardWidget.Skeleton withLogo withActions />
          </div>
        ))}
      </Slider>
    );
  }

  if (!loading && !news?.length) {
    return (
      <Box textAlign="center" padding={2}>
        <Typography variant="h2">
          <Emoji emoji="🤷‍♂️" />
        </Typography>
        {t('news.noNews')}
      </Box>
    );
  }

  return (
    <>
      <Slider items={items} pagination paginationPosition="below" equalHeight>
        {news?.map(newsItem => (
          <div key={newsItem.id}>
            <CardWidget
              displayFlex
              title={newsItem.title}
              coverUrl={newsItem.coverUrl}
              onClick={() => goToNews(newsItem.slug)}
              cardActions={[
                {
                  type: 'secondary',
                  label: t('news.readMore'),
                  onClick: () => history.push(`/news/${newsItem.slug}`),
                },
                {
                  render: () => (
                    <ShareButton
                      url={`https://tilllate.world/news/${newsItem.slug}`}
                      renderButton={openPopOver => (
                        <Button
                          className={globalStyles.iconButton}
                          size="small"
                          color="primary"
                          disableElevation
                          onClick={e => openPopOver(e)}
                        >
                          <ShareOutlined fontSize="small" />
                        </Button>
                      )}
                    />
                  ),
                },
              ]}
            >
              {newsItem.newsCategories?.length ? (
                newsItem.newsCategories.map(category => (
                  <Chip
                    component="span"
                    key={`${category}`}
                    label={newsCategories && newsCategories[category]?.name}
                    variant="outlined"
                    size="small"
                    className={globalStyles.mr1}
                  />
                ))
              ) : (
                <Chip
                  component="span"
                  label={t('shared.newsCategories.noCategory')}
                  variant="outlined"
                  size="small"
                  className={globalStyles.mr1}
                />
              )}
              <Typography
                align="justify"
                variant="body2"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                <HtmlDecode>
                  {textShortener(
                    newsItem.description.replace(/<[^>]*>?/gm, ''),
                    160,
                  )}
                </HtmlDecode>
              </Typography>
            </CardWidget>
          </div>
        ))}
      </Slider>
    </>
  );
};

TopNewsCarousel.displayName = 'TopNewsCarousel';

export default React.memo(TopNewsCarousel);
