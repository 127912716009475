import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TopEventsCarousel } from '..';
import { Spacer } from '../../atoms';
import MyPhotoAlbums from '../MyPhotoAlbums';
import MyGuestListRegistrations from '../MyGuestListRegistrations';
import MyEvents from '../MyEvents';
import { useEvents } from '../../../hooks';
import { RootState } from '../../../store/reducers';
import { SectionHeader } from '../../molecule';

type Props = {};

const ProfileMain: React.FC<Props> = () => {
  const { t } = useTranslation();
  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const { suggestedEvents, loaded } = useEvents({
    region: region?.id,
  });
  const regionText = region ? ` (${t('shared.regions.' + region?.id)})` : '';

  return (
    <>
      <SectionHeader
        title={t('profile.suggestedEvents')}
        subtitle={regionText}
        linkUrl="/events"
      />
      <TopEventsCarousel
        items={3}
        loading={!loaded}
        events={suggestedEvents.splice(0, 6)}
      />
      <Spacer height="30px" />
      <MyPhotoAlbums />
      <Spacer height="30px" />
      <MyEvents />
      <Spacer height="30px" />
      <MyGuestListRegistrations />
      <Spacer height="30px" />
    </>
  );
};

ProfileMain.displayName = 'ProfileMain';

export default React.memo(ProfileMain);
