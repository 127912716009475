import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventForm } from '..';
import {
  useGlobalStyles,
  useEvent,
  useEditGuard,
  useCompressedImage,
} from '../../../hooks';
import { LoadingCover, Spacer } from '../../atoms';
import EventCover from '../EventCover';
import { Form } from '../EventForm/EventForm';

type Props = {
  slugOrId: string;
};

const EditEvent: React.FC<Props> = ({ slugOrId }) => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, setCover, setCompressCover] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { loading, updateEvent, event } = useEvent({
    slugOrId,
  });

  useEditGuard(event);

  const onSubmit = async (values: any) => {
    if (values.organizations) {
      values.organizations = Object.keys(values.organizations).reduce(
        (acc, curr) => {
          if (values.organizations[curr]) {
            acc[curr] = values.organizations[curr];
          }
          return acc;
        },
        {},
      );
    }
    if (values.artists) {
      values.artists = Object.keys(values.artists).reduce((acc, curr) => {
        if (values.artists[curr]) {
          acc[curr] = values.artists[curr];
        }
        return acc;
      }, {});
    }
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    if (!values.date) {
      setCustomErrors(prev => ({
        ...prev,
        date: t('form.dateRequired'),
      }));
      return;
    }
    if (!values.endDate) {
      setCustomErrors(prev => ({
        ...prev,
        endDate: t('form.endDateRequired'),
      }));

      return;
    }

    if (
      dayjs(values.date, 'D MMMM YYYY @ HH:mm', 'it').valueOf() >
      dayjs(values.endDate, 'D MMMM YYYY @ HH:mm', 'it').valueOf()
    ) {
      setCustomErrors(prev => ({
        ...prev,
        endDate: t('form.wrongEndDate'),
      }));

      return;
    }
    if (!values.musicStyles.length) {
      setCustomErrors(prev => ({
        ...prev,
        musicStyles: [t('form.musicStyleRequired')],
      }));

      return;
    }

    setCustomErrors({});

    updateEvent({ cover, ...values });
  };

  useEffect(() => {
    if (event) {
      setCover(event?.coverUrl || []);
    }
  }, [event, setCover]);
  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <EventCover.Form setCover={setCompressCover} cover={cover} />
      {event && (
        <EventForm
          event={event}
          onSubmit={onSubmit}
          customErrors={customErrors}
        />
      )}
    </div>
  );
};

EditEvent.displayName = 'EditEvent';

export default React.memo(EditEvent);
