import React from 'react';
import { PrivacyPolicy } from '../../components/molecule';
import { PageWrapper } from '../../components/molecule';

const PrivacyPolicyContainer = () => {
  return (
    <PageWrapper>
      <PrivacyPolicy />
    </PageWrapper>
  );
};

export default PrivacyPolicyContainer;
