import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useVenues } from '../../../hooks';
import { Spacer } from '../../atoms';
import { LabeledBox, SectionHeader } from '../../molecule';
import Item from './Item';

type Props = {
  limit?: number;
  unbox?: boolean;
  disableCover?: boolean;
};

const MyVenues: React.FC<Props> = ({ unbox, limit, disableCover }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { myVenuesList } = useVenues({
    myVenues: true,
  });

  if (unbox) {
    return (
      <>
        {myVenuesList.length > 0 ? (
          <Box>
            <SectionHeader titleVariant="body1" title={t('profile.myVenues')} />
            {myVenuesList.slice(0, limit).map(venue => (
              <Item venue={venue} disableCover={disableCover} key={venue.id} />
            ))}
            {limit && myVenuesList.length > limit && (
              <>
                <Spacer height="8px" />
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() =>
                    history.push({
                      pathname: '/profile-dashboard',
                      state: { selectedMenuItem: 'myVenues' },
                    })
                  }
                >
                  {t('venue.allMyVenues')}
                </Button>
              </>
            )}
          </Box>
        ) : null}
      </>
    );
  }

  return (
    <>
      {myVenuesList.length > 0 ? (
        <LabeledBox label={t('profile.myVenues')}>
          <Grid container spacing={2}>
            {myVenuesList.slice(0, limit).map(venue => (
              <Grid item xs={12} key={venue.id}>
                <Item venue={venue} disableCover={disableCover} />
              </Grid>
            ))}
          </Grid>
        </LabeledBox>
      ) : null}
    </>
  );
};

MyVenues.displayName = 'MyVenues';

export default React.memo(MyVenues);
