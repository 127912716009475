import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFirestoreQuerySetting, useFirestore } from 'react-redux-firebase';
import { useMedia } from '..';
import { photoAlbumSelector } from '../../store/selectors/photoAlbums';
import { PhotoAlbum } from '../../store/types';
import { useAuthentication } from '../auth';

type Props = {
  id?: string;
};

type RegisterPhotoAlbum = {
  id?: string;
  cover?: File[] | string;
  images?: (File | string)[];
  eventId: string;
  imagesToDelete?: string[];
};

type UsePhotoAlbum = {
  photoAlbum: PhotoAlbum;
  getPhotoAlbumById: (string: string) => Promise<PhotoAlbum>;
  registerPhotoAlbum: (props: RegisterPhotoAlbum) => void;
  updatePhotoAlbum: (props: RegisterPhotoAlbum) => void;
  loading?: boolean;
  isAdmin: boolean;
};

export const usePhotoAlbum = (props: Props = {}): UsePhotoAlbum => {
  const { id = '' } = props;
  const [loading, setLoading] = useState<boolean>();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { uploadMedia, uploadMedias, getMediaData, deleteMedia } = useMedia();
  const photoAlbum = useSelector(photoAlbumSelector(id));
  const isAdmin = useMemo(
    () =>
      (photoAlbum && userId === photoAlbum.created_by) ||
      profile?.roles?.includes('admin'),
    [photoAlbum, userId, profile],
  );
  const { id: photoAlbumId } = photoAlbum || {};

  const registerPhotoAlbum = useCallback(
    async ({ eventId, cover, images }: RegisterPhotoAlbum) => {
      let coverUrl: string | null = null;
      let imagesUrls: string[] | null = null;
      setLoading(true);
      const photoAlbumRef = await firestore.collection('/photoAlbums').add({
        eventId,
        created_by: userId,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      if ((!cover || !cover[0]) && (!images || !images.length)) {
        setLoading(false);
        return;
      }

      if (cover && cover[0]) {
        const coverRef = await uploadMedia({
          path: `/images/photoAlbum/${photoAlbumRef.id}`,
          file: cover[0],
        });
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }

      if (images && images.length) {
        const files = images.filter(image => typeof image !== 'string');
        const res = await uploadMedias({
          path: `/images/photoAlbum/${photoAlbumRef.id}`,
          files,
        });
        if (res.length) {
          imagesUrls = res.map(image => image.url);
        }
      }

      await firestore
        .collection('/photoAlbums')
        .doc(photoAlbumRef.id)
        .update({ coverUrl, images: imagesUrls });
      setLoading(false);
    },
    [firestore, getMediaData, uploadMedia, uploadMedias, userId],
  );

  const updatePhotoAlbum = useCallback(
    async ({
      id,
      eventId,
      cover,
      images,
      imagesToDelete,
    }: RegisterPhotoAlbum) => {
      let coverUrl: string | null = typeof cover === 'string' ? cover : null;
      let imagesUrls: string[] | null =
        (images?.filter(image => typeof image === 'string') as string[]) ?? [];
      if (!id) {
        return;
      }
      setLoading(true);

      if ((!cover || !cover[0]) && (!images || !images.length)) {
        setLoading(false);
        return;
      }

      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/photoAlbum/${id}`,
          file: cover[0],
        });
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
      }

      if (images && images.length) {
        const files = images.filter(image => typeof image !== 'string');
        const res = await uploadMedias({
          path: `/images/photoAlbum/${id}`,
          files,
        });
        if (res.length) {
          imagesUrls = [...imagesUrls, ...res.map(image => image.url)];
        }
      }

      if (imagesToDelete && imagesToDelete.length) {
        for (const url of imagesToDelete) {
          await deleteMedia({ url });
        }
      }

      await firestore.collection('/photoAlbums').doc(id).update({
        coverUrl,
        eventId,
        images: imagesUrls,
        updated_at: new Date().getTime(),
      });
      setLoading(false);
    },
    [deleteMedia, firestore, getMediaData, uploadMedia, uploadMedias],
  );

  const getPhotoAlbumById = useCallback(
    async id => {
      const album = (
        await firestore.collection('/photoAlbums').doc(id).get()
      ).data();

      return album as PhotoAlbum;
    },
    [firestore],
  );

  useEffect(() => {
    if (!photoAlbumId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'photoAlbums',
      };

      if (id) {
        query.doc = id;
      }
      firestore.get(query);
    }
  }, [id, firestore, photoAlbumId]);

  return {
    photoAlbum,
    updatePhotoAlbum,
    getPhotoAlbumById,
    registerPhotoAlbum,
    loading,
    isAdmin,
  };
};
