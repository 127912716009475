import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCompressedImage,
  useEditGuard,
  useGlobalStyles,
  useOrganization,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import OrganizationForm from '../OrganizationForm';
import { Form } from '../OrganizationForm/OrganizationForm';
import { LoadingCover, Spacer } from '../../atoms';

type Props = {
  slugOrId: string;
};

const EditOrganization: React.FC<Props> = ({ slugOrId }) => {
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, setCover, setCompressCover] = useCompressedImage();
  const [logo, setLogo, setCompressLogo] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { updateOrganization, loading, organization } = useOrganization({
    slugOrId,
  });
  useEditGuard(organization);
  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    if (!values.fullAddress) {
      setCustomErrors(prev => ({
        ...prev,
        fullAddress: t('form.invalidAddress'),
      }));
      return;
    }
    setCustomErrors({});
    updateOrganization({ cover, logo, ...values });
  };

  useEffect(() => {
    if (organization) {
      setLogo(organization?.logoUrl || []);
      setCover(organization?.coverUrl || []);
    }
  }, [organization, setCover, setLogo]);

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <PageCoverAndLogo.Form
        logo={logo}
        setLogo={setCompressLogo}
        cover={cover}
        setCover={setCompressCover}
      />
      {organization && (
        <OrganizationForm
          onSubmit={onSubmit}
          customErrors={customErrors}
          organization={organization}
        />
      )}
    </div>
  );
};

EditOrganization.displayName = 'EditOrganization';

export default React.memo(EditOrganization);
