import { Grid, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MUSIC_STYLES, REGIONS } from '../../../constants';
import { useMediaQuery } from '../../../hooks';
import { Organization } from '../../../store/types';
import { GridBreak, Spacer } from '../../atoms';
import { FormField, SelectAdmins } from '../../molecule';
import { AddressComponent } from '../../molecule/FormField/AddressField';

export type Form = Organization;

type Props = {
  organization?: Form;
  onSubmit: (values: Form) => void;
  customErrors?: Partial<Form>;
};

const OrganizationForm: React.FC<Props> = ({
  onSubmit,
  customErrors,
  organization,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const [admins, setAdmins] = useState<string[]>([]);
  const { handleSubmit, register, errors, control, setValue } = useForm<Form>({
    defaultValues: {
      region: '',
      address: '',
      city: '',
      admins: [],
      ...organization,
    },
  });

  const updateAddress = useCallback(
    ({ address, lat, lng, address_components }) => {
      address_components?.forEach((area: AddressComponent) => {
        if (
          REGIONS.find(
            R => R.id === area.long_name?.toLowerCase()?.replace(/ /g, '-'),
          )
        ) {
          setValue('region', area.long_name?.toLowerCase()?.replace(/ /g, '-'));
        }
        if (area?.types?.includes('administrative_area_level_3')) {
          setValue('city', area.long_name);
        }
      });
      if (!lat || !lng) {
        setValue('fullAddress', '');
        return;
      }
      setValue('fullAddress', JSON.stringify({ address, lat, lng }));
    },
    [setValue],
  );

  const selectAdmins = (adminsList: string[]) => {
    setAdmins(adminsList);
  };

  useEffect(() => {
    setValue('admins', admins);
  }, [admins, setValue]);

  return (
    <form onSubmit={handleSubmit(v => onSubmit({ ...v, admins }))}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormField
            name="name"
            label={t('organization.name')}
            register={register}
            required
            error={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectAdmins
            selectAdmins={selectAdmins}
            defaultAdmins={organization?.admins}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormField
            name="address"
            type="address"
            control={control}
            setValue={setValue}
            label={t('organization.address')}
            register={register}
            onChange={updateAddress}
            required
            error={
              errors.address?.message || (customErrors?.fullAddress as string)
            }
          />
          <FormField
            type="hidden"
            name="fullAddress"
            label=""
            register={register}
          />
          {!isMobile && <Spacer height="10px" />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="region"
            type="select"
            control={control}
            langDomain="shared.regions"
            disabled
            options={REGIONS.map(r => r.id)}
            label={t('organization.region')}
            register={register}
            required
            error={errors.region?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="city"
            type="city"
            control={control}
            setValue={setValue}
            disabled
            label={t('organization.city')}
            register={register}
            required
            error={errors.city?.message}
          />
          {!isMobile && <Spacer height="10px" />}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormField
            name="facebook"
            type="facebook"
            label={t('organization.linkFacebook')}
            register={register}
            error={errors.facebook?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormField
            name="instagram"
            type="instagram"
            label={t('organization.linkInstagram')}
            register={register}
            error={errors.instagram?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormField
            name="website"
            type="url"
            label={t('organization.website')}
            register={register}
            error={errors.website?.message}
          />
          {!isMobile && <Spacer height="10px" />}
        </Grid>
        <GridBreak />
        <Grid item xs={12} sm={6}>
          <FormField
            name="phone"
            label={t('organization.phone')}
            helperText={t('organization.reservedInformation')}
            register={register}
            required
            error={errors.phone?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            name="email"
            label={t('organization.email')}
            helperText={t('organization.reservedInformation')}
            register={register}
            required
            error={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="musicStyles"
            label={t('organization.musicStyles')}
            type="checkbox"
            langDomain="shared.musicStyles"
            options={MUSIC_STYLES}
            defaultOptions={organization?.musicStyles}
            register={register}
          />
        </Grid>

        <Grid item xs={12}>
          <FormField
            name="description"
            label={t('organization.description')}
            type="textarea"
            required
            control={control}
            setValue={setValue}
            register={register}
            error={customErrors?.description}
          />
          <Spacer height="20px" />
        </Grid>
        <Grid container justify="space-around">
          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
          >
            {organization ? t('organization.edit') : t('organization.goLive')}
          </Button>
        </Grid>
      </Grid>
      <Spacer height="40px" />
    </form>
  );
};

OrganizationForm.displayName = 'OrganizationForm';

export default React.memo(OrganizationForm);
