import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { RootState } from '../../store/reducers';
import { Artist } from '../../store/types';
import { THRESHOLD } from '../../constants';

type Props = {
  userId?: string;
  searchFor?: string;
  idsArts?: string[];
  musicStyles?: string[];
  page?: number;
};

type UseArtists = {
  artists: { [key: string]: Artist };
  artistsList: Artist[];
  filteredArtists: Artist[];
  loaded?: boolean;
  findArtists: (artIDs: string[]) => [string, Artist][];
  selectedArtists: Artist[];
  searchedArtists: (text: string) => Artist[];
  filteredArtistsByPage: { artists: Artist[]; totalResults: number };
};

export const useArtists = (props: Props = {}): UseArtists => {
  const { userId, searchFor, idsArts, musicStyles, page = 1 } = props;
  const artists = useSelector<RootState>(
    state => state.firestore?.data?.artists,
  ) as { [key: string]: Artist };
  const artistsList = useSelector<RootState>(
    state => state.firestore?.ordered?.artists,
  ) as Artist[] | undefined;

  const filteredArtists =
    artistsList?.filter(art => {
      if (searchFor && !musicStyles) {
        return art.name.toLowerCase().includes(searchFor.toLowerCase());
      }
      if (searchFor || musicStyles) {
        const textFilter =
          searchFor && art.name.toLowerCase().includes(searchFor.toLowerCase());
        let musicStyleFilter: boolean | undefined;
        if (musicStyles) {
          musicStyleFilter = art.musicStyles?.some(style =>
            musicStyles?.includes(style),
          );
        }
        return (
          (!searchFor || textFilter) &&
          (!musicStyles?.length || musicStyleFilter)
        );
      }
      if (userId) {
        return art.created_by === userId || art.admins?.includes(userId);
      }
      return true;
    }) ?? [];

  const filteredArtistsByPage = {
    artists:
      filteredArtists.slice(THRESHOLD * (page - 1), THRESHOLD * page) || [],
    totalResults: filteredArtists?.length || 0,
  };

  const selectedArtists =
    artistsList?.filter(art => {
      if (idsArts?.length && art.id) {
        return idsArts.includes(art?.id);
      }
      return false;
    }) ?? [];

  const findArtists = useCallback(
    (artIDs: string[]) =>
      Object.entries(artists).filter(([id, art]) => {
        if (artIDs.includes(id)) {
          return art;
        }
        return false;
      }),
    [artists],
  );

  const searchedArtists = useCallback(
    (text: string) => {
      return (
        artistsList?.filter(artist => {
          if (text) {
            return artist.name.toLowerCase().startsWith(text.toLowerCase());
          }
          return false;
        }) ?? []
      );
    },
    [artistsList],
  );

  const loaded = isLoaded(artists);

  useFirestoreConnect({
    collection: 'artists',
  });

  return {
    artists,
    loaded,
    artistsList: artistsList || [],
    filteredArtists,
    selectedArtists,
    findArtists,
    searchedArtists,
    filteredArtistsByPage,
  };
};
