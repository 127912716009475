import { Card, Box, Grid, TextField, Button, Divider } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { RegionSelect, DateSelector } from '../../molecule';
import useStyle from './style';

type Props = {};

const TopSearchWidget: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const history = useHistory();
  const style = useStyle({ isMobile });
  const globalStyle = useGlobalStyles();
  const [collection, setCollection] = useState('events');
  const [date, setDate] = useState<any>(null);
  const [searchFor, setSearchFor] = useState('');

  const onChange = useCallback((_, newVal) => {
    setSearchFor('');
    setDate(null);
    if (newVal) {
      setCollection(newVal);
    }
  }, []);

  const onSearch = useCallback(() => {
    history.push({
      pathname: `/${collection}`,
      state: {
        searchFor,
        date,
      },
    });
  }, [collection, date, history, searchFor]);

  return (
    <Card elevation={5} className={style.widgetWrapper}>
      <Box padding={2} paddingBottom={isMobile ? 1 : undefined}>
        <Box className={style.toggleGroupWrapper}>
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={collection}
            onChange={onChange}
            className={globalStyle.bgSecondaryLight}
          >
            <ToggleButton
              value="events"
              aria-label="events"
              className={style.toggleButton}
            >
              {t('general.events')}
            </ToggleButton>
            <ToggleButton
              value="venues"
              aria-label="clubs"
              className={style.toggleButton}
            >
              {t('general.venues')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <RegionSelect />
          </Grid>
          <Grid item xs={12} md={5}>
            {collection === 'events' ? (
              <DateSelector date={date} setDate={setDate} />
            ) : (
              <TextField
                variant="outlined"
                fullWidth
                value={searchFor}
                onChange={e => setSearchFor(e.target.value)}
                size="small"
                label={t('venue.nameVenue')}
              />
            )}
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                fullWidth
                onClick={onSearch}
                style={{ height: 40 }}
              >
                {t('general.search').slice(0, -3)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      {isMobile && (
        <>
          <Divider className={globalStyle.bgPrimary} />
          <Box padding={2} paddingTop={1}>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              fullWidth
              onClick={onSearch}
              size="small"
            >
              {t('general.search').slice(0, -3)}
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
};

TopSearchWidget.displayName = 'TopSearchWidget';

export default React.memo(TopSearchWidget);
