import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/molecule';
import { SearchCategories, SearchResults } from '../../components/organisms';
import { useSearch } from '../../hooks';
import {
  SearchCategoryTypes,
  SearchResults as SearchResultsT,
} from '../../store/types';
import { HtmlDecode } from '../../components/atoms';

type Props = {};
interface ParamType {
  category: SearchCategoryTypes;
}

const SearchPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { category } = useParams<ParamType>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchFor = query.get('q');
  const history = useHistory();
  const [collection, setCollection] = useState<SearchCategoryTypes>(category);
  const { searchFunction } = useSearch();
  const [results, setResults] = useState<SearchResultsT | undefined>([]);
  const [loaded, setLoaded] = useState(true);
  const searchCategories = useCallback(() => {
    return {
      all: {
        name: t('general.all'),
      },
      futureEvent: {
        name: t('general.futureEvents'),
      },
      pastEvent: {
        name: t('general.pastEvents'),
      },
      venue: {
        name: t('general.venue'),
      },
      organization: {
        name: t('general.organization'),
      },
      artist: {
        name: t('general.artist'),
      },
      news: {
        name: t('general.news'),
      },
      photoAlbum: {
        name: t('general.photoAlbums'),
      },
    };
  }, [t]);

  useEffect(() => {
    if (!Object.keys(searchCategories()).includes(category)) {
      history.push('/search');
    }
  }, [history, category, searchCategories]);

  useEffect(() => {
    if (searchFor) {
      const { results: r, loaded: l } = searchFunction(searchFor, category);
      setResults(r);
      setLoaded(l);
      setCollection(category);
    }
  }, [searchFor, category, searchFunction]);

  return (
    <PageWrapper boxed={false}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="h6" color="textSecondary" component="p">
              <HtmlDecode>{t('general.searchFor', { searchFor })}</HtmlDecode>
            </Typography>
            <Divider />
            <SearchCategories
              categories={searchCategories()}
              selectedCategory={collection}
              searchFor={searchFor}
            />
          </Grid>
          <Grid item md={9}>
            <SearchResults
              results={results}
              collection={collection}
              loaded={loaded}
            />
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default SearchPage;
