import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestList } from '../../../../store/types';
import GuestListForm from '../../GuestListForm';

type Form = GuestList;

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: Form) => void;
};

const CreateGuestListDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const closeAndSubmit = useCallback(
    v => {
      onClose();
      onSubmit(v);
    },
    [onClose, onSubmit],
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('guestList.createGuestList')}</DialogTitle>
      <DialogContent>
        <GuestListForm onSubmit={closeAndSubmit} />
      </DialogContent>
    </Dialog>
  );
};

CreateGuestListDialog.displayName = 'CreateGuestListDialog';

export default React.memo(CreateGuestListDialog);
