import { Box, Paper, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { EventCover, ResendVerificationEmailButton } from '..';
import {
  useAuthentication,
  useCompressedImage,
  useEvent,
  useGlobalStyles,
  useVenues,
  useVerification,
} from '../../../hooks';
import { Emoji, LoadingCover, Spacer } from '../../atoms';
import EventForm from '../EventForm';
import { Form } from '../EventForm/EventForm';

type Props = {};

const RegisterEvent: React.FC<Props> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const { verified } = useAuthentication();
  const { onEventCreationEmail } = useVerification();
  const { canCreateEvent } = useVenues({ myVenues: true });
  const [cover, , setCompressCover] = useCompressedImage();
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const { loading, registerEvent, registerEventWithGuestLists } = useEvent();

  const onSubmit = async (values: any, guestLists?: any) => {
    if (values.organizations) {
      values.organizations = Object.keys(values.organizations).reduce(
        (acc, curr) => {
          if (values.organizations[curr]) {
            acc[curr] = values.organizations[curr];
          }
          return acc;
        },
        {},
      );
    }
    if (values.artists) {
      values.artists = Object.keys(values.artists).reduce((acc, curr) => {
        if (values.artists[curr]) {
          acc[curr] = values.artists[curr];
        }
        return acc;
      }, {});
    }
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));

      return;
    }
    if (!values.date) {
      setCustomErrors(prev => ({
        ...prev,
        date: t('form.dateRequired'),
      }));

      return;
    }
    if (!values.endDate) {
      setCustomErrors(prev => ({
        ...prev,
        endDate: t('form.endDateRequired'),
      }));

      return;
    }

    if (
      dayjs(values.date, 'D MMMM YYYY @ HH:mm', 'it').valueOf() >
      dayjs(values.endDate, 'D MMMM YYYY @ HH:mm', 'it').valueOf()
    ) {
      setCustomErrors(prev => ({
        ...prev,
        endDate: t('form.wrongEndDate'),
      }));

      return;
    }
    if (!values.musicStyles.length) {
      setCustomErrors(prev => ({
        ...prev,
        musicStyles: [t('form.musicStyleRequired')],
      }));

      return;
    }

    setCustomErrors({});

    if (guestLists?.length) {
      const { slug } = await registerEventWithGuestLists({
        cover,
        initialGuestLists: guestLists,
        ...values,
      });
      onEventCreationEmail(slug);
      history.push(`/events/${slug}`);

      return;
    }

    const { slug } = await registerEvent({ cover, ...values });
    onEventCreationEmail(slug);
    history.push(`/events/${slug}`);
  };

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      {!verified ? (
        <ResendVerificationEmailButton />
      ) : !canCreateEvent ? (
        <Paper>
          <Box p={2} textAlign="center">
            <Typography variant="h2" color="error" align="center">
              <Emoji emoji="❗️" />
            </Typography>
            <Typography variant="h4">
              <b style={{ fontWeight: 900 }}>Ops...</b>
            </Typography>
            <Spacer height="20px" />
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {t('event.cantRegister')}
            </Typography>
          </Box>
        </Paper>
      ) : (
        <>
          <EventCover.Form setCover={setCompressCover} cover={cover} />
          <EventForm onSubmit={onSubmit} customErrors={customErrors} />
        </>
      )}
      <Spacer height="20px" />
    </div>
  );
};

RegisterEvent.displayName = 'RegisterEvent';

export default React.memo(RegisterEvent);
