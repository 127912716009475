import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/molecule';
import { EditPhotoAlbum } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};
const CreateEventScreen: React.FC<Props> = ({ match }) => {
  const { album: id } = match.params;
  const { t } = useTranslation();
  return (
    <PageWrapper title={t('photoAlbums.modifyAlbum')}>
      <EditPhotoAlbum id={id} />
    </PageWrapper>
  );
};

export default CreateEventScreen;
