import { Typography, Divider, Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication, useGlobalStyles } from '../../../hooks';
import { Spacer, Flex } from '../../atoms';

type Props = {};

const ReactivateProfile: React.FC<Props> = () => {
  const { logout, reactivateProfile } = useAuthentication();
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">{t('profile.reactivateProfile')}</Typography>
      <Divider />
      <Spacer height="30px" />
      <Typography variant="h2" align="center" color="error">
        {t('profile.deactivated')}
      </Typography>
      <Divider />
      <Spacer height="30px" />
      <Typography variant="body2" align="center" color="error">
        {t('profile.needsToReactivate')}
      </Typography>
      <Spacer height="30px" />
      <Divider />
      <Spacer height="30px" />
      <Flex flexDirection="column" height="130px" justifyContent="space-around">
        <Button variant="outlined" size="large" onClick={reactivateProfile}>
          {t('profile.reactivateMyProfile')}
        </Button>
        <Button
          variant="text"
          className={globalStyle.danger}
          size="small"
          onClick={logout}
        >
          {t('shared.logout')}
        </Button>
      </Flex>
    </>
  );
};

ReactivateProfile.displayName = 'ReactivateProfile';

export default React.memo(ReactivateProfile);
