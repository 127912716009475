import { FilterCheckboxType } from './../../../store/types/general';

export const sortCheckboxOptionsBySelectedValues = (
  options: FilterCheckboxType,
): FilterCheckboxType => {
  return Object.entries(options)
    .sort(([, x], [, y]) => {
      return x.value === y.value ? 0 : x.value ? -1 : 1;
    })
    .reduce((r, [key, values]) => ({ ...r, [key]: values }), {});
};
