import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { PageWrapper } from '../../components/molecule';
import { Spacer } from '../../components/atoms';
import { RegisterBox } from '../../components/organisms';
import { useAuthentication } from '../../hooks';

const Register = () => {
  const { isAuthenticated } = useAuthentication();
  const history = useHistory();
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/profile');
    }
  }, [history, isAuthenticated]);
  return (
    <PageWrapper title="Crea account">
      <Spacer height="50px" />
      <RegisterBox />
      <Spacer height="100px" />
    </PageWrapper>
  );
};

export default Register;
