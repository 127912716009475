import React, { Fragment } from 'react';
import {
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import defaultLogo from '../../../assets/img/defaultLogo.jpg';
import { useGlobalStyles } from '../../../hooks';
import useStyle from './styles';

type Props = {
  coverUrl?: string;
  logoUrl?: string;
  title?: string;
  subtitle?: string;
  renderLogo?: React.ReactNode;
  date?: string;
  renderTopIcons?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
  hideCover?: boolean;
  cardActions?: {
    type?: 'primary' | 'secondary';
    label?: string;
    onClick?: () => void;
    render?: () => React.ReactElement;
  }[];
  displayFlex?: boolean;
};

const CardWidget: React.FC<Props> = ({
  coverUrl,
  logoUrl,
  title,
  subtitle,
  renderLogo,
  renderTopIcons,
  onClick,
  children,
  cardActions,
  hideCover,
  displayFlex,
}) => {
  const style = useStyle();
  const globalStyle = useGlobalStyles();

  return (
    <Card
      variant="outlined"
      className={[style.card, displayFlex && style.container].join(' ')}
    >
      {!hideCover && (
        <CardMedia
          image={coverUrl || defaultCover}
          title={title}
          className={[
            style.cardMedia,
            renderTopIcons ? style.cardMediaWithIcons : '',
          ].join(' ')}
        />
      )}
      {renderLogo && <Box className={style.logo}>{renderLogo}</Box>}
      {renderTopIcons && <Box className={style.topIcons}>{renderTopIcons}</Box>}
      {logoUrl && !hideCover && (
        <CardMedia
          image={logoUrl || defaultLogo}
          title={title}
          className={style.logo}
        />
      )}
      {onClick ? (
        <CardActionArea
          onClick={onClick}
          className={[
            displayFlex && style.container,
            displayFlex && style.grow,
          ].join(' ')}
        >
          <CardHeader
            title={title}
            subheader={subtitle}
            className={[
              globalStyle.max100,
              displayFlex && style.alignSelf,
            ].join(' ')}
            titleTypographyProps={{
              variant: 'h6',
              className: globalStyle.ellipsis,
            }}
            subheaderTypographyProps={{
              variant: 'body2',
              className: globalStyle.ellipsis,
            }}
          />
          <CardContent
            className={[style.content, displayFlex && style.grow].join(' ')}
          >
            {children}
          </CardContent>
        </CardActionArea>
      ) : (
        <>
          <CardHeader
            title={title}
            subheader={subtitle}
            className={[
              globalStyle.max100,
              displayFlex && style.alignSelf,
            ].join(' ')}
            titleTypographyProps={{
              variant: 'h6',
              className: globalStyle.ellipsis,
            }}
            subheaderTypographyProps={{
              variant: 'body2',
              className: globalStyle.ellipsis,
            }}
          />
          <CardContent
            className={[style.content, displayFlex && style.grow].join(' ')}
          >
            {children}
          </CardContent>
        </>
      )}
      {cardActions && (
        <CardActions>
          {cardActions.map((action, i) => (
            <Fragment key={`action-${i}`}>
              {action.render ? (
                action.render()
              ) : (
                <Button
                  key={`action-${i}`}
                  size="small"
                  variant={action.type === 'primary' ? 'contained' : 'outlined'}
                  color="primary"
                  disableElevation
                  fullWidth
                  onClick={action.onClick}
                  className={style.button}
                >
                  {action.label}
                </Button>
              )}
            </Fragment>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

CardWidget.displayName = 'CardWidget';

export default React.memo(CardWidget);
