import { Grid } from '@material-ui/core';
import React from 'react';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import { useGlobalStyles } from '../../../hooks';
import { useStyle } from './styles';

type Props = {
  cover?: string;
  renderActions?: (containerClassName?: string) => false | JSX.Element;
};

const PhotoAlbumCover: React.FC<Props> = ({ cover, renderActions }) => {
  const globalStyles = useGlobalStyles();
  const style = useStyle();
  return (
    <Grid
      item
      md={12}
      className={globalStyles.relative}
      style={{ height: 400 }}
    >
      <img src={cover || defaultCover} className={style.cover} alt="cover" />
      {renderActions && renderActions(style.ctaSection)}
    </Grid>
  );
};

PhotoAlbumCover.displayName = 'PhotoAlbumCover';

export default React.memo(PhotoAlbumCover);
