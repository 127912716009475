import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Card, Typography, Button, Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Spacer } from '../../atoms';
import { CardWidget, FormField, SearchBar } from '../../molecule';
import useStyle from '../EventForm/styles';
import {
  useGlobalStyles,
  useMediaQuery,
  useOrganizations,
} from '../../../hooks';
import { ORGANIZATION_RULES } from '../../../constants';
import { Event } from '../../../store/types';
import { useToggleState } from '../../../hooks/ui/useToggleState';

type Props = {
  register: any;
  setSelectedOrgs: any;
  selectedOrgs?: Event['organizations'];
};

const SelectOrganizations: React.FC<Props> = ({
  register,
  selectedOrgs,
  setSelectedOrgs,
}) => {
  const style = useStyle();
  const { isMobile } = useMediaQuery();
  const [open, toggleOpen] = useToggleState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { relative, flexCenter } = useGlobalStyles();
  const [searchFor, setSearchFor] = useState('');
  const idsOrgs =
    selectedOrgs && Object.keys(selectedOrgs).filter(key => selectedOrgs[key]);
  const { filteredOrganizations, selectedOrgnaizations } = useOrganizations({
    searchFor,
    idsOrgs,
  });

  const [limit, setLimit] = useState<number>(3);
  const { t } = useTranslation();

  const sortedOrganizations = Array.from(
    new Set([...selectedOrgnaizations, ...filteredOrganizations]),
  );

  const handleChange = useCallback(
    (val: Event['organizations']) => {
      val && Object.entries(val)[0][1] === 'canEdit' && setShowWarning(true);

      setSelectedOrgs((s: Event['organizations']) => ({
        ...s,
        ...val,
      }));
    },
    [setSelectedOrgs],
  );

  const renderOrganizationsItems = useMemo(() => {
    return sortedOrganizations.slice(0, limit).map(org => {
      return (
        <Grid item xs={12} sm={6} md={4} key={org.id} className={relative}>
          <Card variant="outlined" style={{ height: '100%' }}>
            <CardWidget
              title={org.name}
              subtitle={org.city}
              coverUrl={org.coverUrl}
              logoUrl={org.logoUrl}
              hideCover={isMobile}
            >
              <FormField
                fieldClassName={style.FormField}
                name={'organizations'}
                defaultGroups={selectedOrgs}
                label={t('general.definePermission')}
                type="radiogroup"
                langDomain="shared.organizationsRules"
                onChange={handleChange}
                allowClear
                groups={[
                  {
                    id: `${org.id}`,
                    label: t('general.definePermission'),
                    options: ORGANIZATION_RULES,
                  },
                ]}
                register={register}
              />
            </CardWidget>
          </Card>
        </Grid>
      );
    });
  }, [
    sortedOrganizations,
    limit,
    relative,
    isMobile,
    style.FormField,
    selectedOrgs,
    t,
    handleChange,
    register,
  ]);

  const renderOrganizations = useMemo(() => {
    return (
      <Grid container spacing={2}>
        {renderOrganizationsItems}
        <Grid container justify="center">
          {showWarning && (
            <Typography align="center" color="error">
              {t('event.canEditOrg')}
            </Typography>
          )}
        </Grid>
        {limit < sortedOrganizations.length && (
          <Grid item xs={12} className={flexCenter}>
            <Button
              size="small"
              onClick={() => setLimit(limit + 3)}
              variant="outlined"
            >
              {t('organization.showMore')}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }, [
    renderOrganizationsItems,
    showWarning,
    limit,
    sortedOrganizations.length,
    flexCenter,
    t,
  ]);

  return (
    <>
      <>
        <Button onClick={toggleOpen}>
          {t('general.selectOrganizersInfo')}{' '}
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={open}>
          <Grid item xs={12} sm={12} md={12}>
            <Spacer height="5px" />
            <SearchBar
              label={t('organization.search')}
              searchText={searchFor}
              setSearchText={setSearchFor}
            />
            <Spacer height="15px" />
          </Grid>
          {sortedOrganizations.length ? (
            renderOrganizations
          ) : (
              <Typography variant="h4" align="center">
                {t('organization.notFound')}
              </Typography>
            )}

          <Spacer height="20px" />
        </Collapse>
      </>
    </>
  );
};

SelectOrganizations.displayName = 'SelectOrganizations';

export default React.memo(SelectOrganizations);
