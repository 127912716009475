import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
    '& .MuiCardContent-root': {
      width: '100%',
      overflow: 'hidden',
    },
  },
  cover: {
    width: 120,
    height: 120,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 120,
  },
});
