import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useGlobalStyles, usePhotoAlbums } from '../../../hooks';
import { LabeledBox } from '../../molecule';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import useStyle from './style';

type Props = {};

const MyPhotoAlbums: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const style = useStyle();
  const { populatedAlbumsListByUser } = usePhotoAlbums({
    myAlbums: true,
  });

  return (
    <>
      {populatedAlbumsListByUser.length ? (
        <LabeledBox label={t('profile.myUploadedPhotoAlbums')}>
          <Grid container spacing={2}>
            {populatedAlbumsListByUser.map(album => (
              <Grid item md={6} xs={12} key={album.id}>
                <Card variant="outlined">
                  <CardActionArea
                    onClick={() => history.push(`photo-album/${album.id}`)}
                    className={globalStyle.flexStart}
                  >
                    <CardMedia
                      image={album.coverUrl || defaultCover}
                      className={style.cover}
                    />
                    <CardContent>
                      <Typography>
                        <b>{t('event.name')}:</b>{' '}
                        {album.eventId && album?.event?.name}
                      </Typography>
                      <Typography>
                        <b>{t('event.date')}:</b>{' '}
                        {album.eventId &&
                          dayjs(album?.event?.date).format('DD MMM YYYY')}
                      </Typography>
                      <Typography>
                        <b>{t('shared.photos')}:</b> {album.images?.length}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </LabeledBox>
      ) : null}
    </>
  );
};

MyPhotoAlbums.displayName = 'MyPhotoAlbums';

export default React.memo(MyPhotoAlbums);
