import React, { useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';

type Props = {
  control: any;
  name: string;
  error?: string | null;
  required?: boolean;
  setValue: any;
  register: any;
  fieldClassName?: string;
  initialValue?: string;
};

const EditorField: React.FC<Props> = ({
  control,
  name,
  error,
  required,
  register,
  setValue,
  fieldClassName,
  initialValue,
}) => {
  const onEditorChange = useCallback(
    value => {
      setValue(value);
    },
    [setValue],
  );

  return (
    <>
      <Controller
        name={name}
        control={control}
        onEditorChange={onEditorChange}
        required={required}
        className={fieldClassName}
        as={
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            ref={register}
            // onBlur={test}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist paste autolink lists link charmap print preview anchor',
                'searchreplace visualblocks fullscreen spellchecker',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | spellchecker help',
            }}
            value={initialValue}
          />
        }
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </>
  );
};

EditorField.displayName = 'EditorField';

export default React.memo(EditorField);
