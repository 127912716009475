import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Snackbar,
} from '@material-ui/core';
import { firestore as fire } from 'firebase';
import { useHistory } from 'react-router-dom';
import { ReportRounded } from '@material-ui/icons';
import { useFirestore } from 'react-redux-firebase';
import { useAuthentication } from '../../../hooks';
import { Container } from './styles';

type Props = {
  item: any;
  chatReport?: Function;
  category: 'news' | 'events' | 'users_meta' | 'clubs';
};

const ReportAbuse: React.FC<Props> = ({ item, chatReport, category }) => {
  const firestore = useFirestore();
  const history = useHistory();
  const auth = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [text, setText] = React.useState('');
  const [abuseType, setAbuseType] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [submitResult, setSubmitResult] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTextInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setText(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseIcon = () => {
    setOpenDialog(false);
  };

  const submitReport = async () => {
    if (text === '' || abuseType === '') {
      setOpenSnackbar(true);
      setSubmitResult('Compila tutti i campi');
    } else {
      try {
        const date = new Date().getTime();
        const userId = auth.userId;

        /**
         * Check if user is logged, then add report to news or event array by pushing his own uid
         * if user's uid is contained in doc, the user will not see this doc.
         * Same logic for the chat, but data are stored in user 'reported_user' array
         */
        if (userId) {
          let updateAbuse:
            | {
                reported_by: fire.FieldValue;
              }
            | {
                reported_user: fire.FieldValue;
              };

          if (category !== 'users_meta') {
            updateAbuse = {
              reported_by: firestore.FieldValue.arrayUnion(userId),
            };
          } else {
            updateAbuse = {
              reported_user: firestore.FieldValue.arrayUnion(
                item.reported_user,
              ),
            };
          }

          firestore.collection(category).doc(item.id).update(updateAbuse);

          if (category === 'events') {
            firestore
              .collection('photoAlbums')
              .where('eventId', '==', item.id)
              .get()
              .then(snap => {
                const docs = snap.docs;
                if (docs.length) {
                  docs.forEach(doc => {
                    firestore
                      .collection('photoAlbums')
                      .doc(doc.id)
                      .update(updateAbuse);
                  });
                }
              })
              .catch(err => {
                console.log(err);
              });
          }

          await firestore
            .collection('abuse_reports')
            .add({
              date_report: date,
              reported_by: userId,
              item: item,
              abuse_type: abuseType,
              description: text,
            })
            .then(() => {
              setSubmitResult('Segnalazione inviata');
              setText('');
              setAbuseType('');
              setOpenDialog(false);
              setOpenSnackbar(true);

              if (chatReport) {
                chatReport();
              }
              history.goBack();
            });
        } else {
          setOpenSnackbar(true);
          setSubmitResult('Ops, qualcosa è andato storto');
        }
      } catch (err) {
        console.log('error: ' + err);
        setOpenSnackbar(true);
        setSubmitResult('Ops, qualcosa è andato storto');
      }
    }
  };

  return (
    <Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={submitResult}
      />

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseIcon}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Descrivi il tipo di abuso'}</DialogTitle>
        <DialogContent>
          <RadioGroup
            row
            aria-label="gender"
            name="row-radio-buttons-group"
            onChange={e => setAbuseType(e.target.value)}
          >
            <FormControlLabel
              control={<Radio />}
              label="Nudità"
              value="nudità"
            />
            <FormControlLabel control={<Radio />} label="Droga" value="droga" />
            <FormControlLabel
              control={<Radio />}
              label="Violenza"
              value="violenza"
            />
            <FormControlLabel control={<Radio />} label="Altro" value="altro" />
          </RadioGroup>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text"
          >
            Aggiungi una descrizione per aiutarci a capire il tipo di abuso.
          </DialogContentText>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            rowsMax={4}
            rows={1}
            value={text}
            onChange={handleChangeTextInput}
            fullWidth
            placeholder="Aggiungi descrizione*"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseIcon}>annulla</Button>
          <Button
            onClick={submitReport}
            color={text === '' || abuseType === '' ? 'secondary' : 'primary'}
          >
            invia
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        id="button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ReportRounded id="icon" />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            if (auth.userId) {
              handleOpenDialog();
              handleClose();
            } else {
              history.push('/contact');
              handleClose();
            }
          }}
        >
          Segnala abuso
        </MenuItem>
        <MenuItem onClick={handleClose}>Chiudi</MenuItem>
      </Menu>
    </Container>
  );
};

export default ReportAbuse;
