import React, { useCallback } from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Typography,
} from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Spacer } from '../../atoms';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { useCoverStyle, useStyle } from './styles';

type Props = {
  setImages: (images: File[]) => void;
  images?: (File | string)[];
};

const Form: React.FC<Props> = ({ images, setImages }) => {
  const { t } = useTranslation();
  const [open, toggleDialog, , toggleOff] = useToggleState(false);
  const style = useStyle();

  const dropzoneCoverClasses = useCoverStyle({});

  const addImages = useCallback(
    (files: any) => {
      toggleOff();
      const filteredImages = files.filter(
        (file: any) => !images?.find((img: any) => img.name === file.name),
      );
      setImages([...(images || []), ...filteredImages]);
    },
    [images, setImages, toggleOff],
  );

  return (
    <>
      {!images?.length ? (
        <Typography align="center">
          {t('photoGallery.noImages')}{' '}
          <Typography component="span" color="primary" onClick={toggleDialog}>
            {t('photoGallery.clickHereToAdd')}
          </Typography>
        </Typography>
      ) : (
          <Spacer height="30px" />
        )}
      <Fab
        onClick={toggleDialog}
        size="small"
        className={style.fab}
        color="primary"
      >
        <AddAPhoto />
      </Fab>
      <Dialog open={open} onClose={toggleDialog} maxWidth="lg">
        <DialogTitle>{t('photoGallery.addPhotos')}</DialogTitle>
        <DialogContent className={style.dialogContent}>
          <DropzoneAreaBase
            classes={dropzoneCoverClasses}
            fileObjects={[]}
            filesLimit={1000}
            dropzoneText={t('photoGallery.uploadPhotos')}
            getFileLimitExceedMessage={limit =>
              `Hai superato il limite massimo di foto che puoi caricare in quest'area: ${limit}`
            }
            maxFileSize={30000000}
            acceptedFiles={['image/*']}
            onDrop={addImages}
          />
          <Spacer height="30px" />
        </DialogContent>
      </Dialog>
    </>
  );
};

Form.displayName = 'PhotoAlbumCoverForm';

export default React.memo(Form);
