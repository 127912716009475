import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ThumbDownTwoTone, ThumbUpTwoTone } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback } from 'react';
import {
  TillDateNavigator,
  TillDatePageWrapper,
} from '../../components/molecule';
import { useGlobalStyles } from '../../hooks';
import useDateRoomPlayers from '../../hooks/dateRooms/useDateRoomPlayers';
import useMyDateRoomPlayer from '../../hooks/dateRooms/useMyDateRoomPlayer';
import useMyDateRooms from '../../hooks/dateRooms/useMyDateRooms';

const useStyle = makeStyles(theme => ({
  navigator: {
    backgroundColor: '#f8f7f4',
    width: '100%',
    position: 'fixed',
    zIndex: 99,
  },
  toggleButton: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const TillDateLikesScreen = () => {
  const { selectedRoom } = useMyDateRooms();
  const style = useStyle();
  const globalStyle = useGlobalStyles();
  const {
    liked,
    disliked,
    toggleLikes,
    toggleDislikes,
    myPlayerProfile,
  } = useMyDateRoomPlayer();
  const { dateRoomPlayers } = useDateRoomPlayers({
    dateRoomId: selectedRoom?.id,
  });

  const handleToggleLike = useCallback(
    (_, v, playerId) => {
      if (v === 'like') {
        toggleLikes(playerId);
        return;
      }
      if (v === 'dislike') {
        toggleDislikes(playerId);
        return;
      }
    },
    [toggleDislikes, toggleLikes],
  );

  const renderLike = useCallback(
    (like: string) => {
      const likeProfile = dateRoomPlayers[like];
      if (likeProfile) {
        return (
          <React.Fragment key={like}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt={likeProfile.nickname}
                  src={likeProfile.profilePicture}
                />
              </ListItemAvatar>
              <ListItemText primary={likeProfile.nickname} />
              <ListItemSecondaryAction>
                <ToggleButtonGroup
                  exclusive
                  size="small"
                  color="primary"
                  value={Array.from(liked).includes(like) ? 'like' : 'dislike'}
                  onChange={(e, v) => handleToggleLike(e, v, like)}
                  className={globalStyle.bgSecondaryLight}
                >
                  <ToggleButton
                    value="like"
                    aria-label="like"
                    className={style.toggleButton}
                  >
                    <ThumbUpTwoTone />
                  </ToggleButton>
                  <ToggleButton
                    value="dislike"
                    aria-label="dislike"
                    className={style.toggleButton}
                  >
                    <ThumbDownTwoTone />
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" />
          </React.Fragment>
        );
      }
    },
    [
      dateRoomPlayers,
      globalStyle.bgSecondaryLight,
      handleToggleLike,
      liked,
      style.toggleButton,
    ],
  );

  if (!myPlayerProfile) {
    return null;
  }

  return (
    <TillDatePageWrapper unbox>
      <Box p={2} className={style.navigator}>
        <TillDateNavigator selectedRoom={selectedRoom} />
      </Box>
      <Box paddingTop={7}>
        <List>
          {[...Array.from(liked), ...Array.from(disliked)].map(like =>
            renderLike(like),
          )}
        </List>
      </Box>
    </TillDatePageWrapper>
  );
};

export default TillDateLikesScreen;
