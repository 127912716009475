import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonHeader, IonToolbar } from '@ionic/react';
import { Box, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { CloseRounded, KeyboardArrowLeftRounded } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '../../atoms';
import { useGlobalStyles } from '../../../hooks';
import logo from '../../../assets/img/till_date.png';
import {
  addToInAppHistory,
  updateInAppHistory,
} from '../../../store/actions/configurations/inAppHistory';
import { inAppHistorySelector } from '../../../store/selectors';
import useStyle from './style';

type Props = {
  canGoBack?: boolean;
};

const DashboardHeader: React.FC<Props> = ({ canGoBack }) => {
  const inAppHistory = useSelector(inAppHistorySelector);
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false);
  const { flex } = useGlobalStyles();
  const style = useStyle();
  const history = useHistory();
  const { pathname } = useLocation();
  const goBack = useCallback(() => {
    const newHistory = [...inAppHistory];
    newHistory.splice(-2);
    dispatch(updateInAppHistory(newHistory));
    history.goBack();
  }, [dispatch, history, inAppHistory]);

  useEffect(() => {
    if (pathname && !added) {
      setAdded(true);
      dispatch(addToInAppHistory(pathname));
    }
  }, [pathname, dispatch, added]);

  const renderToolbarContent = useMemo(() => {
    return (
      <Box paddingX={1}>
        <Flex justifyContent="center">
          <Box paddingX={1} className={flex} alignItems="center">
            {canGoBack && (
              <IconButton onClick={goBack} className={style.backBtn}>
                <KeyboardArrowLeftRounded />
              </IconButton>
            )}
            <Box flexGrow="1" textAlign="center">
              <img src={logo} alt="Tilllate World" width={180} />
            </Box>
            <IconButton
              onClick={() => history.push('/')}
              className={style.closeBtn}
            >
              <CloseRounded />
            </IconButton>
          </Box>
        </Flex>
      </Box>
    );
  }, [canGoBack, goBack, style.backBtn, style.closeBtn, flex, history]);
  return (
    <Box className={style.wrapper}>
      <IonHeader className={style.mobileHeader}>
        <IonToolbar style={{ height: 'calc(env(safe-area-inset-top) + 62px)' }}>
          <>{renderToolbarContent}</>
        </IonToolbar>
      </IonHeader>
    </Box>
  );
};

DashboardHeader.displayName = 'DashboardHeader';

export default React.memo(DashboardHeader);
