import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { Spacer } from '../../atoms';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import defaultLogo from '../../../assets/img/defaultLogo.jpg';
import { useStyle } from './styles';
import { ReportAbuse } from '../../molecule';

type Props = {
  cover?: string;
  logo?: string;
  renderActions?: (containerClassName?: string) => false | JSX.Element;
  venueId?: string | undefined;
};

const PageCoverAndLogo: React.FC<Props> = ({
  logo,
  cover,
  renderActions,
  venueId,
}) => {
  const { isMobile } = useMediaQuery();
  const globalStyles = useGlobalStyles();
  const style = useStyle();
  useEffect(() => {
    let image = document.getElementById('cover');
    let logo = document.getElementById('logo');
    logo?.addEventListener('error', error => {
      image?.setAttribute('src', defaultLogo);
    });
    image?.addEventListener('error', error => {
      image?.setAttribute('src', defaultCover);
    });
    return () => {};
  }, []);
  return (
    <Grid item md={12} className={globalStyles.relative}>
      <ReportAbuse
        category="clubs"
        item={{ logo: logo, cover: cover, id: venueId }}
      />
      <img
        src={cover || defaultCover}
        className={isMobile ? style.coverMobile : style.cover}
        alt="cover"
        id="cover"
      />
      <img
        src={logo || defaultLogo}
        className={style.logo}
        alt="logo"
        id="logo"
      />
      {renderActions &&
        renderActions(isMobile ? style.ctaSectionMobile : style.ctaSection)}
      <Spacer height="30px" />
    </Grid>
  );
};

PageCoverAndLogo.displayName = 'PageCoverAndLogo';

export default React.memo(PageCoverAndLogo);
