import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { EditOrganization } from '../../components/organisms';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const EditOrganizationScreen: React.FC<Props> = ({ match }) => {
  const { organization: slugOrId } = match.params;
  return (
    <PageWrapper title="Modifica Organizzazione">
      {slugOrId && <EditOrganization slugOrId={slugOrId} />}
    </PageWrapper>
  );
};

export default EditOrganizationScreen;
