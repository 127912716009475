import React from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import { useAuthentication } from '../../../hooks';
import Login from '../../../views/Login';
import { Roles } from '../../../store/types';

type Props = RouteProps & {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  role?: Roles;
};

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  role,
  ...rest
}) => {
  const { isAuthenticated, loaded, profile } = useAuthentication();
  if (!loaded) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          if (role) {
            if (
              profile?.roles?.includes(role) ||
              profile?.roles?.includes('admin')
            ) {
              return <Component {...props} />;
            }
            return <Redirect to="/home" />;
          }
          return <Component {...props} />;
        }
        return <Login />;
      }}
    />
  );
};

export default PrivateRoute;
