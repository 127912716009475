import { makeStyles } from '@material-ui/core';

export default makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '0px',
  },
  listItem: {
    flex: '0 1 32%',
    paddingTop: '0px',
    paddingBottom: '0px',
    border: '1px solid #fdb845',
    borderRadius: '2px',
    margin: '4px',
  },
  listItemText: {
    margin: '0px',
  },
});
