import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import { useMediaQuery } from '../../../hooks';
import useNotifications from '../../../hooks/notifications/useNotifications';

type Props = ToastContainerProps;

const NotificationsContainer: React.FC<Props> = options => {
  const { isMobile } = useMediaQuery();
  useNotifications();

  return (
    <ToastContainer
      position={isMobile ? 'top-center' : 'top-right'}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={options.onClick ? false : true}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      {...options}
    />
  );
};

NotificationsContainer.displayName = 'NotificationsContainer';

export default React.memo(NotificationsContainer);
