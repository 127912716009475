import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../../assets/img/logo.png';

type Props = {
  className?: string;
  width?: string;
  height?: string;
  style?: any;
  logo?: string;
  to?: string;
};

const StyledLogo = styled.img<Props>`
  width: ${({ width = '200px' }) => width};
  height: ${({ height = 'auto' }) => height};
`;

const Logo: React.FC<Props> = ({
  width,
  height,
  logo: logoProp,
  to = '/',
  className,
}) => {
  return (
    <Link to={to}>
      <StyledLogo
        className={className}
        src={logoProp || logo}
        alt="Tilllate.world"
        width={width}
        height={height}
      />
    </Link>
  );
};

Logo.displayName = 'Logo';

export default React.memo(Logo);
