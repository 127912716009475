import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { Box } from '@material-ui/core';
import { useMemo } from 'react';
import { Container } from '../../atoms';
import DashboardHeader from './DashboardHeader';

type Props = {
  children: any;
  entrance?: boolean;
  canGoBack?: boolean;
  hideFooter?: boolean;
  unbox?: boolean;
};

const TillDatePageWrapper: React.FC<Props> = ({
  children,
  canGoBack,
  unbox,
}) => {
  const renderContent = useMemo(
    () => <Box padding={unbox ? 0 : 2}>{children}</Box>,
    [children, unbox],
  );

  return (
    <IonPage>
      <DashboardHeader canGoBack={canGoBack} />
      <IonContent scrollEvents>
        <Container>{renderContent}</Container>
      </IonContent>
      {/* {!entrance && !hideFooter && renderFooter} */}
    </IonPage>
  );
};

TillDatePageWrapper.displayName = 'TillDatePageWrapper';

export default React.memo(TillDatePageWrapper);
