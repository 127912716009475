import dayjs from 'dayjs';

import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { PdfGuestListData } from '../types/PdfGuestListData';
import { styles } from '../../../../../utils/pdf';
import {
  drawVerticalLine,
  drawHorizontalLine,
  createSomeEmptySpace,
  createText,
  createGapBetweenColumns,
  createImage,
} from '../../../../../utils/pdf';

import {
  createGuestListTable,
  createOrganizations,
  createLinesFromBenefitsOrConditions,
} from './createData';

export const createDocumentDefinition = (
  pdfData: PdfGuestListData,
): TDocumentDefinitions => {
  const date = dayjs(pdfData.date).format('DD MMM YYYY, HH:MM');
  const benefits = createLinesFromBenefitsOrConditions(pdfData?.benefits);
  const conditions = createLinesFromBenefitsOrConditions(pdfData?.conditions);

  return {
    pageSize: 'A4',
    info: {
      title: pdfData.guestListName + ' Guest List',
      subject: pdfData.guestListName,
      keywords: pdfData.guestListName + date + pdfData.venueName,
    },
    content: [
      {
        columns: [
          {
            stack: [
              createText(pdfData.clubInfo, ['size10', 'bold', 'underlined']),
              createSomeEmptySpace(['size5']),
              createImage(pdfData?.venueLogoBase64, 50, 50),
              createSomeEmptySpace(['size5']),
              createText(pdfData.venueName, ['size8', 'italics']),
              createSomeEmptySpace(['size5']),
              drawHorizontalLine(90, 1),
              createSomeEmptySpace(['size5']),
              createText(pdfData.organizationsInfo, [
                'size10',
                'bold',
                'underlined',
              ]),
              createSomeEmptySpace(['size5']),
              createOrganizations(pdfData.organizations),
            ],
            width: 100,
          },
          createGapBetweenColumns(10),
          {
            stack: [
              {
                alignment: 'center',
                columns: [
                  {
                    stack: [
                      createImage(pdfData?.eventCoverBase64, 250),
                      createSomeEmptySpace(['size10']),
                    ],
                    width: 410,
                  },
                ],
              },
              {
                text: [
                  createText(`${pdfData.eventName}  `, [
                    'size30',
                    'bold',
                    'center',
                  ]),
                  createText(date, ['size20', 'bold', 'center']),
                ],
              },
              createSomeEmptySpace(['size10']),
              createText(`${pdfData.guestListName} Guest List`, [
                'size20',
                'bold',
                'underlined',
                'italics',
              ]),
              createSomeEmptySpace(['size5']),
              {
                alignment: 'center',
                columns: [
                  createText(pdfData.benefitText, [
                    'size15',
                    'bold',
                    'underlined',
                  ]),
                  createText(pdfData.conditionsText, [
                    'size15',
                    'bold',
                    'underlined',
                  ]),
                ],
              },
              createSomeEmptySpace(['size5']),
              {
                alignment: 'center',
                columns: [
                  createText(benefits, ['size11']),
                  createText(conditions, ['size11']),
                ],
              },
            ],
            width: 410,
          },
        ],
      },
      createSomeEmptySpace(['size10']),
      drawHorizontalLine(520, 1),
      createSomeEmptySpace(['size5']),
      {
        alignment: 'center',
        stack: [
          {
            columns: [
              {
                stack: [
                  {
                    text: pdfData.registeringPerson,
                    style: ['size10', 'bold'],
                  },
                ],
                width: 120,
              },
              drawVerticalLine(15, 1.5),
              {
                stack: [{ text: pdfData.guests, style: ['size10', 'bold'] }],
                width: 370,
              },
            ],
          },
        ],
      },
      createSomeEmptySpace(['size5']),
      drawHorizontalLine(520, 0.5),
      drawHorizontalLine(520, 0.5),
      createGuestListTable(pdfData.guestListRegistration),
    ],
    styles,
  };
};
