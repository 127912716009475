import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { NewsCategory } from '../../../store/types';
import { FormField } from '../../molecule';
import { Spacer } from '../../atoms';

export type Form = NewsCategory;

type Props = {
  newsCategory?: Form;
  onSubmit: (values: Form) => void;
  customErrors?: Partial<Form>;
};

const NewsCategoryForm: React.FC<Props> = ({
  onSubmit,
  customErrors,
  newsCategory,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, errors, control, setValue } = useForm<Form>({
    defaultValues: newsCategory,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            name="name"
            label={t('newsCategory.name')}
            register={register}
            required
            error={errors.name?.message}
          />
        </Grid>
        <Spacer height="10px" />
        <Grid item xs={12}>
          <FormField
            name="description"
            label={t('newsCategory.description')}
            type="textarea"
            required
            control={control}
            setValue={setValue}
            register={register}
            error={customErrors?.description}
          />
          <Spacer height="20px" />
        </Grid>
        <Grid container justify="space-around">
          <Button type="submit" variant="outlined" color="primary">
            {t('newsCategory.goLive')}
          </Button>
        </Grid>
      </Grid>
      <Spacer height="20px" />
    </form>
  );
};

NewsCategoryForm.displayName = 'NewsCategoryForm';

export default React.memo(NewsCategoryForm);
