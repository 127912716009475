import React, { useCallback } from 'react';
import { Button, Chip } from '@material-ui/core';
import { HowToReg, ShareOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Event } from '../../../store/types';
import { CardListWidget, DateWidget, ShareButton } from '..';
import {
  useGlobalStyles,
  useGuestListRegistrations,
  useGuestLists,
  useVenues,
} from '../../../hooks';

type Props = {
  event: Event;
  label?: string;
};

const Item: React.FC<Props> = ({ label, event }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const { allGuestListsList } = useGuestLists();
  const { guestListRegistrationsByUser } = useGuestListRegistrations({
    myRegistrations: true,
  });
  const { venues } = useVenues();
  const { slug } = event || {};
  const goTo = useCallback(() => history.push(`/events/${slug}`), [
    history,
    slug,
  ]);
  return (
    <CardListWidget
      label={label}
      onClick={goTo}
      renderLogo={<DateWidget date={event.date} hideTime />}
      coverUrl={event.coverUrl}
      title={event.name}
      subtitle={`
                ${venues[event.venueId]?.name}, ${venues[event.venueId]?.city}`}
      cardActions={[
        {
          type: 'primary',
          label: t('event.viewEvent'),
          onClick: goTo,
        },
        {
          type: 'secondary',
          label: t('event.viewVenue'),
          onClick: () => history.push(`/venues/${venues[event?.venueId].slug}`),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tilllate.world/events/${event.slug}`}
              renderButton={openPopOver => (
                <Button
                  className={globalStyle.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={e => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
      renderTopIcons={
        allGuestListsList.some(gl => gl.eventId === event.id) && (
          <CardListWidget.Icon
            icon={<HowToReg />}
            label={t('event.registerToGuestList')}
            activeLabel={t('event.registeredToGuestList')}
            active={
              !!guestListRegistrationsByUser.find(gl => gl.eventId === event.id)
            }
          />
        )
      }
    >
      {event.musicStyles?.length ? (
        <>
          {event.musicStyles.slice(0, 2).map(music => (
            <Chip
              component="span"
              key={`${event.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
            />
          ))}
          {event.musicStyles.length > 2 && (
            <Chip
              variant="outlined"
              size="small"
              component="span"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
              label={`+ ${event.musicStyles.length - 2}`}
            />
          )}
        </>
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(' ')}
        />
      )}
    </CardListWidget>
  );
};

Item.displayName = 'Item';

export default React.memo(Item);
