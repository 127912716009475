import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'react-redux-firebase';
import { useAuthentication } from '../auth';
import { useSendEmail } from '../email';
import { useSendSms } from '../sms/useSendSms';
type Props = {
  id?: string;
};
export const useVerification = (props: Props = {}) => {
  const firestore = useFirestore();
  const { id } = props;
  const { updateProfile, userId, profile }: any = useAuthentication();
  const [otp, setOtp] = useState<number | null>(null);
  const { sendEmail } = useSendEmail();
  const { sendSms } = useSendSms();
  const { t } = useTranslation();

  const verifyEmail = useCallback(async () => {
    try {
      const decodedId = window.atob(id || '');

      if (decodedId !== userId) {
        return {
          success: false,
          message: t('emails.accounts.verification.error'),
        };
      }
      const userRef = await firestore.collection('users_meta').doc(decodedId);
      return userRef.get().then(doc => {
        if (doc.exists) {
          if (doc.data()?.emailVerified) {
            return {
              success: true,
              message: t('emails.accounts.verification.alreadyVerified'),
            };
          } else {
            updateProfile({ emailVerified: true });
            return {
              success: true,
              message: t('emails.accounts.verification.success'),
            };
          }
        } else {
          return {
            success: false,
            message: t('emails.accounts.verification.error'),
          };
        }
      });
    } catch (error) {
      return {
        success: false,
        message: t('emails.accounts.verification.error'),
      };
    }
  }, [id, userId, firestore, t, updateProfile]);

  const sendVerificationSms = useCallback(() => {
    const min = Math.ceil(123456);
    const max = Math.floor(987654);
    const newOtp = Math.floor(Math.random() * (max - min) + min);
    sendSms({
      text: `Usa il codice di verifica ${newOtp} per verificare il tuo numero su Tilllate.World`,
    });
    setOtp(newOtp);
    return newOtp;
  }, [sendSms]);

  const verifyPhoneNumber = useCallback(
    (otpReceived: number) => {
      if (otpReceived === otp) {
        updateProfile({ phoneVerified: true });
        return {
          success: true,
          message: t('emails.accounts.verification.success'),
        };
      }
      return {
        success: false,
        message: t('emails.accounts.verification.error'),
      };
    },
    [otp, t, updateProfile],
  );

  const sendAccountCreationEmail = useCallback(
    (email, newId) => {
      sendEmail({
        to: email,
        subject: t('emails.accounts.registration.subject'),
        content1: t('emails.accounts.registration.content1', {
          displayName: profile.displayName,
        }),
        content2: t('emails.accounts.registration.content2'),
        cta: {
          url: window.location.origin + '/confirm/' + window.btoa(newId),
          text: t('emails.accounts.registration.ctaText'),
        },
        shortText: t('emails.accounts.registration.shortText'),
        text: t('emails.accounts.registration.text', {
          displayName: profile.displayName,
          url: window.location.origin + '/confirm/' + window.btoa(newId),
        }),
      });
    },
    [profile, sendEmail, t],
  );
  const sendPasswordUpdateEmail = useCallback(
    email => {
      sendEmail({
        to: email,
        subject: t('emails.accounts.updatePassword.subject'),
        content1: t('emails.accounts.updatePassword.content1', {
          displayName: profile.displayName,
        }),
        content2: t('emails.accounts.updatePassword.content2'),
        cta: {
          url: window.location.origin + '/login',
          text: t('emails.accounts.updatePassword.ctaText'),
        },
        shortText: t('emails.accounts.updatePassword.shortText'),
        text: t('emails.accounts.updatePassword.text', {
          displayName: profile.displayName,
          url: window.location.origin + '/login',
        }),
      });
    },
    [profile, sendEmail, t],
  );
  const resendVerificationEmail = useCallback(() => {
    sendEmail({
      subject: t('emails.accounts.resendVerification.subject'),
      content1: t('emails.accounts.resendVerification.content1', {
        displayName: profile.displayName,
      }),
      content2: t('emails.accounts.resendVerification.content2'),
      cta: {
        url: window.location.origin + '/confirm/' + window.btoa(userId),
        text: t('emails.accounts.resendVerification.ctaText'),
      },
      shortText: t('emails.accounts.resendVerification.shortText'),
      text: t('emails.accounts.resendVerification.text', {
        displayName: profile.displayName,
        url: window.location.origin + '/confirm/' + window.btoa(userId),
      }),
    });
  }, [t, profile.displayName, userId, sendEmail]);

  const onEventCreationEmail = useCallback(
    (slug: string) => {
      sendEmail({
        subject: t('emails.events.subject'),
        content1: t('emails.events.content1', {
          displayName: profile.displayName,
        }),
        content2: t('emails.events.content2'),
        cta: {
          url: window.location.origin + '/events/' + slug,
          text: t('emails.events.ctaText'),
        },
        shortText: t('emails.events.shortText'),
        text: t('emails.events.text', {
          displayName: profile.displayName,
          url: window.location.origin + '/events/' + slug,
        }),
      });
    },
    [profile, sendEmail, t],
  );

  const onPageCreationEmail = useCallback(
    (slug: string, email: string) => {
      sendEmail({
        to: email,
        subject: t('emails.pages.subject'),
        content1: t('emails.pages.content1', {
          displayName: profile.displayName,
        }),
        content2: t('emails.pages.content2'),
        cta: {
          url: window.location.origin + '/venues/' + slug,
          text: t('emails.pages.ctaText'),
        },
        shortText: t('emails.pages.shortText'),
        text: t('emails.pages.text', {
          displayName: profile.displayName,
          url: window.location.origin + '/venues/' + slug,
        }),
      });
    },
    [profile.displayName, sendEmail, t],
  );

  const onOrganizationCreationEmail = useCallback(
    (slug: string) => {
      sendEmail({
        subject: t('emails.organizations.subject'),
        content1: t('emails.organizations.content1', {
          displayName: profile.displayName,
        }),
        content2: t('emails.organizations.content2'),
        cta: {
          url: window.location.origin + '/organizations/' + slug,
          text: t('emails.organizations.ctaText'),
        },
        shortText: t('emails.organizations.shortText'),
        text: t('emails.organizations.text', {
          displayName: profile.displayName,
          url: window.location.origin + '/organizations/' + slug,
        }),
      });
    },
    [profile, sendEmail, t],
  );

  const onPhotoAlbumCreationEmail = useCallback(
    (
      email: string,
      displayName: string,
      eventName: string,
      eventId: string,
    ) => {
      sendEmail({
        to: email,
        subject: t('emails.photoAlbums.subject'),
        content1: t('emails.photoAlbums.content1', {
          displayName,
          eventName,
        }),
        content2: t('emails.photoAlbums.content2'),
        cta: {
          url: window.location.origin + '/events/' + eventId,
          text: t('emails.photoAlbums.ctaText'),
        },
        shortText: t('emails.photoAlbums.shortText'),
        text: t('emails.photoAlbums.text', {
          displayName,
          url: window.location.origin + '/events/' + eventId,
        }),
      });
    },
    [sendEmail, t],
  );

  return {
    verifyEmail,
    sendAccountCreationEmail,
    resendVerificationEmail,
    sendVerificationSms,
    onEventCreationEmail,
    onPageCreationEmail,
    onOrganizationCreationEmail,
    sendPasswordUpdateEmail,
    onPhotoAlbumCreationEmail,
    verifyPhoneNumber,
  };
};
