import React, { Fragment, useMemo } from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import defaultLogo from '../../../assets/img/defaultLogo.jpg';
import { Slider } from '../../molecule';
import useStyle from './styles';

type Props = {
  album: string[];
  hideLightgallery?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  paginationPosition?: 'hover' | 'below';
  direction?: 'horizontal' | 'vertical';
  height?: number;
};

const AlbumGallery: React.FC<Props> = ({
  album,
  hideLightgallery,
  pagination = true,
  navigation = true,
  paginationPosition = 'below',
  direction,
  height = 320,
}) => {
  const style = useStyle();

  const renderPhotos = useMemo(() => {
    if (album.length > 1) {
      return (
        <Slider
          items={1}
          pagination={pagination}
          paginationPosition={paginationPosition}
          navigation={navigation}
          direction={direction}
        >
          {album.map(single => {
            return (
              <div key={single}>
                {!hideLightgallery && (
                  <LightgalleryItem group="any" src={single || defaultLogo}>
                    <button>
                      <SettingsOverscanIcon fontSize="large" color="primary" />
                    </button>
                  </LightgalleryItem>
                )}
                <div className={style.singleImage}>
                  <img
                    src={single}
                    data-src={single}
                    className="swiper-lazy"
                    style={{ height }}
                    alt=""
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />
                </div>
              </div>
            );
          })}
        </Slider>
      );
    }
    return (
      <div>
        {!hideLightgallery && (
          <LightgalleryItem group="any" src={album[0] || defaultLogo}>
            <button>
              <SettingsOverscanIcon fontSize="large" color="primary" />
            </button>
          </LightgalleryItem>
        )}
        <div className={style.singleImage}>
          <img
            src={album[0] || defaultLogo}
            data-src={album[0] || defaultLogo}
            style={{ height }}
            alt=""
          />
        </div>
      </div>
    );
  }, [
    album,
    direction,
    height,
    hideLightgallery,
    navigation,
    pagination,
    paginationPosition,
    style.singleImage,
  ]);

  if (hideLightgallery) {
    return (
      <Fragment>
        <div className={style.productLargeImageWrapper}>{renderPhotos}</div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={style.productLargeImageWrapper}>
        <LightgalleryProvider
          plugins={[
            'lg-fullscreen.js',
            'lg-thumbnail.js',
            'lg-video.js',
            'lg-zoom.js',
            'lg-hash.js',
            'lg-share.js',
          ]}
        >
          {renderPhotos}
        </LightgalleryProvider>
      </div>
    </Fragment>
  );
};

AlbumGallery.displayName = 'AlbumGallery';

export default React.memo(AlbumGallery);
