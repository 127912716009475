import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import facebook from '../../../assets/img/facebook.png';
import instagram from '../../../assets/img/instagram.png';
import twitter from '../../../assets/img/twitter.png';
import mail from '../../../assets/img/mail.png';

const useStyle = makeStyles({
  icon: {
    width: 30,
    margin: 8,
    cursor: 'pointer',
    filter: 'saturate(0)',
    transition: '0.5s all ease',
    opacity: '0.7',
    '&:hover': {
      filter: 'saturate(1)',
      opacity: 1,
    },
  },
});

const FollowUs: React.FC = () => {
  const { t } = useTranslation();
  const { icon } = useStyle();
  return (
    <Box textAlign="center" p={3} paddingBottom={1}>
      <img
        src={facebook}
        className={icon}
        onClick={() =>
          window.open('https://www.facebook.com/tilllateworlditalia')
        }
        alt={t('social.followUs', { social: 'facebook' })}
      />
      <img
        src={instagram}
        className={icon}
        onClick={() => window.open('https://www.instagram.com/tilllateworld/')}
        alt={t('social.followUs', { social: 'instagram' })}
      />
      <img
        src={twitter}
        className={icon}
        onClick={() => window.open('https://twitter.com/tilllateworld')}
        alt={t('social.followUs', { social: 'twitter' })}
      />
      <img
        src={mail}
        className={icon}
        onClick={() => window.open('mailto:info@tilllate.world')}
        alt={t('social.email')}
      />
    </Box>
  );
};

FollowUs.displayName = 'FollowUs';

export default React.memo(FollowUs);
