import React, { useCallback } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram } from '@material-ui/icons';
import { useGlobalStyles } from '../../../hooks';
import { Spacer } from '../../atoms';
import { Artist } from '../../../store/types';

type Props = {
  artist?: Artist;
};

const ArtistDetails: React.FC<Props> = ({ artist }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const renderField = useCallback(
    (title, content) => (
      <>
        <Typography>
          <b>{title}: </b>
          <br />
          {content}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [],
  );
  return (
    <Box
      className={globalStyle.flex}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Paper variant="outlined" className={globalStyle.size100}>
        <Box p={2}>
          {renderField(t('artist.name'), artist?.name)}
          {renderField(
            t('artist.bio'),
            <span
              dangerouslySetInnerHTML={{ __html: artist?.description ?? '' }}
            />,
          )}
          {renderField(t('artist.website'), artist?.website)}
          {renderField(
            t('artist.musicStyles'),
            artist?.musicStyles?.map(music => (
              <Chip
                component="span"
                key={music}
                label={t(`shared.musicStyles.${music}`)}
                color="secondary"
                variant="outlined"
                size="small"
                style={{ marginRight: 5 }}
              />
            )),
          )}
          {renderField(
            t('artist.followUs'),
            <>
              {artist?.facebook && (
                <IconButton onClick={() => window.open(artist.facebook)}>
                  <Facebook />
                </IconButton>
              )}
              {artist?.facebook && (
                <IconButton onClick={() => window.open(artist.instagram)}>
                  <Instagram />
                </IconButton>
              )}
            </>,
          )}
        </Box>
      </Paper>
    </Box>
  );
};

ArtistDetails.displayName = 'ArtistDetails';

export default React.memo(ArtistDetails);
