import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IonHeader, IonToolbar } from '@ionic/react';
import { Box, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { ArrowBackIos } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Logo } from '../../atoms';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import logoBig from '../../../assets/img/logo_w.png';
import logoSmall from '../../../assets/img/logo_small_w.png';
import Menu from '../Menu';
import { HeaderSearchBar } from '..';
import { RootState } from '../../../store/reducers';
import {
  addToInAppHistory,
  updateInAppHistory,
} from '../../../store/actions/configurations/inAppHistory';
import useStyle from './style';

type Props = {
  logo?: string;
  hideMenu?: boolean;
};

const DashboardHeader: React.FC<Props> = ({ logo, hideMenu }) => {
  const { isMobile } = useMediaQuery();
  const inAppHistory: any = useSelector<RootState>(
    state => state.configuration.inAppHistory,
  );
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false);
  const { flex, flexGrow, mh2, bgSecondaryDark } = useGlobalStyles();
  const style = useStyle({ isMobile });
  const logoImg = logo || isMobile ? logoSmall : logoBig;
  const logoWidth = isMobile ? '40px' : '250px';
  const history = useHistory();
  const { pathname } = useLocation();
  const goBack = useCallback(() => {
    const newHistory = [...inAppHistory];
    newHistory.splice(-2);
    dispatch(updateInAppHistory(newHistory));
    history.goBack();
  }, [dispatch, history, inAppHistory]);

  const canGoBack = useMemo(() => {
    return inAppHistory.length > 1 && pathname !== '/home' && isMobile;
  }, [inAppHistory.length, isMobile, pathname]);

  useEffect(() => {
    if (pathname && !added) {
      setAdded(true);
      dispatch(addToInAppHistory(pathname));
    }
  }, [pathname, dispatch, added]);

  const renderToolbarContent = useMemo(() => {
    return (
      <Box paddingX={1}>
        <Flex justifyContent="space-between">
          {!canGoBack ? (
            <Logo logo={logoImg} width={logoWidth} to="/home" />
          ) : (
              <IconButton size="small" onClick={goBack}>
                <ArrowBackIos style={{ color: 'white' }} />
              </IconButton>
            )}

          <div className={[mh2, flex, flexGrow].join(' ')}>
            <HeaderSearchBar />
          </div>
          {!hideMenu && <Menu />}
        </Flex>
      </Box>
    );
  }, [canGoBack, goBack, logoImg, logoWidth, mh2, flex, flexGrow, hideMenu]);
  return (
    <Box className={style.wrapper}>
      {isMobile ? (
        <IonHeader className={style.mobileHeader}>
          <IonToolbar className={bgSecondaryDark}>
            <>{renderToolbarContent}</>
          </IonToolbar>
        </IonHeader>
      ) : (
          <Box className={[bgSecondaryDark, style.header].join(' ')}>
            <>{renderToolbarContent}</>
          </Box>
        )}
    </Box>
  );
};

DashboardHeader.displayName = 'DashboardHeader';

export default React.memo(DashboardHeader);
