import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory, useParams } from 'react-router';
import { useMediaQuery, useSearch } from '../../../hooks';
import { HeaderSearchResult, SearchCategoryTypes } from '../../../store/types';
import useDebounce from '../../../hooks/ui/useDebounce';
import AutocompletePopper from './AutocompletePopper';
import SearchResult from './SearchResult';

type Props = {};

const useInputOutlineStyles = makeStyles(theme => ({
  cssOutlinedInput: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&$cssFocused $notchedOutline': {
      border: 'none',
    },
  },
  cssFocused: {},

  notchedOutline: {
    border: 'none',
  },
}));

const useAutocompleteStyles = makeStyles({
  root: {
    maxWidth: ({ isMobile }: any) => (isMobile ? undefined : 200),
    transition: '0.5s all ease',
  },
  focused: {
    maxWidth: ({ isMobile }: any) => (isMobile ? undefined : 500),
  },
  clearIndicator: {
    display: 'none',
  },
  popupIndicator: {
    display: 'none',
  },
  popper: {
    position: 'fixed',
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
  },
  option: {
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  listbox: {
    margin: 0,
    padding: 0,
  },
});

type categoryTypes = SearchCategoryTypes | undefined;
interface ParamType {
  category: categoryTypes;
}

const HeaderSearchBar: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<HeaderSearchResult[]>([]);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value.trim(), 300);
  const [loading, setLoading] = useState(false);
  const { headerSearch } = useSearch();
  const { isMobile } = useMediaQuery();
  const classes = useAutocompleteStyles({ isMobile });
  const inputStyles = useInputOutlineStyles();
  const history = useHistory();
  const { category } = useParams<ParamType>();

  useEffect(() => {
    if (debouncedValue === '') {
      return;
    }
    setLoading(true);
    const results = headerSearch(debouncedValue);
    setOptions(results);
    setLoading(false);
  }, [debouncedValue, headerSearch]);

  const handleChange = useCallback((val: string) => {
    setValue(val);
    if (val === '') {
      setOptions([]);
      return;
    }
  }, []);

  const handleClose = useCallback(() => {
    setOptions([]);
    setValue('');
  }, []);

  const onKeyPress = useCallback(
    (key: string) => {
      if (value.trim() === '') {
        return;
      }
      if (key === 'Enter') {
        history.push(
          `/search/${category ? category : 'all'}?q=${value.trim()}`,
        );
        handleClose();
      }
    },
    [value, history, category, handleClose],
  );

  return (
    <Autocomplete
      inputValue={value}
      getOptionLabel={option => (option?.name ? option.name : '')}
      options={options}
      fullWidth
      classes={classes}
      renderOption={option => <SearchResult data={option} />}
      onClose={handleClose}
      PaperComponent={props => (
        <AutocompletePopper
          searchedText={value}
          deleteOptions={() => setOptions([])}
          deleteValue={() => setValue('')}
          showMore={options.length > 6}
          {...props}
        />
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('general.search')}
          className={'without-padding'}
          onChange={e => handleChange(e.target.value)}
          onKeyDown={e => onKeyPress(e.key)}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: inputStyles.cssOutlinedInput,
              focused: inputStyles.cssFocused,
              notchedOutline: inputStyles.notchedOutline,
            },
            style: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: <SearchIcon color="inherit" />,
          }}
        />
      )}
    />
  );
};

HeaderSearchBar.displayName = 'HeaderSearchBar';

export default React.memo(HeaderSearchBar);
