import { isPlatform } from '@ionic/react';
import {
  Box,
  Paper,
  Button,
  Divider,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { SocialLoginButton } from '..';
import { useAuthentication, useMediaQuery } from '../../../hooks';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import { authErrorSelector } from '../../../store/selectors';
import { Spacer, Flex } from '../../atoms';
import FormField from '../../molecule/FormField';

type Props = {
  showRegister?: boolean;
  width?: string | number;
  onLoginCallback?: () => void;
  small?: boolean;
};

type Form = {
  email: string;
  password: string;
};

type FormRecover = {
  email: string;
};

const LoginBox: React.FC<Props> = ({
  showRegister = true,
  width = 500,
  small = false,
  onLoginCallback,
}) => {
  const { handleSubmit, errors, register } = useForm<Form>();
  const {
    handleSubmit: handleRecoverSubmit,
    errors: errorsRecover,
    register: registerRecover,
  } = useForm<FormRecover>();
  const { sendResetPasswordEmail } = useAuthentication();
  const [resetSent, setResetSent] = useState<string | null>(null);
  const [resetError, setResetError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const authError = useSelector(authErrorSelector);
  const firebase = useFirebase();
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const history = useHistory();
  const goToRegister = useCallback(() => history.push('/register'), [history]);
  const [recoverMode, toggleRecoverMode] = useToggleState(false);
  const isCapacitor = isPlatform('capacitor');
  const onSubmit = useCallback(
    (values: any) => {
      setLoading(true);
      const { email, password } = values;
      firebase
        .login({ email, password })
        .then(() => {
          if (onLoginCallback) {
            onLoginCallback();
            history.push('/home');
          }
        })
        .finally(() => setLoading(false));
    },
    [firebase, history, onLoginCallback],
  );
  const onRecover = useCallback(
    async (values: FormRecover) => {
      setResetError(null);
      setResetSent(null);
      try {
        const res = await sendResetPasswordEmail(values);
        setResetSent(res);
      } catch (e) {
        //@ts-ignore
        setResetError(e.message);
      }
    },
    [sendResetPasswordEmail],
  );

  const loginForm = useMemo(
    () => (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="email"
          label="Email"
          type="email"
          register={register}
          error={errors.email?.message}
        />
        <Spacer height="10px" />
        <FormField
          name="password"
          label="Password"
          type="password"
          register={register}
        />
        <Spacer height="10px" />
        <Typography variant="body2" color="error">
          {authError && authError.message}
        </Typography>
        <Spacer height="10px" />
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size="20px" /> : t('shared.login')}
        </Button>
      </form>
    ),
    [authError, errors, handleSubmit, loading, onSubmit, register, t],
  );
  const recoverForm = useMemo(
    () => (
      <form onSubmit={handleRecoverSubmit(onRecover)}>
        <FormField
          name="email"
          label="Email"
          type="email"
          register={registerRecover}
          error={errorsRecover.email?.message}
        />
        <Typography variant="body2" color="error">
          {resetError && t(resetError)}
        </Typography>
        <Typography variant="body2" color="error">
          {resetSent && t(resetSent)}
        </Typography>
        <Spacer height="10px" />
        <Button variant="outlined" color="primary" type="submit" fullWidth>
          {t('shared.resetPassword')}
        </Button>
      </form>
    ),
    [
      errorsRecover,
      handleRecoverSubmit,
      onRecover,
      registerRecover,
      resetError,
      resetSent,
      t,
    ],
  );

  return (
    <Box width={width} maxWidth="100%" margin="auto">
      <Paper variant="outlined">
        <Box padding={2}>
          {recoverMode ? recoverForm : loginForm}
          <Spacer height="10px" />
          <Flex flexDirection={isMobile || small ? 'column' : 'row'}>
            <SocialLoginButton
              provider="facebook"
              scopes={['email']}
              onLoginCallback={onLoginCallback}
            />
            {!isCapacitor ? (
              <>
                <Spacer width="10px" height="10px" />
                <SocialLoginButton
                  provider="twitter"
                  onLoginCallback={onLoginCallback}
                />
              </>
            ) : null}
            <Spacer width="10px" height="10px" />
            <SocialLoginButton
              provider="google"
              onLoginCallback={onLoginCallback}
            />
            {isCapacitor && (isPlatform('ios') || isPlatform('ipad')) ? (
              <>
                <Spacer width="10px" height="10px" />
                <SocialLoginButton
                  provider="apple.com"
                  onLoginCallback={onLoginCallback}
                />
              </>
            ) : null}
          </Flex>
          {showRegister && (
            <>
              <Spacer height="20px" />
              <Divider variant="middle" />
              <Spacer height="20px" />
              <Button variant="outlined" fullWidth onClick={goToRegister}>
                {t('shared.firstTime')}
              </Button>
            </>
          )}
        </Box>
      </Paper>
      <Spacer height="10px" />
      <Typography variant="body1" align="center" onClick={toggleRecoverMode}>
        {recoverMode ? t('shared.login') : t('shared.passwordForgotten')}
      </Typography>
    </Box>
  );
};

LoginBox.displayName = 'LoginBox';

export default React.memo(LoginBox);
