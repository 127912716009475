import { makeStyles } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';

export default makeStyles({
  facebook: {
    color: 'white',
    backgroundColor: blue[800],
    '&:hover': {
      backgroundColor: blue[900],
    },
  },
  twitter: {
    color: 'white',
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  google: {
    color: 'white',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[800],
    },
  },
  'apple.com': {
    color: 'white',
    backgroundColor: '#333',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  appleLogin: {
    background: '#000',
    borderRadius: '4px',
    textTransform: 'none',
    margin: 0,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#000',
    },
  },
  googleLogin: {
    background: '#fff',
    borderRadius: '4px',
    textTransform: 'none',
    color: 'hsl(48deg 2% 53%)',
    fontWeight: 'bold',
    fontSize: '13px',
    margin: 0,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#fff',
    },
    '& img': {
      width: '20px',
      marginRight: '3px',
    },
  },
  facebookLogin: {
    background: '#4267b2',
    borderRadius: '4px',
    textTransform: 'none',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '13px',
    margin: 0,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#4267b2',
    },
  },
  facebookIcon: {
    transform: 'scale(1.15)',
  },
});
