import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import ITit from 'dayjs/locale/it';
import ENen from 'dayjs/locale/en';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles({
  clearIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 5,
  },
  container: {
    position: 'relative',
  },
});

type Props = {
  date?: {
    day: number;
    month: number;
    year: number;
  } | null;
  setDate?: React.Dispatch<
    React.SetStateAction<{
      day: number;
      month: number;
      year: number;
    } | null>
  >;
  label?: string;
};

const DateSelector: React.FC<Props> = ({ date, setDate, label }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const { i18n } = useTranslation('home');
  const classes = useStyles();

  useEffect(() => {
    if (setDate) {
      if (selectedDate) {
        const choosenDate = new Date(selectedDate);
        setDate({
          day: choosenDate.getDate(),
          month: choosenDate.getMonth(),
          year: choosenDate.getFullYear(),
        });
      } else {
        setDate(null);
      }
    }
  }, [selectedDate, setDate]);

  useEffect(() => {
    if (date && !selectedDate) {
      setSelectedDate(date ? new Date(date.year, date.month, date.day) : null);
    }
  }, [date, selectedDate]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <MuiPickersUtilsProvider
        utils={DayJsUtils}
        locale={i18n.language === 'it' ? ITit : ENen}
      >
        <DatePicker
          inputVariant="outlined"
          size="small"
          fullWidth
          format="D MMMM YYYY"
          value={selectedDate}
          placeholder={!label ? t('shared.selectDate') : undefined}
          label={label && label}
          onChange={e => setSelectedDate(e?.toString())}
          showTodayButton={true}
        />
      </MuiPickersUtilsProvider>
      {selectedDate && (
        <HighlightOffIcon
          color="primary"
          className={classes.clearIcon}
          onClick={() => {
            setSelectedDate(null);
            if (setDate) {
              setDate(null);
            }
          }}
        />
      )}
    </Box>
  );
};

DateSelector.displayName = 'DateSelector';

export default React.memo(DateSelector);
