import React, { useCallback } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram } from '@material-ui/icons';
import { useAuthentication, useGlobalStyles } from '../../../hooks';
import { Spacer } from '../../atoms';
import { UserAvatar } from '..';
import { getAgeByDate } from '../../../utils';

type Props = {};

const UserDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { profile } = useAuthentication();
  const globalStyle = useGlobalStyles();
  const renderField = useCallback(
    (title, content) => (
      <>
        <Typography>
          <b>{title}: </b>
          <br />
          {content}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [],
  );
  return (
    <Box
      className={globalStyle.flex}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <UserAvatar />
      <Spacer height="30px" />
      <Paper variant="outlined" className={globalStyle.size100}>
        <Box p={2}>
          {renderField(
            t('profile.name'),
            profile.displayName
              ? profile.displayName
              : `${profile.firstName} ${profile.lastName}`,
          )}
          {profile.status &&
            renderField(
              t('profile.status'),
              profile.status
                ? t(`profile.statusOptions.${profile.status}`)
                : '',
            )}
          {profile.birthday &&
            renderField(
              t('profile.age'),
              profile.birthday &&
                new Date(profile.birthday) &&
                getAgeByDate(new Date(profile.birthday)),
            )}
          {profile.aboutMe &&
            renderField(t('profile.aboutMe'), profile.aboutMe)}
          {profile.preferredMusic &&
            renderField(
              t('profile.preferredMusicLabel'),
              profile.preferredMusic?.map(music => (
                <Chip
                  component="span"
                  key={music}
                  label={t(`shared.musicStyles.${music}`)}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  style={{ marginRight: 5 }}
                />
              )),
            )}
          {(profile.facebook || profile.instagram) &&
            renderField(
              t('profile.followMe'),
              <>
                {profile.facebook && (
                  <IconButton onClick={() => window.open(profile.facebook)}>
                    <Facebook />
                  </IconButton>
                )}
                {profile.instagram && (
                  <IconButton onClick={() => window.open(profile.instagram)}>
                    <Instagram />
                  </IconButton>
                )}
              </>,
            )}
        </Box>
      </Paper>
    </Box>
  );
};

UserDetails.displayName = 'UserDetails';

export default React.memo(UserDetails);
