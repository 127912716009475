export const getSelectedCheckboxes = (object: {
  [key: string]: { value: boolean; name: string };
}) => {
  const selectedCheckboxes: string[] = [];
  Object.entries(object).forEach(([key, values]) => {
    if (values.value) {
      selectedCheckboxes.push(key);
    }
  });
  return selectedCheckboxes;
};

export const createCheckboxOptions = (
  data: { keyValue: string; initialValue: boolean; name: string }[],
): { [key: string]: { value: boolean; name: string } } => {
  const object = {};
  data.forEach(
    d =>
      (object[d.keyValue] = {
        value: d.initialValue,
        name: d.name,
      }),
  );
  return object;
};
