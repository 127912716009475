import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEditGuard,
  useGlobalStyles,
  useArtist,
  useCompressedImage,
} from '../../../hooks';
import PageCoverAndLogo from '../PageCoverAndLogo';
import ArtistForm from '../ArtistForm';
import { Form } from '../ArtistForm/ArtistForm';
import { LoadingCover, Spacer } from '../../atoms';

type Props = {
  slugOrId: string;
};

const EditArtist: React.FC<Props> = ({ slugOrId }) => {
  const { t } = useTranslation();
  const [customErrors, setCustomErrors] = useState<Partial<Form>>({});
  const [cover, setCover, setCompressCover] = useCompressedImage();
  const [logo, setLogo, setCompressLogo] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const { updateArtist, loading, artist } = useArtist({ slugOrId });
  useEditGuard(artist);

  const onSubmit = async (values: any) => {
    if (!values.description) {
      setCustomErrors(prev => ({
        ...prev,
        description: t('form.descriptionRequired'),
      }));
      return;
    }
    if (!values.fullAddress) {
      setCustomErrors(prev => ({
        ...prev,
        fullAddress: t('form.invalidAddress'),
      }));
      return;
    }
    setCustomErrors({});
    updateArtist({ cover, logo, ...values });
  };

  useEffect(() => {
    if (artist) {
      setLogo(artist?.logoUrl || []);
      setCover(artist?.coverUrl || []);
    }
  }, [artist, setCover, setLogo]);

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      <Spacer height="20px" />
      <PageCoverAndLogo.Form
        logo={logo}
        setLogo={setCompressLogo}
        cover={cover}
        setCover={setCompressCover}
      />
      {artist && (
        <ArtistForm
          onSubmit={onSubmit}
          customErrors={customErrors}
          artist={artist}
        />
      )}
    </div>
  );
};

EditArtist.displayName = 'EditArtist';

export default React.memo(EditArtist);
