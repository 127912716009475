import React, { useMemo } from 'react';
import { IonIcon } from '@ionic/react';
import { logOut, logoGoogle, logoTwitter } from 'ionicons/icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Flex, Logo, Spacer } from '../../components/atoms';
import { useMediaQuery, useAuthentication } from '../../hooks';
import { SocialLoginButton } from '../../components/organisms';
import bg from '../../assets/img/bg.jpg';
import STI from '../../assets/img/STI.png';
import AMO from '../../assets/img/AMO.png';
import TOR from '../../assets/img/TOR.png';
import NAN from '../../assets/img/NAN.png';
import DO from '../../assets/img/DO.png';
import WE from '../../assets/img/WE.png';
import RE from '../../assets/img/RE.png';
import COM from '../../assets/img/COM.png';
import ING from '../../assets/img/ING.png';
import BACK from '../../assets/img/BACK.png';
import facebook from '../../assets/img/facebook.png';
import instagram from '../../assets/img/instagram.png';
import twitter from '../../assets/img/twitter.png';
import mail from '../../assets/img/mail.png';

const LandingPage: React.FC = () => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const isIt = useMemo(() => i18next.language.indexOf('it') > -1, []);
  const { isAuthenticated, logout } = useAuthentication();

  return (
    <Flex
      flexDirection="column"
      justifyContent="start"
      style={
        isMobile
          ? {
              background: `url(${bg}) top center / cover no-repeat`,
            }
          : {
              background: `url(${bg}) bottom center / cover no-repeat`,
              boxShadow: '0px 0px 100vh 20vh inset black',
            }
      }
    >
      <Flex
        justifyContent="space-between"
        height="50px"
        style={{
          padding: 10,
          position: !isMobile ? 'absolute' : undefined,
        }}
      >
        <Flex justifyContent="flex-end">
          <img
            src={facebook}
            style={{ width: 50, margin: 10, cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.facebook.com/tilllateworlditalia')
            }
            alt="Follow us"
          />
          <img
            src={instagram}
            style={{ width: 50, margin: 10, cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.instagram.com/tilllateworld/')
            }
            alt="Follow us"
          />
          <img
            src={twitter}
            style={{ width: 50, margin: 10, cursor: 'pointer' }}
            onClick={() => window.open('https://twitter.com/tilllateworld')}
            alt="Follow us"
          />
          <img
            src={mail}
            style={{ width: 50, margin: 10, cursor: 'pointer' }}
            onClick={() => window.open('mailto:info@tilllate.world')}
            alt="Follow us"
          />
          {isAuthenticated && (
            <Flex width="50px">
              {/* <Avatar /> */}
              <IonIcon icon={logOut} size="large" onClick={logout} />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent={isMobile ? 'start' : 'center'}
        height={!isMobile ? '100vh' : undefined}
      >
        {isMobile ? (
          <>
            <Spacer height="90px" />
            <Logo width="200px" />
            <Spacer height="90px" />
            <Flex width="100%" justifyContent="start">
              <img
                src={isIt ? STI : WE}
                style={{ height: isIt ? 100 : 120 }}
                alt="Presto online"
              />
            </Flex>
            <Flex width="100%" justifyContent="flex-end">
              <img
                src={isIt ? AMO : RE}
                style={{ height: 100, marginRight: 20 }}
                alt="Presto online"
              />
            </Flex>
            <Spacer height="90px" />
            <Flex width="100%" justifyContent="start">
              <img
                src={isIt ? TOR : COM}
                style={{ height: 100 }}
                alt="Presto online"
              />
            </Flex>
            <Flex width="100%" justifyContent="center">
              <img
                src={isIt ? NAN : ING}
                style={{ height: 100 }}
                alt="Presto online"
              />
            </Flex>
            <Flex width="100%" justifyContent="center">
              <img
                src={isIt ? DO : BACK}
                style={{ height: 100 }}
                alt="Presto online"
              />
            </Flex>
          </>
        ) : (
          <>
            <Spacer height="30px" />
            <Logo width="200px" />
            <Spacer height="40px" />
            <div>
              <img
                src={isIt ? STI : WE}
                style={{ height: isIt ? 100 : 120 }}
                alt="Presto online"
              />
              <img
                src={isIt ? AMO : RE}
                style={{ height: 100, transform: 'translateY(30px)' }}
                alt="Presto online"
              />
            </div>
            <Spacer height="60px" />
            <div>
              <img
                src={isIt ? TOR : COM}
                style={{ height: 100, margin: 10 }}
                alt="Presto online"
              />
              <img
                src={isIt ? NAN : ING}
                style={{
                  height: 100,
                  margin: 10,
                  transform: 'translateY(30px)',
                }}
                alt="Presto online"
              />
              <img
                src={isIt ? DO : BACK}
                style={{
                  height: 100,
                  margin: 10,
                  transform: 'translateY(60px)',
                }}
                alt="Presto online"
              />
            </div>
            <Spacer height="90px" />
          </>
        )}
        <>
          {!isAuthenticated ? (
            <Flex
              width={isMobile ? '80%' : '800px'}
              alignItems="stretch"
              style={{
                padding: isMobile ? 30 : 10,
                border: '2px solid white',
                borderRadius: 10,
                background: 'rgba(0,0,0,0.8)',
                margin: 30,
              }}
              flexDirection="column"
            >
              <h2
                style={{
                  padding: isMobile ? 30 : 0,
                  textAlign: 'center',
                  fontWeight: 900,
                  color: '#fff',
                }}
              >
                {t('landingPage.stayTuned')}
                {!isMobile && <br />}
                {t('landingPage.tilllateClubbing')}
              </h2>
              {!isMobile && <Spacer height="30px" />}
              <Flex flexDirection={isMobile ? 'column' : 'row'}>
                {/* <SocialLoginButton provider="facebook" scopes={['email']}>
                  <IonIcon slot="start" icon={logoFacebook} />
                  <span>{t('landingPage.loginWith')} Facebook</span>
                </SocialLoginButton> */}
                <SocialLoginButton provider="twitter">
                  <IonIcon slot="start" icon={logoTwitter} />
                  <span>{t('landingPage.loginWith')} Twitter</span>
                </SocialLoginButton>
                <SocialLoginButton provider="google">
                  <IonIcon slot="start" icon={logoGoogle} />
                  <span>{t('landingPage.loginWith')} Google</span>
                </SocialLoginButton>
              </Flex>
              {!isMobile && <Spacer height="10px" />}
              {/* <small>Registrati con la tua email</small> */}
            </Flex>
          ) : (
            <Flex
              width={isMobile ? '80%' : '800px'}
              alignItems="stretch"
              style={{
                padding: isMobile ? 30 : 10,
                border: '2px solid white',
                borderRadius: 10,
                background: 'rgba(0,0,0,0.8)',
                margin: 30,
              }}
              flexDirection="column"
            >
              <h2
                style={{
                  padding: isMobile ? 30 : 0,
                  textAlign: 'center',
                  fontWeight: 900,
                  color: '#fff',
                }}
                dangerouslySetInnerHTML={{
                  __html: t('landingPage.thankYou', { html: true }),
                }}
              />
              <Flex justifyContent="center">
                <img
                  src={facebook}
                  style={{ width: 50, margin: 10, cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://www.facebook.com/tilllateworlditalia')
                  }
                  alt="Follow us"
                />
                <img
                  src={instagram}
                  style={{ width: 50, margin: 10, cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://www.instagram.com/tilllateworld/')
                  }
                  alt="Follow us"
                />
                <img
                  src={twitter}
                  style={{ width: 50, margin: 10, cursor: 'pointer' }}
                  onClick={() =>
                    window.open('https://twitter.com/tilllateworld')
                  }
                  alt="Follow us"
                />
                <img
                  src={mail}
                  style={{ width: 50, margin: 10, cursor: 'pointer' }}
                  onClick={() => window.open('mailto:info@tilllate.world')}
                  alt="Follow us"
                />
              </Flex>
            </Flex>
          )}
        </>
      </Flex>
      {isMobile && <Spacer height="90px" />}
    </Flex>
  );
};

LandingPage.displayName = 'LandingPage';

export default React.memo(LandingPage);
