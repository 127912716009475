import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  PageWrapper,
  SearchBar,
  ArtistsList,
  FilterCategory,
  RegionSelect,
  FilterCheckboxOptions,
  MobileFilterDialog,
} from '../../components/molecule';
import { useArtists, useGlobalStyles, useMediaQuery } from '../../hooks';
import { RootState } from '../../store/reducers';
import { MUSIC_STYLES } from '../../constants';
import useCheckboxOptions from '../../hooks/ui/useCheckboxOptions';
import { getSelectedCheckboxes } from '../../utils';
import useDebounce from '../../hooks/ui/useDebounce';
import { Emoji } from '../../components/atoms';

const Artists = () => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const [searchFor, setSearchFor] = useState('');
  const debouncedSearchFor = useDebounce(searchFor.trim(), 300);

  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const { createMusicStyleObject } = useCheckboxOptions();
  const [musicStylesState, setMusicStylesState] = useState(
    createMusicStyleObject(MUSIC_STYLES),
  );
  const [page, setPage] = useState(1);

  const {
    filteredArtistsByPage: { artists, totalResults },
    loaded,
  } = useArtists({
    searchFor: debouncedSearchFor,
    musicStyles: getSelectedCheckboxes(musicStylesState),
    page,
  });

  useEffect(() => {
    setPage(1);
  }, [region, debouncedSearchFor, musicStylesState]);

  const loadMoreArtists = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const renderFilter = useCallback(() => {
    return (
      <Grid
        container
        justify="space-around"
        spacing={2}
        className={globalStyle.mt3}
      >
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.search').slice(0, -3)}>
            <SearchBar
              label={t('artist.searchArtists')}
              searchText={searchFor}
              setSearchText={setSearchFor}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('venue.region')}>
            <RegionSelect />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('artist.musicStyles')}>
            <FilterCheckboxOptions
              state={musicStylesState}
              setState={setMusicStylesState}
              title={t('artist.musicStyles')}
            />
          </FilterCategory>
        </Grid>
      </Grid>
    );
  }, [globalStyle.mt3, musicStylesState, searchFor, t]);

  return (
    <PageWrapper title={`${t('artist.artists')}`}>
      <Grid container spacing={2}>
        {isMobile ? (
          <MobileFilterDialog
            renderFilter={renderFilter}
            label={t('artist.filter')}
          />
        ) : (
            <Grid item md={3}>
              {renderFilter()}
            </Grid>
          )}
        <Grid item xs={12} md={9}>
          {artists.length ? (
            <ArtistsList
              artists={artists}
              loading={!loaded}
              loadMore={loadMoreArtists}
              totalResults={totalResults}
              page={page}
            />
          ) : (
              <Box textAlign="center" padding={2}>
                <Typography variant="h2">
                  <Emoji emoji="🤷‍♂️" />
                </Typography>
                {t('artist.notFound')}
              </Box>
            )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Artists;
