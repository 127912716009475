import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Controller } from 'react-hook-form';
import { loadScript } from '../../../utils/ui';

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type Props = {
  disabled?: boolean;
  inputProps?: any;
  control: any;
  size?: 'medium' | 'small';
  variant?: 'filled' | 'outlined' | 'standard';
  name: string;
  helperText?: string | null;
  error?: string | null;
  required?: boolean;
  fullWidth: boolean;
  type?: 'city' | 'region' | 'address';
  label: string;
  setValue: any;
  fieldClassName?: string;
  onChange?: (props: {
    address: any;
    lng?: string | null;
    lat?: string | null;
    address_components?: AddressComponent[];
  }) => void;
};

const AddressField: React.FC<Props> = ({
  disabled,
  size,
  variant,
  name,
  helperText,
  error,
  required,
  control,
  type,
  label,
  setValue,
  onChange: onChangeProp,
  fieldClassName,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<PlaceType[]>([]);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
    if (onChangeProp) {
      onChangeProp({ address: event.target.value, lng: null, lat: null });
    }
  };

  const onChange = ([, data]: any) => {
    setInputValue(data?.description ?? '');
    const geocoder = new (window as any).google.maps.Geocoder();
    let lat: string | null = null;
    let lng: string | null = null;
    geocoder.geocode({ placeId: data?.place_id }, async (locations: any[]) => {
      if (locations !== null && locations[0]) {
        lat = await locations[0].geometry?.location?.lat();
        lng = await locations[0].geometry?.location?.lng();
      }
      if (onChangeProp) {
        onChangeProp({
          address: data?.description,
          lng,
          lat,
          address_components: locations?.[0]?.address_components,
        });
      }
    });
  };

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      (window as any).google &&
      (window as any).google.maps &&
      (window as any).google.maps.places
    ) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types: type === 'address' ? ['address'] : ['(regions)'],
        componentRestrictions: { country: 'it' },
      },
      (results?: PlaceType[]) => {
        if (active) {
          setOptions(results || []);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch, type]);

  useEffect(() => {
    if (inputValue) {
      setValue(name, inputValue);
    }
  }, [inputValue, name, setValue]);

  useEffect(() => {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      onChange={onChange}
      disabled={disabled}
      className={fieldClassName}
      as={
        <Autocomplete
          id="google-map-demo"
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.description
          }
          filterOptions={x => x}
          options={options}
          fullWidth
          autoComplete={false}
          includeInputInList
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
              onChange={handleChange}
              size={size}
              variant={variant}
              label={label}
              helperText={error || helperText}
              error={!!error}
              required={required}
              fullWidth
            />
          )}
          renderOption={option => {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [
                match.offset,
                match.offset + match.length,
              ]),
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      }
    />
  );
};

export default React.memo(AddressField);
