import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase';
import {
  photographersListSelector,
  usersListSelector,
  usersSelector,
  usersByNamesSelector,
} from '../../store/selectors/users';
import { User } from '../../store/types';

type UseUsers = {
  users: { [key: string]: User };
  usersList: User[];
  photographersList: User[];
  loaded?: boolean;
  usersByNames: User[];
};

type Props = {
  photographers?: boolean;
  searchFor?: string;
  ids?: string[];
  names?: string[];
};

export const useUsers = ({
  photographers,
  searchFor,
  ids,
  names,
}: Props = {}): UseUsers => {
  const firestore = useFirestore();
  const users = useSelector(usersSelector);
  const usersList = useSelector(usersListSelector);
  const photographersList = useSelector(photographersListSelector);
  const usersByNames = useSelector(usersByNamesSelector(names));

  const loaded = isLoaded(users);

  const query: ReduxFirestoreQuerySetting = {
    collection: 'users_meta',
    limit: 1,
  };

  if (photographers) {
    const where: WhereOptions[] = [['photographer', '==', true]];
    query.where = query.where ? query.where.concat(where) : where;
    query.limit = 99;
  }
  if (ids && ids.length) {
    const where: WhereOptions[] = [
      [(firestore.FieldPath as any).documentId(), 'in', ids.slice(0, 10)],
    ];
    query.where = query.where ? query.where.concat(where) : where;
    query.limit = 10;
  }
  if (searchFor) {
    const where: WhereOptions[] = [
      ['email', '>=', searchFor],
      ['email', '<=', searchFor + '\uf8ff'],
    ];
    query.where = where;
  }

  if (names && names.length) {
    const where: WhereOptions[] = [['displayName', 'in', names]];
    query.where = where;
    query.limit = 99;
  }

  useFirestoreConnect(query);

  return {
    users,
    loaded,
    photographersList,
    usersList,
    usersByNames,
  };
};
