import React, { useMemo } from 'react';
import { Box, makeStyles, fade } from '@material-ui/core';
import { IonContent, IonPage } from '@ionic/react';
import { DashboardFooter, DashboardHeader } from '..';
import SectionHeader from '../SectionHeader';
import hp_1 from '../../../assets/img/hp_1.png';
import { useMediaQuery } from '../../../hooks';
import { Container } from '../../atoms';

type Props = {
  title?: string;
  subtitle?: string;
  children?: any;
  boxed?: boolean;
};

const useStyle = makeStyles(theme => ({
  wrapper: {
    background: `url(${hp_1}) center top / cover`,
    margin: `0px -${theme.spacing(2)}px`,
    boxShadow: `-100px -100px 0px 100px inset ${fade(
      theme.palette.secondary.dark,
      0.8,
    )}`,
  },
}));

const PageWrapper: React.FC<Props> = ({
  title,
  subtitle,
  children,
  boxed = true,
}) => {
  const style = useStyle();
  const { isMobile } = useMediaQuery();

  const renderContent = useMemo(
    () => (
      <Box paddingX={boxed ? 2 : 0}>
        {title && (
          <Box className={style.wrapper} padding={2} paddingBottom={0}>
            <SectionHeader
              title={title}
              variant="light"
              subtitle={subtitle}
              subtitleVariant="body2"
            />
          </Box>
        )}
        {children}
      </Box>
    ),
    [boxed, children, style.wrapper, subtitle, title],
  );

  if (isMobile) {
    return (
      <IonPage>
        <DashboardHeader />
        <IonContent scrollEvents>
          <Container>{renderContent}</Container>
        </IonContent>
        <DashboardFooter />
      </IonPage>
    );
  }
  return (
    <IonPage>
      <IonContent scrollEvents>
        <Container>
          <DashboardHeader />
          <Box flexGrow={1} paddingBottom={1}>
            {renderContent}
          </Box>
          <DashboardFooter />
        </Container>
      </IonContent>
    </IonPage>
  );
};

PageWrapper.displayName = 'PageWrapper';

export default React.memo(PageWrapper);
