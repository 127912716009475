import { TDocumentDefinitions } from 'pdfmake/interfaces';
import {
  styles,
  createSomeEmptySpace,
  createText,
  createInlineTextWithDifferentStyles,
} from '../../../utils/pdf';
import { pdfDataType } from './usePdfCreation';
import {
  drawQrCode,
  createDetailBoxWithTitle,
  createBoxWithTwoSubBoxes,
  createLabeledBoxWithComplexContent,
} from './createPdfElements';

const LINE_HEIGHT = 10;
const LINE_HEIGHT_VENUE_DETAILS = 10;
const SMALL_BOX_BASE_HEIGHT = 20;
const SMALL_BOX_BASE_WIDTH = 240;
const BIG_BOX_BASE_HEIGHT = 45;
const BIG_BOX_BASE_HEIGHT_WIDTH_TITLE = 74;
const EVENT_NAME_LINE_HEIGHT = 13;
const FULL_PAGE_WIDTH = 520;
const SMALLER_COLUMN_WIDTH = 170;
const LARGER_COLUMN_WIDTH = 350;

export const createDocumentDefinition = (
  pdfData: pdfDataType,
): TDocumentDefinitions => {
  const docDefinition: TDocumentDefinitions = {
    pageSize: 'A4',
    info: {
      title: `${pdfData.guestListName} (${pdfData.eventDetails.title})`,
      subject: pdfData.guestListName,
      keywords:
        pdfData.guestListName +
        pdfData.eventDetails.title +
        pdfData.venueDetails.name,
    },
    content: [
      {
        columns: [
          {
            stack: [drawQrCode(150, 'QR Code')],
            width: SMALLER_COLUMN_WIDTH,
          },
          createLabeledBoxWithComplexContent(
            LARGER_COLUMN_WIDTH,
            90 + pdfData.venueDetails.extraLines * LINE_HEIGHT_VENUE_DETAILS,
            pdfData.venueDetails.clubInfoText,
            [
              {
                stack: [
                  createInlineTextWithDifferentStyles(
                    `${pdfData.venueDetails.nameText}: `,
                    ['bold', 'size10'],
                    pdfData.venueDetails.name,
                    ['size10'],
                  ),
                  createSomeEmptySpace(['size5']),
                  createInlineTextWithDifferentStyles(
                    `${pdfData.venueDetails.addressText}: `,
                    ['bold', 'size10'],
                    pdfData.venueDetails.address,
                    ['size10'],
                  ),
                  createSomeEmptySpace(['size5']),
                  createInlineTextWithDifferentStyles(
                    `${pdfData.venueDetails.cityText}: `,
                    ['bold', 'size10'],
                    pdfData.venueDetails.city,
                    ['size10'],
                  ),
                  createSomeEmptySpace(['size5']),
                  createInlineTextWithDifferentStyles(
                    `${pdfData.venueDetails.regionText}: `,
                    ['bold', 'size10'],
                    pdfData.venueDetails.region,
                    ['size10'],
                  ),
                ],
                width: LARGER_COLUMN_WIDTH,
              },
            ],
          ),
        ],
      },
      {
        columns: [
          {
            stack: [
              createSomeEmptySpace(['size15']),
              createText(pdfData.guestListName, ['size15', 'bold']),
              createSomeEmptySpace(['size15']),
            ],
            width: FULL_PAGE_WIDTH,
            alignment: 'center',
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              createDetailBoxWithTitle(
                FULL_PAGE_WIDTH,
                BIG_BOX_BASE_HEIGHT_WIDTH_TITLE +
                  EVENT_NAME_LINE_HEIGHT *
                    pdfData.eventDetails.linesOfEventName,
                pdfData.eventDetails.mainLabel,
                SMALL_BOX_BASE_WIDTH,
                SMALL_BOX_BASE_WIDTH,
                SMALL_BOX_BASE_HEIGHT + LINE_HEIGHT,
                SMALL_BOX_BASE_HEIGHT + LINE_HEIGHT,
                pdfData.eventDetails.dateLabel,
                pdfData.eventDetails.timeLabel,
                pdfData.eventDetails.date,
                pdfData.eventDetails.time,
                pdfData.eventDetails.title,
                pdfData.eventDetails.linesOfEventName,
              ),
              createSomeEmptySpace(['size15']),
              createBoxWithTwoSubBoxes(
                FULL_PAGE_WIDTH,
                BIG_BOX_BASE_HEIGHT +
                  LINE_HEIGHT * pdfData.bookingDetails.maxNumberOfLines,
                pdfData.bookingDetails.mainLabel,
                SMALL_BOX_BASE_WIDTH,
                SMALL_BOX_BASE_WIDTH,
                SMALL_BOX_BASE_HEIGHT +
                  LINE_HEIGHT * pdfData.bookingDetails.linesOfBenefits,
                SMALL_BOX_BASE_HEIGHT +
                  LINE_HEIGHT * pdfData.bookingDetails.linesOfConditions,
                pdfData.bookingDetails.conditionsLabel,
                pdfData.bookingDetails.benefitsLabel,
                pdfData.bookingDetails.benefits,
                pdfData.bookingDetails.conditions,
              ),
              createSomeEmptySpace(['size15']),
              createBoxWithTwoSubBoxes(
                FULL_PAGE_WIDTH,
                BIG_BOX_BASE_HEIGHT +
                  LINE_HEIGHT * pdfData.guests.numberOfNameLines,
                pdfData.guests.mainLabel,
                SMALL_BOX_BASE_WIDTH,
                SMALL_BOX_BASE_WIDTH,
                SMALL_BOX_BASE_HEIGHT + LINE_HEIGHT,
                SMALL_BOX_BASE_HEIGHT +
                  LINE_HEIGHT * pdfData.guests.numberOfNameLines,
                pdfData.guests.guestsNumberLabel,
                pdfData.guests.guestsLabel,
                pdfData.guests.numberOfNameLines.toString(),
                pdfData.guests.names,
              ),
            ],
            width: FULL_PAGE_WIDTH,
          },
        ],
      },
    ],
    images: {
      qrCode: pdfData.qrCode,
    },
    styles,
  };

  return docDefinition;
};
