import React from 'react';
import { Box } from '@material-ui/core';
import { useGlobalStyles } from '../../../hooks';

type Props = {
  index: number;
  value: number;
  children?: React.ReactChild | React.ReactNode;
};

const TabPanel: React.FC<Props> = ({ value, index, children }) => {
  const styles = useGlobalStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === index && <Box className={styles.mv3}>{children}</Box>}
    </div>
  );
};

TabPanel.displayName = 'TabPanel';

export default React.memo(TabPanel);
