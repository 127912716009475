import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { Firebase } from '../../rrf.config';
import {
  authIsLoadedSelector,
  isAuthenticatedSelector,
  profileSelector,
  userIdSelector,
} from '../../store/selectors';
import { getNameInitials } from '../../store/utils/ui/getNameInitials';
import { getPreferredProfilePicture } from '../../store/utils/ui/getProfilePicture';

export const useAuthentication = () => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const profile = useSelector(profileSelector);
  const userId = useSelector(userIdSelector);
  const loaded = useSelector(authIsLoadedSelector);
  const profilePicture = getPreferredProfilePicture(profile);
  const profilePictures = profile.profilePictures || [];
  const initials = getNameInitials(profile.displayName);
  const isDeactivated = profile.deactivated;
  const logout = useCallback(() => firebase.logout(), [firebase]);
  const isProfileComplete =
    profile &&
    profile.city &&
    profile.gender &&
    profile.firstName &&
    profile.lastName &&
    profile.birthday &&
    profile.phone &&
    profile.status &&
    profile.preferredMusic;
  const emailVerified = profile.emailVerified;
  const phoneVerified = profile.phoneVerified;
  const verified = emailVerified && phoneVerified;
  const updateProfile = useCallback(
    async entry => {
      if (!userId) {
        return null;
      }
      setLoading(true);
      let newEntry = { ...entry, updated_at: new Date().getTime() };
      // Photographers now need to send a request
      // if (entry?.photographer) {
      //   let roles = profile?.roles || [];
      //   if (entry.photographer === 'yes') {
      //     if (!roles.includes('photographer')) {
      //       roles.push('photographer');
      //     }
      //   } else {
      //     roles = profile?.roles?.filter(role => role !== 'photographer') ?? [];
      //   }
      //   delete entry.photographer;
      //   newEntry.roles = roles;
      // }

      if (entry?.birthday) {
        newEntry.birthday = dayjs(
          entry.birthday,
          'D MMMM YYYY',
          'it',
        ).toString();
      }

      try {
        firestore.collection('users_meta').doc(userId).update(newEntry);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [userId, firestore],
  );
  const deleteProfile = useCallback(() => {
    firestore
      .collection('/users_meta')
      .doc(userId)
      .delete()
      .then(() => {
        firebase.auth().currentUser?.delete();
      });
    Firebase.auth().currentUser?.delete();
  }, [firebase, firestore, userId]);

  const deactivateProfile = useCallback(() => {
    updateProfile({
      deactivated: true,
      updated_at: new Date().getTime(),
    })?.then(() => logout());
  }, [logout, updateProfile]);

  const reactivateProfile = useCallback(() => {
    updateProfile({ deactivated: false, updated_at: new Date().getTime() });
  }, [updateProfile]);

  const updatePassword = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const credential = Firebase.auth.EmailAuthProvider.credential(
        email,
        password,
      );
      const auth = Firebase.auth();
      if (auth) {
        if (
          auth?.currentUser?.providerData.find(
            prodiver => prodiver?.providerId === 'password',
          )
        ) {
          try {
            await auth?.currentUser?.updatePassword(password);
            return 'profile.passwordUpdated';
          } catch (error) {
            console.error(error);
            throw new Error(error.code);
          }
        }
        try {
          await auth?.currentUser?.linkWithCredential(credential);
          return 'profile.passwordUpdated';
        } catch (error) {
          console.error(error);
          throw new Error(error.code);
        }
      }
      return null;
    },
    [],
  );

  const sendResetPasswordEmail = useCallback(
    async ({ email }: { email: string }) => {
      const auth = await Firebase.auth();
      try {
        await auth.sendPasswordResetEmail(email);
        return 'profile.resetPasswordEmailSent';
      } catch (e) {
        console.error(e);
        return e.code;
      }
    },
    [],
  );

  return {
    profile,
    loaded,
    userId,
    verified,
    phoneVerified,
    emailVerified,
    logout,
    initials,
    loading,
    isDeactivated,
    deleteProfile,
    updateProfile,
    profilePicture,
    profilePictures,
    isAuthenticated,
    isProfileComplete,
    deactivateProfile,
    reactivateProfile,
    updatePassword,
    sendResetPasswordEmail,
  };
};
