import React from 'react';
import { Typography } from '@material-ui/core';

const TextArea = () => {
  return (
    <>
      <div style={{ padding: '20px 40px' }}>
        <Typography variant="h6" style={{ color: 'white', fontWeight: 700 }}>
          Chi siamo?
        </Typography>
        <Typography style={{ color: 'white' }}>
          Tilllate Italia è il più grande portale di night life non solo in
          Italia ma in tutto il mondo.
          <br />
          Il modo migliore per dare visibilità ai tuoi eventi ed aumentarei tuoi
          ospiti!
        </Typography>
      </div>
      <div style={{ padding: '20px 40px' }}>
        <Typography variant="h6" style={{ color: 'white', fontWeight: 700 }}>
          Come funziona?
        </Typography>
        <Typography style={{ color: 'white' }}>
          Organizza il tuo evento e carica tutto su tilllate.world
          <br />
          Da quel momento tutti potranno vederlo e iscriversi alla tua Guestlist
          personale tilllate!
          <br />
          Specialmente persone che prima non sapevano neanche della tua
          esistenza arriveranno ora a conoscerti.
          <br />
          In questo modo andrai a raggiungere moltissime persone nuove.
        </Typography>
      </div>
      <div style={{ padding: '20px 40px' }}>
        <Typography variant="h6" style={{ color: 'white', fontWeight: 700 }}>
          Quanto costa?
        </Typography>
        <Typography style={{ color: 'white' }}>
          Il servizio d’iscrizione alle Guestlist di tilllate.world è{' '}
          <b>gratuito</b>!
        </Typography>
      </div>
    </>
  );
};

export default TextArea;
