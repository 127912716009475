import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { CardListWidget, InfiniteScroller } from '..';
import VirtualizedList from '../VirtualizedList';
import { NewsItem } from '../../../store/types';
import Item from './Item';

type Props = {
  news: NewsItem[];
  loading?: boolean;
  loadMore?: () => void;
  totalResults?: number;
  page?: number;
};

const NewsList: React.FC<Props> = ({
  news,
  loading,
  loadMore,
  totalResults,
  page,
}) => {
  const renderItem = useCallback(
    (newsItem: NewsItem) => <Item newsItem={newsItem} />,
    [],
  );

  if (loading) {
    return (
      <Box marginTop={3}>
        <VirtualizedList
          data={new Array(3).fill(true)}
          renderItem={() => <CardListWidget.Skeleton withLogo withActions />}
        />
      </Box>
    );
  }
  return (
    <Box marginTop={3}>
      {loadMore && totalResults && page !== undefined ? (
        <InfiniteScroller
          data={news}
          renderItem={renderItem}
          loadMore={loadMore}
          totalResults={totalResults}
          page={page}
        />
      ) : (
        <VirtualizedList data={news} renderItem={renderItem} />
      )}
    </Box>
  );
};

NewsList.displayName = 'NewsList';

export default React.memo(NewsList);
