import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { FiberNewTwoTone, KeyboardArrowRightRounded } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  TillDateNavigator,
  TillDatePageWrapper,
} from '../../components/molecule';
import { useAuthentication } from '../../hooks';
import useChatRoom from '../../hooks/dateRooms/useChatRoom';
import useMyDateRooms from '../../hooks/dateRooms/useMyDateRooms';
import { ChatRoom } from '../../store/types/dateRooms';

const useStyle = makeStyles(() => ({
  navigator: {
    backgroundColor: '#f8f7f4',
    width: '100%',
    position: 'fixed',
    zIndex: 99,
  },
}));

const TillDateMatch = () => {
  const history = useHistory();
  const { userId } = useAuthentication();
  const style = useStyle();
  const { selectedRoom } = useMyDateRooms();
  const { id } = selectedRoom || {};
  const { chatRooms, registerOpenedChatRoom } = useChatRoom({
    dateRoom: selectedRoom,
  });

  const handleOpenChat = useCallback(
    (room, playerId) => {
      registerOpenedChatRoom(playerId);
      history.push(`/tilldate-chat/${id}/${room?.id}`);
    },
    [history, id, registerOpenedChatRoom],
  );

  const renderChatItem = useCallback(
    (room: ChatRoom) => {
      const partnerId = room.users.find(u => u !== userId);
      if (partnerId) {
        const partnerData = room.usersData[partnerId];
        if (partnerData) {
          return (
            <React.Fragment key={room.id}>
              <ListItem button onClick={() => handleOpenChat(room, partnerId)}>
                <ListItemAvatar>
                  <Avatar
                    alt={partnerData.nickname}
                    src={partnerData.profilePicture}
                  />
                </ListItemAvatar>
                <ListItemText primary={partnerData.nickname} />
                <ListItemSecondaryAction>
                  {!room.openedBy.includes(userId) && (
                    <FiberNewTwoTone color="error" />
                  )}
                  <KeyboardArrowRightRounded />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" />
            </React.Fragment>
          );
        }
      }
    },
    [handleOpenChat, userId],
  );

  return (
    <TillDatePageWrapper unbox>
      <Box p={2} className={style.navigator}>
        <TillDateNavigator selectedRoom={selectedRoom} />
      </Box>
      <Box paddingTop={7}>
        <List>{chatRooms.map(room => renderChatItem(room))}</List>
      </Box>
    </TillDatePageWrapper>
  );
};

export default TillDateMatch;
