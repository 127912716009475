import React from 'react';
import { NewsItem } from '../../../store/types';
import TopNewsCarousel from './TopNewsCarousel';

type Props = {
  items: number;
  loading?: boolean;
  news?: NewsItem[];
};

const TopNews: React.FC<Props> = ({ items, news, loading }) => {
  return (
    <>
      <TopNewsCarousel
        items={items}
        loading={loading}
        news={news?.slice(0, 8)}
      />
    </>
  );
};

TopNews.displayName = 'TopNews';

export default React.memo(TopNews);
