import React from 'react';
import Swiper from 'react-id-swiper';
import useStyle from './style';
import 'swiper/css/swiper.css';

type Props = {
  items?: number;
  pagination?: boolean;
  navigation?: boolean;
  equalHeight?: boolean;
  paginationPosition?: 'hover' | 'below';
  direction?: 'horizontal' | 'vertical';
  children?: any;
};

const Slider: React.FC<Props> = ({
  items,
  pagination,
  navigation,
  equalHeight,
  paginationPosition = 'hover',
  direction = 'horizontal',
  children,
}) => {
  const style = useStyle({ paginationPosition });
  return (
    <div
      className={[
        paginationPosition === 'below' ? style.paginationBelow : '',
        equalHeight ? style.equalHeight : '',
        pagination ? 'withPagination' : '',
        style.pagination,
        style.navigation,
      ].join(' ')}
    >
      <Swiper
        // @ts-ignore
        spaceBetween={10}
        slidesPerView={items}
        shouldSwiperUpdate={true}
        direction={direction}
        navigation={
          navigation
            ? {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
            : false
        }
        lazy
        pagination={
          pagination && {
            el:
              direction === 'vertical'
                ? '.swiper-pagination.swiper-pagination-v'
                : '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          }
        }
        breakpoints={{
          1024: {
            slidesPerView: items ? items : 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: items ? Math.min(items, 2) : 2,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: items ? Math.min(items, 1) : 1,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: items ? Math.min(items, 1) : 1,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: items ? Math.min(items, 1) : 1,
            spaceBetween: 10,
          },
        }}
      >
        {children}
      </Swiper>
    </div>
  );
};

Slider.displayName = 'Slider';

export default React.memo(Slider);
