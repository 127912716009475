import { Box, Grid, IconButton } from '@material-ui/core';
import { CheckCircleOutlineTwoTone } from '@material-ui/icons';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../hooks';
import { Spacer } from '../../atoms';
import getCroppedImg from './cropImage';
import useStyle from './style';

type Props = {
  coverUrl: string;
  setCover: (files: File[]) => void;
};

const DropzoneWithCrop: React.FC<Props> = ({ coverUrl, setCover }) => {
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const style = useStyle({
    bg: coverUrl || undefined,
  });
  const [fileName, setFileName] = useState<string | null>(null);
  const [tempImg, setTempImg] = useState<string | null>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((_, area) => {
    setCroppedAreaPixels(area);
  }, []);

  const onConfirm = useCallback(async () => {
    if (tempImg) {
      try {
        const croppedImage = await getCroppedImg(tempImg, croppedAreaPixels);
        const file: any = croppedImage;
        file.name = fileName;
        file.lastModifiedDate = new Date();
        setCover([file]);
        setTempImg(null);
        setFileName(null);
      } catch (e) {
        console.error(e);
      }
    }
  }, [croppedAreaPixels, fileName, setCover, tempImg]);

  const handleDrop = useCallback((files: File[]) => {
    setTempImg(URL.createObjectURL(files[0]));
    setFileName(files[0].name);
  }, []);

  return (
    <Grid item md={12} className={globalStyles.relative}>
      {tempImg ? (
        <Box className={style.cropperWrapper}>
          <Cropper
            image={tempImg}
            crop={crop}
            zoom={zoom}
            aspect={4 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <IconButton onClick={onConfirm} className={style.confirmBtn}>
            <CheckCircleOutlineTwoTone fontSize="large" />
          </IconButton>
        </Box>
      ) : (
          <DropzoneAreaBase
            classes={style}
            fileObjects={[]}
            maxFileSize={30000000}
            filesLimit={1}
            dropzoneText={t('general.uploadCover')}
            getFileLimitExceedMessage={limit =>
              `Hai superato il limite massimo di foto che puoi caricare in quest'area: ${limit}`
            }
            acceptedFiles={['image/*']}
            onDrop={handleDrop}
          />
        )}
      <Spacer height="30px" />
    </Grid>
  );
};

DropzoneWithCrop.displayName = 'DropzoneWithCrop';

export default React.memo(DropzoneWithCrop);
