import { makeStyles } from '@material-ui/core';

export default makeStyles({
  section: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  titlePage: {
    padding: 0,
    margin: '20px 0 0 0 ',
  },
  subTitlePage: {
    padding: 0,
    margin: '8px 0 0 0 ',
    fontWeight: 'normal',
    fontSize: '20px',
  },
  term: { fontSize: '17px' },
  text: {
    lineHeight: '21px',
    fontSize: '14px',
    letterSpacing: '0.8px',
  },
});
