import { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Event,
  NewsItem,
  Organization,
  PhotoAlbum,
  Venue,
} from '../../store/types';
import { useAuthentication } from './useAuthentication';

type Entity = Venue | Organization | Event | NewsItem | PhotoAlbum;

export const useEditGuard = (entity: Entity) => {
  const { userId, loaded, profile: user } = useAuthentication();
  const history = useHistory();
  useEffect(() => {
    if (entity && loaded) {
      if (!userId) {
        history.push('/home');
      }

      if (
        !(
          entity?.created_by === userId ||
          user?.roles?.includes('admin') ||
          (entity as Event)?.admins?.includes(userId) ||
          (entity as Event)?.venue?.created_by === userId ||
          (entity as Event)?.venue?.admins?.includes(userId) ||
          ((entity as NewsItem)?.newsCategories &&
            user?.roles?.includes('superEditor'))
        )
      ) {
        history.push('/home');
      }
    }
  }, [entity, userId, loaded, history, user]);
};
