import { Grid } from '@material-ui/core';
import React from 'react';
import { useMediaQuery } from '../../../hooks';

export default React.memo(
  ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const childrenList = Array.isArray(children) ? children : [children];
    const { isTabletOrMobile } = useMediaQuery();
    return (
      <Grid component="div" container spacing={1} justify="center">
        {childrenList.map(
          (child: JSX.Element, key: number): JSX.Element | null => {
            if (child) {
              const xs = child.props
                ? child.props.xs || child.props.w || 12
                : 12;
              const md = child.props ? child.props.md || child.props.w || 6 : 6;
              const lg = child.props ? child.props.lg || child.props.w || 4 : 4;
              return (
                <Grid
                  component="div"
                  key={`website-summary-${key}`}
                  item
                  xs={isTabletOrMobile ? null : xs}
                  md={md}
                  lg={lg}
                >
                  {child}
                </Grid>
              );
            }
            return null;
          },
        )}
      </Grid>
    );
  },
);
