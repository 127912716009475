import React, { useCallback } from 'react';
import { Button, Chip } from '@material-ui/core';
import { ShareOutlined, VerifiedUser } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Organization } from '../../../store/types';
import { CardListWidget, ShareButton } from '..';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';

type Props = {
  organization: Organization;
  label?: string;
};

const Item: React.FC<Props> = ({ label, organization }) => {
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();
  const { slug } = organization || {};
  const history = useHistory();
  const { isMobile } = useMediaQuery();
  const goTo = useCallback(() => history.push(`/organizations/${slug}`), [
    history,
    slug,
  ]);
  return (
    <CardListWidget
      onClick={goTo}
      label={label}
      logoUrl={organization.logoUrl}
      coverUrl={organization.coverUrl}
      title={organization.name}
      subtitle={organization.address}
      cardActions={[
        {
          type: 'primary',
          label: t('event.future'),
        },
        {
          type: 'secondary',
          label: t('event.past'),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tilllate.world/organizations/${organization.slug}`}
              renderButton={openPopOver => (
                <Button
                  className={globalStyle.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={e => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
      renderTopIcons={
        organization.isVerified && (
          <CardListWidget.Icon
            icon={<VerifiedUser />}
            label={t('organization.isVerified')}
            activeLabel={t('organization.isVerified')}
            active
          />
        )
      }
    >
      {organization.musicStyles?.length ? (
        organization.musicStyles
          .slice(0, isMobile ? 3 : 4)
          .map((music: any) => (
            <Chip
              component="span"
              key={`${organization.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(' ')}
            />
          ))
      ) : (
        <Chip
          component="span"
          label={t('shared.musicStyles.noStyle')}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(' ')}
        />
      )}
    </CardListWidget>
  );
};

Item.displayName = 'Item';

export default React.memo(Item);
