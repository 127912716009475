import { CanvasElement, ContentColumns } from 'pdfmake/interfaces';

export const drawVerticalLine = (
  lineLength: number,
  lineWidth: number,
): ContentColumns => {
  return {
    columns: [
      {
        canvas: [
          {
            type: 'line',
            x1: 1,
            y1: 1,
            x2: 1,
            y2: lineLength,
            lineWidth,
          },
        ],
        width: 10,
      },
    ],
  };
};

export const drawHorizontalLine = (
  lineLength: number,
  lineWidth: number,
): ContentColumns => {
  return {
    columns: [
      {
        canvas: [
          {
            type: 'line',
            x1: 1,
            y1: 1,
            x2: lineLength,
            y2: 1,
            lineWidth,
          },
        ],
      },
    ],
  };
};

export const createSomeEmptySpace = (style: string[]) => {
  return { text: ' ', style };
};

export const createText = (text: string, style: string[]) => {
  return { text, style };
};

export const createInlineTextWithDifferentStyles = (
  text1: string,
  style1: string[],
  text2: string,
  style2: string[],
) => {
  return {
    text: [
      { text: text1, style: style1 },
      { text: text2, style: style2 },
    ],
  };
};

export const createGapBetweenColumns = (width: number) => {
  return {
    stack: [{ text: '' }],
    width,
  };
};

export const createImage = (
  base64Image: unknown | undefined,
  width: number,
  height?: number,
) => {
  if (base64Image && typeof base64Image === 'string') {
    return height
      ? { image: base64Image, width, height }
      : { image: base64Image, width };
  }
  return createSomeEmptySpace(['size0']);
};

export const createFourColumnCenteredLayout = (data: string[][]) => {
  return {
    alignment: 'center',
    stack: [
      {
        columns: [
          {
            stack: data[0],
            width: 120,
          },
          data[1].length
            ? drawVerticalLine(data[1].length * 15, 1.5)
            : drawVerticalLine(15, 1.5),
          {
            stack: data[1],
            width: 122,
          },
          {
            stack: data[2],
            width: 122,
          },
          {
            stack: data[3],
            width: 122,
          },
        ],
      },
    ],
  };
};

export const createRectWithLabel = (
  width: number,
  height: number,
  label: string,
): CanvasElement[] => {
  const labelLengthMultiplier = label.length <= 5 ? 5.5 : 4.5;
  return [
    {
      type: 'line',
      x1: 0,
      y1: 0,
      x2: 10,
      y2: 0,
      lineWidth: 0.2,
      lineColor: 'gray',
    },
    {
      type: 'line',
      x1: 10 + label.length * labelLengthMultiplier + 5,
      y1: 0,
      x2: width,
      y2: 0,
      lineWidth: 0.2,
      lineColor: 'gray',
    },
    {
      type: 'line',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: height,
      lineWidth: 0.2,
      lineColor: 'gray',
    },
    {
      type: 'line',
      x1: 0,
      y1: height,
      x2: width,
      y2: height,
      lineWidth: 0.2,
      lineColor: 'gray',
    },
    {
      type: 'line',
      x1: width,
      y1: 0,
      x2: width,
      y2: height,
      lineWidth: 0.2,
      lineColor: 'gray',
    },
  ];
};

export const createLabeledBox = (
  width: number,
  height: number,
  relHeightCorrection: number,
  label: string,
  content: any,
): ContentColumns => {
  return {
    columns: [
      {
        stack: [
          {
            canvas: createRectWithLabel(width, height, label),
          },
          {
            columns: content,
            relativePosition: {
              x: 10,
              y: -height + relHeightCorrection,
            },
          },
          {
            columns: [
              {
                noWrap: true,
                text: label,
                fontSize: 8,
                bold: true,
              },
            ],
            relativePosition: {
              x: 15,
              y: -height - 4.5,
            },
          },
        ],
      },
    ],
  };
};
