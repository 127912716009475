import {
  Box,
  Card,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import { isPlatform, useIonViewDidEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ReportAbuse, TillDatePageWrapper } from '../../components/molecule';
import { useAuthentication } from '../../hooks';
import useChat from '../../hooks/dateRooms/useChat';
import useMyDateRoomPlayer from '../../hooks/dateRooms/useMyDateRoomPlayer';
import { Emoji } from '../../components/atoms';
import useStyle from './style';
import Message from './Message';

const { Keyboard } = Plugins;

type Props = {
  match: {
    params: { [key: string]: string };
  };
};
const TillDateChatScreen: React.FC<Props> = ({ match }) => {
  const { chatRoomId, dateRoomId } = match.params;
  const [message, setMessage] = useState<string>('');
  const { userId } = useAuthentication();
  const { chatRoomMessages, sendMessage } = useChat({ chatRoomId, dateRoomId });
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);
  const style = useStyle({ visible });
  const isCapacitor = isPlatform('capacitor');
  const history = useHistory();
  const { t } = useTranslation();
  const { addPlayerToDislikes, removePlayerFromLikes } = useMyDateRoomPlayer();

  const scrollToBottom = useCallback((behavior?: 'auto' | 'smooth') => {
    messagesEndRef.current?.scrollIntoView({ behavior: behavior || 'auto' });
  }, []);
  const [userToReport, setUserToReport] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!userToReport) {
      for (let i = 0; i < chatRoomMessages.length; i++) {
        if (chatRoomMessages[i].fromId !== userId) {
          setUserToReport(chatRoomMessages[i].fromId);
          break;
        }
      }
    }
    //eslint-disable-next-line
  }, [chatRoomMessages]);

  useIonViewDidEnter(() => {
    scrollToBottom();
    if (chatRoomMessages.length && !visible) {
      setTimeout(() => setVisible(true), 500);
    }
  }, [chatRoomMessages, scrollToBottom, visible]);

  useIonViewDidEnter(() => {
    if (isCapacitor) {
      Keyboard.addListener('keyboardDidShow', () => {
        scrollToBottom('smooth');
      });
      Keyboard.addListener('keyboardDidHide', () => {
        scrollToBottom('smooth');
      });
    }
  }, []);

  useEffect(() => {
    scrollToBottom('smooth');
    if (chatRoomMessages.length && !visible) {
      setTimeout(() => setVisible(true), 500);
    }
  }, [chatRoomMessages.length, visible, scrollToBottom]);

  const handleSend = useCallback(() => {
    sendMessage(message);
    setMessage('');
    inputRef.current?.focus();
  }, [message, sendMessage]);

  const isSpamming = useMemo(() => {
    return (
      chatRoomMessages.length >= 3 &&
      chatRoomMessages
        .slice(chatRoomMessages.length - 3)
        .every(msg => msg.fromId === userId)
    );
  }, [chatRoomMessages, userId]);

  return (
    <TillDatePageWrapper canGoBack hideFooter>
      <Box className={style.messagesWrapper}>
        <div className={style.report}>
          <ReportAbuse
            category="users_meta"
            item={{
              type: 'ChatRoom',
              messages: chatRoomMessages,
              dateRoomId: dateRoomId,
              chatRoomId: chatRoomId,
              reported_user: userToReport,
            }}
            //@ts-ignore
            chatReport={
              userToReport
                ? () => {
                    addPlayerToDislikes(userToReport);
                    removePlayerFromLikes(userToReport);
                    history.push('/tilldatematch');
                  }
                : undefined
            }
          />
        </div>
        <Box>
          {chatRoomMessages.map(msg => (
            <Message
              message={msg}
              myMessage={msg.fromId === userId}
              key={msg.id}
            />
          ))}
          <div ref={messagesEndRef} />
        </Box>
      </Box>
      <Box className={style.messageInputWrapper}>
        {isSpamming ? (
          <Card className={style.isSpamming}>
            <Typography variant="caption">
              {t('tilldate.isSpamming')} <Emoji emoji="😉" />
            </Typography>
          </Card>
        ) : (
          <TextField
            inputRef={inputRef}
            onChange={e => setMessage(e.target.value)}
            value={message}
            disabled={isSpamming}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={handleSend}
                  disabled={!message}
                >
                  <Send />
                </IconButton>
              ),
            }}
          />
        )}
      </Box>
    </TillDatePageWrapper>
  );
};

export default TillDateChatScreen;
