import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  #button {
    cursor: pointer;

    #icon {
      font-size: 2rem;
      color: #fdb844;
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 1));
    }
  }
`;
