import React, { useCallback } from 'react';
import { Avatar, Chip, makeStyles } from '@material-ui/core';
import { EditTwoTone } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

type Props = {
  toggleUpdateDialog: Function;
};

const useStyles = makeStyles({
  absolute: {
    // position: 'absolute',
    // right: '20px',
    // top: '220px',
  },
});

const RegisterNewsCategoryBtn: React.FC<Props> = ({ toggleUpdateDialog }) => {
  const { t } = useTranslation();

  const styles = useStyles();

  const onTogleUpdateDialog = useCallback(() => {
    toggleUpdateDialog();
  }, [toggleUpdateDialog]);

  return (
    <Chip
      className={styles.absolute}
      onClick={onTogleUpdateDialog}
      label={t('newsCategory.title')}
      avatar={
        <Avatar>
          <EditTwoTone style={{ width: 18 }} />
        </Avatar>
      }
    />
  );
};

RegisterNewsCategoryBtn.displayName = 'RegisterNewsCategoryBtn';

export default React.memo(RegisterNewsCategoryBtn);
