import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestore,
} from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { useMedia } from '..';
import { Venue } from '../../store/types';
import { findSlug } from '../../utils/firebase';
import { useAuthentication } from '../auth';
import { venueSelector } from '../../store/selectors/venues';

type Props = {
  slugOrId?: string;
};

type RegisterVenue = Partial<Venue> & {
  cover?: File[] | string;
  logo?: File[] | string;
};

type UseVenue = {
  venue: Venue;
  venueId?: string;
  loading?: boolean;
  loaded?: boolean;
  empty?: boolean;
  registerVenue: (props: RegisterVenue) => Promise<string>;
  updateVenue: (props: RegisterVenue) => void;
  isAdmin: boolean;
};

export const useVenue = (props: Props = {}): UseVenue => {
  const { slugOrId } = props;
  const [loading, setLoading] = useState<boolean>();
  const history = useHistory();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { uploadMedia, getMediaData, deleteMedia } = useMedia();
  const venue = useSelector(venueSelector(slugOrId || ''));
  const venueId = venue?.id;

  const loaded = isLoaded(venue);
  const empty = isEmpty(venue);

  const isAdmin =
    venue?.created_by === userId ||
    venue?.admins?.includes(userId) ||
    profile?.roles?.includes('admin');

  const registerVenue = useCallback(
    async ({ cover, logo, ...values }: RegisterVenue) => {
      let coverUrl: string | null = null;
      let logoUrl: string | null = null;
      setLoading(true);
      const slug: string = await findSlug(values.name!, '/clubs');
      const pageRef = await firestore.collection('/clubs').add({
        ...values,
        created_by: userId,
        slug,
        isVerified: false,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      if ((!cover || !cover[0]) && (!logo || !logo[0])) {
        setLoading(false);
        history.push(`/venues/${slug}`);
        return slug;
      }

      if (cover && cover[0]) {
        const coverRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: cover[0],
        });
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      if (logo && logo[0]) {
        const logoRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: logo[0],
        });
        const logoMedia = await getMediaData(logoRef.path);
        logoUrl = logoMedia?.url || null;
      }
      await firestore
        .collection('/clubs')
        .doc(pageRef.id)
        .update({ logoUrl, coverUrl });
      setLoading(false);

      history.push(`/venues/${slug}`);
      return slug;
    },
    [firestore, getMediaData, history, uploadMedia, userId],
  );

  const updateVenue = useCallback(
    async ({ cover, admins, logo, ...values }: RegisterVenue) => {
      if (!venue || !venueId) {
        return;
      }
      let coverUrl: string | null = venue.coverUrl || '';
      let logoUrl: string | null = venue.logoUrl || '';
      setLoading(true);
      if (!admins) {
        admins = [];
      }
      await firestore
        .collection('/clubs')
        .doc(venueId)
        .update({ ...values, admins, updated_at: new Date().getTime() });
      if ((!cover || !cover[0]) && (!logo || !logo[0])) {
        setLoading(false);
        return;
      }
      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/pages/${venueId}`,
          file: cover[0],
        });
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      if (Array.isArray(logo) && logo[0]) {
        const oldLogo = logoUrl;
        const logoRef = await uploadMedia({
          path: `/images/pages/${venueId}`,
          file: logo[0],
        });
        if (oldLogo) {
          await deleteMedia({ url: oldLogo });
        }
        const logoMedia = await getMediaData(logoRef.path);
        logoUrl = logoMedia?.url || null;
      }
      await firestore
        .collection('/clubs')
        .doc(venueId)
        .update({ logoUrl, coverUrl });
      setLoading(false);

      history.push(`/venues/${venue.slug}`);
    },
    [
      deleteMedia,
      firestore,
      getMediaData,
      history,
      uploadMedia,
      venue,
      venueId,
    ],
  );

  useEffect(() => {
    if (!venueId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'clubs',
      };

      if (slugOrId) {
        query.where = [['slug', '==', slugOrId]];
      }
      firestore.get(query);
    }
  }, [venueId, slugOrId, firestore]);

  return {
    venue,
    venueId,
    loading,
    loaded,
    empty,
    registerVenue,
    updateVenue,
    isAdmin,
  };
};
