import { useCallback, useState } from 'react';
import { functions } from '../../rrf.config';

type SendNotification = {
  to: string;
  title: string;
  body: string;
  onClick?: string;
};

type SendNotificationToTopic = {
  topic: string;
  title: string;
  body: string;
  onClick?: string;
};

type UseSendEmail = {
  loading: boolean;
  success: boolean;
  error: boolean;
  sendNotification: (params: SendNotification) => void;
  sendNotificationToTopic: (params: SendNotificationToTopic) => void;
};

export const useSendNotification = (): UseSendEmail => {
  const send = functions.httpsCallable('sendPushNotificationToUsers');
  const sendToTopic = functions.httpsCallable('sendPushNotificationToTopic');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const sendNotification = useCallback(
    ({ to, title, body, onClick }) => {
      setLoading(true);
      send({ to, title, body, onClick })
        .then(() => setSuccess(true))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    [send],
  );
  const sendNotificationToTopic = useCallback(
    ({ topic, title, body, onClick }) => {
      setLoading(true);
      sendToTopic({ topic, title, body, onClick })
        .then(() => setSuccess(true))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    [sendToTopic],
  );
  return { loading, success, error, sendNotification, sendNotificationToTopic };
};
