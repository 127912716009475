import React from 'react';
import { Box, Grid, AppBar, Typography, Divider } from '@material-ui/core';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import logo from '../../../assets/img/logo_w.png';
import { Logo, Spacer } from '../../atoms';
import FooterMenuLink from '../FooterMenuLink';
import Menu from '../Menu';
import FollowUs from '../FollowUs';
import useStyle from './style';
import { Link } from 'react-router-dom';

type Props = {};
const DashboardFooter: React.FC<Props> = () => {
  const globalStyle = useGlobalStyles();
  const style = useStyle();
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return (
      <Box
        className={[
          globalStyle.bgSecondaryDark,
          globalStyle.safeAreaBottom,
          style.tabBarWrapper,
        ].join(' ')}
      >
        <Menu tabBar />
      </Box>
    );
  }
  return (
    <>
      <Box className={globalStyle.bgSecondaryDark} p={2}>
        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="baseline"
        >
          <Grid item md={12}>
            <Box textAlign="center" paddingBottom={2}>
              <Logo logo={logo} width="250px" to="/home" />
            </Box>
            <Divider className={style.divider} />
          </Grid>
          <Grid md={12} container justify="space-around" item>
            <Grid item md={2} />
            <Grid item md={8}>
              <Box style={{ columns: 3, textAlign: 'center' }}>
                <FooterMenuLink title="Venues" to="/venues" />
                <FooterMenuLink title="Events" to="/events" />
                <FooterMenuLink title="News" to="/news" />
                <FooterMenuLink title="Organizations" to="/organizations" />
                <FooterMenuLink title="Artists" to="/artists" />
                <FooterMenuLink title="Photos" to="/photos" />
                <FooterMenuLink title="Contact us" to="/contact" />
                <FooterMenuLink title="About us" to="/about" />
                <FooterMenuLink title="Regione" to="/regione" />
              </Box>
            </Grid>
            <Grid item md={2} />
            <Grid item md={12}>
              <FollowUs />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid item md={12} style={{ padding: isMobile ? '10px 0' : '0' }}>
        <AppBar position="relative">
          <Typography align="center" variant="subtitle1">
            <Link to="/privacy-policy">Privacy policy - Terms of Use</Link>
          </Typography>
          <Spacer height="5px" />

          <Typography align="center" variant="subtitle2">
            All Rights Reserved.
          </Typography>
          <Spacer height="5px" />
        </AppBar>
      </Grid>
    </>
  );
};

DashboardFooter.displayName = 'DashboardFooter';

export default React.memo(DashboardFooter);
