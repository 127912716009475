import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PageWrapper, SectionHeader } from '../../components/molecule';
import {
  TopEventsCarousel,
  TopNews,
  TopStories,
  ClubsCarousel,
} from '../../components/organisms';
import { Spacer } from '../../components/atoms';
// import PhotoGallerySlider from '../../components/organisms/PhotoGallery/PhotoGallerySlider';
import {
  useAbuseFilter,
  useAuthentication,
  useEvents,
  useNews,
} from '../../hooks';
import { RootState } from '../../store/reducers';
// import { useSendNotification } from '../../hooks/notifications/useSendNotification';
// import { useRegisterToTopic } from '../../hooks/notifications/useRegisterToTopic';

const Homepage = () => {
  const { isAuthenticated, userId } = useAuthentication();

  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const { filteredEvents, suggestedEvents, loaded } = useEvents({
    region: region?.id,
    future: true,
  });
  const { t } = useTranslation();
  const regionText = region ? ` (${t('shared.regions.' + region?.id)})` : '';
  const { topNews, loaded: loadedNews } = useNews();

  // const { sendNotification, sendNotificationToTopic } = useSendNotification();
  // const { registerToTopic } = useRegisterToTopic();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tilllate World - Homepage</title>
        <link rel="canonical" href="https://tilllate.world" />
      </Helmet>
      <PageWrapper>
        <Box marginX={-2}>
          <TopStories />
        </Box>
        {/* <Button
          onClick={() =>
            sendNotification({
              to: userId,
              title: 'test not',
              body: 'test body',
            })
          }
        >
          send not
        </Button>
        <Button
          onClick={() =>
            sendNotificationToTopic({
              topic: 'topic-test-3',
              title: 'test topic',
              body: 'test body',
            })
          }
        >
          send top
        </Button>
        <Button
          onClick={() =>
            registerToTopic({
              topic: 'topic-test-3',
            })
          }
        >
          register
        </Button>
			*/}
        <Spacer height="50px" />
        <Box paddingX={2} justifyContent="center">
          {isAuthenticated && suggestedEvents.length ? (
            <>
              <SectionHeader
                title={t('profile.suggestedEvents')}
                subtitle={regionText}
                linkUrl="/events"
              />
              <TopEventsCarousel
                items={3}
                loading={!loaded}
                //@ts-ignore
                events={useAbuseFilter.filterAbuseReports(
                  suggestedEvents.splice(0, 6),
                  userId,
                )}
              />
              <Spacer height="50px" />
            </>
          ) : null}
          {filteredEvents.length ? (
            <>
              <SectionHeader
                title={t('event.comingEvents')}
                subtitle={regionText}
                linkUrl="/events"
              />
              <TopEventsCarousel
                items={3}
                loading={!loaded}
                //@ts-ignore
                events={useAbuseFilter.filterAbuseReports(
                  filteredEvents.splice(0, 6),
                  userId,
                )}
              />
              <Spacer height="50px" />
            </>
          ) : null}

          {topNews.length ? (
            <>
              <SectionHeader title={t('news.news')} linkUrl="/news" />
              <TopNews
                items={3}
                loading={!loadedNews}
                news={useAbuseFilter
                  .filterAbuseReportsSync(topNews, userId)
                  .slice(0, 8)}
              />
            </>
          ) : null}
          <Spacer height="50px" />
          {/* <AdsRow />
          <Spacer height="50px" /> */}
          <SectionHeader
            title={t('general.venues')}
            subtitle={regionText}
            linkUrl="/venues"
          />
          <ClubsCarousel items={3} />
          {/* <Spacer height="50px" />
          <SectionHeader title={t('event.photoGalleries')} linkUrl="/photos" />
          <PhotoGallerySlider items={3} /> */}
          <Spacer height="100px" />
        </Box>
      </PageWrapper>
    </>
  );
};

export default Homepage;
