import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFirestoreQuerySetting, useFirestore } from 'react-redux-firebase';
import { useNewsCategories } from '..';
import { newsCategorySelector } from '../../store/selectors/newsCategories';
import { NewsCategory } from '../../store/types';
import { findSlug } from '../../utils/firebase';
import { useAuthentication } from '../auth';

type Props = {
  slugOrId?: string;
};

type RegisterNewsCategory = Partial<NewsCategory>;

type UseNewsCategory = {
  newsCategory: NewsCategory;
  newsCategoryId?: string;
  loading?: boolean;
  loaded?: boolean;
  registerNewsCategory: (props: RegisterNewsCategory) => void;
  updateNewsCategory: (props: RegisterNewsCategory) => void;
  isAdmin: boolean;
};

export const useNewsCategory = (props: Props = {}): UseNewsCategory => {
  const { slugOrId } = props;
  const [loading, setLoading] = useState<boolean>();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { loaded } = useNewsCategories();
  const newsCategory = useSelector(newsCategorySelector(slugOrId || ''));

  const newsCategoryId = newsCategory?.id;

  const isAdmin =
    newsCategory?.created_by === userId ||
    newsCategory?.admins?.includes(userId) ||
    profile?.roles?.includes('admin');

  const registerNewsCategory = useCallback(
    async ({ ...values }: RegisterNewsCategory) => {
      setLoading(true);

      const slug = await findSlug(values.name!, '/newsCategories');

      await firestore.collection('/newsCategories').add({
        ...values,
        created_by: userId,
        slug,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      setLoading(false);
    },
    [firestore, userId],
  );

  const updateNewsCategory = useCallback(
    async ({ ...values }: RegisterNewsCategory) => {
      if (!newsCategory || !newsCategoryId) {
        return;
      }

      setLoading(true);

      await firestore
        .collection('/newsCategories')
        .doc(newsCategoryId)
        .update({ ...values, updated_at: new Date().getTime() });

      setLoading(false);
    },
    [firestore, newsCategory, newsCategoryId],
  );

  useEffect(() => {
    if (!newsCategoryId) {
      const query: ReduxFirestoreQuerySetting = {
        collection: 'newsCategories',
      };

      if (slugOrId) {
        query.where = [['slug', '==', slugOrId]];
      }
      firestore.get(query);
    }
  }, [newsCategoryId, slugOrId, firestore]);

  return {
    newsCategory,
    newsCategoryId,
    loading,
    loaded,
    registerNewsCategory,
    updateNewsCategory,
    isAdmin,
  };
};
