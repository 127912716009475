import firebase from 'firebase/app';

const firestore = firebase.firestore;

export const findSlug = async (
  title: string,
  collection: string,
  increase = 0,
): Promise<any> => {
  const slug = title
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
  const findAlias = await firestore()
    .collection(collection)
    .where('slug', '==', slug + (increase ? '-' + increase : ''))
    .get();
  if (findAlias.size) {
    increase++;
    return await findSlug(title, collection, increase);
  } else {
    return slug + (increase ? '-' + increase : '');
  }
};
