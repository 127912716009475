import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  DateSelector,
  EventsList,
  FilterAutocompleteOptions,
  FilterCategory,
  FilterCheckboxOptions,
  FilterRadioOptions,
  MobileFilterDialog,
  PageWrapper,
  RegionSelect,
  SearchBar,
  SectionHeader,
} from '../../components/molecule';
import { TopEventsCarousel } from '../../components/organisms';
import {
  useArtists,
  useEvents,
  useGlobalStyles,
  useMediaQuery,
  useOrganizations,
  useVenues,
} from '../../hooks';
import { MUSIC_STYLES } from '../../constants';
import { RootState } from '../../store/reducers';
import useCheckboxOptions from '../../hooks/ui/useCheckboxOptions';
import { getSelectedCheckboxes } from '../../utils';
import useDebounce from '../../hooks/ui/useDebounce';
import { Emoji } from '../../components/atoms';
import { createFilterOptionsArray } from '../../utils/ui/createFilterOptionsArray';

const Events = () => {
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();
  const location = useLocation<any>();
  const region = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const [searchFor, setSearchFor] = useState('');
  const debouncedSearchFor = useDebounce(searchFor.trim(), 300);
  const { createMusicStyleObject } = useCheckboxOptions();
  const [musicStylesState, setMusicStylesState] = useState(
    createMusicStyleObject(MUSIC_STYLES),
  );
  const [organizationsState, setOrganizationsState] = useState<string[]>([]);
  const [venuesState, setVenuesState] = useState<string[]>([]);
  const [artistsState, setArtistsState] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<{
    day: number;
    month: number;
    year: number;
  } | null>(null);
  const [eventTimePeriod, setEventTimePeriod] = useState('');
  const [page, setPage] = useState(1);

  const [searchForVenues, setSearchForVenues] = useState('');
  const { filteredVenues, loaded: loadedVenues } = useVenues({
    searchFor: searchForVenues,
  });

  const [searchForArtists, setSearchForArtists] = useState('');
  const { filteredArtists, loaded: loadedArtists } = useArtists({
    searchFor: searchForArtists,
  });

  const [searchForOrganizations, setSearchForOrganizations] = useState('');
  const {
    filteredOrganizations,
    loaded: loadedOrganizations,
  } = useOrganizations({
    searchFor: searchForOrganizations,
  });

  const {
    mainEvents,
    premiumEvents,
    filteredEventsByPage: { events: filteredEvents, totalResults },
    loaded,
  } = useEvents({
    region: region?.id,
    searchFor: debouncedSearchFor,
    musicStyles: getSelectedCheckboxes(musicStylesState),
    selectedOrganizationIds: organizationsState,
    selectedVenueIds: venuesState,
    selectedDate,
    selectedArtistIds: artistsState,
    past: eventTimePeriod === 'past',
    future: eventTimePeriod === 'future',
    page,
  });

  useEffect(() => {
    setPage(1);
  }, [
    region,
    debouncedSearchFor,
    musicStylesState,
    organizationsState,
    venuesState,
    selectedDate,
    artistsState,
    eventTimePeriod,
  ]);

  const loadMoreEvents = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    if (location?.state?.date) {
      setSelectedDate(location?.state?.date);
    }
  }, [location]);

  const renderFilter = useCallback(() => {
    return (
      <Grid
        container
        justify="space-around"
        spacing={2}
        className={globalStyles.mt3}
      >
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.search').slice(0, -3)}>
            <SearchBar
              label={t('venue.search')}
              searchText={searchFor}
              setSearchText={setSearchFor}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('venue.region')}>
            <RegionSelect />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('event.date')}>
            <DateSelector date={selectedDate} setDate={setSelectedDate} />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('general.timePeriod')}>
            <FilterRadioOptions
              options={[
                { value: 'past', name: t('event.past') },
                { value: 'future', name: t('event.future') },
              ]}
              setState={setEventTimePeriod}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('venue.pageTitle')}>
            <FilterAutocompleteOptions
              options={createFilterOptionsArray(filteredVenues)}
              setIds={setVenuesState}
              setSearchFor={setSearchForVenues}
              loaded={loadedVenues !== undefined && loadedVenues}
              placeholder={t('venue.search')}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('event.musicStyles')}>
            <FilterCheckboxOptions
              state={musicStylesState}
              setState={setMusicStylesState}
              title={t('event.musicStyles')}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('event.artists')}>
            <FilterAutocompleteOptions
              options={createFilterOptionsArray(filteredArtists)}
              setIds={setArtistsState}
              setSearchFor={setSearchForArtists}
              loaded={loadedArtists !== undefined && loadedArtists}
              placeholder={t('artist.searchFor')}
            />
          </FilterCategory>
        </Grid>
        <Grid item xs={12} sm={9} md={12}>
          <FilterCategory category={t('event.organizations')}>
            <FilterAutocompleteOptions
              options={createFilterOptionsArray(filteredOrganizations)}
              setIds={setOrganizationsState}
              setSearchFor={setSearchForOrganizations}
              loaded={loadedOrganizations !== undefined && loadedOrganizations}
              placeholder={t('venue.search')}
            />
          </FilterCategory>
        </Grid>
      </Grid>
    );
  }, [
    filteredArtists,
    filteredOrganizations,
    filteredVenues,
    globalStyles.mt3,
    loadedArtists,
    loadedOrganizations,
    loadedVenues,
    musicStylesState,
    searchFor,
    selectedDate,
    t,
  ]);

  return (
    <PageWrapper title="Events">
      <Grid container spacing={2} justify="center">
        {isMobile ? (
          <MobileFilterDialog
            renderFilter={renderFilter}
            label={t('event.filter')}
          />
        ) : (
          <Grid item md={3}>
            {renderFilter()}
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <Box paddingY={2} textAlign="center">
            <SectionHeader title="Main events" />
            <TopEventsCarousel
              items={2}
              events={mainEvents}
              loading={!loaded}
            />
          </Box>
          <Box paddingY={2} textAlign="center">
            <SectionHeader title="Premium events" />
            <TopEventsCarousel
              items={2}
              events={premiumEvents}
              loading={!loaded}
            />
          </Box>
          {loaded && !filteredEvents.length ? (
            <Box textAlign="center" padding={2}>
              <Typography variant="h2">
                <Emoji emoji="🤷‍♂️" />
              </Typography>
              {t('event.noEventsFound')}
            </Box>
          ) : (
            <EventsList
              events={filteredEvents.filter(
                e =>
                  !mainEvents.find(m => m.id === e.id) &&
                  !premiumEvents.find(p => p.id === e.id),
              )}
              loading={!loaded}
              loadMore={loadMoreEvents}
              totalResults={
                totalResults - mainEvents.length - premiumEvents.length
              }
              page={page}
            />
          )}
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Events;
