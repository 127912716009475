import { useCallback, useState } from 'react';
import { functions } from '../../rrf.config';
import { useAuthentication } from '../auth';

type SendEmail = {
  to?: string;
  subject: string;
  content1?: string;
  content2?: string;
  cta?: { url: string; text: string };
  shortText?: string;
  text?: string;
};

type UseSendEmail = {
  loading: boolean;
  success: boolean;
  error: boolean;
  sendEmail: (params: SendEmail) => void;
};

export const useSendEmail = (): UseSendEmail => {
  const send = functions.httpsCallable('sendEmail');
  const {
    profile: { email },
  } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const sendEmail = useCallback(
    ({ to = email, subject, content1, content2, cta, shortText, text }) => {
      setLoading(true);
      console.log('Sending email...');
      send({ to, subject, content1, content2, cta, shortText, text })
        .then(res => {
          console.log(res);
          if (res.data.status === 'success') {
            setSuccess(true);
            return;
          }
          setError(true);
          console.error(res.data);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    [email, send],
  );
  return { loading, success, error, sendEmail };
};
