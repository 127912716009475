import React from 'react';
import { isPlatform } from '@ionic/react';
import { useCallback, useEffect, useState } from 'react';
import {
  PushNotifications,
  ActionPerformed,
  Token,
} from '@capacitor/push-notifications';
import { DeviceUUID } from 'device-uuid';
import { toast } from 'react-toastify';
import { Box, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useFirestore } from 'react-redux-firebase';
import { Firebase } from '../../rrf.config';
import { useAuthentication } from '../auth';

const useNotifications = () => {
  const isCapacitor = isPlatform('capacitor');
  const firestore = useFirestore();
  const { userId, isAuthenticated } = useAuthentication();
  const history = useHistory();
  const location = useLocation();
  const [isGranted, setIsGranted] = useState<boolean>();
  const [init, setInit] = useState(false);

  const registerTokenToUser = useCallback(
    (UUID: string, token: string) => {
      firestore
        .collection('users_meta')
        .doc(userId)
        .collection('messagingTokens')
        .doc(UUID)
        .set({ token });
    },
    [firestore, userId],
  );

  const initWeb = useCallback(() => {
    try {
      const messaging = Firebase.messaging();
      window.Notification.requestPermission()
        .then(() => {
          console.log('Have permission');
          return messaging.getToken();
        })
        .then(token => {
          const device = new DeviceUUID().get();
          registerTokenToUser(device, token);
          setIsGranted(true);
        })
        .catch(e => {
          setIsGranted(false);
          console.log('Dont have permission', e);
        });

      messaging.onMessage(payload => {
        console.log('received', payload, location?.pathname);
        if (
          !(
            (payload.onClick && window.location.pathname === payload.onClick) ||
            (payload.data &&
              payload.data.onClick &&
              window.location.pathname === payload.data.onClick)
          )
        ) {
          toast(
            <Box>
              <Typography>
                <b>{payload.data.title}</b>
              </Typography>
              <Typography variant="caption">{payload.data.body}</Typography>
            </Box>,
            {
              onClick: payload.data.onClick
                ? () => history.push(payload.data.onClick)
                : undefined,
            },
          );
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [history, location, registerTokenToUser]);

  const initNative = useCallback(() => {
    PushNotifications.requestPermissions().then((result: any) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
    // On succcess, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      console.log(token.value);
      const device = new DeviceUUID().get();
      registerTokenToUser(device, token.value);
      //alert('Push registration success, token: ' + token.value);
    });
    // Some issue with your setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log(
        'Error on registration notifications: ' + JSON.stringify(error),
      );
    });
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (e: ActionPerformed) => {
        // alert(JSON.stringify(notification));
        if (
          !(
            e.notification.click_action &&
            window.location.pathname === e.notification.click_action
          )
        ) {
          toast(
            <Box>
              <Typography>
                <b>{e.notification.title}</b>
              </Typography>
              <Typography variant="caption">{e.notification.body}</Typography>
            </Box>,
            {
              onClick: e.notification.click_action
                ? () => history.push(e.notification.click_action!)
                : undefined,
            },
          );
        }
      },
    );
    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (e: ActionPerformed) => {
        // alert('clicked ' + JSON.stringify(notification));
        if (e.notification.data && e.notification.click_action) {
          history.push(e.notification.click_action!);
        }
      },
    );
  }, [history, registerTokenToUser]);

  useEffect(() => {
    if (isAuthenticated && !init) {
      if (isCapacitor) {
        initNative();
      } else {
        initWeb();
      }
      setInit(true);
    }
  }, [init, initNative, initWeb, isCapacitor, isAuthenticated]);

  return { isGranted };
};

export default useNotifications;
