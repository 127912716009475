export const loadScript = (
  src: string,
  position: HTMLElement | null,
  id: string,
) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};
