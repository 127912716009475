import { fade, makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: { width: '100%', height: 300 },
  pin: {
    width: 50,
    height: 50,
    background: fade(theme.palette.primary.main, 0.75),
    borderRadius: '100%',
    padding: 10,
  },
}));
