import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { REGIONS } from '../../../constants';
import { selectRegion } from '../../../store/actions/configurations/selectRegion';
import { RootState } from '../../../store/reducers';

type Props = {};

const RegionSelect: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const regionInStore = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const [selectedRegion, setSelectedRegion] = useState(
    regionInStore ? regionInStore.id : '',
  );

  useEffect(() => {
    if (!selectedRegion && regionInStore) {
      setSelectedRegion(regionInStore.id);
      return;
    }
    if (regionInStore && selectedRegion !== regionInStore.id) {
      setSelectedRegion(regionInStore.id);
    }
  }, [regionInStore, selectedRegion]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (event.target.value !== '') {
        const region = REGIONS.find(
          r => r.id === (event.target.value as string),
        );
        if (region) {
          dispatch(selectRegion(region));
        }
      } else {
        dispatch(selectRegion(null));
        setSelectedRegion('');
      }
    },
    [dispatch],
  );

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <Select
        value={selectedRegion}
        onChange={handleChange}
        displayEmpty
        placeholder={t('shared.allItaly')}
      >
        <MenuItem value="">{t('shared.allItaly')}</MenuItem>
        {REGIONS.map(r => (
          <MenuItem key={r.id} value={r.id}>
            {t(`shared.regions.${r.id}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

RegionSelect.displayName = 'RegionSelect';

export default React.memo(RegionSelect);
