import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import TinderCard from 'react-tinder-card';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import {
  TillDateNavigator,
  TillDatePageWrapper,
} from '../../components/molecule';
import { useAuthentication, useGlobalStyles } from '../../hooks';
import useDateRoomPlayers from '../../hooks/dateRooms/useDateRoomPlayers';
import useMyDateRoomPlayer from '../../hooks/dateRooms/useMyDateRoomPlayer';
import useMyDateRooms from '../../hooks/dateRooms/useMyDateRooms';
import TillDateEntrance from '../TillDateEntrance';
import empty_profile from '../../assets/img/empty_profile.png';
import swipe_lottie from '../../assets/lottie/swipe.json';
import no_players from '../../assets/lottie/no_players.json';
import useStyle from './style';
import { isPreferredGenderMatch } from './utils/isPreferredGenderMatch';

const TillDateScreen = () => {
  const { selectedRoom } = useMyDateRooms();
  const { userId } = useAuthentication();
  const history = useHistory();
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();
  const style = useStyle();
  const [isEntrance, setIsEntrance] = useState(false);
  const { dateRoomPlayersList } = useDateRoomPlayers({
    dateRoomId: selectedRoom?.id,
  });
  const {
    liked,
    disliked,
    myPlayerProfile,
    addPlayerToLikes,
    addPlayerToDislikes,
  } = useMyDateRoomPlayer();
  const dateRoomPlayerFiltered = useMemo(
    () =>
      dateRoomPlayersList.filter(
        player =>
          player.active &&
          player.userId !== userId &&
          isPreferredGenderMatch(myPlayerProfile, player) &&
          !liked.has(player.userId) &&
          !disliked.has(player.userId),
      ),
    [dateRoomPlayersList, disliked, liked, myPlayerProfile, userId],
  );
  const childRefs = useMemo(
    () => dateRoomPlayerFiltered.map(() => React.createRef<any>()),
    [dateRoomPlayerFiltered],
  );

  const swiped = useCallback(
    (direction, playerId) => {
      if (direction === 'left') {
        addPlayerToLikes(playerId);
        return;
      }
      if (direction === 'right') {
        addPlayerToDislikes(playerId);
      }
    },
    [addPlayerToDislikes, addPlayerToLikes],
  );

  useEffect(() => {
    setIsEntrance(myPlayerProfile && !myPlayerProfile?.active);
  }, [history, myPlayerProfile]);

  if (!myPlayerProfile) {
    return null;
  }

  if (isEntrance) {
    return (
      <TillDatePageWrapper entrance>
        <TillDateEntrance setIsEntrance={setIsEntrance} />
      </TillDatePageWrapper>
    );
  }

  return (
    <TillDatePageWrapper>
      <Box paddingBottom={2}>
        <TillDateNavigator selectedRoom={selectedRoom} />
      </Box>
      <Box className={globalStyle.relative} height={360}>
        {!dateRoomPlayerFiltered?.length && (
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Card elevation={3}>
              <Lottie
                style={{ height: 200, width: 200 }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: no_players,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                isStopped={false}
                isPaused={false}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {t('tilldate.noOtherProperPlayers')}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
        {dateRoomPlayerFiltered?.map((player, i) => (
          <TinderCard
            // @ts-ignore
            className={style.swipe}
            preventSwipe={['up', 'down']}
            ref={childRefs[i]}
            key={player.userId}
            onCardLeftScreen={dir => swiped(dir, player.userId)}
          >
            <Card elevation={i < 3 ? 3 : 0}>
              <CardMedia
                className={style.cardMedia}
                image={player?.profilePicture ?? empty_profile}
                title={player.nickname}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  <b>{player?.nickname}</b> ({player?.age})
                </Typography>
              </CardContent>
            </Card>
          </TinderCard>
        ))}
      </Box>
      <Box className={globalStyle.flex} alignItems="center">
        <Box className={style.crush}>CRUSH</Box>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: swipe_lottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isStopped={false}
          isPaused={false}
        />
        <Box className={style.trash}>TRASH</Box>
      </Box>
    </TillDatePageWrapper>
  );
};

export default TillDateScreen;
