import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { useMedia, useArtists } from '..';
import { RootState } from '../../store/reducers';
import { Artist } from '../../store/types';
import { findSlug } from '../../utils/firebase';
import { useAuthentication } from '../auth';

type Props = {
  slugOrId?: string;
};

type RegisterArtist = Partial<Artist> & {
  cover?: File[] | string;
  logo?: File[] | string;
};

type UseArtist = {
  artist: Artist;
  artistId?: string;
  loading?: boolean;
  loaded?: boolean;
  registerArtist: (props: RegisterArtist) => void;
  updateArtist: (props: RegisterArtist) => void;
  isAdmin: boolean;
};

export const useArtist = (props: Props = {}): UseArtist => {
  const { slugOrId } = props;
  const [loading, setLoading] = useState<boolean>();
  const history = useHistory();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { uploadMedia, getMediaData, deleteMedia } = useMedia();
  const { loaded } = useArtists();
  const artist = useSelector<RootState>(
    state =>
      slugOrId &&
      state.firestore?.ordered?.artists?.find(
        art => art.id === slugOrId || art.slug === slugOrId,
      ),
  ) as Artist;
  const artistId = artist?.id;

  const isAdmin = !!(
    artist?.created_by === userId ||
    artist?.admins?.includes(userId) ||
    profile?.roles?.includes('admin')
  );

  const registerArtist = useCallback(
    async ({ cover, logo, ...values }: RegisterArtist) => {
      let coverUrl: string | null = null;
      let logoUrl: string | null = null;
      setLoading(true);
      const slug = await findSlug(values.name!, '/artists');
      const pageRef = await firestore.collection('/artists').add({
        ...values,
        created_by: userId,
        slug,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });

      if ((!cover || !cover[0]) && (!logo || !logo[0])) {
        setLoading(false);
        history.push(`/artists/${slug}`);
        return;
      }

      if (cover && cover[0]) {
        const coverRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: cover[0],
        });
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      if (logo && logo[0]) {
        const logoRef = await uploadMedia({
          path: `/images/pages/${pageRef.id}`,
          file: logo[0],
        });
        const logoMedia = await getMediaData(logoRef.path);
        logoUrl = logoMedia?.url || null;
      }

      await firestore
        .collection('/artists')
        .doc(pageRef.id)
        .update({ logoUrl, coverUrl });
      setLoading(false);
      history.push(`/artists/${slug}`);
    },
    [firestore, getMediaData, history, uploadMedia, userId],
  );

  const updateArtist = useCallback(
    async ({ cover, logo, ...values }: RegisterArtist) => {
      if (!artist || !artistId) {
        return;
      }
      let coverUrl: string | null = artist.coverUrl || '';
      let logoUrl: string | null = artist.logoUrl || '';
      setLoading(true);
      await firestore
        .collection('/artists')
        .doc(artistId)
        .update({ ...values, updated_at: new Date().getTime() });
      if ((!cover || !cover[0]) && (!logo || !logo[0])) {
        setLoading(false);
        return;
      }
      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/pages/${artistId}`,
          file: cover[0],
        });
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      if (Array.isArray(logo) && logo[0]) {
        const oldLogo = logoUrl;
        const logoRef = await uploadMedia({
          path: `/images/pages/${artistId}`,
          file: logo[0],
        });
        if (oldLogo) {
          await deleteMedia({ url: oldLogo });
        }
        const logoMedia = await getMediaData(logoRef.path);
        logoUrl = logoMedia?.url || null;
      }
      await firestore
        .collection('/artists')
        .doc(artistId)
        .update({ logoUrl, coverUrl });
      setLoading(false);

      history.push(`/artists/${artist.slug}`);
    },
    [
      deleteMedia,
      firestore,
      getMediaData,
      history,
      uploadMedia,
      artist,
      artistId,
    ],
  );

  useFirestoreConnect({ collection: 'artists' });

  return {
    artist,
    artistId,
    loading,
    loaded,
    registerArtist,
    updateArtist,
    isAdmin,
  };
};
