import React, { useCallback, useMemo, useState } from 'react';
import {
  Grid,
  Button,
  InputAdornment,
  IconButton,
  Box,
  MenuItem,
  Select,
  FormControl,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core';
import DayJsUtils from '@date-io/dayjs';
import ITit from 'dayjs/locale/it';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RemoveCircleOutline } from '@material-ui/icons';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { GuestList } from '../../../store/types';
import { Spacer } from '../../atoms';
import { FormField } from '../../molecule';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';

export type Form = GuestList;

type Props = {
  guestList?: Form;
  onSubmit: (values: Form) => void;
};

const GuestListForm: React.FC<Props> = ({ onSubmit, guestList }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const [extra, setExtra] = useState<null | string>(null);
  const [extraVal, setExtraVal] = useState<null | any>(null);
  const { handleSubmit, register, errors, control } = useForm<Form>({
    defaultValues: guestList,
  });
  const {
    fields: benefitsFields,
    append: benefitsAppend,
    remove: benefitsRemove,
  } = useFieldArray({
    name: 'benefits',
    control,
  });
  const {
    fields: conditionsFields,
    append: conditionsAppend,
    remove: conditionsRemove,
  } = useFieldArray({
    name: 'conditions',
    control,
  });
  const canCreateGuestList =
    benefitsFields.length > 0 && conditionsFields.length > 0;

  const handleSelectConditions = useCallback(
    e => {
      if (e.target.value === 'Ingresso entro le') {
        setExtra('Specifica orario ingresso');
        return;
      }
      conditionsAppend({ val: e.target.value });
    },
    [conditionsAppend],
  );

  const handleSelectBenefit = useCallback(
    e => {
      if (e.target.value === 'Riduzione biglietto') {
        setExtra('Specifica valore riduzione');
        return;
      }
      if (e.target.value === 'Free drinks') {
        setExtra('Specifica quanti free drinks');
        return;
      }
      benefitsAppend({
        val: e.target.value,
      });
    },
    [benefitsAppend],
  );

  const renderExtraField = useMemo(() => {
    if (extra === 'Specifica orario ingresso') {
      return (
        <MuiPickersUtilsProvider utils={DayJsUtils} locale={ITit}>
          <TimePicker
            inputVariant="outlined"
            fullWidth
            label="Specifica orario"
            value={extraVal}
            format="HH:mm"
            size="small"
            ampm={false}
            minutesStep={5}
            onChange={date => {
              setExtraVal(date);
            }}
          />
          <Spacer height="10px" />
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => {
              conditionsAppend({
                val: `Ingresso entro le ${extraVal?.format('HH:mm')}`,
              });
              setExtra(null);
              setExtraVal(null);
            }}
          >
            Apply
          </Button>
        </MuiPickersUtilsProvider>
      );
    }
    if (extra === 'Specifica valore riduzione') {
      return (
        <>
          <TextField
            type="number"
            variant="outlined"
            fullWidth
            value={extraVal}
            label="Valore riduzione"
            InputProps={{ endAdornment: '%' }}
            size="small"
            onChange={e => {
              setExtraVal(e?.target?.value);
            }}
          />
          <Spacer height="10px" />
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => {
              benefitsAppend({
                val: `Riduzione biglietto: ${extraVal}%`,
              });
              setExtra(null);
              setExtraVal(null);
            }}
          >
            Apply
          </Button>
        </>
      );
    }
    if (extra === 'Specifica quanti free drinks') {
      return (
        <>
          <TextField
            type="number"
            variant="outlined"
            fullWidth
            value={extraVal}
            label="Free drinks"
            size="small"
            onChange={e => {
              setExtraVal(e?.target?.value);
            }}
          />
          <Spacer height="10px" />
          <Button
            variant="outlined"
            fullWidth
            size="small"
            onClick={() => {
              benefitsAppend({
                val: `${extraVal} Free Drinks`,
              });
              setExtra(null);
              setExtraVal(null);
            }}
          >
            Apply
          </Button>
        </>
      );
    }
    return null;
  }, [benefitsAppend, conditionsAppend, extra, extraVal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={!!extra}>
        <DialogTitle>{extra}</DialogTitle>
        <DialogContent>{renderExtraField}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setExtra(null);
              setExtraVal(null);
            }}
            fullWidth
            variant="outlined"
            size="small"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            name="name"
            label={t('guestList.name')}
            register={register}
            required
            error={errors.name?.message}
          />
          <Spacer height="10px" />
        </Grid>
        <Grid item xs={12} md={6}>
          {!benefitsFields.length ? (
            <FormControl size="small" variant="outlined" fullWidth>
              <Select onChange={handleSelectBenefit} value={0}>
                <MenuItem value={0}>{t('guestList.addBenefit')}</MenuItem>
                <MenuItem value={'Free drinks'}>Free drinks</MenuItem>
                <MenuItem value={'Ingresso gratuito'}>
                  {t('guestList.freeEntry')}
                </MenuItem>
                <MenuItem value={'Riduzione biglietto'}>
                  {t('guestList.ticketReduction')}
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}
          <Spacer height="10px" />
          {benefitsFields.map((benefit, index) => (
            <Box key={`benefit${index}`}>
              <FormField
                name={`benefits[${index}].val`}
                label={t('guestList.benefits')}
                register={register}
                defaultValue={benefit.val}
                required
                error={errors.benefits?.[index]?.message}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => benefitsRemove(index)}
                      size="small"
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Spacer height="10px" />
            </Box>
          ))}
          {/*!benefitsFields.length ? (
            <IconButton
              onClick={() => benefitsAppend({ val: '' })}
              size="small"
            >
              <AddCircleOutline />
            </IconButton>
          ) : null*/}
          {!isMobile && <Spacer height="10px" />}
        </Grid>
        {isMobile && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          {!conditionsFields.length ? (
            <FormControl size="small" variant="outlined" fullWidth>
              <Select onChange={handleSelectConditions} value={0}>
                <MenuItem value={0}>{t('guestList.addCondition')}</MenuItem>
                <MenuItem value={'Ingresso entro le'}>
                  {t('guestList.entranceAsSingle')}
                </MenuItem>
                <MenuItem value={'Ingresso in coppia'}>
                  {t('guestList.entranceAsCouple')}
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}
          <Spacer height="10px" />
          {conditionsFields.map((condition, index) => (
            <Box key={`condition${index}`}>
              <FormField
                name={`conditions[${index}].val`}
                label={t('guestList.conditions')}
                register={register}
                defaultValue={condition.val}
                required
                error={errors.conditions?.[index]?.message}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => conditionsRemove(index)}
                      size="small"
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Spacer height="10px" />
            </Box>
          ))}
          {/*<IconButton
            onClick={() => conditionsAppend({ val: '' })}
            size="small"
          >
            <AddCircleOutline />
          </IconButton>*/}
          <Spacer height="10px" />
        </Grid>
        {!canCreateGuestList && (
          <Grid container item xs={12} justify="center">
            <Typography
              align="center"
              color="error"
              className={globalStyle.textSmall}
            >
              {t('guestList.cantCreateGuestList')}
            </Typography>
          </Grid>
        )}
        <Grid container item xs={12} justify="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            disabled={!canCreateGuestList}
          >
            {guestList ? t('guestList.edit') : t('guestList.goLive')}
          </Button>
        </Grid>
      </Grid>
      <Spacer height="30px" />
    </form>
  );
};

GuestListForm.displayName = 'GuestListForm';

export default React.memo(GuestListForm);
