import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import {
  dateRoomPlayersListSelector,
  dateRoomPlayersSelector,
} from '../../store/selectors/dateRooms';

type UseDateRoomPlayers = {
  dateRoomId?: string;
};

const useDateRoomPlayers = ({ dateRoomId }: UseDateRoomPlayers) => {
  const firestore = useFirestore();
  const dateRoomPlayers = useSelector(dateRoomPlayersSelector(dateRoomId));
  const dateRoomPlayersList = useSelector(
    dateRoomPlayersListSelector(dateRoomId),
  );

  useEffect(() => {
    if (dateRoomId) {
      firestore.setListener({
        collection: 'dateRooms',
        doc: dateRoomId,
        subcollections: [{ collection: 'players' }],
        storeAs: `dateRoomPlayers/${dateRoomId}`,
      });
    }
  }, [firestore, dateRoomId]);

  return { dateRoomPlayers, dateRoomPlayersList };
};

export default useDateRoomPlayers;
