import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  FormField: {
    borderRadius: 0,
    borderWidth: 1,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    background: theme.palette.secondary.light,
  },
  OwlCarousel: {
    marginTop: 20,
  },
}));
