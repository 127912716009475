import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PageWrapper } from '../../components/molecule';
import {
  DeleteProfile,
  EditProfile as EditProfileComponent,
  UserDetails,
} from '../../components/organisms';
import { Spacer } from '../../components/atoms';
import { useGlobalStyles } from '../../hooks';

const EditProfile = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const goToProfile = useCallback(() => history.push('/profile'), [history]);
  const toggleDeleteMode = useCallback(() => setDeleteMode(prev => !prev), []);

  const renderMain = useMemo(() => {
    if (deleteMode) {
      return <DeleteProfile toggleDeleteMode={toggleDeleteMode} />;
    }
    return <EditProfileComponent />;
  }, [deleteMode, toggleDeleteMode]);

  return (
    <PageWrapper>
      <Box>
        <Spacer height="20px" />
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <UserDetails />
            <Spacer height="10px" />
            <Button fullWidth variant="outlined" onClick={goToProfile}>
              {t('profile.backToProfile')}
            </Button>
            <Spacer height="20px" />
            <Button
              className={globalStyle.danger}
              onClick={toggleDeleteMode}
              fullWidth
              variant="text"
              size="small"
            >
              {!deleteMode
                ? t('profile.deleteProfile')
                : t('profile.backToEditProfile')}
            </Button>
            <Spacer height="40px" />
          </Grid>
          <Grid item md={9} xs={12}>
            {renderMain}
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default EditProfile;
