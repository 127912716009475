import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { PageWrapper } from '../../components/molecule';
import { Spacer } from '../../components/atoms';
import {
  MyEvents,
  MyGuestListRegistrations,
  MyMissingPhotoAlbums,
  MyOrganizations,
  MyPhotoAlbums,
  MyVenues,
} from '../../components/organisms';

const useStyle = makeStyles({
  menuElement: {
    fontSize: 12,
    cursor: 'pointer',
    textTransform: 'uppercase',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  selectedMenuElement: {
    fontWeight: 'bold',
  },
  button: {
    fontSize: 12,
  },
});

type StateType = {
  selectedMenuItem?: string;
};

const ProfileDashboard = () => {
  const history = useHistory();
  const { state } = useLocation<StateType>();
  const { t } = useTranslation();
  const style = useStyle();
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    state?.selectedMenuItem ? state.selectedMenuItem : '',
  );

  useEffect(() => {
    setSelectedMenuItem(state?.selectedMenuItem ? state.selectedMenuItem : '');
  }, [state]);

  const goToProfile = useCallback(() => history.push('/profile'), [history]);

  const renderMenuItem = useCallback(
    menuItem => (
      <>
        <Typography
          variant="subtitle2"
          className={[
            style.menuElement,
            selectedMenuItem === menuItem ? style.selectedMenuElement : '',
          ].join(' ')}
          onClick={() => setSelectedMenuItem(menuItem)}
        >
          {t(`profile.${menuItem}`)}
        </Typography>
        <Divider />
      </>
    ),
    [selectedMenuItem, style.menuElement, style.selectedMenuElement, t],
  );

  const myLists = {
    myEvents: <MyEvents />,
    myVenues: <MyVenues />,
    myOrganizations: <MyOrganizations />,
    myPhotoAlbums: (
      <>
        <MyMissingPhotoAlbums />
        <Spacer height="30px" />
        <MyPhotoAlbums />
      </>
    ),
    myPastGuestLists: <MyGuestListRegistrations timePeriod="past" />,
    myFutureGuestLists: <MyGuestListRegistrations timePeriod="future" />,
  };

  return (
    <PageWrapper title={t('profile.dashboard')}>
      <Box>
        <Spacer height="20px" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Divider />
            {Object.keys(myLists).map((menuItem, id) => (
              <Box key={menuItem + id}>{renderMenuItem(menuItem)}</Box>
            ))}
            <Spacer height="20px" />
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="outlined"
                onClick={goToProfile}
                className={style.button}
              >
                {t('profile.backToProfile')}
              </Button>
            </Box>
          </Grid>
          <Grid item md={9} xs={12}>
            {myLists?.[selectedMenuItem] ? myLists[selectedMenuItem] : null}
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default ProfileDashboard;
