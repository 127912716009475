import { ActionType } from 'typesafe-actions';
import { RootState } from '..';
import { selectRegion } from '../../actions/configurations/selectRegion';

export const selectRegionCase = (
  state: RootState['configuration'],
  action: ActionType<typeof selectRegion>,
): RootState['configuration'] => ({
  ...state,
  region: action.payload,
});
