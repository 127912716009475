import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvents } from '../events';
import { useOrganizations } from '../organizations';
import { useVenues } from '../venues';
import defaultLogo from '../../assets/img/defaultLogo.jpg';
import { sortObjectByValues, textShortener } from '../../utils';
import { HeaderSearchResult, SearchResult } from '../../store/types';
import { useNews } from '../news';
import { useArtists } from '../artists';
import { usePhotoAlbums } from '../photoAlbums';

type UseSearch = {
  searchFunction: (
    text: string,
    collection: string,
  ) => { results: SearchResult[]; loaded: boolean };
  headerSearch: (text: string) => HeaderSearchResult[];
};

export const useSearch = (): UseSearch => {
  const { searchedVenues, loaded: loadedVenues } = useVenues();
  const { searchedEvents, loaded: loadedEvents } = useEvents();
  const {
    searchedOrganizations,
    loaded: loadedOrganizations,
  } = useOrganizations();
  const { searchedNews, loaded: loadedNews } = useNews();
  const { searchedArtists, loaded: loadedArtists } = useArtists();
  const { photoGalleriesList, loaded: loadedPhotoAlbums } = usePhotoAlbums();

  const { t } = useTranslation();

  const searchAll = useCallback(
    (text: string) => {
      const results: SearchResult[] = [
        ...mapSearchResults(searchedVenues(text), 'venue', t('general.venue')),
        ...mapSearchResults(searchedEvents(text), 'event', t('general.event')),
        ...mapSearchResults(
          searchedOrganizations(text),
          'organization',
          t('general.organization'),
        ),
        ...mapSearchResults(
          searchedArtists(text),
          'artist',
          t('general.artist'),
        ),
        ...mapSearchResults(searchedNews(text), 'news', t('general.news')),
      ];

      return sortObjectByValues(results, 'name');
    },
    [
      searchedEvents,
      searchedOrganizations,
      searchedVenues,
      searchedNews,
      searchedArtists,
      t,
    ],
  );

  const searchPhotoAlbums = useCallback(
    (wording: string) => {
      if (photoGalleriesList) {
        return photoGalleriesList.filter(gallery =>
          gallery?.name?.toLowerCase().startsWith(wording.toLowerCase()),
        );
      } else {
        return [];
      }
    },
    [photoGalleriesList],
  );

  const searchFunction = useCallback(
    (text: string, collection: string) => {
      const collectionList = {
        futureEvent: (wording: string) => searchedEvents(wording, 'future'),
        pastEvent: (wording: string) => searchedEvents(wording, 'past'),
        venue: searchedVenues,
        organization: searchedOrganizations,
        news: searchedNews,
        artist: searchedArtists,
        photoAlbum: searchPhotoAlbums,
        all: searchAll,
      };
      const loadedStates = {
        futureEvent: loadedEvents,
        pastEvent: loadedEvents,
        venue: loadedVenues,
        organization: loadedOrganizations,
        news: loadedNews,
        artist: loadedArtists,
        photoAlbum: loadedPhotoAlbums,
        all:
          loadedEvents &&
          loadedEvents &&
          loadedVenues &&
          loadedOrganizations &&
          loadedNews &&
          loadedArtists,
      };
      if (!collectionList[collection]) {
        return {
          results: [],
          loaded: true,
        };
      }
      return {
        results: collectionList[collection](text),
        loaded: loadedStates[collection],
      };
    },
    [
      searchedVenues,
      searchedOrganizations,
      searchedNews,
      searchedArtists,
      searchPhotoAlbums,
      searchAll,
      loadedEvents,
      loadedVenues,
      loadedOrganizations,
      loadedNews,
      loadedArtists,
      loadedPhotoAlbums,
      searchedEvents,
    ],
  );

  const headerSearch = useCallback(
    (text: string) => {
      const results: HeaderSearchResult[] = [
        ...mapHeaderSearchResults(
          searchedVenues(text),
          'venues',
          t('general.venue'),
        ),
        ...mapHeaderSearchResults(
          searchedEvents(text),
          'events',
          t('general.event'),
        ),
        ...mapHeaderSearchResults(
          searchedOrganizations(text),
          'organizations',
          t('general.organization'),
        ),
      ];

      let sortedResults = sortObjectByValues(results, 'name');
      if (sortedResults.length > 6) {
        sortedResults = sortedResults.slice(0, 7);
      }
      return sortedResults;
    },
    [searchedVenues, searchedEvents, searchedOrganizations, t],
  );

  return {
    headerSearch,
    searchFunction,
  };
};

const mapSearchResults = <
  T extends {
    name?: string;
    title?: string;
    slug: string;
    description: string;
    logoUrl?: string;
    coverUrl?: string;
  }
>(
  results: T[],
  collection: string,
  type: string,
): SearchResult[] => {
  return results.map(result => {
    return {
      name: result?.name || result?.title || '',
      type,
      collection,
      ...result,
    };
  });
};

const mapHeaderSearchResults = <
  T extends {
    name: string;
    slug: string;
    logoUrl?: string;
    coverUrl?: string;
  }
>(
  results: T[],
  collection: string,
  type: string,
): HeaderSearchResult[] => {
  return results.map(result => {
    return {
      name: textShortener(result.name, 20),
      slug: result.slug,
      logoUrl: result?.logoUrl
        ? result.logoUrl
        : result?.coverUrl
          ? result.coverUrl
          : defaultLogo,
      type,
      collection,
    };
  });
};
