import { TextField } from '@material-ui/core';
import React from 'react';
import { Validate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MuiPhoneNumber from 'material-ui-phone-number';
import AddressField from './AddressField';
import CheckboxField from './CheckboxField';
import DateField from './DateField';
import DateTimeField from './DateTimeField';
import EditorField from './EditorField';
import RadioField from './RadioField';
import SelectField from './SelectField';

type Props = {
  register: any;
  control?: any;
  name: string;
  label: string;
  type?: string;
  error?: string | null;
  helperText?: string;
  required?: boolean;
  validate?: Validate;
  options?: (string | { id: any; value: any })[];
  langDomain?: string;
  groups?: { id: string; options: string[]; label?: string }[];
  defaultOptions?: string[];
  defaultValue?: string;
  defaultGroups?: { [key: string]: string } | { [key: string]: string }[];
  setValue?: any;
  disabled?: boolean;
  onChange?: (data: any) => void;
  endAdornment?: JSX.Element;
  fieldClassName?: string;
  allowClear?: boolean;
  initialValue?: string;
  cbFunction?: Function;
  minDate?: Date;
  maxDate?: Date;
};

const FormField: React.FC<Props> = ({
  register,
  error,
  required,
  name,
  label,
  validate,
  type = 'text',
  options: optionsProps,
  groups,
  helperText,
  defaultOptions,
  defaultGroups,
  defaultValue,
  langDomain,
  control,
  setValue,
  disabled,
  onChange,
  endAdornment,
  allowClear,
  fieldClassName,
  initialValue,
  cbFunction,
  minDate,
  maxDate,
}) => {
  const { t } = useTranslation();
  const patterns: any = {
    email: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: t('form.invalidEmail') as string,
    },
    url: {
      value: /\b(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]*[-A-Za-z0-9+&@#/%=~_|]/,
      message: t('form.invalidUrl') as string,
    },
    facebook: {
      value: /\b(https?|ftp|file):\/\/(www.)?facebook.com+\/[A-Z0-9.-]/i,
      message: t('form.invalidFacebook') as string,
    },
    instagram: {
      value: /\b(https?|ftp|file):\/\/(www.)?instagram.com+\/[A-Z0-9.-]/i,
      message: t('form.invalidInstagram') as string,
    },
  };
  const options = optionsProps?.map(option => {
    if (typeof option === 'string') {
      return { id: option, value: option };
    }
    return option;
  });

  if (type === 'editor') {
    return (
      <EditorField
        control={control}
        setValue={setValue}
        name={name}
        error={error}
        register={register}
        fieldClassName={fieldClassName}
        initialValue={initialValue}
      />
    );
  }
  if (type === 'city' || type === 'address' || type === 'region') {
    return (
      <AddressField
        type={type}
        control={control}
        size="small"
        variant="outlined"
        setValue={setValue}
        name={name}
        label={label}
        helperText={error}
        error={error}
        required={required}
        fullWidth
        onChange={onChange}
        disabled={disabled}
        fieldClassName={fieldClassName}
      />
    );
  }
  if (type === 'date') {
    return (
      <DateField
        error={error}
        label={label}
        name={name}
        register={register}
        control={control}
        fieldClassName={fieldClassName}
        cbFunction={cbFunction}
        minDate={minDate}
        maxDate={maxDate}
        defaultValue={defaultValue}
      />
    );
  }
  if (type === 'datetime') {
    return (
      <DateTimeField
        error={error}
        label={label}
        name={name}
        register={register}
        control={control}
        fieldClassName={fieldClassName}
        cbFunction={cbFunction}
        minDate={minDate}
      />
    );
  }
  if (type === 'checkbox') {
    return (
      <CheckboxField
        error={error}
        label={label}
        defaultOptions={defaultOptions}
        options={options}
        langDomain={langDomain}
        name={name}
        register={register}
        required={required}
        fieldClassName={fieldClassName}
      />
    );
  }
  if (type === 'select') {
    return (
      <SelectField
        control={control}
        error={error}
        label={label}
        options={options}
        langDomain={langDomain}
        defaultValue={defaultValue}
        name={name}
        required={required}
        fieldClassName={fieldClassName}
        disabled={disabled}
      />
    );
  }
  if (type === 'radiogroup') {
    return (
      <RadioField
        error={error}
        label={label}
        defaultGroups={defaultGroups}
        groups={groups}
        langDomain={langDomain}
        name={name}
        register={register}
        required={required}
        allowClear={allowClear}
        fieldClassName={fieldClassName}
        onChange={onChange}
      />
    );
  }
  if (type === 'hidden') {
    return (
      <input
        type="hidden"
        ref={register({
          required: required ? (t('form.required') as string) : undefined,
          pattern: patterns[type],
          validate,
        })}
        name={name}
      />
    );
  }
  if (type === 'phone') {
    return (
      <MuiPhoneNumber
        preferredCountries={['it']}
        excludeCountries={['va']}
        // @ts-ignore
        regions={'europe'}
        defaultCountry="it"
        inputProps={{
          ref: register({
            required: required ? (t('form.required') as string) : undefined,
            pattern: patterns[type],
            validate,
          }),
          autoComplete: 'chrome-off',
        }}
        InputProps={{
          endAdornment,
          autoComplete: 'chrome-off',
        }}
        size="small"
        variant="outlined"
        name={name}
        label={label}
        type={type}
        onChange={onChange}
        defaultValue={defaultValue}
        helperText={error || helperText}
        className={fieldClassName}
        disabled={disabled}
        error={!!error}
        required={required}
        autoComplete="off"
        fullWidth
      />
    );
  }
  return (
    <TextField
      inputProps={{
        ref: register({
          required: required ? (t('form.required') as string) : undefined,
          pattern: patterns[type],
          validate,
        }),
        autoComplete: 'chrome-off',
      }}
      InputProps={{
        endAdornment,
        autoComplete: 'chrome-off',
      }}
      size="small"
      variant="outlined"
      name={name}
      label={label}
      type={type}
      onChange={onChange}
      defaultValue={defaultValue}
      multiline={type === 'textarea'}
      rows={8}
      helperText={error || helperText}
      className={fieldClassName}
      disabled={disabled}
      error={!!error}
      required={required}
      autoComplete="off"
      fullWidth
    />
  );
};

FormField.displayName = 'FormField';

export default React.memo(FormField);
