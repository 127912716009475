import { useCallback } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { DateRoom } from '../../store/types/dateRooms';

type UseDateRoomPlayer = {
  dateRoom: DateRoom | null;
};

const useDateRoomPlayer = ({ dateRoom }: UseDateRoomPlayer) => {
  const firestore = useFirestore();
  const { id } = dateRoom || {};
  const registerDateRoomPlayer = useCallback(
    async userId => {
      if (id && userId) {
        firestore
          .collection('dateRooms')
          .doc(id)
          .collection('players')
          .doc(userId)
          .set({
            userId,
            matches: [],
            liked: [],
            disliked: [],
            active: false,
          });
      }
    },
    [firestore, id],
  );
  const unregisterDateRoomPlayer = useCallback(
    async userId => {
      if (id && userId) {
        firestore
          .collection('dateRooms')
          .doc(id)
          .collection('players')
          .doc(userId)
          .delete();
      }
    },
    [firestore, id],
  );
  const updateDateRoomPlayer = useCallback(
    async (userId, value) => {
      if (id && userId && value) {
        firestore
          .collection('dateRooms')
          .doc(id)
          .collection('players')
          .doc(userId)
          .update(value);
      }
    },
    [firestore, id],
  );
  const addLike = useCallback(
    async (userId, partnerId) => {
      await firestore
        .collection('dateRooms')
        .doc(id)
        .collection('players')
        .doc(userId)
        .update({ liked: firestore.FieldValue.arrayUnion(partnerId) });
    },
    [firestore, id],
  );

  return {
    registerDateRoomPlayer,
    unregisterDateRoomPlayer,
    updateDateRoomPlayer,
    addLike,
  };
};

export default useDateRoomPlayer;
