import { populate } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { firestoreState, profileSelector } from '..';
import { Event } from '../../types';
import { authSelector } from '../selectors';

type FilteredEventsSelector = {
  region?: string | undefined;
  searchFor?: string;
  musicStyles?: string[];
  future?: boolean;
  past?: boolean;
  selectedOrganizationIds?: string[];
  selectedVenueIds?: string[];
  selectedDate?: { day: number; month: number; year: number } | null;
  selectedArtistIds?: string[];
  page?: number;
  currentUser?: string;
};

export const eventsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: Event } => firestore?.data?.events ?? {},
);

export const eventsListSelector = createSelector(
  eventsSelector,
  (events): Event[] =>
    Object.entries(events || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const eventSelector = (slugOrId: string) =>
  createSelector(
    eventsSelector,
    eventsListSelector,
    (events, eventsList): Event =>
      events[slugOrId] || eventsList.find(o => o.slug === slugOrId),
  );

export const futureEventsSelector = createSelector(
  eventsListSelector,
  events => {
    const now = new Date().getTime();
    return events
      .filter(e => e?.date && new Date(e?.date).getTime() > now)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  },
);

export const pastEventsSelector = createSelector(eventsListSelector, events => {
  const now = new Date().getTime();
  return events
    .filter(e => e?.date && new Date(e?.date).getTime() < now)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
});

export const populatedEventsSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: Event } =>
    populate(firestore, 'events', [
      { child: 'venueId', root: 'clubs', childAlias: 'venue' },
    ]) || {},
);

export const populatedEventsListSelector = createSelector(
  populatedEventsSelector,
  (populatedEvents): Event[] =>
    Object.entries(populatedEvents || {}).map(([key, val]: any[]) => ({
      ...val,
      id: key,
    })),
);

export const populatedPastEventsSelector = createSelector(
  populatedEventsListSelector,
  events => {
    const now = new Date().getTime();
    return events
      .filter(e => e?.date && new Date(e?.date).getTime() < now)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  },
);

export const populatedFutureEventsSelector = createSelector(
  populatedEventsListSelector,
  events => {
    const now = new Date().getTime();
    return events
      .filter(e => e?.date && new Date(e?.date).getTime() > now)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  },
);

export const myEventsSelector = createSelector(
  populatedEventsListSelector,
  authSelector,
  (events, auth) => {
    return events
      .filter(
        e =>
          e?.created_by === auth.uid ||
          e?.admins?.includes(auth.uid) ||
          e?.venue?.created_by === auth.uid ||
          e?.venue?.admins?.includes(auth.uid),
      )
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  },
);

export const suggestedEventsSelector = (region?: string) =>
  createSelector(
    populatedFutureEventsSelector,
    profileSelector,
    (events, profile) => {
      if (!profile?.preferredMusic?.length) {
        return [];
      }
      const selectedRegion = region;
      return events?.filter(e => {
        if (selectedRegion) {
          return (
            e?.venue?.region === selectedRegion &&
            e?.musicStyles?.some(style =>
              profile.preferredMusic?.includes(style),
            )
          );
        }
        return e?.musicStyles?.some(style =>
          profile.preferredMusic?.includes(style),
        );
      });
    },
  );

export const populatedEventSelector = (slugOrId: string) =>
  createSelector(populatedEventsListSelector, (events): Event | undefined =>
    events.find(e => e.id === slugOrId || e.slug === slugOrId),
  );

export const filteredEventsSelector = ({
  future,
  past,
  searchFor,
  region,
  selectedDate,
  musicStyles,
  selectedOrganizationIds,
  selectedVenueIds,
  selectedArtistIds,
  currentUser,
}: FilteredEventsSelector) =>
  createSelector(
    populatedEventsListSelector,
    populatedFutureEventsSelector,
    populatedPastEventsSelector,
    (populatedEvents, populatedFutureEvents, populatedPastEvents): Event[] => {
      let initialEvents = populatedEvents;
      if (future || past) {
        initialEvents = future ? populatedFutureEvents : populatedPastEvents;
      }
      const filtered = initialEvents.filter(event => {
        let musicStyleFilter: boolean | undefined,
          textFilter: boolean | undefined,
          organizationsFilter: boolean | undefined,
          venuesFilter: boolean | undefined,
          dateFilter: boolean | undefined,
          artistsFilter: boolean | undefined;
        const regionFilter = region === event?.venue?.region;
        if (searchFor) {
          textFilter =
            event.name.toLowerCase().includes(searchFor.toLowerCase()) ||
            event.description.toLowerCase().includes(searchFor.toLowerCase());
        }
        if (selectedDate) {
          const eventDate = new Date(event.date);
          dateFilter =
            selectedDate.day === eventDate.getDate() &&
            selectedDate.month === eventDate.getMonth() &&
            selectedDate.year === eventDate.getFullYear();
        }
        if (musicStyles && musicStyles.length) {
          musicStyleFilter = event.musicStyles?.some(style =>
            musicStyles?.includes(style),
          );
        }
        if (selectedOrganizationIds && selectedOrganizationIds.length) {
          const organizationsOnEvent =
            event?.organizations && Object.keys(event.organizations);
          organizationsFilter =
            organizationsOnEvent &&
            organizationsOnEvent?.some(org =>
              selectedOrganizationIds?.includes(org),
            );
        }
        if (selectedVenueIds && selectedVenueIds.length) {
          venuesFilter = selectedVenueIds.includes(event.venueId);
        }
        if (selectedArtistIds && selectedArtistIds.length) {
          const artistsOnEvent = event?.artists && Object.keys(event.artists);
          artistsFilter =
            artistsOnEvent &&
            artistsOnEvent?.some(artist => selectedArtistIds?.includes(artist));
        }
        // Check if the current user reported for abuse a clubs in the list
        let reportedForAbuseByUser = false;

        if (currentUser && event.reported_by) {
          reportedForAbuseByUser = event.reported_by.includes(currentUser);
        }

        return (
          (!region || regionFilter) &&
          (!searchFor || textFilter) &&
          (!musicStyles?.length || musicStyleFilter) &&
          (!selectedOrganizationIds?.length || organizationsFilter) &&
          (!selectedVenueIds?.length || venuesFilter) &&
          (!selectedDate || dateFilter) &&
          (!selectedArtistIds?.length || artistsFilter) &&
          !reportedForAbuseByUser
        );
      });
      return filtered;
    },
  );

export const populatedMainEventsSelector = (filters: FilteredEventsSelector) =>
  createSelector(filteredEventsSelector(filters), populatedEvents =>
    populatedEvents.filter(
      e => e.isMain && new Date(e.date).getTime() > Date.now(),
    ),
  );

export const populatedPremiumEventsSelector = (
  filters: FilteredEventsSelector,
) =>
  createSelector(filteredEventsSelector(filters), populatedEvents =>
    populatedEvents.filter(e => e.isPremium),
  );

export const eventsPhotographerIsMeSelector = createSelector(
  populatedEventsListSelector,
  authSelector,
  (events, auth) => {
    return events.filter(
      e =>
        e?.photographers?.includes(auth.uid) &&
        (e?.endDate
          ? new Date().getTime() > new Date(e.endDate).getTime()
          : new Date().getTime() >
            new Date(e.date).getTime() + 18 * 3600 * 1000),
    );
  },
);
