import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';
import {
  guestListsByEventIdListSelector,
  guestListsListSelector,
  guestListsSelector,
} from '../../store/selectors/guestLists';
import { Event, GuestList } from '../../store/types';

type Props = {
  event?: Event;
};

type UseGuestLists = {
  allGuestLists: { [key: string]: GuestList };
  allGuestListsList: GuestList[];
  guestListsList: GuestList[];
  loaded: boolean;
};

export const useGuestLists = ({ event }: Props = {}): UseGuestLists => {
  const allGuestLists = useSelector(guestListsSelector);
  const allGuestListsList = useSelector(guestListsListSelector);
  const guestListsList = useSelector(
    guestListsByEventIdListSelector(event?.id ?? ''),
  );

  const loaded = isLoaded(allGuestLists);

  const query: ReduxFirestoreQuerySetting = {
    collection: 'guestLists',
    limit: 99,
  };

  if (event?.id) {
    query.where = [['eventId', '==', event.id]];
  }

  useFirestoreConnect(query);

  return {
    allGuestLists,
    allGuestListsList,
    loaded,
    guestListsList,
  };
};
