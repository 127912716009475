import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/molecule';
import { RegisterVenue } from '../../components/organisms';

const RegisterVenueScreen = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper title={t('venue.createVenue')}>
      <RegisterVenue />
    </PageWrapper>
  );
};

export default RegisterVenueScreen;
