import { ActionType, createReducer } from 'typesafe-actions';
import { selectRegion } from '../../actions/configurations/selectRegion';
import { RootState } from '..';
import {
  addToInAppHistory,
  updateInAppHistory,
} from '../../actions/configurations/inAppHistory';
import { selectRegionCase } from './region';
import { addToInAppHistoryCase, updateInAppHistoryCase } from './inAppHistory';

const initialState = {
  region: undefined,
  inAppHistory: [],
};

export const configurationReducer = createReducer<
  RootState['configuration'],
  ActionType<
    typeof selectRegion | typeof updateInAppHistory | typeof addToInAppHistory
  >
>(initialState)
  .handleAction(selectRegion, selectRegionCase)
  .handleAction(updateInAppHistory, updateInAppHistoryCase)
  .handleAction(addToInAppHistory, addToInAppHistoryCase);
