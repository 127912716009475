import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import QRCode from 'qrcode';
import {
  PageWrapper,
  LabeledBox,
  RegistrationDetails,
} from '../../components/molecule';
import { Spacer } from '../../components/atoms';
import {
  useEvent,
  useGuestListRegistrations,
  useGlobalStyles,
  useGuestList,
} from '../../hooks';
import { EventCover } from '../../components/organisms';
import { downloadPdf } from '../../utils';
import { createDocumentDefinition } from './pdf/createDocumentDefinition';
import { usePdfCreation } from './pdf/usePdfCreation';

type Props = {
  match: {
    params: { [key: string]: string };
  };
};

const ConfirmationPage: React.FC<Props> = ({ match }) => {
  const { event: slugOrId } = match.params;
  const { t } = useTranslation();
  const theme = useTheme();
  const globalStyle = useGlobalStyles();
  const [qrCode, setQrCode] = useState<string>();
  const { event } = useEvent({
    slugOrId,
  });
  const { generatePdfData } = usePdfCreation({ event });
  const {
    getUserExistingRegistrationsByEvent,
    loaded: registrationsLoaded,
  } = useGuestListRegistrations({ myRegistrations: true });
  const guestListRegistration =
    event && event.id ? getUserExistingRegistrationsByEvent(event?.id) : null;
  const { guestList, loaded: guestListLoaded } = useGuestList({
    id: guestListRegistration?.[0]?.guestListId,
  });

  const generateQR = useCallback(async () => {
    const opts: QRCode.QRCodeToDataURLOptions = {
      errorCorrectionLevel: 'H',
      type: 'image/png',
      margin: 1,
      rendererOpts: {
        quality: 1,
      },
      color: {
        dark: theme.palette.secondary.dark,
        light: theme.palette.secondary.light,
      },
    };
    try {
      setQrCode(
        await QRCode.toDataURL(guestListRegistration?.[0]?.id || '', opts),
      );
    } catch (err) {
      console.error(err);
    }
  }, [
    guestListRegistration,
    theme.palette.secondary.dark,
    theme.palette.secondary.light,
  ]);

  //eslint-disable-next-line
  const downloadAsPdf = useCallback(async () => {
    const pdfData = await generatePdfData(
      qrCode,
      guestList,
      guestListRegistration,
    );
    const documentDefinition = createDocumentDefinition(pdfData);
    downloadPdf(documentDefinition);
  }, [generatePdfData, qrCode, guestList, guestListRegistration]);

  useEffect(() => {
    if (guestListRegistration?.[0]?.id) {
      generateQR();
    }
  }, [generateQR, guestListRegistration]);

  if (!guestList) {
    return null;
  }

  if (!guestList && registrationsLoaded && guestListLoaded) {
    return <Typography>No registration found</Typography>;
  }

  return (
    <PageWrapper boxed={false}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <RegistrationDetails
              event={event}
              guestListRegistration={guestListRegistration?.[0]!}
              qrCode={qrCode}
            />
          </Grid>
          <Grid item md={9}>
            <EventCover
              item={event}
              cover={event?.coverUrl}
              category="events"
            />
            <Typography
              color="textPrimary"
              variant="h5"
              align="center"
              className={[globalStyle.bold, globalStyle.mv2].join(' ')}
            >
              {t('guestList.guestList')} {guestList?.name}
            </Typography>
            {/*<Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => downloadAsPdf()}
            >
              {t('general.export')}
            </Button>*/}
            <Spacer height="20px" />
            <LabeledBox label={t('event.eventDetails')}>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Spacer height="10px" />
                    <Typography
                      className={globalStyle.bold}
                      variant="h6"
                      align="center"
                    >
                      {event?.name}
                    </Typography>
                    <Spacer height="10px" />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LabeledBox label={t('event.date')}>
                      <Typography color="textPrimary" align="left">
                        {event?.date &&
                          dayjs(event?.date).format('DD MMM YYYY')}
                      </Typography>
                    </LabeledBox>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LabeledBox label={t('event.time')}>
                      <Typography color="textPrimary" align="left">
                        {event?.date && dayjs(event?.date).format(' HH:MM')}
                      </Typography>
                    </LabeledBox>
                  </Grid>
                </Grid>
              </>
            </LabeledBox>
            <Spacer height="20px" />
            <LabeledBox label={t('guestList.details')}>
              <>
                <Spacer height="20px" />
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <LabeledBox label={t('guestList.conditions')}>
                      <Typography color="textPrimary" align="left">
                        {guestList?.conditions
                          ? guestList?.conditions.map(c => (
                              <Typography
                                color="textPrimary"
                                key={c.val}
                                align="left"
                              >
                                {c.val}
                              </Typography>
                            ))
                          : t('guestList.noConditions')}
                      </Typography>
                    </LabeledBox>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LabeledBox label={t('guestList.benefits')}>
                      <Typography color="textPrimary" align="left">
                        {guestList?.benefits
                          ? guestList?.benefits.map(b => (
                              <Typography
                                color="textPrimary"
                                key={b.val}
                                align="left"
                              >
                                {b.val}
                              </Typography>
                            ))
                          : t('guestList.noBenefits')}
                      </Typography>
                    </LabeledBox>
                  </Grid>
                </Grid>
              </>
            </LabeledBox>
            <Spacer height="20px" />
            <LabeledBox label={t('guestList.guests')}>
              <>
                <Spacer height="20px" />
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <LabeledBox label={t('guestList.guestsNumber')}>
                      <Typography color="textPrimary" align="left">
                        {guestListRegistration?.[0]?.guests?.length ?? 0}
                      </Typography>
                    </LabeledBox>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <LabeledBox label={t('guestList.guests')}>
                      <>
                        {!!guestListRegistration?.[0]?.guests?.length &&
                          guestListRegistration?.[0]?.guests?.map(guest => (
                            <Typography
                              color="textPrimary"
                              key={guest.name}
                              align="left"
                            >
                              {guest.name}
                            </Typography>
                          ))}
                      </>
                    </LabeledBox>
                  </Grid>
                </Grid>
              </>
            </LabeledBox>
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default ConfirmationPage;
