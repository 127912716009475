import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Event, GuestList, GuestListRegistration } from '../../../store/types';
import { useVenue } from '../../../hooks';

type Props = {
  event: Event | undefined;
};

export type pdfDataType = {
  qrCode: string;
  guestListName: string;
  venueDetails: {
    clubInfoText: string;
    nameText: string;
    name: string;
    addressText: string;
    address: string;
    cityText: string;
    city: string;
    regionText: string;
    region: string;
    extraLines: number;
  };
  bookingDetails: {
    benefits: string;
    conditions: string;
    linesOfBenefits: number;
    linesOfConditions: number;
    maxNumberOfLines: number;
    benefitsLabel: string;
    conditionsLabel: string;
    mainLabel: string;
  };
  guests: {
    mainLabel: string;
    guestsNumberLabel: string;
    guestsLabel: string;
    names: string;
    numberOfNameLines: number;
  };
  eventDetails: {
    mainLabel: string;
    title: string;
    linesOfEventName: number;
    dateLabel: string;
    timeLabel: string;
    date: string;
    time: string;
  };
};

export const usePdfCreation = ({ event }: Props) => {
  const { t } = useTranslation();
  const { venue } = useVenue({
    slugOrId: event?.venueId,
  });

  const generatePdfData = useCallback(
    async (
      qrCode: string | undefined,
      guestList: GuestList | undefined,
      guestListRegistration: GuestListRegistration[] | null,
    ) => {
      const {
        concatenatedValues: benefits,
        numberOfLines: linesOfBenefits,
      } = concatenateBenefitsOrConditions(guestList?.benefits);
      const {
        concatenatedValues: conditions,
        numberOfLines: linesOfConditions,
      } = concatenateBenefitsOrConditions(guestList?.conditions);

      const { names, numberOfNameLines } = getNamesFromRegistration(
        guestListRegistration,
      );

      const venueDetails = {
        clubInfoText: t('event.clubInfo'),
        nameText: t('venue.name'),
        name: venue?.name ? venue.name : '',
        addressText: t('venue.address'),
        address: venue?.address ? venue.address : '',
        cityText: t('venue.city'),
        city: venue?.city ? venue.city : '',
        regionText: t('venue.region'),
        region: venue?.region ? t(`shared.regions.${venue.region}`) : '',
      };

      const {
        updatedText: eventName,
        lineNumber: linesOfEventName,
      } = createNewLinesIfNeeded(event?.name, 85);

      const extraVenuelines = extraLinesNeeded([
        // each of these information will start in a new line on the pdf document
        // about 60 character can fit in one line. Since I put this information in a canvas element,
        // we have to know if the content needs more lines, to have a bigger box
        venueDetails.nameText.length + venueDetails.name.length,
        venueDetails.addressText.length + venueDetails.address.length,
        venueDetails.cityText.length + venueDetails.city.length,
        venueDetails.regionText.length + venueDetails.region.length,
      ]);

      const pdfData = {
        qrCode: qrCode ? qrCode : '',
        guestListName: guestList?.name ?? '',
        venueDetails: {
          ...venueDetails,
          extraLines: extraVenuelines,
        },
        bookingDetails: {
          benefits,
          conditions,
          linesOfBenefits,
          linesOfConditions,
          maxNumberOfLines:
            linesOfBenefits > linesOfConditions
              ? linesOfBenefits
              : linesOfConditions,
          benefitsLabel: t('guestList.benefits'),
          conditionsLabel: t('guestList.conditions'),
          mainLabel: t('guestList.details'),
        },
        guests: {
          mainLabel: t('guestList.guests'),
          guestsNumberLabel: t('guestList.guestsNumber'),
          guestsLabel: t('guestList.guests'),
          names,
          numberOfNameLines,
        },
        eventDetails: {
          mainLabel: t('event.eventDetails'),
          title: eventName,
          linesOfEventName,
          dateLabel: t('event.date'),
          timeLabel: t('event.time'),
          date: dayjs(event?.date).format('DD MMM YYYY'),
          time: dayjs(event?.date).format(' HH:MM'),
        },
      };
      return pdfData;
    },
    [event, t, venue],
  );

  return {
    generatePdfData,
  };
};

// convert all the values into a string
const concatenateBenefitsOrConditions = (
  arrayOfValues: { val: string }[] | undefined,
) => {
  let concatenatedValues = '';
  let numberOfLines = 0;
  if (arrayOfValues) {
    arrayOfValues.forEach(({ val }) => {
      const { updatedText, lineNumber } = createNewLinesIfNeeded(val, 51);
      concatenatedValues += updatedText;
      numberOfLines += lineNumber;
    });
  }

  if (!numberOfLines) {
    numberOfLines = 1;
  }
  return {
    concatenatedValues,
    numberOfLines,
  };
};

// convert all the values into a string
const getNamesFromRegistration = (
  guestListRegistration: GuestListRegistration[] | null,
) => {
  let names = '';
  let numberOfNameLines = 0;
  if (guestListRegistration) {
    names += guestListRegistration[0].name + '\n';
    numberOfNameLines += 1;
    guestListRegistration[0].guests.forEach(({ name }) => {
      const { updatedText, lineNumber } = createNewLinesIfNeeded(name, 51);
      names += updatedText;
      numberOfNameLines += lineNumber;
    });
  }

  if (!numberOfNameLines) {
    numberOfNameLines = 1;
  }
  return {
    names,
    numberOfNameLines,
  };
};

const extraLinesNeeded = (textLengths: number[]) => {
  const CHARACTER_LIMIT_IN_ONE_LINE = 60;
  let extraLines = 0;
  textLengths.forEach(length => {
    if (length > CHARACTER_LIMIT_IN_ONE_LINE) {
      extraLines +=
        (length - CHARACTER_LIMIT_IN_ONE_LINE) / CHARACTER_LIMIT_IN_ONE_LINE;
    }
  });
  return extraLines;
};

const createNewLinesIfNeeded = (
  text: string | undefined,
  charsInOneLine: number,
) => {
  // In a small labeled box about 51 characters fits in 1 line. Here I create new lines if necessary
  if (!text) {
    return {
      updatedText: '',
      lineNumber: 1,
    };
  }
  let updatedText = text;
  const regex = new RegExp(`(.{${charsInOneLine}})`, 'g');
  if (text.length > charsInOneLine) {
    updatedText = text.replace(regex, '$1-\n') + '\n';
  } else {
    updatedText = text + '\n';
  }
  return {
    updatedText,
    lineNumber:
      text.length > charsInOneLine
        ? Math.ceil(text.length / charsInOneLine)
        : 1,
  };
};
