import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Error } from '@material-ui/icons';
import { LabeledBox, PageWrapper } from '../../components/molecule';
import {
  UserDetails,
  ProfileMain,
  ReactivateProfile,
} from '../../components/organisms';
import { Emoji, Spacer } from '../../components/atoms';
import { useAuthentication, useVerification } from '../../hooks';

const useStyles = makeStyles({
  venueButton: {
    background: '#fdb845',
  },
});

const Profile = () => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const goToEdit = useCallback(() => history.push('/edit-profile'), [history]);
  const goToRegisterVenue = useCallback(() => history.push('/register-venue'), [
    history,
  ]);
  const goToRegisterOrganization = useCallback(
    () => history.push('/register-organization'),
    [history],
  );
  const goToBecomePhotographer = useCallback(
    () => history.push('/become-photographer'),
    [history],
  );
  const goToDashboard = useCallback(
    () =>
      history.push({
        pathname: '/profile-dashboard',
        state: { selectedMenuItem: 'myEvents' },
      }),
    [history],
  );
  const {
    resendVerificationEmail,
    sendVerificationSms,
    verifyPhoneNumber,
  } = useVerification();
  const {
    isDeactivated,
    isProfileComplete,
    profile,
    emailVerified,
    phoneVerified,
    verified,
  } = useAuthentication();
  const [emailSent, setEmailSent] = useState(false);
  const [phoneResponse, setPhoneResponse] = useState<boolean>();
  const [smsSent, setSmsSent] = useState(false);
  const [otp, setOtp] = useState<number>();

  const canBeVerified = isProfileComplete;

  const isPhotographer = profile?.roles?.includes('photographer');

  const handleSendLink = useCallback(() => {
    resendVerificationEmail();
    setEmailSent(true);
  }, [resendVerificationEmail]);

  const handleSendSms = useCallback(() => {
    sendVerificationSms();
    setSmsSent(true);
  }, [sendVerificationSms]);

  const handleVerifyPhone = useCallback(() => {
    if (otp) {
      const { success } = verifyPhoneNumber(otp);
      setPhoneResponse(success);
    }
  }, [otp, verifyPhoneNumber]);

  const renderMain = useMemo(() => {
    if (isDeactivated) {
      return <ReactivateProfile />;
    }
    return <ProfileMain />;
  }, [isDeactivated]);

  return (
    <PageWrapper>
      <Box>
        <Spacer height="20px" />
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <UserDetails />
            <Spacer height="10px" />
            {verified && (
              <>
                <Paper variant="outlined">
                  <Box
                    p={2}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography variant="h3">
                      <Emoji emoji="🛡" />
                    </Typography>
                    <Typography>
                      <b>{t('profile.profileVerified')}</b>
                    </Typography>
                  </Box>
                </Paper>
                <Spacer height="10px" />
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={goToRegisterVenue}
                  className={styles.venueButton}
                >
                  {t('shared.registerVenue')}
                </Button>
                <Spacer height="10px" />
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={goToRegisterOrganization}
                >
                  {t('shared.registerOrganization')}
                </Button>
                <Spacer height="10px" />
              </>
            )}
            {isPhotographer && (
              <>
                <Paper variant="outlined">
                  <Box
                    p={2}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography variant="h3">
                      <Emoji emoji="📸" />
                    </Typography>
                    <Typography>
                      <b>{t('profile.photographerVerified')}</b>
                    </Typography>
                  </Box>
                </Paper>
                <Spacer height="10px" />
              </>
            )}
            {!canBeVerified && (
              <>
                <Button fullWidth variant="outlined" onClick={goToEdit}>
                  {t('profile.completeProfileAndVerify')}
                </Button>
                <Spacer height="10px" />
              </>
            )}
            {canBeVerified && !verified && (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleSendLink}
                  disabled={emailSent || emailVerified}
                >
                  {emailSent
                    ? t('profile.emailSent')
                    : emailVerified
                    ? t('profile.emailVerified')
                    : t('profile.verifyEmail')}
                </Button>
                <Spacer height="10px" />
              </>
            )}
            {canBeVerified && !verified && (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleSendSms}
                  disabled={smsSent || phoneVerified}
                >
                  {smsSent
                    ? t('shared.verificationSmsResent')
                    : phoneVerified
                    ? t('profile.phoneVerified')
                    : t('profile.verifyPhone')}
                </Button>
                <Spacer height="10px" />
              </>
            )}
            {smsSent && !phoneVerified && !phoneResponse && (
              <>
                <Spacer height="10px" />
                <LabeledBox label="Verifica SMS">
                  <Box p={1}>
                    <Typography variant="caption">
                      Inserisci il codice ricevuto per SMS
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      color="primary"
                      size="small"
                      fullWidth
                      value={otp || ''}
                      onChange={e => setOtp(Number(e?.target?.value))}
                      InputProps={{
                        endAdornment:
                          !phoneResponse && phoneResponse !== undefined ? (
                            <Error color="error" />
                          ) : undefined,
                      }}
                    />
                    <Spacer height="10px" width="20px" />
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      size="small"
                      fullWidth
                      disabled={!otp || String(otp).length < 6}
                      onClick={handleVerifyPhone}
                    >
                      Verifica
                    </Button>
                  </Box>
                </LabeledBox>
                <Spacer height="10px" />
              </>
            )}
            {!isPhotographer && (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={goToBecomePhotographer}
                >
                  {t('profile.becomePhotographer')}
                </Button>
                <Spacer height="10px" />
              </>
            )}
            <Button fullWidth variant="outlined" onClick={goToEdit}>
              {t('profile.editProfile')}
            </Button>
            <Spacer height="10px" />
            <Button fullWidth variant="outlined" onClick={goToDashboard}>
              {t('profile.dashboard')}
            </Button>
            <Spacer height="20px" />
          </Grid>
          <Grid item md={9} xs={12}>
            {renderMain}
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default Profile;
