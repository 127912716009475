import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import {
  guestListRegistrationsByUserSelector,
  guestListRegistrationsListSelector,
  guestListRegistrationsByEventSelector,
} from '../../store/selectors/guestListRegistrations';
import {
  GuestListRegistration,
  PopulatedGuestListRegistration,
} from '../../store/types';
import { useAuthentication } from '../auth';
import { useSendEmail } from '../email/useSendEmail';

type Props = {
  myRegistrations?: boolean;
  guestListId?: string;
  eventId?: string;
  timePeriod?: 'past' | 'future';
};

type UseGuestListRegistrations = {
  loaded: boolean;
  registerGuestListRegistration: (registration: GuestListRegistration) => void;
  getUserExistingRegistrationsByEvent: (
    guestListId: string,
  ) => GuestListRegistration[];
  getEveryRegistrationsByEvent: (eventId: string) => GuestListRegistration[];
  getRegistrationsByGuestList: (guestListId: string) => GuestListRegistration[];
  guestListRegistrationsByUser: PopulatedGuestListRegistration[];
  updateGuestListRegistration: (
    guestListRegistrationId: string,
    updatedGuestListRegistration: GuestListRegistration,
  ) => void;
  guestListRegistrationsByEvent: GuestListRegistration[];
};

export const useGuestListRegistrations = ({
  myRegistrations,
  guestListId,
  eventId,
  timePeriod,
}: Props = {}): UseGuestListRegistrations => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentication();
  const { sendEmail } = useSendEmail();
  const guestListRegistrations = useSelector(
    guestListRegistrationsListSelector,
  );
  const guestListRegistrationsByUser = useSelector(
    guestListRegistrationsByUserSelector(timePeriod),
  );
  const guestListRegistrationsByEvent = useSelector(
    guestListRegistrationsByEventSelector(eventId),
  );

  const registerGuestListRegistration = useCallback(
    async (registration: GuestListRegistration) => {
      const associatedEventRef = await firestore
        .collection('events')
        .doc(registration.eventId)
        .get();
      const associatedEvent = await associatedEventRef.data();
      if (registration.id) {
        await firestore
          .collection('/guestListRegistrations')
          .doc(registration.id)
          .update({ ...registration, updated_at: new Date().getTime() });
        if (associatedEvent) {
          sendEmail({
            subject: t('emails.guestListRegistration.update.subject'),
            content1: t('emails.guestListRegistration.update.content1', {
              displayName: profile.displayName,
              guestListName: registration.guestListName,
              eventName: associatedEvent.name,
              eventDate: associatedEvent.date,
            }),
            content2: t('emails.guestListRegistration.update.content2'),
            cta: {
              url: `${window.location.origin}/events/${associatedEvent.slug}/confirmation`,
              text: t('emails.guestListRegistration.update.ctaText'),
            },
            shortText: t('emails.guestListRegistration.update.shortText', {
              guestListName: registration.guestListName,
            }),
            text: t('emails.guestListRegistration.update.text', {
              displayName: profile.displayName,
              guestListName: registration.guestListName,
              eventName: associatedEvent.name,
              url: `${window.location.origin}/events/${associatedEvent.slug}/confirmation`,
              eventDate: associatedEvent.date,
            }),
          });
        }
        return;
      }
      delete registration.id;
      await firestore.collection('/guestListRegistrations').add({
        ...registration,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
      });
      if (associatedEvent) {
        sendEmail({
          subject: t('emails.guestListRegistration.create.subject'),
          content1: t('emails.guestListRegistration.create.content1', {
            displayName: profile.displayName,
            guestListName: registration.guestListName,
            eventName: associatedEvent.name,
            eventDate: associatedEvent.date,
          }),
          content2: t('emails.guestListRegistration.create.content2'),
          cta: {
            url: `${window.location.origin}/events/${associatedEvent.slug}/confirmation`,
            text: t('emails.guestListRegistration.create.ctaText'),
          },
          shortText: t('emails.guestListRegistration.create.shortText', {
            guestListName: registration.guestListName,
          }),
          text: t('emails.guestListRegistration.create.text', {
            displayName: profile.displayName,
            guestListName: registration.guestListName,
            eventName: associatedEvent.name,
            url: `${window.location.origin}/events/${associatedEvent.slug}/confirmation`,
            eventDate: associatedEvent.date,
          }),
        });
      }

      return;
    },
    [firestore, profile.displayName, sendEmail, t],
  );

  const updateGuestListRegistration = useCallback(
    async (
      guestListRegistrationId: string,
      updatedGuestListRegistration: GuestListRegistration,
    ) => {
      if (guestListRegistrationId) {
        await firestore
          .collection('/guestListRegistrations')
          .doc(guestListRegistrationId)
          .update({ ...updatedGuestListRegistration });
      }
    },
    [firestore],
  );

  const getUserExistingRegistrationsByEvent = useCallback(
    (eventId: string) => {
      return guestListRegistrations.filter(
        registration =>
          registration.eventId === eventId && registration.userId === userId,
      );
    },
    [guestListRegistrations, userId],
  );

  const getEveryRegistrationsByEvent = useCallback(
    (id: string) => {
      return guestListRegistrations.filter(
        registration => registration.eventId === id,
      );
    },
    [guestListRegistrations],
  );
  const getRegistrationsByGuestList = useCallback(
    (id: string) => {
      return guestListRegistrations.filter(
        registration => registration.guestListId === id,
      );
    },
    [guestListRegistrations],
  );

  const loaded = isLoaded(guestListRegistrations);

  const query: ReduxFirestoreQuerySetting = {
    collection: 'guestListRegistrations',
  };

  if (myRegistrations && userId) {
    query.where = ['userId', '==', userId];
  }

  if (guestListId) {
    query.where = ['guestListId', '==', guestListId];
  }

  if (eventId) {
    query.where = ['eventId', '==', eventId];
  }

  useFirestoreConnect(query);

  return {
    loaded,
    registerGuestListRegistration,
    getUserExistingRegistrationsByEvent,
    getEveryRegistrationsByEvent,
    getRegistrationsByGuestList,
    guestListRegistrationsByUser,
    updateGuestListRegistration,
    guestListRegistrationsByEvent,
  };
};
