import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  logo: {
    height: 80,
    width: 80,
    position: 'absolute',
    left: theme.spacing(2),
    transform: 'translateY(-70px)',
    border: `${theme.spacing(0.25)}px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.secondary.light + '!important',
    boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
  },
  topIcons: {
    position: 'absolute',
    right: theme.spacing(1),
    transform: 'translateY(-40px)',
    color: '#ffffff',
  },
  card: {
    width: '100%',
    position: 'relative',
  },
  cardMedia: {
    height: 140,
  },
  content: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  cardMediaWithIcons: {
    boxShadow: '0 -40px 40px -15px rgba(0,0,0,0.5) inset',
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  grow: {
    flexGrow: 1,
  },
  alignSelf: {
    alignSelf: 'flex-start',
  },
  button: {
    '@media (max-width: 430px)': {
      fontSize: 11,
    },
    '@media (max-width: 370px)': {
      fontSize: 10,
    },
  },
}));
