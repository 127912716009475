import React from 'react';
import { Card, CardActionArea, CardMedia, Typography } from '@material-ui/core';
import { Organization, Venue } from '../../../store/types';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import defaultLogo from '../../../assets/img/defaultLogo.jpg';
import { useGlobalStyles } from '../../../hooks';
import useStyle from './style';

type Props = {
  page: Venue | Organization;
  onClick?: () => void;
};

const PageHeaderWidget: React.FC<Props> = ({ page, onClick }) => {
  const style = useStyle();
  const globalStyles = useGlobalStyles();
  const logo = (page?.logoUrl && page?.logoUrl) || defaultLogo;
  const cover = (page?.coverUrl && page?.coverUrl) || defaultCover;
  return (
    <Card
      className={[
        globalStyles.relative,
        globalStyles.notRounded,
        style.card,
      ].join(' ')}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia
          image={cover}
          title={page?.name}
          className={style.CardMedia}
        />
        <CardMedia image={logo} title={page?.name} className={style.logo} />
        <Typography className={style.pageTitle}>{page?.name}</Typography>
      </CardActionArea>
    </Card>
  );
};

PageHeaderWidget.displayName = 'PageHeaderWidget';

export default React.memo(PageHeaderWidget);
