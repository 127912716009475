import React from 'react';
import { TillDatePageWrapper } from '../../components/molecule';

const TillDateScreen = () => {
  return (
    <TillDatePageWrapper>
      qui modifichi come gli altri ti vedono
    </TillDatePageWrapper>
  );
};

export default TillDateScreen;
