import React, { useCallback } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { IonIcon, isPlatform } from '@ionic/react';
import { UserInfo } from 'firebase/app';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { logoTwitter, logoApple } from 'ionicons/icons';
import { Facebook } from '@material-ui/icons';
// import { cfaSignIn, mapUserToUserInfo } from 'capacitor-firebase-auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import Firebase from 'firebase';
import googleLogo from '../../../assets/img/google_g.svg';
import { useMediaQuery } from '../../../hooks';
import { analytics } from '../../../rrf.config';
import useStyles from './styles';

type Props = {
  provider: 'facebook' | 'google' | 'twitter' | 'apple.com';
  scopes?: string[];
  children?: string | JSX.Element | JSX.Element[];
  onLoginCallback?: () => void;
};

const SocialLoginButton: React.FC<Props> = ({
  provider,
  scopes = [],
  onLoginCallback,
}) => {
  const { isMobile } = useMediaQuery();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { t } = useTranslation();
  const classes = useStyles();
  const icons = {
    facebook: <Facebook className={classes.facebookIcon} />,
    twitter: <IonIcon icon={logoTwitter} />,
    google: <img src={googleLogo} alt="" />,
    'apple.com': <IonIcon icon={logoApple} />,
  };

  // Check if the user exists on db on mobile login, if not create it
  const checkUserOnDb = async (user: UserInfo) => {
    try {
      const userOnDb = (
        await firestore.collection('users_meta').doc(user.uid).get()
      ).exists;

      if (userOnDb) {
        return;
      } else {
        let profilePictures: string[] = [];

        //
        // Apple does not return displayName, photoUrl and pictures
        // so we have to check them first, else create user on db fails
        //
        let newUser: {
          email: string | null;
          displayName: string;
          photoUrl?: string;
          avatarUrl?: string;
          preferredProfilePicture?: string | null;
          profilePictures: string[];
          created_at: number;
          updated_at: number;
          providerData: string[];
        } = {
          email: user.email,
          displayName: user.displayName ? user.displayName : 'Denxer',
          profilePictures: profilePictures,
          created_at: new Date().getTime(),
          updated_at: new Date().getTime(),
          providerData: [provider],
        };

        if (user.photoURL) {
          newUser.avatarUrl = user.photoURL;
          newUser.photoUrl = user.photoURL;
          newUser.preferredProfilePicture = user.photoURL;
          newUser.profilePictures.push(user.photoURL);
        }

        return await firestore
          .collection('users_meta')
          .doc(user.uid)
          .set(newUser);
      }
    } catch (err) {
      console.log('checkUserOnDb: ' + err);
    }
  };

  const signIn = useCallback(async () => {
    try {
      const isCapacitor = isPlatform('capacitor');

      /*
        if (isCapacitor && provider === 'google') {
          cfaSignIn('google.com')
            .pipe(mapUserToUserInfo())
            .subscribe((user: UserInfo) => {
              if (user) {
                checkUserOnDb(user).then(() => {
                  console.log('entrato user');

                  //
                  // To avoid problems with delete account (you can call deleteAccount by login with a different account)
                  // we check if who calls deleteAccount is the owner by checking his mail
                  //
                  const checkCurrentUser = firebase.auth().currentUser;

                  if (onLoginCallback && user.email === checkCurrentUser?.email) {
                    onLoginCallback();
                  }
                });
              }
            });
          return;
        }

        if (isCapacitor && provider === 'apple.com') {
          cfaSignIn('apple.com')
            .pipe(mapUserToUserInfo())
            .subscribe((user: UserInfo) => {
              if (user) {
                checkUserOnDb(user).then(() => {
                  //
                  // To avoid problems with delete account (you can call deleteAccount by login with a different account)
                  // we check if who calls deleteAccount is the owner by checking his mail
                  //
                  const checkCurrentUser = firebase.auth().currentUser;

                  if (onLoginCallback && user.email === checkCurrentUser?.email) {
                    onLoginCallback();
                  }
                });
              }
            });
          return;
        }
      */

      if (isCapacitor && provider === 'google') {
        const user = await GoogleAuth.signIn();

        if (user.authentication.accessToken) {
          const responseCredential = Firebase.auth.GoogleAuthProvider.credential(
            user.authentication.accessToken,
          );
          firebase
            .auth()
            .signInWithCredential(responseCredential)
            .then(success => {
              console.log('Firebase success: ' + JSON.stringify(success));
            });
        }

        return;
      }

      if (isCapacitor && provider === 'facebook') {
        try {
          await FacebookLogin.login({
            permissions: ['email', 'public_profile'],
          }).then(response => {
            if (!response.accessToken) {
              return;
            }

            const responseCredential = Firebase.auth.FacebookAuthProvider.credential(
              response.accessToken.token,
            );

            firebase
              .auth()
              .signInWithCredential(responseCredential)
              .then(success => {
                console.log('Firebase success: ' + JSON.stringify(success));
              });
          });
        } catch (error) {
          console.log('facebook login error', error);
          return;
        }
        return;
      }

      return firebase
        .login({
          provider,
          scopes,
          type: !isMobile ? 'redirect' : 'popup',
        })
        .then(() => {
          analytics.logEvent('login', { method: provider });
        })
        .catch(err => console.log(err));
    } catch (error) {
      console.log('SOCIAL LOGIN ----- ', error);
    }

    //eslint-disable-next-line
  }, [firebase, isMobile, onLoginCallback, provider, scopes]);

  return (
    <>
      {provider === 'apple.com' ? (
        <Button
          color="primary"
          variant="contained"
          onClick={signIn}
          startIcon={icons[provider]}
          className={classes.appleLogin}
          fullWidth
          autoCapitalize="false"
        >
          {t('social.appleSign')}
        </Button>
      ) : null}
      {provider === 'facebook' ? (
        <Button
          color="primary"
          variant="contained"
          onClick={signIn}
          startIcon={icons[provider]}
          className={classes.facebookLogin}
          fullWidth
          autoCapitalize="false"
        >
          {t('social.facebookSign')}
        </Button>
      ) : null}
      {provider === 'google' ? (
        <Button
          color="primary"
          variant="contained"
          onClick={signIn}
          startIcon={icons[provider]}
          className={classes.googleLogin}
          fullWidth
          autoCapitalize="false"
        >
          {t('social.googleSign')}
        </Button>
      ) : null}
    </>
  );
};

SocialLoginButton.displayName = 'SocialLoginButton';

export default React.memo(SocialLoginButton);
