import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useUsers } from '../../../hooks';
import { Spacer } from '../../atoms';
import useDebounce from '../../../hooks/ui/useDebounce';

type Props = {
  selectAdmins: (adminsList: string[]) => void;
  defaultAdmins?: any[];
};

const SelectAdmins: React.FC<Props> = ({ selectAdmins, defaultAdmins }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState<string>('');
  const debouncedSearchFor = useDebounce(input.trim(), 300);
  const { usersList } = useUsers({
    searchFor: debouncedSearchFor,
    ids: defaultAdmins ?? [],
  });

  return (
    <Grid item md={12}>
      {usersList && (
        <Autocomplete
          size="small"
          multiple
          open={input.length >= 2}
          id="multiple-limit-tags"
          options={usersList.map(u => u.id)}
          getOptionSelected={(option, value) => {
            return option === value;
          }}
          defaultValue={defaultAdmins}
          onInputChange={(_, v) => {
            setInput(v);
          }}
          getOptionLabel={option =>
            usersList.find(u => u.id === option)?.email ?? ''
          }
          onChange={(_, values) => {
            selectAdmins(values);
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={t('general.admins')}
              inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
              placeholder={t('general.adminEmail')}
            />
          )}
        />
      )}
      <Spacer height="10px" />
    </Grid>
  );
};

export default SelectAdmins;
