import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { Venue } from '../../types';
import { authSelector } from '../selectors';

type FilteredVenueSelector = {
  region?: string | undefined;
  searchFor?: string;
  musicStyles?: string[];
  userId?: string;
  isVerified?: boolean;
  currentUser: string | undefined;
};

export const venuesSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: Venue } => firestore?.data?.clubs ?? {},
);

export const venuesListSelector = createSelector(
  venuesSelector,
  (venues): Venue[] =>
    Object.entries(venues || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const venueSelector = (slugOrId: string) =>
  createSelector(
    venuesSelector,
    venuesListSelector,
    (venues, venuesList): Venue =>
      venues[slugOrId] || venuesList.find(v => v.slug === slugOrId),
  );

export const myVenuesSelector = createSelector(
  venuesListSelector,
  authSelector,
  (venues, auth) => {
    return venues.filter(
      v => v?.created_by === auth.uid || v?.admins?.includes(auth.uid),
    );
  },
);

export const filteredVenuesSelector = ({
  searchFor,
  region,
  musicStyles,
  userId,
  isVerified,
  currentUser,
}: FilteredVenueSelector) =>
  createSelector(venuesListSelector, (venues): Venue[] => {
    const filtered =
      venues?.filter(venue => {
        if (region || searchFor || musicStyles) {
          const regionFilter = region && region === venue.region;
          const textFilter =
            searchFor &&
            (venue.name?.toLowerCase()?.includes(searchFor?.toLowerCase()) ||
              venue.description
                ?.toLowerCase()
                ?.includes(searchFor?.toLowerCase()));
          const musicStyleFilter =
            musicStyles &&
            musicStyles.length &&
            venue.musicStyles?.some(style => musicStyles?.includes(style));
          const verifiedFilter = isVerified && venue.isVerified;
          // Check if the current user reported for abuse a clubs in the list
          let reportedForAbuseByUser = false;

          if (currentUser && venue.reported_by) {
            reportedForAbuseByUser = venue.reported_by.includes(currentUser);
          }

          return (
            (!region || regionFilter) &&
            (!searchFor || textFilter) &&
            (!isVerified || verifiedFilter) &&
            (!musicStyles?.length || musicStyleFilter) &&
            !reportedForAbuseByUser
          );
        }

        if (userId) {
          return venue.created_by === userId || venue.admins?.includes(userId);
        }
        return true;
      }) ?? [];
    return filtered;
  });
