import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useUsers } from '../../../hooks';
import { Spacer } from '../../atoms';
import useDebounce from '../../../hooks/ui/useDebounce';

type Props = {
  selectPhotographers: (PhotographersList: string[]) => void;
  defaultPhotographers?: string[];
};

const SelectPhotographers: React.FC<Props> = ({
  selectPhotographers,
  defaultPhotographers,
}) => {
  const { t } = useTranslation();
  const [input, setInput] = useState<string>('');
  const debouncedSearchFor = useDebounce(input.trim(), 300);
  const { photographersList } = useUsers({
    searchFor: debouncedSearchFor,
    ids: defaultPhotographers ?? [],
    photographers: true,
  });

  return (
    <Grid item md={12}>
      {photographersList && (
        <Autocomplete
          size="small"
          multiple
          open={input.length >= 2}
          id="multiple-limit-tags"
          options={photographersList.map(u => u.id)}
          defaultValue={defaultPhotographers}
          getOptionSelected={(option, value) => {
            return option === value;
          }}
          onInputChange={(_, v) => {
            setInput(v);
          }}
          getOptionLabel={option =>
            photographersList.find(u => u.id === option)?.email ?? ''
          }
          onChange={(_, values) => {
            selectPhotographers(values);
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={t('general.photographers')}
              inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
              placeholder={t('general.photographerEmail')}
            />
          )}
        />
      )}
      <Spacer height="10px" />
    </Grid>
  );
};

export default SelectPhotographers;
