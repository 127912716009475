import React from 'react';
import {
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { GuestListRegistration } from '../../../store/types';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import useStyle from './styles';
import RegisterEventGuestDetails from './RegisterEventGuestDetails';

type Props = {
  guestListRegistration: GuestListRegistration;
  onAccessedGuest: (
    guestListRegistration: GuestListRegistration,
    guestName: string,
  ) => void;
  onAccessedAll: (
    checked: boolean,
    guestListRegistration: GuestListRegistration,
  ) => void;
};

const SingleRegisterEventGuests: React.FC<Props> = ({
  guestListRegistration: registration,
  onAccessedGuest,
  onAccessedAll,
}) => {
  const { t } = useTranslation();
  const styles = useStyle();
  const [open, toggleDetails] = useToggleState(false);
  const isAllAccessed = registration.guests.every(guest => guest.accessed);
  const primaryArr = [
    t('guestList.registeringPerson'),
    registration.name,
    t('guestList.name'),
    registration.guestListName,
  ];
  const secondaryArr = [
    t('guestList.guestsNumber'),
    registration.guests.length,
    t('guestList.accessedGuests'),
    registration.guests.filter(guest => guest.accessed).length || 0,
  ];

  const primary = `${primaryArr[0]}: ${primaryArr[1]} - ${primaryArr[2]}: ${primaryArr[3]}`;
  const secondary = `${secondaryArr[0]}: ${secondaryArr[1]} / ${secondaryArr[2]}: ${secondaryArr[3]}`;

  return (
    <>
      <ListItem key={registration.id}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isAllAccessed}
            disableRipple
            onChange={(_, c) => onAccessedAll(c, registration)}
          />
        </ListItemIcon>
        <ListItemText
          className={styles.listItemText}
          primary={primary}
          secondary={secondary}
        />
        {!!registration.guests.length && (
          <ListItemSecondaryAction>
            <IconButton onClick={toggleDetails}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Divider variant="inset" />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <RegisterEventGuestDetails
          guestListRegistration={registration}
          onAccessedGuest={onAccessedGuest}
        />
      </Collapse>
    </>
  );
};

SingleRegisterEventGuests.displayName = 'SingleRegisterEventGuests';

export default React.memo(SingleRegisterEventGuests);
