import { useIonViewWillLeave } from '@ionic/react';
import { Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import { PhotoAlbum, PhotoAlbumCover } from '..';
import {
  useAuthentication,
  useCompressedImage,
  useCompressedImages,
  useEditGuard,
  useEvent,
  useGlobalStyles,
} from '../../../hooks';
import { usePhotoAlbum } from '../../../hooks/photoAlbums';
import { LoadingCover, Spacer } from '../../atoms';

type Props = {
  id: string;
};

const EditPhotoAlbum: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [cover, setCover, setCompressCover] = useCompressedImage();
  const [
    images,
    setImages,
    setCompressImages,
    progress,
  ] = useCompressedImages();
  const [canSave, setCanSave] = useState(false);
  const { userId } = useAuthentication();
  const [eventId, setEventId] = useState<string>();
  const globalStyles = useGlobalStyles();
  const [contentSaved, setContentSaved] = useState(true);
  const { loading, photoAlbum, updatePhotoAlbum } = usePhotoAlbum({ id });
  const { event } = useEvent({
    slugOrId: eventId,
  });

  const handleSave = useCallback(async () => {
    if (eventId && event) {
      await updatePhotoAlbum({ id, eventId, images, cover, imagesToDelete });
      setContentSaved(true);
      history.push(`/events/${event.slug}`);
    }
  }, [
    cover,
    event,
    eventId,
    history,
    id,
    images,
    imagesToDelete,
    updatePhotoAlbum,
  ]);

  useEditGuard(photoAlbum);

  useEffect(() => {
    if (photoAlbum) {
      setEventId(photoAlbum.eventId);
      setImages(photoAlbum?.images as (string | File)[]);
      setCover(photoAlbum?.coverUrl || []);
    }
  }, [photoAlbum, setCover, setImages, userId]);

  useEffect(() => {
    setCanSave(!!eventId && !!images.length && !!cover);
    setContentSaved(false);
  }, [cover, eventId, images.length]);

  useIonViewWillLeave(() => {
    setContentSaved(true);
  });

  return (
    <div className={globalStyles.relative}>
      {loading && <LoadingCover />}
      {!!progress && <LoadingCover progress={progress} />}

      <Prompt
        when={!!(images.length && !contentSaved)}
        message={t('photoAlbums.exit')}
      />
      <Spacer height="10px" />
      <Typography
        color="textPrimary"
        variant="h4"
        align="center"
        className={globalStyles.bold}
      >
        Event: {event?.name}
      </Typography>
      <Spacer height="10px" />
      <PhotoAlbumCover.Form setCover={setCompressCover} cover={cover} />
      <PhotoAlbum
        images={images}
        setImages={setImages}
        setCompressImages={setCompressImages}
        setImagesToDelete={setImagesToDelete}
      />
      <Spacer height="20px" />
      <Button
        onClick={handleSave}
        variant="outlined"
        color="primary"
        disabled={!canSave}
      >
        {t('shared.save')}
      </Button>
      <Spacer height="20px" />
    </div>
  );
};

EditPhotoAlbum.displayName = 'EditPhotoAlbum';

export default React.memo(EditPhotoAlbum);
