import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import it from '../../../assets/privacy/it.json';
import en from '../../../assets/privacy/en.json';
import useStyle from './style';

type Props = {};

type ListType = {
  h3: string;
  p1?: string;
  l1t?: string;
  l1?: string[];
  p2?: string;
  l2t?: string;
  l2?: string[];
  p3?: string;
  l3t?: string;
  l3?: string[];
  p4?: string;
};

type PrivacyData = {
  title: string;
  subTitle: string;
  firstText: string;
  list: ListType[];
};

const PrivacyPolicy: React.FC<Props> = () => {
  const style = useStyle();
  const [data, setData] = useState<PrivacyData>(en);
  const lang = i18next.language;

  useEffect(() => {
    if (lang === 'it' || lang === 'it-IT') {
      setData(it);
    } else {
      setData(en);
    }
  }, [lang]);

  return (
    <div className={style.section}>
      <div className={style.wrapper}>
        <h1 className={style.titlePage}>{data.title}</h1>
        <h2 className={style.subTitlePage}>{data.subTitle}</h2>
        <p className={style.text}>{data.firstText}</p>

        {data.list.map((item, index) => (
          <span key={index}>
            <h3 className={style.term}>{item.h3}</h3>
            <p className={style.text}>{item.p1}</p>
            <p className={style.text}>{item.l1t}</p>
            {!item.l1 ? null : (
              <ul>
                {item.l1.map((string1, index1) => (
                  <li className={style.text} key={index1}>
                    {string1}
                  </li>
                ))}
              </ul>
            )}

            <p className={style.text}>{item.p2}</p>

            <p className={style.text}>{item.l2t}</p>
            {!item.l2 ? null : (
              <ul>
                {item.l2.map((string2, index2) => (
                  <li key={index2} className={style.text}>
                    {string2}
                  </li>
                ))}
              </ul>
            )}

            <p className={style.text}>{item.p3}</p>

            <p className={style.text}>{item.l3t}</p>
            {!item.l3 ? null : (
              <ul>
                {item.l3.map((string3, index3) => (
                  <li key={index3} className={style.text}>
                    {string3}
                  </li>
                ))}
              </ul>
            )}

            <p className={style.text}>{item.p4}</p>
          </span>
        ))}
      </div>
    </div>
  );
};

PrivacyPolicy.displayName = 'PrivacyPolicy';

export default React.memo(PrivacyPolicy);
