import React, { useState } from 'react';
import { SearchBar } from '../../molecule';

type Props = {};

const SearchVenuesBar: React.FC<Props> = () => {
  const [searchText, setSearchText] = useState<string>();
  return (
    <SearchBar
      label="Cerca clubs"
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

SearchVenuesBar.displayName = 'SearchVenuesBar';

export default React.memo(SearchVenuesBar);
