import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  messagesWrapper: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
    opacity: ({ visible }: any) => (visible ? 1 : 0),
    transition: 'all 0.2s ease',
  },
  messageInputWrapper: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    left: 0,
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    paddingBottom: `calc(env(safe-area-inset-bottom) + ${theme.spacing(2)}px)`,
  },
  isSpamming: {
    width: '100%',
    padding: theme.spacing(1),
  },
  report: {
    minHeight: 50,
  },
}));
