import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

type Props = {
  genders: {
    emoji: string;
    text: string;
  }[];
  selectedGender: string;
  setSelectedGender: React.Dispatch<React.SetStateAction<string>>;
  myGender?: boolean;
};

const GenderSelector: React.FC<Props> = ({
  genders,
  selectedGender,
  setSelectedGender,
  myGender,
}) => {
  const styles = useStyles();

  if (myGender) {
    return (
      <Box display="flex">
        {genders.map(gender => (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={styles.signContainer}
            key={gender.text}
          >
            <Box
              className={[
                styles.signs,
                selectedGender === gender.text ? styles.selectedSign : '',
              ].join(' ')}
              onClick={() => setSelectedGender(gender.text)}
            >
              <span
                role="img"
                aria-label={gender.text}
                className={styles.emoji}
              >
                {gender.emoji}
              </span>
            </Box>
            <Typography align="center" className={styles.signHelperText}>
              {gender.text}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }
  return (
    <Box display="flex">
      {genders.map((gender, idx) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className={styles.signContainer}
          key={gender.text}
        >
          <Box
            className={[
              styles.signs,
              selectedGender === gender.text ? styles.selectedSign : '',
            ].join(' ')}
            onClick={() => setSelectedGender(gender.text)}
          >
            <span role="img" aria-label={gender.text} className={styles.emoji}>
              {gender.emoji}
            </span>
          </Box>
          <Typography align="center" className={styles.signHelperText}>
            {gender.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

GenderSelector.displayName = 'GenderSelector';

export default React.memo(GenderSelector);
