import React, { Fragment, useMemo } from 'react';
import {
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  Divider,
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import defaultCover from '../../../assets/img/defaultCover.jpg';
import defaultLogo from '../../../assets/img/defaultLogo.jpg';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import useStyle from './styles';

type Props = {
  coverUrl?: string;
  logoUrl?: string;
  title?: string;
  subtitle?: string;
  renderLogo?: React.ReactNode;
  renderTopIcons?: React.ReactNode;
  children?: React.ReactNode;
  disableCover?: boolean;
  onClick?: () => void;
  cardActions?: {
    type?: 'primary' | 'secondary';
    label?: string;
    onClick?: () => void;
    render?: () => React.ReactElement;
  }[];
  displayFlex?: boolean;
  label?: string;
};

const CardListWidget: React.FC<Props> = ({
  coverUrl,
  logoUrl,
  title,
  subtitle,
  renderLogo,
  renderTopIcons,
  onClick,
  children,
  cardActions,
  displayFlex,
  disableCover,
  label,
}) => {
  const style = useStyle();
  const globalStyle = useGlobalStyles();
  const { isMobile } = useMediaQuery();
  const renderAvatar = useMemo(() => {
    if (renderLogo) {
      return <Box className={style.logo}>{renderLogo}</Box>;
    }
    return (
      <CardMedia
        image={logoUrl || defaultLogo}
        title={title}
        className={style.logo}
      />
    );
  }, [logoUrl, renderLogo, style.logo, title]);

  const renderMain = useMemo(() => {
    return (
      <>
        <CardHeader
          title={title}
          subheader={subtitle}
          avatar={renderAvatar}
          className={[globalStyle.max100, displayFlex && style.alignSelf].join(
            ' ',
          )}
          titleTypographyProps={{
            variant: 'h6',
            className: [globalStyle.ellipsis, globalStyle.max75].join(' '),
          }}
          subheaderTypographyProps={{
            variant: 'body2',
            className: globalStyle.ellipsis,
          }}
        />
        <CardContent
          className={[style.content, displayFlex && style.grow].join(' ')}
        >
          {children}
        </CardContent>
      </>
    );
  }, [
    children,
    displayFlex,
    globalStyle.ellipsis,
    globalStyle.max100,
    globalStyle.max75,
    renderAvatar,
    style.alignSelf,
    style.content,
    style.grow,
    subtitle,
    title,
  ]);

  return (
    <Card
      variant="outlined"
      className={[style.card, displayFlex && style.container].join(' ')}
    >
      {!isMobile && !disableCover && (
        <CardMedia
          image={coverUrl || defaultCover}
          title={title}
          className={[
            style.cardMedia,
            renderTopIcons ? style.cardMediaWithIcons : '',
          ].join(' ')}
        />
      )}
      {label && <Box className={style.label}>{label}</Box>}
      <Box
        width="100%"
        className={[
          globalStyle.flexColumn,
          globalStyle.max100,
          globalStyle.overflowHidden,
        ].join(' ')}
        justifyContent="space-between"
      >
        {renderTopIcons && (
          <Box className={style.topIcons}>{renderTopIcons}</Box>
        )}
        {onClick ? (
          <CardActionArea
            onClick={onClick}
            className={[
              displayFlex && style.container,
              displayFlex && style.grow,
              style.cardActionArea,
            ].join(' ')}
          >
            {renderMain}
          </CardActionArea>
        ) : (
            renderMain
          )}
        {cardActions && (
          <Box>
            <Divider />
            <CardActions className={isMobile ? null : globalStyle.mh1}>
              {cardActions.map((action, i) => (
                <Fragment key={`action-${i}`}>
                  {action.render ? (
                    action.render()
                  ) : (
                      <Button
                        key={`action-${i}`}
                        size="small"
                        variant={
                          action.type === 'primary' ? 'contained' : 'outlined'
                        }
                        color="primary"
                        disableElevation
                        onClick={action.onClick}
                        fullWidth
                        className={style.mobileButton}
                      >
                        {action.label}
                      </Button>
                    )}
                </Fragment>
              ))}
            </CardActions>
          </Box>
        )}
      </Box>
    </Card>
  );
};

CardListWidget.displayName = 'CardListWidget';

export default React.memo(CardListWidget);
