import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  title: string;
};

const FooterMenuLink: React.FC<Props> = ({ to, title }) => {
  return (
    <>
      <Link to={to}>
        <Box p={0.5}>
          <Typography color="inherit" variant="body2" style={{color: 'white'}}>
            {title}
          </Typography>
        </Box>
      </Link>
    </>
  );
};

FooterMenuLink.displayName = 'FooterMenuLink';

export default React.memo(FooterMenuLink);
