import { fade, makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  logo: {
    height: 60,
    width: 60,
    border: `${theme.spacing(0.25)}px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.secondary.light + '!important',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
  },
  topIcons: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    backgroundColor: 'white',
    zIndex: 99,
    '& .active': {
      color: '#ffffff',
    },
  },
  card: {
    height: 180,
    width: '100%',
    position: 'relative',
    display: 'flex',
  },
  cardMedia: {
    height: 180,
    minWidth: 180,
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardActionArea: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    justifyContent: 'start',
  },
  cardMediaWithIcons: {
    boxShadow: '0 -40px 40px -15px rgba(0,0,0,0.5) inset',
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  grow: {
    width: '100%',
    flexGrow: 1,
  },
  alignSelf: {
    alignSelf: 'flex-start',
    padding: 30,
  },
  label: {
    position: 'absolute',
    width: 180,
    textAlign: 'center',
    height: 46,
    bottom: 0,
    background: fade(theme.palette.secondary.dark, 0.5),
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileButton: {
    '@media (max-width: 390px)': {
      fontSize: 11,
    },
    '@media (max-width: 340px)': {
      fontSize: 10,
    },
  },
}));
