import { StyleDictionary } from 'pdfmake/interfaces';

export const styles: StyleDictionary = {
  size30: {
    fontSize: 30,
  },
  size20: {
    fontSize: 20,
  },
  size15: {
    fontSize: 15,
  },
  size11: {
    fontSize: 11,
  },
  size8: {
    fontSize: 8,
  },
  size5: {
    fontSize: 5,
  },
  size0: {
    fontSize: 0,
  },
  bold: {
    bold: true,
  },
  center: {
    alignment: 'center',
  },
  underlined: {
    decoration: 'underline',
  },
  italics: {
    italics: true,
  },
};
