import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Card, Typography, Button, Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Spacer } from '../../atoms';
import { CardWidget, FormField, SearchBar } from '../../molecule';
import useStyle from '../EventForm/styles';
import { useGlobalStyles, useArtists, useMediaQuery } from '../../../hooks';
import { ARTIST_RULES } from '../../../constants';
import { Event } from '../../../store/types';
import { useToggleState } from '../../../hooks/ui/useToggleState';

type Props = {
  register: any;
  setSelectedArts: any;
  selectedArts?: Event['artists'];
};

const SelectArtists: React.FC<Props> = ({
  register,
  selectedArts,
  setSelectedArts,
}) => {
  const style = useStyle();
  const { isMobile } = useMediaQuery();
  const [open, toggleOpen] = useToggleState(false);
  const { relative, flexCenter } = useGlobalStyles();
  const [searchFor, setSearchFor] = useState('');
  const idsArts =
    selectedArts && Object.keys(selectedArts).filter(key => selectedArts[key]);
  const { filteredArtists, selectedArtists } = useArtists({
    searchFor,
    idsArts,
  });

  const [limit, setLimit] = useState<number>(3);
  const { t } = useTranslation();

  const sortedArtists = Array.from(
    new Set([...selectedArtists, ...filteredArtists]),
  );

  const handleChange = useCallback(
    (val: Event['artists']) => {
      setSelectedArts((s: Event['artists']) => ({
        ...s,
        ...val,
      }));
    },
    [setSelectedArts],
  );

  const renderArtistsItems = useMemo(() => {
    return sortedArtists.slice(0, limit).map(art => {
      return (
        <Grid item xs={12} sm={6} md={4} key={art.id} className={relative}>
          <Card variant="outlined" style={{ height: '100%' }}>
            <CardWidget
              title={art.name}
              coverUrl={art.coverUrl}
              logoUrl={art.logoUrl}
              hideCover={isMobile}
            >
              <FormField
                fieldClassName={style.FormField}
                name={'artists'}
                defaultGroups={selectedArts}
                label={t('general.definePermission')}
                type="radiogroup"
                langDomain="shared.artistsRules"
                onChange={handleChange}
                allowClear
                groups={[
                  {
                    id: `${art.id}`,
                    label: t('general.definePermission'),
                    options: ARTIST_RULES,
                  },
                ]}
                register={register}
              />
            </CardWidget>
          </Card>
        </Grid>
      );
    });
  }, [
    sortedArtists,
    limit,
    relative,
    isMobile,
    style.FormField,
    selectedArts,
    t,
    handleChange,
    register,
  ]);

  const renderArtists = useMemo(() => {
    return (
      <Grid container spacing={2}>
        {renderArtistsItems}
        {limit < sortedArtists.length && (
          <Grid item xs={12} className={flexCenter}>
            <Button
              size="small"
              onClick={() => setLimit(limit + 3)}
              variant="outlined"
            >
              {t('artist.showMore')}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }, [sortedArtists.length, flexCenter, limit, renderArtistsItems, t]);

  return (
    <>
      <>
        <Button onClick={toggleOpen}>
          {t('general.selectArtistInfo')}{' '}
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={open}>
          <Grid item md={12}>
            <Spacer height="5px" />
            <SearchBar
              label={t('artist.search')}
              searchText={searchFor}
              setSearchText={setSearchFor}
            />
            <Spacer height="15px" />
          </Grid>
          {sortedArtists.length ? (
            renderArtists
          ) : (
              <Typography variant="h4" align="center">
                {t('artist.notFound')}
              </Typography>
            )}
          <Spacer height="20px" />
        </Collapse>
      </>
    </>
  );
};

SelectArtists.displayName = 'SelectArtists';

export default React.memo(SelectArtists);
