import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/molecule';
import { RegisterEvent } from '../../components/organisms';

const CreateEventScreen = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('event.create')}>
      <RegisterEvent />
    </PageWrapper>
  );
};

export default CreateEventScreen;
