import React from 'react';
import { Grid } from '../../molecule';
import image from '../../../assets/img/event.jpg';

type Props = {};

const AdsRow: React.FC<Props> = () => {
  return (
    <Grid>
      <img src={image} className="advbox" alt="Adv" />
      <img src={image} className="advbox" alt="Adv" />
      <img src={image} className="advbox" alt="Adv" />
    </Grid>
  );
};

AdsRow.displayName = 'AdsRow';

export default React.memo(AdsRow);
