import React from 'react';
import { DropzoneWithCrop } from '../../molecule';

type Props = {
  cover: File[] | string;
  setCover: (files: File[]) => void;
};

const Form: React.FC<Props> = ({ cover, setCover }) => {
  const coverUrl =
    typeof cover === 'string'
      ? cover
      : cover[0] && URL.createObjectURL(cover[0]);

  return <DropzoneWithCrop setCover={setCover} coverUrl={coverUrl} />;
};

Form.displayName = 'PhotoAlbumCoverForm';

export default React.memo(Form);
