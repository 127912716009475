import { createSelector } from 'reselect';
import { firestoreState } from '..';
import { User } from '../../types';

type FilteredOrganizationSelector = {
  region?: string | undefined;
  searchFor?: string;
  musicStyles?: string[];
  userId?: string;
  isVerified?: boolean;
};

export const usersSelector = createSelector(
  firestoreState,
  (firestore): { [key: string]: User } => firestore?.data?.users_meta ?? {},
);

export const usersListSelector = createSelector(
  usersSelector,
  (users): User[] =>
    Object.entries(users || {}).map(([id, value]) => ({
      ...value,
      id,
    })),
);

export const photographersListSelector = createSelector(
  usersListSelector,
  (usersList): User[] =>
    usersList.filter(u => u?.roles?.includes('photographer')),
);

export const usersByNamesSelector = (names?: string[]) =>
  createSelector(
    usersListSelector,
    (usersList): User[] =>
      (names && usersList.filter(u => names.includes(u.displayName))) ?? [],
  );
