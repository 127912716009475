import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Close, FilterList } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../hooks';
import { useToggleState } from '../../../hooks/ui/useToggleState';

type Props = {
  renderFilter: () => JSX.Element;
  label: string;
};

const MobileFilterDialog: React.FC<Props> = ({ renderFilter, label }) => {
  const [open, toggleOpen] = useToggleState(false);
  const globalStyle = useGlobalStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box
        className={globalStyle.flex}
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
      >
        {label}
        <IconButton
          edge="end"
          color="inherit"
          onClick={toggleOpen}
          aria-label="close"
        >
          <FilterList />
        </IconButton>
      </Box>
      <Divider light />
      <Dialog open={open} onClose={toggleOpen} fullScreen>
        <AppBar variant="outlined">
          <Box
            paddingX={1}
            className={globalStyle.flex}
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingTop: 28 }}
          >
            {label}
            <IconButton
              edge="end"
              color="inherit"
              onClick={toggleOpen}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>
        </AppBar>
        <Box p={2} paddingTop={6}>
          {renderFilter()}
          <Grid container justify="space-around">
            <Grid item xs={12} sm={9}>
              <Button
                onClick={toggleOpen}
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
              >
                {t('shared.applyFilter')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Grid>
  );
};

MobileFilterDialog.displayName = 'MobileFilterDialog';

export default React.memo(MobileFilterDialog);
