import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSendEmail } from '../../../hooks/email';
import { Venue } from '../../../store/types';

type Props = {
  open: boolean;
  venue: Venue;
  onClose: () => void;
};

const VenueActivationDialog: React.FC<Props> = ({ open, venue, onClose }) => {
  const [email, setEmail] = useState('');
  const handleChange = useCallback(e => setEmail(e.target.value), []);
  const { sendEmail, loading, success, error } = useSendEmail();
  const handleSend = useCallback(() => {
    sendEmail({
      subject: 'Attiva la tua pagina!',
      content1: `<p>Ciao,</p>
				<p>tramite il link di seguito puoi associare la pagina del tuo club (${venue.name}) al tuo profilo!</p>
				<p>Ricorda che devi avere un account registrato per poter completare la procedura!</p>`,
      content2: 'Buon divertimento :)',
      cta: {
        url: `${window.location.origin}/venues/${
          venue.slug
        }/activate?key=${window.btoa(venue?.id ?? '')}`,
        text: 'Attiva ora la tua pagina',
      },
      shortText: 'Attiva la tua pagina su Tilllate!',
      text:
        'Ciao, tramite il link di seguito puoi associare la pagina evento al tuo profilo! Ricorda che devi avere un account registrato per poter completare la procedura!',
    });
  }, [sendEmail, venue]);

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [onClose, success]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invia link di attivazione</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          value={email}
          label="Email"
          onChange={handleChange}
        />
      </DialogContent>
      {error}
      <DialogActions>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          color="primary"
          onClick={handleSend}
        >
          {loading ? <CircularProgress /> : 'Invia'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VenueActivationDialog.displayName = 'VenueActivationDialog';

export default React.memo(VenueActivationDialog);
