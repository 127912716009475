import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    height: 'calc( env(safe-area-inset-top) + 62px)',
    backgroundColor: theme.palette.secondary.light,
  },
  mobileHeader: {
    position: 'fixed',
    height: 'calc( env(safe-area-inset-top) + 62px)',
    zIndex: 100,
  },
  closeBtn: { position: 'absolute', right: 0 },
  backBtn: { position: 'absolute', left: 0 },
}));
