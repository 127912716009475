import { Typography, Divider, Button } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginDialog } from '..';
import { useAuthentication, useGlobalStyles } from '../../../hooks';
import { Flex, Spacer } from '../../atoms';

type Props = {
  toggleDeleteMode: () => void;
};

const DeleteProfile: React.FC<Props> = ({ toggleDeleteMode }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { deleteProfile, deactivateProfile } = useAuthentication();
  const toggleConfirmation = useCallback(
    () => setShowConfirm(prev => !prev),
    [],
  );

  const toggleLoginDialog = useCallback(() => setShowLogin(prev => !prev), []);

  const handleDeleteProfile = useCallback(() => {
    toggleLoginDialog();
  }, [toggleLoginDialog]);

  const renderActions = useMemo(() => {
    if (showConfirm) {
      return (
        <Flex
          flexDirection="column"
          height="130px"
          justifyContent="space-around"
        >
          <Button variant="outlined" size="large" onClick={toggleDeleteMode}>
            {t('profile.dontDelete')}
          </Button>
          <Button
            variant="text"
            className={globalStyle.danger}
            size="small"
            onClick={deactivateProfile}
          >
            {t('profile.wantDeactivate')}
          </Button>
          <Button variant="text" size="small" onClick={handleDeleteProfile}>
            {t('profile.deleteForEver')}
          </Button>
        </Flex>
      );
    }
    return (
      <Flex flexDirection="column" height="100px" justifyContent="space-around">
        <Button variant="outlined" size="large" onClick={toggleDeleteMode}>
          {t('profile.dontDelete')}
        </Button>
        <Button
          variant="text"
          className={globalStyle.danger}
          size="small"
          onClick={toggleConfirmation}
        >
          {t('profile.yesDelete')}
        </Button>
      </Flex>
    );
  }, [
    deactivateProfile,
    globalStyle.danger,
    handleDeleteProfile,
    showConfirm,
    t,
    toggleConfirmation,
    toggleDeleteMode,
  ]);

  return (
    <>
      <LoginDialog
        open={showLogin}
        onClose={toggleLoginDialog}
        onLoginCallback={deleteProfile}
        showRegister={false}
        title={t('shared.login')}
        text={t('profile.reauthenticate')}
      />
      <Typography variant="h5">{t('profile.deleteProfile')}</Typography>
      <Divider />
      <Spacer height="30px" />
      <Typography variant="h2" align="center" color="error">
        {t('profile.warning')}
      </Typography>
      <Divider />
      <Spacer height="30px" />
      <Typography variant="body2" align="center" color="error">
        {showConfirm ? t('profile.deactivateInstead') : t('profile.sureDelete')}
      </Typography>
      <Spacer height="30px" />
      <Divider />
      <Spacer height="30px" />
      {renderActions}
      <Spacer height="60px" />
    </>
  );
};

DeleteProfile.displayName = 'DeleteProfile';

export default React.memo(DeleteProfile);
