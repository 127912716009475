import React from 'react';
import { PageWrapper } from '../../components/molecule';
import { RegisterArtist } from '../../components/organisms';

const RegisterArtistScreen = () => {
  return (
    <PageWrapper
      title="Crea Artist"
      subtitle="Proin pretium, leo ac pellentesque mollis, felis nunc ultrices eros, sed gravida augue augue mollis justo. Donec vitae sapien ut libero venenatis faucibus. Donec mi odio, faucibus at, scelerisque quis, convallis in, nisi. Duis lobortis massa imperdiet quam. Sed aliquam ultrices mauris. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam."
    >
      <RegisterArtist />
    </PageWrapper>
  );
};

export default RegisterArtistScreen;
