import { makeStyles } from '@material-ui/core';

export default makeStyles({
  cover: {
    height: 280,
    width: '100%',
    objectFit: 'cover',
  },
  coverMobile: {
    height: 170,
    width: '100%',
    objectFit: 'cover',
  },
});
