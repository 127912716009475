import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEvents, useOrganizations } from '../../../hooks';
import { Spacer } from '../../atoms';
import { Event } from '../../../store/types';
import {
  DividerWithSpace,
  EventHeaderWidget,
  GoogleMap,
  LabeledBox,
  PageHeaderWidget,
} from '../../molecule';

type Props = {
  event?: Event;
};

const EventDetails: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  const { venueId, slug, venue } = event || {};
  const { getEventsByVenue } = useEvents();
  const [comingEvents, setComingEvents] = useState<Event[]>([]);
  const { organizations } = event || {};
  const history = useHistory();
  const { organizations: allOrganizations } = useOrganizations();
  const goTo = useCallback((to: string) => history.push(to), [history]);
  const renderField = useCallback(
    (title, content) => (
      <>
        <Typography>
          <b>{title}: </b>
          <br />
          {content}
        </Typography>
        <Spacer height="10px" />
        <Divider />
        <Spacer height="10px" />
      </>
    ),
    [],
  );
  const organizationsIds = useMemo(
    () => organizations && Object.keys(organizations),
    [organizations],
  );

  useEffect(() => {
    if (venueId && slug) {
      const eventsOfVenue = getEventsByVenue(venueId);
      setComingEvents(
        eventsOfVenue.filter(
          e =>
            e.slug !== slug &&
            e.date &&
            new Date(e.date).getTime() > new Date().getTime(),
        ),
      );
    }
  }, [getEventsByVenue, slug, venueId]);

  return (
    <>
      <LabeledBox
        label={t('event.clubInfo')}
        boxProps={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <>
          {venue && (
            <PageHeaderWidget
              page={venue}
              onClick={() => goTo(`/venues/${venue?.slug}`)}
            />
          )}
          <DividerWithSpace space="10px" />
          {venue?.fullAddress && (
            <GoogleMap location={JSON.parse(venue?.fullAddress)} />
          )}

          <Spacer height="10px" />
          {renderField(t('event.venue'), venue?.name)}
          {renderField(t('venue.address'), venue?.address)}
          {renderField(t('venue.city'), venue?.city)}
          {renderField(t('venue.region'), t(`shared.regions.${venue?.region}`))}
        </>
      </LabeledBox>
      <Spacer height="20px" />
      {!!organizationsIds?.length && allOrganizations && (
        <LabeledBox
          label={t('event.associatedOrganizations')}
          boxProps={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            {organizationsIds.map((id, i) => (
              <div key={`org-${id}`}>
                <PageHeaderWidget
                  page={allOrganizations[id]}
                  onClick={() =>
                    goTo(`/organizations/${allOrganizations?.[id]?.slug ?? id}`)
                  }
                />
                {i + 1 !== organizationsIds.length && (
                  <DividerWithSpace space="10px" />
                )}
              </div>
            ))}
          </>
        </LabeledBox>
      )}
      <Spacer height="20px" />
      {!!comingEvents.length && (
        <LabeledBox
          label={t('event.comingEvents')}
          boxProps={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <>
            {comingEvents.map((e, i) => (
              <Fragment key={i}>
                <EventHeaderWidget
                  event={e}
                  onClick={() => goTo(`/events/${e.slug}`)}
                />
                {i + 1 !== comingEvents.length && (
                  <DividerWithSpace space="10px" />
                )}
              </Fragment>
            ))}
          </>
        </LabeledBox>
      )}
    </>
  );
};

EventDetails.displayName = 'EventDetails';

export default React.memo(EventDetails);
