import { FilterOptionType } from '../../store/types';

export const createFilterOptionsArray = <
  T extends {
    name: string;
    id?: string;
  }
>(
  list: T[],
): FilterOptionType[] => {
  if (!list.length) {
    return [];
  }
  const results: FilterOptionType[] = [];
  list.forEach(values => {
    if (values?.id) {
      results.push({ id: values.id, name: values.name });
    }
  });
  return results;
};
