import React, { useState, useEffect } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import ITit from 'dayjs/locale/it';
import { Controller } from 'react-hook-form';

type Props = {
  error?: string | null;
  label: string;
  defaultOptions?: string[];
  control: any;
  name: string;
  register: any;
  required?: boolean;
  fieldClassName?: string;
  cbFunction?: Function;
  minDate?: Date;
  maxDate?: Date;
  defaultValue?: string;
};

const DateField: React.FC<Props> = ({
  error,
  label,
  control,
  name,
  register,
  required,
  fieldClassName,
  cbFunction,
  minDate,
  maxDate,
  defaultValue = null,
}) => {
  const [selectedDate, handleDateChange] = useState<any>();

  useEffect(() => {
    if (cbFunction) {
      cbFunction(selectedDate);
    }
  }, [selectedDate, cbFunction]);

  return (
    <MuiPickersUtilsProvider utils={DayJsUtils} locale={ITit}>
      <Controller
        name={name}
        control={control}
        onChange={([date]) => {
          handleDateChange(date.toString());
          return date.toString();
        }}
        format="D MMMM YYYY"
        className={fieldClassName}
        as={
          <DatePicker
            inputProps={{
              ref: register({ required }),
            }}
            inputVariant="outlined"
            size="small"
            fullWidth
            format="D MMMM YYYY"
            value={selectedDate}
            onChange={e => handleDateChange(e?.toString())}
            error={!!error}
            helperText={error}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
          />
        }
      />
    </MuiPickersUtilsProvider>
  );
};

DateField.displayName = 'DateField';

export default React.memo(DateField);
